<div class="container __cart-container" *ngIf="!emptyErrorPage">
    <!-- <div class="row loader-wishlist" *ngIf="loader">
        <div class="col-md-12" style="text-align: center;">
            <div class="lds-ring">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    </div> -->
    <div class="row">
        <div class="col-12 col-lg-12 col-md-12">
            <label class="__cart-title">{{'customWishlist.savedItems' | cxTranslate}}</label>
            <hr class="__cart-hr">
        </div>
    </div>
    <div class="row">
        <div class="col-7 col-lg-6 col-md-6">
            <label class="__cart-sub-title">{{'customWishlist.product' | cxTranslate}}</label>
        </div>
        <div class="col-2 col-lg-4 col-md-4">
            <label class="__cart-sub-title">{{'customWishlist.type' | cxTranslate}}</label>
        </div>
        <div class="col-3 col-lg-2 col-md-2">
            <label class="__cart-sub-title">{{'customWishlist.remove' | cxTranslate}}</label>
        </div>
    </div>
    <!-- <div class="card __cart-list"> -->
        <div class="__cart-list" *ngFor="let wishListItems of products">
        <div class="row align-items-center">
        <div class="col-3 col-lg-2 col-md-2">
            <div class="__cart-imag-bg text-center p-3">
            <img (click)="redirectPDP(wishListItems.product.url, wishListItems.product.code)" style="cursor:pointer" class="__cart-image tab-pane" src="{{domainUrl + wishListItems?.product.productImage}}" alt="">
            </div>
        </div>
        <div class="col-4 col-lg-4 col-md-4">
            <label (click)="redirectPDP(wishListItems.product.url, wishListItems.product.code)" style="cursor:pointer" class="__cart-section-title tab-pane">{{wishListItems?.product.name}}</label>
        </div>
        <div class="col-2 col-lg-4 col-md-4">
            <label class="__cart-section-qty tab-pane">{{wishListItems?.product.packageType}}</label>
        </div>
        <div class="col-3 col-lg-2 col-md-2">
            <img class="__cart-section-icon tab-pane" src="/assets/cart-delete.svg" (click) = "deleteProduct(wishListItems?.product.code)">
        </div>
    <!-- </div> -->
</div>
</div>
    <div class="row">
        <div class="col-12 col-lg-12 col-md-12">
            <button (click)="redirectHome()" type="button" class="btn btn-primary">{{'customWishlist.continue' | cxTranslate}}
                <!-- <i aria-hidden="true" class="fa fa-chevron-right __footer-btn-right-arrow"></i> -->
            </button>
        </div>
    </div>

</div>
<!-- Empty cart template -->
<div class="container" *ngIf="emptyErrorPage">
    <div class="cart">
        <div class="cart-body __cart-body-empty-cart">
            <img src="assets/cart-empty.png">
            <hr class="__cart-empty-hr">
            <label class="cart-empty-title">{{'customWishlist.empty' | cxTranslate}}</label><br>
            <span class="cart-empty-text">{{'customWishlist.noItemsFound' | cxTranslate}}</span>
            <br>
            <button (click)="redirectHome()" class="btn btn-primary" style="display: inline; margin-top: 20px;" type="button">{{'customWishlist.continue' | cxTranslate}}
                <!-- <i aria-hidden="true" class="fa fa-chevron-right __btn-right-arrow"></i> -->
            </button>
        </div>
    </div>
</div>