<div class="product-summary bg-modal-header-bg">
    <!-- new design with grid system for  sample request -->
    <ng-container *ngIf="displaySummaryFlag">
        <div class="row">
          <div class="col-md-12">
            <h3>{{'customProductSummary.requestSamples' | cxTranslate}}</h3>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <p>{{'customProductSummary.howMuchWould' | cxTranslate}}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-auto pb-2">
            <select (change)="selectChangeSample($event)">
              <option *ngFor="let sample of filterSample" [value]="sample" [selected]="sample[0]">{{sample}}</option>
            </select>
            <select class="units" (change)="selectChangeUnit($event)">
              <option *ngFor="let unit of units" [value]="unit.codeVal" [selected]="unit.codeVal == true">{{unit.code}}</option>
            </select>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 py-2">
              <p>{{'customProductSummary.ifYouRequire' | cxTranslate}} <span class="d-inline-block d-md-block"></span>{{'customProductSummary.infineumAccountManager' | cxTranslate}}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-10">
            <div class="cta">
                <button (click)="addToCart(requestModelAddToCart)" class="btn btn-icon shadow-none icon-drop text-white w-100 mb-3" type="button">
                  {{'customProductSummary.addToSampleRequest' | cxTranslate}}
                </button>
                <button id="wishListBtn" class='btn btn-icon icon-star shadow-none border-darkgrey color-darkgrey bg-transparent w-100' type="button" (click)="addToWishList()">{{isAdded}}
                    {{wishListBtnTxt}}
                </button> 
            </div>
            <!--
            <button (click)="addToCart(requestModelAddToCart)" class="__sample-request-btn" type="button">
              <img src='assets/SamplesIcon.png'>
              ADD TO SAMPLE REQUEST
          </button>-->
          </div>
        </div>
        <!--
        <div class="row">
          <div class="col-md-12">
            
            <br>
            <button id="wishListBtn" class='__pdp-save_button' type="button" (click)="addToWishList()">
              <i class='far fa-star fa-lg whishlist' id="st1"></i> <span>{{wishListBtnTxt}}</span>
            </button> 
          </div>
        </div>-->
    </ng-container>
    <!-- new design with grid system for not available sample reques -->
    <ng-container *ngIf="!displaySummaryFlag">
        <div class="row">
          <div class="col-md-12">
            <h3>{{'customProductSummary.notAvailable' | cxTranslate}}</h3>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <p>{{'customProductSummary.pleaseMerge' | cxTranslate}}<span class="d-inline-block d-md-block"></span> {{'customProductSummary.discusYourRequirements' | cxTranslate}}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <button (click) = "navigateToContact()" class="need-info-contact-btn cursorPointer" type="button">{{'customProductSummary.contact' | cxTranslate}}
              <i aria-hidden="true" class="fa fa-chevron-right __btn-right-arrow"></i>
            </button>
          </div>
        </div>
  </ng-container>

</div>


<!-- add to cart request modal template -->
<ng-template #requestModelAddToCart let-modal>
    <div class="modal-header">
        <h4 class="modal-title">{{'customProductSummary.sampleAddedToYour' | cxTranslate}}</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true"><img  src="/assets/closeDialog.svg"></span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row d-none d-md-flex">
            <div class="col-12 col-md-6">
                  <label class="__cart-sub-title">{{'customProductSummary.product' | cxTranslate}}</label>
              </div>
              <div class="col-12 col-md-4">
                  <label class="__cart-sub-title">{{'customProductSummary.quantityRequest' | cxTranslate}}</label>
              </div>
              <div class="col-12 col-md-2">
                  <label class="__cart-sub-title">{{'customProductSummary.remove' | cxTranslate}}</label>
              </div>
        </div>
        <div class="__cart-list">
            <div class="row align-items-center">
                <div class="col-12 col-md-3">
                    <div class="__cart-imag-bg text-center">
                        <img (click) = "changeAddToCart()" class="__cart-image p-4" src="{{cartProductImageUrl}}" alt="" role="button">
                    </div>
                </div>
                <div class="col-12 col-md-3">
                      <label (click) = "changeAddToCart()" class="__cart-section-title" role="button">{{cartProductName}}</label>
                </div>
                <div class="col-12 col-md-2">
                      <label class="__cart-section-qty">{{cartQuantityAdded}}</label>
                </div>
                <div class="col-12 col-md-2 d-none d-md-flex">
                    <button (click) = "changeAddToCart()" class="btn btn-primary btn-ghost-inverse btn-plain" type="button">{{'customProductSummary.change' | cxTranslate}}</button>
                </div>
                <div class="col-12 col-md-2 d-none d-md-flex">
                      <img (click) = "deleteCart(cartId, entryNumber)" class="__cart-section-icon" src="assets/cart-delete.svg" role="button">
                </div>
                <div class="cta d-flex d-md-none">
                     <button (click) = "changeAddToCart()" class="btn btn-primary btn-ghost-inverse btn-plain" type="button">{{'customProductSummary.change' | cxTranslate}}</button>
                    <img (click) = "deleteCart(cartId, entryNumber)" class="__cart-section-icon" src="assets/cart-delete.svg" role="button">
                </div>
            </div>
        </div><!-- .__cart-list -->
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <hr class="__cart-hr">
                <label class="__cart-sub-title">{{'customProductSummary.infineumReserves' | cxTranslate}}</label>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button (click)="redirectCheckout()" class="__clear">{{'customProductSummary.checkout' | cxTranslate}}</button>
        <button (click)="redirectToCartPage()" class="__clear2">{{'customProductSummary.viewSampleRequest' | cxTranslate}}</button>
        <a (click)="redirectHome()">{{'customProductSummary.contineuBrowsing' | cxTranslate}}</a>
    </div>
</ng-template>

<!--
    <div class="container p-0">
              <div class="__card-title">
                <div class="row">
                  <div class="col-md-10">
                    <span class="__cart-title">{{'customProductSummary.sampleAddedToYour' | cxTranslate}}
                    </span>
                  </div>
                  <div  class="col-md-2">
                    <button class="__claim-dialog-close-btn" aria-label="Close" (click)="modal.dismiss('Cross click')" type="button">
                      <img  src="/assets/closeDialog.svg">
                    </button>
                  </div>
                </div>
              <div class="row" style="margin-top: 38px;">
                  <div class="col-lg-6 col-md-6">
                      <label class="__cart-sub-title">{{'customProductSummary.product' | cxTranslate}}</label>
                  </div>
                  <div class="col-lg-4 col-md-4">
                      <label class="__cart-sub-title">{{'customProductSummary.quantityRequest' | cxTranslate}}</label>
                  </div>
                  <div class="col-lg-2 col-md-2">
                      <label class="__cart-sub-title">{{'customProductSummary.remove' | cxTranslate}}</label>
                  </div>
              </div>
              <div class="row">
                <div class="col-lg-12 col-md-12">
                    <hr class="__cart-hr">
                </div>
            </div>
            <div class="__cart-list">
              <div class="row align-items-center">
                  <div class="col-lg-2 col-md-2">
                      <div class="__cart-imag-bg">
                      <img (click) = "changeAddToCart()" style="cursor: pointer;" class="__cart-image" src="{{cartProductImageUrl}}" alt="">
                      </div>
                  </div>
                  <div class="col-lg-4 col-md-4">
                      <label (click) = "changeAddToCart()" style="cursor: pointer;" class="__cart-section-title">{{cartProductName}}</label>
                  </div>
                  <div class="col-lg-2 col-md-2">
                      <label class="__cart-section-qty">{{cartQuantityAdded}}</label>
                  </div>
                  
                  <div class="col-lg-2 col-md-2">
                      <button (click) = "changeAddToCart()" class="__cart-section-btn" type="button">{{'customProductSummary.change' | cxTranslate}}</button>
                  </div>
                  <div class="col-lg-2 col-md-2">
                      <img (click) = "deleteCart(cartId, entryNumber)" class="__cart-section-icon" src="assets/cart-delete.jpg">
                  </div>
                </div>
            </div>
          <div class="row" style="margin-top: -33px;">
            <div class="col-lg-12 col-md-12">
                <hr class="__cart-hr">
                <label class="__cart-sub-title">{{'customProductSummary.infineumReserves' | cxTranslate}}</label>
            </div>
        </div>
               
              </div>
            <div class="modal-footer">
                <button (click)="redirectCheckout()" class="__clear" style="margin-right: 20px;">{{'customProductSummary.checkout' | cxTranslate}}
                </button>
                <button (click)="redirectToCartPage()" class="__clear2">{{'customProductSummary.viewSampleRequest' | cxTranslate}}
                </button>
                <a style="cursor:pointer" (click)="redirectHome()">{{'customProductSummary.contineuBrowsing' | cxTranslate}} ></a>
            </div>
    </div>
</ng-template>-->

