import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UserServiceService } from '../APIcall/user-service.service';
import { ProductDetailsService } from '../APIcall/product-details.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-update-user',
  templateUrl: './update-user.component.html',
  styleUrls: ['./update-user.component.scss']
})
export class UpdateUserComponent implements OnInit {

  userDetails: any = {};
  jobTitle: any = "";
  phone1: any = "";
  phone2:any = "";
  __styleToaster: string = "none";
  constructor(private userService: UserServiceService, private ref: ChangeDetectorRef, private productDetailsService: ProductDetailsService, private router: Router) { }

  ngOnInit(): void {
    this.productDetailsService.changeBreadcrumb({"breadcumTitle" : "updateProfile", "categoryName": "", "productName": ""});
    this.userService.getUserDetails().then((user) => {
      this.userDetails = user;
      this.userService.getUserProfile(user["uid"]).then((userProfile) => {
        this.jobTitle = userProfile["jobTitle"];
        this.phone2 = userProfile["phone1"];
        this.phone1 = userProfile["cellphone"];
        this.ref.detectChanges();
      })
      this.ref.detectChanges();
    })
  }

  updateProfile(jobTitle, phone1, phone2) {
    this.userService.updateProfile(this.userDetails.uid, {jobTitle:jobTitle, phone1:phone2, cellphone:phone1}).then((res) => {
      console.log("update profile res:::::::::::;",res)
      this.__styleToaster = "block"
          setTimeout(() => {
            this.__styleToaster = "none"
            this.ref.detectChanges();
            this.navigateToHome();
          }, 8000);
        this.ref.detectChanges();
    }).catch((err) => {
      console.log("error in update profile:::::::::", err)
    })
  }

  navigateToHome() {
    this.router.navigate(['/']);
  }
}
