<div class="container-fluid video" > 
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-6" >
          <!--iframe class="iframe" src="https://player.vimeo.com/video/358780086?color=fd3e52&title=0&byline=0&portrait=0" width="100%" height="100%" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe-->
          <!--img src="../../assets/video.png" style="max-width: 100%;"-->
          <div class="iframe"></div>
        </div>
        <div class="col-12 col-md-1"></div>
        <div class="col-12 col-md-5 info">
            <h3><strong>{{ videoTitle }}</strong> {{ videoTitleEnd }}</h3>
            <p class="description"> {{ videoText }} </p>
        </div>
      </div>
    </div>
  </div>