<div class="container news">
    <div class="row text-center">
        <div class="col">
            <h3>{{'customLatestNews.latestNews' | cxTranslate}} <strong>{{'customLatestNews.insight' | cxTranslate}}</strong></h3>
        </div>
    </div>
    <div class="row">
        <div *ngFor="let news of newsComponentList" class="col-12 col-md-6 col-lg-3">
            <div class="card plain">
              <img class="card-img-top" src="{{news.image}}" alt="{{news.title}}" />
              <div class="card-body">
                <a href="{{news.href}}" target="_blank" class="stretched-link">{{news.title}}</a>
                <p class="card-text">{{news.text}}</p>
              </div>
            </div>
        </div>
    </div>
</div>