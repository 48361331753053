import { NgModule } from '@angular/core';
import { translationChunksConfig, translations } from "@spartacus/assets";
import { FeaturesConfig, I18nConfig, OccConfig, provideConfig, RoutingConfig, SiteContextConfig } from "@spartacus/core";
import { defaultCmsContentProviders, layoutConfig, mediaConfig } from "@spartacus/storefront";
import {environment} from '../../environments/environment';
import { CheckoutStepType } from '@spartacus/checkout/base/root';

export const translationOverwrites = {
  en: {
    common: {
      searchBox: {
        "placeholder": "Search here...",
        "help": {
            "insufficientChars": "Please type more characters",
            "noMatch": "We could not find any results",
            "exactMatch": "{{ term }}",
            "empty": "Ask us anything"
        }
      },
    },
  },
  zh: {
    common: {
      searchBox: {
        "placeholder": "搜索产品名称",
        "help": {
          "insufficientChars": "Please type more characters",
          "noMatch": "We could not find any results",
          "exactMatch": "{{ term }}",
          "empty": "Ask us anything"
        }
      },
    },
  },
};

@NgModule({
  declarations: [],
  imports: [
  ],
  providers: [provideConfig(layoutConfig), provideConfig(mediaConfig), ...defaultCmsContentProviders, provideConfig(<OccConfig>{
    backend: {
      occ: {
        baseUrl: environment.baseUrl,
        prefix: '/infineumwebservices/v2/',
        endpoints: {
          user: 'orgUsers/${userId}',
          productSearch:
          'users/userIdPlaceholder/products/search?fields=products(FULL)%2Cfacets%2Cbreadcrumbs%2Cpagination(DEFAULT)%2Csorts(DEFAULT)%2CfreeTextSearch%2CcurrentQuery&query=${query}&pageSize=10&sort=relevance',
      
          addEntries: 'orgUsers/${userId}/carts/${cartId}/entries'
        }
      },
    },
  }), provideConfig(<SiteContextConfig>{
    context: {
      urlParameters: ['baseSite', 'language', 'currency'],
      baseSite: ['infineum'],
      currency: ['USD'],
      language: ['en','zh'],
    },
  }), provideConfig(<I18nConfig>{
    i18n: {
      resources: translations,
      chunks: {
        common: [
          'common',
          'spinner',
          'searchBox',
          'navigation',
          'sorting',
          'httpHandlers',
          'pageMetaResolver',
          'miniCart',
          'skipLink',
          'formErrors',
          'errorHandlers',
          'carousel',
          'assistiveMessage',
          'passwordVisibility',
        ],
        address: [
          'addressForm',
          'addressBook',
          'addressCard',
          'addressSuggestion',
          'addressMessages',
        ],
        payment: [
          'paymentForm',
          'paymentMethods',
          'paymentCard',
          'paymentTypes',
          'paymentMessages',
        ],
        myAccount: [
          'orderDetails',
          'orderHistory',
          'closeAccount',
          'updatePasswordForm',
          'updateProfileForm',
          'consentManagementForm',
          'myCoupons',
          'notificationPreference',
          'myInterests',
          'AccountOrderHistoryTabContainer',
          'returnRequestList',
          'returnRequest',
        ],
        pwa: ['pwa'],
        product: [
          'productDetails',
          'productList',
          'productFacetNavigation',
          'productCarousel',
          'productSummary',
          'productReview',
          'addToCart',
          'addToWishList',
          'CMSTabParagraphContainer',
          'stockNotification',
          'TabPanelContainer',
          'itemCounter',
          'productView',
        ],
        user: ['anonymousConsents', 'loginRegister', 'checkoutLogin', 'authMessages'],
        video: ['player'],
        claims : ["claims"]
      },
      fallbackLang: 'en',
      backend: {
        loadPath: `assets/i18n/{{lng}}/{{ns}}.json`
      }
    },
  }), provideConfig(<FeaturesConfig>{
    features: {
      level: '3.4'
    }
  }), provideConfig (<RoutingConfig>{
    routing: {
      protected: true
  },
  }), provideConfig({
    checkout: {
      steps: [
        {
          id: 'deliveryAddress',
          name: 'checkoutProgress.deliveryAddress',
          routeName: 'checkoutDeliveryAddress',
          type: [CheckoutStepType.DELIVERY_ADDRESS],
        },
        {
          id: 'deliveryMode',
          name: 'checkoutProgress.deliveryMode',
          routeName: 'checkoutDeliveryMode',
          type: [CheckoutStepType.DELIVERY_MODE],
        },
        {
          id: 'paymentDetails',
          name: 'checkoutProgress.paymentDetails',
          routeName: 'checkoutPaymentDetails',
          type: [CheckoutStepType.PAYMENT_DETAILS],
        },
        {
          id: 'reviewOrder',
          name: 'checkoutProgress.reviewOrder',
          routeName: 'checkoutReviewOrder',
          type: [CheckoutStepType.REVIEW_ORDER],
        },
      ],
    },
  }), provideConfig({
    routing: {
      routes: {
        checkout: {
          paths: ['checkout'],
        },
        checkoutDeliveryAddress: {
          paths: ['checkout/shipping-address']
        },
        checkoutDeliveryMode: {
          paths: ['checkout/delivery-mode']
        },
        checkoutPaymentDetails: {
          paths: ['checkout/payment-details']
        },
        checkoutReviewOrder: {
          paths: ['checkout/review-order']
        }
      },
    },
  }),
  provideConfig({
    i18n: { resources: translationOverwrites },
  }),
]
})
export class SpartacusConfigurationModule { 

}
