<div style="background-color:yellow; padding: 20px;text-align: center;margin-bottom: 30px;" class="no-print">
    <span style="font-family:formata-medium;">The yellow area will NOT appear on printout</span>
    <button (click)="printThisPage()" style="margin-left:35%;font-family:formata-medium;">Print Customer Spec</button>
</div>
<div id="print-window-content" style="margin: 0% 2% 0% 2%;">
    <div class="container" style="margin: 0%; padding: 0%;">
        <div class="row">
            <div class="col-6 col-md-8 col-lg-8">
                <span style="margin:0%; font-family: formata-medium;"><h2 style="font-family:formata-medium;">Customer Specification</h2></span>
                
            </div>
                <div class="col-6 col-md-4 col-lg-4 icon-bottom icon-position">
                    <img src="/assets/Infineum_Color_Logo.jpg" style="height: 60px;"/><br>
                    <div style="font-family: formata-regular;">Printed On : <b style="font-family: formata-medium;">{{printDate}}</b></div>
                    <div><b style="font-family: formata-regular;">Proprietary Information</b></div>
                
            </div>
            

        </div>
        
    </div>
    
    <div class="row __margin-table">
        <div class="col-12 col-md-12 col-lg-12">
    <table>
        <tr>
            <td><b style="font-family: formata-regular;">Customer</b>
            </td>
            <td style="padding-left:20px; font-family: 'formata-regular';">{{customerSpecData?.CustomerSpec?.CustomerSpecification?.customerName}}</td>

        </tr>
        <tr>
            <td><b style="font-family: formata-regular;">Customer Designation</b></td>
            <td style="padding-left:20px; font-family: 'formata-regular';">{{customerSpecData?.CustomerSpec?.CustomerSpecification?.customerDesignation}}</td>

        </tr>
        <tr>
            <td><b style="font-family: formata-regular;">Product Description</b></td>
            
                <td style="padding-left:20px; font-family: 'formata-regular';" *ngFor="let descLine of customerSpecData?.CustomerSpec?.CustomerSpecification?.ProductDescription">{{descLine.line}}</td>
          


        </tr>
        <tr>
            <td><b style="font-family: formata-regular;">Recommended Retest Interval</b>
            </td>
            <td style="padding-left:20px; font-family: 'formata-regular';">
                {{customerSpecData?.CustomerSpec?.CustomerSpecification?.retestInterval}}</td>

        </tr>
    </table>
</div>
</div>
    <br/>
    <div *ngIf="customerSpecData?.CustomerSpec?.CustomerSpecification?.Specifications?.ProductTests.length > 0 && customerSpecData?.CustomerSpec?.CustomerSpecification?.Specifications?.ProductTests[0] != null">
        <h3><b style="font-family: formata-medium;font-size: 14px;">SPECIFICATIONS - Test on Products</b></h3>
        <table border="1" style="width:100%">
            <tr bgcolor="#F5F5F5">
                <th style="text-align:left; width:40%; font-family: 'formata-regular';font-size: 12px;">Properties</th>
                <th style="text-align:left; width:10%; font-family: 'formata-regular';font-size: 12px;">Units</th>
                <th style="text-align:left; width:10%; font-family: 'formata-regular';font-size: 12px;">Min</th>
                <th style="text-align:left;width:10%; font-family: 'formata-regular';font-size: 12px;">Target</th>
                <th style="text-align:left;width:10%; font-family: 'formata-regular';font-size: 12px;">Max</th>
                <th style="text-align:left;width:20%; font-family: 'formata-regular';font-size: 12px;">Test Methods</th>
            </tr>
            <tr *ngFor="let data of customerSpecData?.CustomerSpec?.CustomerSpecification?.Specifications?.ProductTests">
                <td style="text-align:left; width:40%; font-family: 'formata-regular';font-size: 12px;">{{data?.property}}</td>
                <td style="text-align:left; width:10%; font-family: 'formata-regular';font-size: 12px;">{{data?.units}}</td>
                <td style="text-align:left; width:10%; font-family: 'formata-regular';font-size: 12px;">{{data?.min}}</td>
                <td style="text-align:left; width:10%; font-family: 'formata-regular';font-size: 12px;">{{data?.target}}</td>
                <td style="text-align:left; width:10%; font-family: 'formata-regular';font-size: 12px;">{{data?.max}}</td>
                <td style="text-align:left; width:20%; font-family: 'formata-regular';font-size: 12px;">{{data?.testMethod}}</td>
            </tr>
        </table>
    </div>
    <div *ngIf="customerSpecData?.CustomerSpec?.CustomerSpecification?.Specifications?.BlendTests.length > 0 && customerSpecData?.CustomerSpec?.CustomerSpecification?.Specifications?.BlendTests[0] != null">
        <br/>
        <h3><b style="font-family: formata-medium;font-size: 14px;">SPECIFICATIONS - Test on Blends</b></h3>{{customerSpecData?.CustomerSpec?.CustomerSpecification?.Specifications?.BlendTests.length}}
        <table border="1" style="width:100%">
            <tr bgcolor="#F5F5F5">
                <th style="text-align:left; width:40%; font-family: 'formata-regular';font-size: 12px;">Properties</th>
                <th style="text-align:left; width:10%; font-family: 'formata-regular';font-size: 12px;">Units</th>
                <th style="text-align:left; width:10%; font-family: 'formata-regular';font-size: 12px;">Min</th>
                <th style="text-align:left;width:10%; font-family: 'formata-regular';font-size: 12px;">Target</th>
                <th style="text-align:left;width:10%; font-family: 'formata-regular';font-size: 12px;">Max</th>
                <th style="text-align:left;width:20%; font-family: 'formata-regular';font-size: 12px;">Test Methods</th>
            </tr>
            <tr *ngFor="let data of customerSpecData?.CustomerSpec?.CustomerSpecification?.Specifications?.BlendTests">
                <td style="text-align:left; width:40%; font-family: 'formata-regular';font-size: 12px;">{{data?.property}}</td>
                <td style="text-align:left; width:10%; font-family: 'formata-regular';font-size: 12px;">{{data?.units}}</td>
                <td style="text-align:left; width:10%; font-family: 'formata-regular';font-size: 12px;">{{data?.min}}</td>
                <td style="text-align:left; width:10%; font-family: 'formata-regular';font-size: 12px;">{{data?.target}}</td>
                <td style="text-align:left; width:10%; font-family: 'formata-regular';font-size: 12px;">{{data?.max}}</td>
                <td style="text-align:left; width:20%; font-family: 'formata-regular';font-size: 12px;">{{data?.testMethod}}</td>
            </tr>
        </table>
    </div>

    <div *ngIf="customerSpecData?.CustomerSpec?.CustomerSpecification?.Specifications?.TypicalValues.length > 0 && customerSpecData?.CustomerSpec?.CustomerSpecification?.Specifications?.TypicalValues[0] != null">
        <br/>
        <h3><b style="font-family: 'formata-medium';font-size: 14px;">SPECIFICATIONS - Typical Values - Not Tested for Certificate of Analysis</b></h3>
        <table class="report" border="1" style="width:100%">
            <tr bgcolor="#F5F5F5">
                <th style="text-align:left; width:40%; font-family: 'formata-regular';font-size: 12px;">Properties</th>
                <th style="text-align:left; width:10%; font-family: 'formata-regular';font-size: 12px;">Units</th>
                <th style="text-align:left; width:10%; font-family: 'formata-regular';font-size: 12px;">Min</th>
                <th style="text-align:left;width:10%; font-family: 'formata-regular';font-size: 12px;">Target</th>
                <th style="text-align:left;width:10%; font-family: 'formata-regular';font-size: 12px;">Max</th>
                <th style="text-align:left;width:20%; font-family: 'formata-regular';font-size: 12px;">Test Methods</th>
            </tr>
            <tr *ngFor="let data of customerSpecData?.CustomerSpec?.CustomerSpecification?.Specifications?.TypicalValues">
                <td style="text-align:left; width:40%; font-family: 'formata-regular';font-size: 12px;">{{data?.property}}</td>
                <td style="text-align:left; width:10%; font-family: 'formata-regular';font-size: 12px;">{{data?.units}}</td>
                <td style="text-align:left; width:10%; font-family: 'formata-regular';font-size: 12px;">{{data?.min}}</td>
                <td style="text-align:left; width:10%; font-family: 'formata-regular';font-size: 12px;">{{data?.target}}</td>
                <td style="text-align:left; width:10%; font-family: 'formata-regular';font-size: 12px;">{{data?.max}}</td>
                <td style="text-align:left; width:20%; font-family: 'formata-regular';font-size: 12px;">{{data?.testMethod}}</td>
            </tr>
        </table>
    </div>
    <div *ngIf="prodAlternativeArr.length > 0">
        <br/>
        <h3><b style="font-family: 'formata-medium';font-size: 14px;">APPROVED ALTERNATE TEST METHODS</b></h3>
        <table border="1" style="width:100%">
            <tr bgcolor="#F5F5F5">
                <th style="text-align:left; width:40%; font-family: 'formata-regular';font-size: 12px;">Properties</th>
                <th style="text-align:left; width:60%; font-family: 'formata-regular';font-size: 12px;">Test Methods</th>
            </tr>
            <tr *ngFor="let data of prodAlternativeArr">
                <td style="text-align:left; width:40%; font-family: 'formata-regular';font-size: 12px;">{{data?.property}}</td>
                <td style="text-align:left; width:60%; font-family: 'formata-regular';font-size: 12px;">{{data?.testMethod}}</td>
            </tr>
        </table>
    </div>
    <div *ngIf="customerSpecData?.CustomerSpec?.CustomerSpecification?.Notes?.Line.length > 0 && customerSpecData?.CustomerSpec?.CustomerSpecification?.Notes?.Line[0] != null">
        <br/>
        <h3><b style="font-family: 'formata-medium';font-size: 14px;">NOTES ON SPECIFICATIONS</b></h3>
        <div *ngFor="let data of customerSpecData?.CustomerSpec?.CustomerSpecification?.Notes?.Line">
            <p style="font-family: 'formata-regular';font-size: 12px;">{{data}}</p>
        </div>
    </div>
    <br/>
    <table border="1" style="width:100%">
        <tr bgcolor="#F5F5F5">
            <th style="text-align:left; width:20%"></th>
            <th style="text-align:left; width:40%; font-family: 'formata-regular';font-size: 12px;">Infineum</th>
            <th style="text-align:left; width:40%; font-family: 'formata-regular';font-size: 12px;">Customer</th>
        </tr>
        <tr>
            <td style="text-align:left; width:20%; border:none"><b style="font-family: 'formata-regular';font-size: 12px;">Approved:</b></td>
            <td rowspan="2" style="text-align:left; width:40%"></td>
            <td rowspan="2" style="text-align:left; width:40%"></td>
        </tr>
        <tr>
            <td  style="text-align:left; width:20%; border:none"><b style="font-family: 'formata-regular';font-size: 12px;">Date:</b></td>
            
        </tr>
    </table>
    <br/>
    <div style="text-align:center;color:'#00a1de'; font-family: 'formata-regular';font-size: 12px;">© Copyright INFINEUM INTERNATIONAL LIMITED {{printYear}} . All rights reserved.</div>
    <div style="text-align:center;color:'#00a1de'; font-family: 'formata-regular';font-size: 12px;">Infineum, 润英联 and the interlocking ripple device are Trade Marks of Infineum International Limited.</div>
</div>


