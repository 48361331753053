<div ng-reflect-position="Tabs" ng-reflect-is-page-fold="false" position="Tabs" class="Tabs has-components">
    <div class="container">
        <div class="row d-none d-md-flex">
            <span (click)="change(0)" class="col-lg-3 col-md-3 tabhead active technical-details">
                <button class="technical-details" > {{'customTabProductDetails.technicalDetails' | cxTranslate}} </button>
            </span>
            <span (click)="change(1)"  class="col-lg-1 col-md-1 tabhead MSDS">
                <button class="MSDS" > {{'customTabProductDetails.msdsTranslation' | cxTranslate}} </button>
                <!--.technical-details, .MSDS, .PDS, .Claims, .customer-specifications, .product-documents-->
            </span>
            <span (click)="change(2)" class="col-lg-1 col-md-1 tabhead PDS">
                <button class="PDS"> {{'customTabProductDetails.pdsTranslation' | cxTranslate}} </button>
            </span>
            <span (click)="change(3)" class="col-lg-1 col-md-1 tabhead Claims">
                <button class="Claims" > {{'customTabProductDetails.claimsTranslation' | cxTranslate}} </button>
            </span>
            <span (click)="change(4)" class="col-lg-3 col-md-3 tabhead customer-specifications">
                <button class="customer-specifications" > {{'customTabProductDetails.customerSpecific' | cxTranslate}} </button>
            </span>
            <span (click)="change(5)" class="col-lg-3 col-md-3 tabhead product-documents">
                <button class="product-documents" > {{'customTabProductDetails.pDocuments' | cxTranslate}} </button>
            </span>
        </div>
        <!-- Mobile -->
        <div class="row d-block d-md-none pt-3">
            <div class="col-12">
                <select (change)="onChange($event.target.value)">
                    <option value="0">{{'customTabProductDetails.technicalDetails' | cxTranslate}} </option>
                    <option value="1">{{'customTabProductDetails.msdsTranslation' | cxTranslate}}</option>
                    <option value="2">{{'customTabProductDetails.pdsTranslation' | cxTranslate}}</option>
                    <option value="3">{{'customTabProductDetails.claimsTranslation' | cxTranslate}}</option>
                    <option value="4">{{'customTabProductDetails.customerSpecific' | cxTranslate}}</option>
                    <option value="5">{{'customTabProductDetails.pDocuments' | cxTranslate}}</option>
                </select>
            </div>
        </div>
        <div class="row">
            <div class="col-12 active technical-details">
                <div class="cx-product-details-tab">
                    <div class="container">
                        <h4>{{'customTabProductDetails.technicalDetails' | cxTranslate}}</h4>
                        <div class="global-comm-style"><span class="global-comm-font">{{'customTabProductDetails.globalCommodityCode' | cxTranslate}} : </span> {{ product.globalCommodityCode }}</div>
                        <div class="row seven-cols">
                            <!-- CATEGORY -->
                            <div class="col-12 col-md-6 col-lg-7 h-100">
                                <div class="card shadow-none">
                                    <div class="card-header">
                                        <img class="card-img-top svg" src="{{categoryImage ? (domainUrl + categoryImage) : ''}}"/>
                                    </div>
                                    <div class="card-body">
                                        <h5 class="card-title">{{'customTabProductDetails.category' | cxTranslate}}</h5>
                                        <p class="card-text">{{ product.categoryName }}</p>
                                      </div>
                                </div>
                            </div>
                            <!-- DENSITY -->
                            <div class="col-12 col-md-6 col-lg-7">
                                <div class="card shadow-none">
                                    <div class="card-header">
                                        <img class="card-img-top" src="/assets/Density.svg">
                                    </div>
                                    <div class="card-body">
                                        <h5 class="card-title">{{'customTabProductDetails.density' | cxTranslate}}</h5>
                                        <p class="card-text" *ngIf="product.densityAt15C">{{'customTabProductDetails.at' | cxTranslate}} {{ product.densityAt15C }} {{'customTabProductDetails.density_unit_1' | cxTranslate}}</p>
                                        <p class="card-text" *ngIf="product.densityAt60F">{{'customTabProductDetails.atSecond' | cxTranslate}} {{ product.densityAt60F }} {{'customTabProductDetails.density_unit_2' | cxTranslate}}</p>
                                      </div>
                                </div>
                            </div>
                            <!-- WEIGHT -->
                            <div class="col-12 col-md-6 col-lg-7">
                                <div class="card shadow-none">
                                    <div class="card-header">
                                        <img class="card-img-top" src="/assets/Weight.svg">
                                    </div>
                                    <div class="card-body">
                                        <h5 class="card-title">{{'customTabProductDetails.weight' | cxTranslate}}</h5>
                                        <p class="card-text" *ngIf="product.grossWeight">{{'customTabProductDetails.gross' | cxTranslate}} {{ product.grossWeight }} {{'customTabProductDetails.weight_unit' | cxTranslate}}</p>
                                        <p class="card-text" *ngIf="product.netWeight">{{'customTabProductDetails.netWeight' | cxTranslate}} {{ product.netWeight }} {{'customTabProductDetails.weight_unit' | cxTranslate}}</p>
                                      </div>
                                </div>
                            </div>
                            <!-- TEMPERATURE (MIN) -->
                            <div class="col-12 col-md-6 col-lg-7">
                                <div class="card shadow-none">
                                    <div class="card-header">
                                        <img class="card-img-top" src="/assets/tempMin.svg">
                                    </div>
                                    <div class="card-body">
                                        <h5 class="card-title">{{'customTabProductDetails.tmpMin' | cxTranslate}}</h5>
                                        <p class="card-text" *ngIf="product.minHandlingTemperature">{{'customTabProductDetails.hading' | cxTranslate}} {{ product.minHandlingTemperature }}</p>
                                        <p class="card-text" *ngIf="product.minStorageTemperature">{{'customTabProductDetails.storage' | cxTranslate}} {{ product.minStorageTemperature }}</p>
                                      </div>
                                </div>
                            </div>
                            <!-- TEMPERATURE (MAX) -->
                            <div class="col-12 col-md-6 col-lg-7">
                                <div class="card shadow-none">
                                    <div class="card-header">
                                        <img class="card-img-top" src="/assets/tempMax.svg">
                                    </div>
                                    <div class="card-body">
                                        <h5 class="card-title">{{'customTabProductDetails.tmpMax' | cxTranslate}}</h5>
                                        <p class="card-text" *ngIf="product.maxHandlingTemperature">{{'customTabProductDetails.hading' | cxTranslate}} {{ product.maxHandlingTemperature }}</p>
                                        <p class="card-text" *ngIf="product.maxStorageTemperature">{{'customTabProductDetails.storage' | cxTranslate}} {{ product.maxStorageTemperature }}</p>
                                      </div>
                                </div>
                            </div>
                            <!-- PACKAGE TYPE -->
                            <div class="col-12 col-md-6 col-lg-7">
                                <div class="card shadow-none">
                                    <div class="card-header">
                                        <img class="card-img-top svg" src="{{packageTypeImage ? (domainUrl + packageTypeImage) : ''}}"/>
                                    </div>
                                    <div class="card-body">
                                        <h5 class="card-title">{{'customTabProductDetails.packageType' | cxTranslate}}</h5>
                                        <p class="card-text">{{ product.packageType }}</p>
                                      </div>
                                </div>
                            </div>
                            <!-- -->
                            <div class="col-12 col-md-6 col-lg-7">
                                <div class="card shadow-none">
                                    <div class="card-header">
                                        <img class="card-img-top svg" src="/assets/Recommended.svg"/>
                                    </div>
                                    <div class="card-body">
                                        <h5 class="card-title">{{'customTabProductDetails.recommendedTest' | cxTranslate}}</h5>
                                        <p class="card-text">{{ product.recommendedRetestInterval }}</p>
                                      </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12 MSDS hide">
                <div class="cx-product-details-tab">
                    <div class="container">
                        <h4>{{'customTabProductDetails.materialSafetyData' | cxTranslate}}</h4>
                        <div *ngIf="!msdsNoResults" class="msds-filters">
                            
                            <select class="form-control" (change)="selectCountry($event)" matNativeControl required id="countries">
                                <option *ngFor="let country of countries" [value]="country">{{ country }}</option>
                                <!-- <option value="all" selected="selected">All countries</option> -->
                            </select>
                            <select class="form-control" (change)="selectLanguage($event)" matNativeControl required id="languages">
                                <option *ngFor="let language of languages" [value]="language">{{ language }}</option>
                            </select>
                        </div>
                        <div *ngIf="!msdsNoResults" class="MSDS-container">
                            <table class='MSDS styled'>
                                <thead>
                                    <th></th>
                                    <th>{{'customTabProductDetails.supplyCompany' | cxTranslate}}</th>
                                    <th>{{'customTabProductDetails.country' | cxTranslate}}</th>
                                    <th>{{'customTabProductDetails.language' | cxTranslate}}</th>
                                    <th>{{'customTabProductDetails.date' | cxTranslate}}</th>
                                    <th>{{'customTabProductDetails.documetsTrans' | cxTranslate}}</th>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let attachmentData of displayMsdsAttachmentList">
                                        <td class='table-icon' data-label="" valign="middle"><img src='/assets/pdf.svg'></td> 
                                        <td id='supplyingCompany' data-label="Supplying Company" valign="middle">{{attachmentData.supplyingCompany}}</td>
                                        <td id='country' data-label="Country" valign="middle">{{attachmentData.country}}</td>
                                        <td id='language' data-label="Language" valign="middle">{{attachmentData.language}}</td>
                                        <td id='date' data-label="Date" valign="middle">{{attachmentData.date ? attachmentData.date.split("T")[0] : ""}}</td>
                                        <td data-label="Document" valign="middle"><button class='btn btn-primary btn-plain' (click) = "downloadMSDS(MSDS.materialNumber, attachmentData.dmsKey, product.name, attachmentData.supplyingCompany,$event )">{{'customTabProductDetails.download' | cxTranslate}}</button></td>
                                    </tr>
                                </tbody>
                            </table>
                            <div class='msds-navigation'>
                                <div class='pageContainer'> 
                                    {{'customTabProductDetails.page' | cxTranslate}} <button id='minus' (click) = "previousPageClickMsds()" class='arrowPagination'> < </button>  <span id='current'>{{currentPage}}</span> {{'customTabProductDetails.of' | cxTranslate}} <span id='max'>{{maxPages}}</span> <button id='plus' (click) = nextPageClickMsds() class='arrowPagination'> > </button>
                                </div>
                            </div>
                        </div>
                        <!-- MSDS Start Error Page content -->
                       <div *ngIf="msdsNoResults">
                        <!-- <div class="row">
                            <div class="col">
                                 <h4>{{'customTabProductDetails.materialSafetyData' | cxTranslate}}</h4>
                            </div>
                        </div> -->
                        <div class="row">
                            <div class="col-12 col-md-6">
                                <div class="card card-info card-alert card-warning box-shadow-none">
                                     <div class="card-body">
                                         <img src='assets/Warning.svg'>
                                         <p>{{'customTabProductDetails.msdsNotFound' | cxTranslate}}<br>{{'customTabProductDetails.pleaseCheckLater' | cxTranslate}}</p>     
                                     </div>
                                 </div>
                            </div>
                            <div class="col-12 col-md-6">
                                 <div class="card card-info bg-white box-shadow-none text-center pt-0">
                                    <div class="card-body __hide-display-flex">
                                        <div class="card-icon">
                                            <img src="/assets/information.svg">
                                        </div>
                                        <h4 class="m-auto color-black">{{'customTabProductDetails.needMoreInfo' | cxTranslate}}</h4>
                                        <p class="pb-4">{{'customTabProductDetails.pleaseContactInfineumAccount' | cxTranslate}}</p>
                                         <button (click) = "navigateToContact()" class="btn btn-primary btn-ghost-inverse m-auto" type="button">{{'customTabProductDetails.contact' | cxTranslate}}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                            <!-- <label class="__PDP-main-Title">Material safety data sheet</label> -->
                            <!-- <div class="card-columns">
                                <div class="card card-info card-alert card-warning">
                                  <div class="card-body">
                                      <img src='/assets/Warning.svg'>
                                      <p>{{'customTabProductDetails.msdsNotFound' | cxTranslate}}<br>{{'customTabProductDetails.pleaseCheckLater' | cxTranslate}}</p>
                                  </div>
                                </div>
                                <div class="card crd-border">
                                  <div class="card-body text-center">
                                      <img src="assets/information.svg">
                                      <div class="hrLine">
                                        <hr>
                                    </div>
                                    <label class="__PDP-main-Title">{{'customTabProductDetails.needMoreInfo' | cxTranslate}}</label>
                                        <span class="__PDP-frame-sub-Title-need-more-info">{{'customTabProductDetails.pleaseContactYourInfineum' | cxTranslate}}</span><br>
                                        <span class="__PDP-frame-sub-Title-need-more-info">{{'customTabProductDetails.requirements' | cxTranslate}}</span>
                                        <br>
                                        <button (click) = "navigateToContact()" class="need-info-contact-btn cursorPointer" type="button">{{'customTabProductDetails.contact' | cxTranslate}}
                                            <i aria-hidden="true" class="fa fa-chevron-right __btn-right-arrow"></i>
                                        </button>
                                  </div>
                                </div>
                                </div>   -->
                        
                    <!-- MSDS END Error Page content -->
                    </div>
                </div>
            </div>
        </div>
            <div class="col-md-12 PDS hide">
                <div class="cx-product-details-tab">
                    <div class="container">
                         <!-- PDS Start Error Page content -->
                       <div *ngIf="noResultPDS">
                           <div class="row">
                               <div class="col">
                                    <h4>{{'customTabProductDetails.productDataSheet' | cxTranslate}}</h4>
                               </div>
                           </div>
                           <div class="row">
                               <div class="col-12 col-md-6">
                                   <div class="card card-info card-alert card-warning box-shadow-none">
                                        <div class="card-body">
                                            <img src='assets/Warning.svg'>
                                            <p>{{'customTabProductDetails.pdsNotFound' | cxTranslate}}<br>{{'customTabProductDetails.pleaseCheckLater' | cxTranslate}}</p>     
                                        </div>
                                    </div>
                               </div>
                               <div class="col-12 col-md-6">
                                    <div class="card card-info bg-white box-shadow-none text-center pt-0">
                                       <div class="card-body __hide-display-flex">
                                           <div class="card-icon">
                                               <img src="/assets/information.svg">
                                           </div>
                                           <h4 class="m-auto color-black">{{'customTabProductDetails.needMoreInfo' | cxTranslate}}</h4>
                                           <p class="pb-4">{{'customTabProductDetails.pleaseContactInfineumAccount' | cxTranslate}}</p>
                                            <button (click) = "navigateToContact()" class="btn btn-primary btn-ghost-inverse m-auto" type="button">{{'customTabProductDetails.contact' | cxTranslate}}</button>
                                       </div>
                                   </div>
                               </div>
                           </div><!-- .row -->
                           <!--
                           <div class="row">
                                <div class="col-12 col-md-6">
                                    
                                    <div class="card box-shadow-none">
                                        <div class="card-body text-center">
                                            <img src="/assets/information.svg">
                                            <div class="hrLine">
                                            <hr>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-md-6">
                                    <div class="card card-info bg-white box-shadow-none text-center pt-0">
                                       <div class="card-body">
                                           <div class="card-icon">
                                               <img src="/assets/information.svg">
                                           </div>
                                           <h4 class="m-auto color-black">Need more information?</h4>
                                           <p class="pb-4">Please contact your Infineum Account Manager to discuss your requirements or use our contact form.</p>
                                            <button (click) = "navigateToContact()" class="btn btn-primary btn-ghost-inverse m-auto" type="button">CONTACT</button>
                                       </div>
                                   </div>
                                </div> 
                            </div> <!-- .row -->
                        </div><!-- .noResultsPDS -->
                    </div><!-- .container -->
                <!-- PDS END Error Page content -->
                        <div class="container" *ngIf="!noResultPDS">
                            <div class="PDS-container">
                                <h4>{{'customTabProductDetails.productDataSheet' | cxTranslate}}</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12 Claims hide">
                <div class="cx-product-details-tab" [ngClass]="showClaims ? 'pt-1' : ''">
                    <div *ngIf="!showClaims" class="container">
                        <div class="row">
                            <div class="col">
                                <h4>{{'customTabProductDetails.claimsTranslation' | cxTranslate}}</h4>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 col-md-6">
                               <div class="card card-info card-alert card-warning box-shadow-none">
                                    <div class="card-body">
                                        <img src='assets/pdpClaim.svg'>
                                        <p>{{'customTabProductDetails.thereNoProduct' | cxTranslate}}</p>     
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-md-6">
                                <div class="card card-info bg-white box-shadow-none text-center pt-0">
                                   <div class="card-body __hide-display-flex">
                                       <div class="card-icon">
                                           <img src="/assets/information.svg">
                                       </div>
                                       <h4 class="m-auto color-black">{{'customTabProductDetails.needMoreInfo' | cxTranslate}}</h4>
                                       <p class="pb-4">{{'customTabProductDetails.pleaseContactInfineumAccount' | cxTranslate}}</p>
                                        <button (click) = "navigateToContact()" class="btn btn-primary btn-ghost-inverse m-auto" type="button">{{'customTabProductDetails.contact' | cxTranslate}}</button>
                                   </div>
                               </div>
                           </div>
                       </div>
                    </div>
                         <!-- Claim Search Start Error Page content -->
                        
                    <!-- Claim search END Error Page content -->
                    <div *ngIf="showClaims" class="container">
                        <app-custom-claim-search></app-custom-claim-search>
                    </div>
                
            </div>
        </div>
    </div>
            <div class="col-md-12 customer-specifications hide">
                <div class="cx-product-details-tab">

                        <div class="customer-specification-data" *ngIf="!customerSpecificationNoResults && customerSpecificationRecordCount == 1">
                            <div class="container">
                                <div class="row">
                                    <div class="col">
                                        <h4>{{'customTabProductDetails.customerSpecific' | cxTranslate}}</h4>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 col-md-6">
                                        <div class="card card-info card-alert box-shadow-none">
                                            <div class="card-body align-items-start">
                                                <img src='assets/file-pdp-doc.svg'>
                                                <div>
                                                    <h4>{{product?.name}}</h4>
                                                    <h5>{{'customTabProductDetails.customerSpecific' | cxTranslate}}</h5>
                                                    <p class="label">{{'customTabProductDetails.openNewWindow' | cxTranslate}}</p>
                                                    <button (click) = "goToCustomerSpecificationLink()" class="btn btn-primary">{{'customTabProductDetails.open' | cxTranslate}}</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-6">
                                        <div class="card card-info bg-white box-shadow-none text-center pt-0">
                                           <div class="card-body __hide-display-flex">
                                               <div class="card-icon">
                                                   <img src="/assets/information.svg">
                                               </div>
                                               <h4 class="m-auto color-black">{{'customTabProductDetails.needMoreInfo' | cxTranslate}}</h4>
                                               <p class="pb-4">{{'customTabProductDetails.pleaseContactInfineumAccount' | cxTranslate}}</p>
                                                <button (click) = "navigateToContact()" class="btn btn-primary btn-ghost-inverse m-auto" type="button">{{'customTabProductDetails.contact' | cxTranslate}}</button>
                                           </div>
                                       </div>
                                    </div>
                                </div>
                                
                                
                                
                                <!--
                                <div class="card-columns">
                                    <div class="card bg-modal-header-bg">
                                        <div class="row">
                                            <div class="col-lg-3 col-md-3 cs-image">
                                                <img src='assets/file-pdp-doc.svg'>
                                            </div>
                                            <div class="col-lg-9 col-md-9 specification-text">
                                        <label class="__PDP-frame-Title __pdp-frame-title-customer-spec">{{product?.name}}</label>
                                        <span class="__PDP-frame-sub-Title">Customer Specification</span>
                                        <br><p class="__PDP-frame-text">Opens in a new window</p>
                                    <button (click) = "goToCustomerSpecificationLink()" class="rounded-pill open-btn-c">OPEN
                                        <i aria-hidden="true" class="fa fa-chevron-right __btn-right-arrow-customer-spec"></i>
                                    </button>        
                                    </div>
                                          </div>
                                    </div>
                                    <div class="card crd-border">
                                      <div class="card-body text-center">
                                          <img src="/assets/information.svg">
                                          <div class="hrLine">
                                            <hr>
                                        </div>
                                        <label class="__PDP-main-Title">Need more information?</label>
                                        <span class="__PDP-frame-sub-Title-need-more-info">Please contact your Infineum Account Manager to discuss your</span><br>
                                        <span class="__PDP-frame-sub-Title-need-more-info">requirements or use our contact form.</span>
                                        <br>
                                        <button (click) = "navigateToContact()" class="need-info-contact-btn cursorPointer" type="button">CONTACT
                                            <i aria-hidden="true" class="fa fa-chevron-right __btn-right-arrow"></i>
                                        </button>
                                      </div>
                                    </div>
                                </div>-->
                            </div>
                        </div>
                        <!-- Customer Specification Start Error Page content -->
                        <div *ngIf="customerSpecificationNoResults">
                            <div class="row">
                                <div class="col">
                                    <h4>{{'customTabProductDetails.customerSpecific' | cxTranslate}}</h4>
                                </div>
                            </div>
                            <div class="row">
                            <div class="col-12 col-md-6">
                                <div class="card card-info card-alert card-warning box-shadow-none">
                                     <div class="card-body">
                                         <img src='assets/Warning.svg'>
                                         <p>{{'customTabProductDetails.thereIsNoCus' | cxTranslate}}<br>{{'customTabProductDetails.specificAvailable' | cxTranslate}}</p>     
                                     </div>
                                 </div>
                            </div>
                            <div class="col-12 col-md-6">
                                 <div class="card card-info bg-white box-shadow-none text-center pt-0">
                                    <div class="card-body __hide-display-flex">
                                        <div class="card-icon">
                                            <img src="/assets/information.svg">
                                        </div>
                                        <h4 class="m-auto color-black">{{'customTabProductDetails.needMoreInfo' | cxTranslate}}</h4>
                                        <p class="pb-4">{{'customTabProductDetails.pleaseContactInfineumAccount' | cxTranslate}}</p>
                                         <button (click) = "navigateToContact()" class="btn btn-primary btn-ghost-inverse m-auto" type="button">{{'customTabProductDetails.contact' | cxTranslate}}</button>
                                    </div>
                                </div>
                            </div>
                            <!-- <h4>{{'customTabProductDetails.customerSpecific' | cxTranslate}}</h4>
                            <div class="card-columns">
                                <div class="card crd-border __error-cart-box">
                                  <div class="card-body card-body-1">
                                    <div class="row">
                                        <div class="col-lg-3 col-md-3">
                                  <img src='assets/file-pdp-doc.svg'>
                                  </div>
                                  <div class="col-lg-9 col-md-9">
                                  <label class="__PDP-frame-sub-Title-noResult-product-document">{{'customTabProductDetails.thereIsNoCus' | cxTranslate}}<br>{{'customTabProductDetails.specificAvailable' | cxTranslate}}</label>
                              </div>     
                              </div>  
                                  </div>
                                </div>
                                
                                
                                        <div class="card card-info bg-white box-shadow-none text-center pt-0">
                                           <div class="card-body">
                                               <div class="card-icon">
                                                   <img src="/assets/information.svg">
                                               </div>
                                               <h4 class="m-auto color-black">{{'customTabProductDetails.needMoreInfo' | cxTranslate}}</h4>
                                               <p class="pb-4">{{'customTabProductDetails.pleaseContactInfineumAccount' | cxTranslate}}</p>
                                                <button (click) = "navigateToContact()" class="btn btn-primary btn-ghost-inverse m-auto" type="button">{{'customTabProductDetails.contact' | cxTranslate}}</button>
                                           </div>
                                        </div>
                                
                                 
                            </div>  -->
                        
                    <!-- Customer Specification END Error Page content -->
                    </div>
                </div>
            </div>
        </div>
            <div class="col-md-12 product-documents hide">
                <div class="cx-product-details-tab">
                    <div class="container">
                        <div class="row">
                            <div class="col">
                                <h4>{{'customTabProductDetails.pDocuments' | cxTranslate}}</h4>
                            </div>
                        </div>
                        <div class="row" *ngIf="noResultProductDoc">
                           <div class="col-12 col-md-6">
                               <div class="card card-info card-alert card-warning box-shadow-none">
                                    <div class="card-body">
                                        <img src='assets/Warning.svg'>
                                        <p>{{'customTabProductDetails.thereNoProduct' | cxTranslate}}</p>     
                                    </div>
                                </div>
                           </div>
                           <div class="col-12 col-md-6">
                                <div class="card card-info bg-white box-shadow-none text-center pt-0">
                                   <div class="card-body __hide-display-flex">
                                       <div class="card-icon">
                                           <img src="/assets/information.svg">
                                       </div>
                                       <h4 class="m-auto color-black">{{'customTabProductDetails.needMoreInfo' | cxTranslate}}</h4>
                                       <p class="pb-4">{{'customTabProductDetails.pleaseContactInfineumAccount' | cxTranslate}}</p>
                                        <button (click) = "navigateToContact()" class="btn btn-primary btn-ghost-inverse m-auto" type="button">{{'customTabProductDetails.contact' | cxTranslate}}</button>
                                   </div>
                               </div>
                           </div>
                        </div><!-- .row -->
                         <!-- product documents Start Error Page content -->
                         <!--
                         <div *ngIf="noResultProductDoc">
                            <div class="card-columns">
                                <div class="card card-info card-alert card-warning">
                                  <div class="card-body">
                                      <img src='/assets/Warning.svg'>
                                      <p>There is no product document available.</p>
                                  </div>
                                </div>
                                
                                        <div class="card card-info bg-white box-shadow-none text-center pt-0">
                                           <div class="card-body">
                                               <div class="card-icon">
                                                   <img src="/assets/information.svg">
                                               </div>
                                               <h4 class="m-auto color-black">Need more information?</h4>
                                               <p class="pb-4">Please contact your Infineum Account Manager to discuss your requirements or use our contact form.</p>
                                                <button (click) = "navigateToContact()" class="btn btn-primary btn-ghost-inverse m-auto" type="button">CONTACT</button>
                                           </div>
                                       </div>
                                
                                
                                </div>  
                            </div>-->
                    <!-- product documents END Error Page content -->
                    

                    <!-- product documents Start Page content -->
                    <div *ngIf="!noResultProductDoc">
                        <div class="row">
                            <div class="col-12 col-md-6">
                                <div *ngFor="let pDetail of productDocDetails"  class="card card-info card-alert box-shadow-none">
                                    <div class="card-body align-items-start">
                                        <img src='assets/file-pdp-doc.svg'>
                                        <div>
                                            <h4>{{product?.name}}</h4>
                                            <h5>{{pDetail?.informationType}}</h5>
                                            <p class="label">{{'customTabProductDetails.openNewWindow' | cxTranslate}}</p>
                                            <button (click)="PDocDownload(pDetail, $event)" class="btn btn-primary">{{'customTabProductDetails.download' | cxTranslate}}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-md-6">
                                <div class="card card-info bg-white box-shadow-none text-center pt-0">
                                   <div class="card-body __hide-display-flex">
                                       <div class="card-icon">
                                           <img src="/assets/information.svg">
                                       </div>
                                       <h4 class="m-auto color-black">{{'customTabProductDetails.needMoreInfo' | cxTranslate}}</h4>
                                       <p class="pb-4">{{'customTabProductDetails.pleaseContactInfineumAccount' | cxTranslate}}</p>
                                        <button (click) = "navigateToContact()" class="btn btn-primary btn-ghost-inverse m-auto" type="button">{{'customTabProductDetails.contact' | cxTranslate}}</button>
                                   </div>
                               </div>
                            </div>
                        </div>

                        <!--
                        <div class="card-columns">
                            <div class="card crd-border __data-customer-spec-cart-box">
                                <div *ngFor="let pDetail of productDocDetails" class="row cart-background-color __productDoc-cart-divide">
                                    <div class="col-lg-3 col-md-3 pdp-doc-image">
                                        <img src='assets/file-pdp-doc.svg'>
                                    </div>
                                    <div class="col-lg-9 col-md-9 pdp-Document-text">
                                <label class="__PDP-frame-Title __pdp-frame-title-customer-spec">{{product?.name}}</label>
                                <span class="__PDP-frame-sub-Title">{{pDetail?.informationType}}</span><br>
                            <button (click)="PDocDownload(pDetail, $event)" class="rounded-pill download-btn-c">Download</button>        
                            </div>
                                  </div>
                            </div>
                            
                            
                                        <div class="card card-info bg-white box-shadow-none text-center pt-0">
                                           <div class="card-body">
                                               <div class="card-icon">
                                                   <img src="/assets/information.svg">
                                               </div>
                                               <h4 class="m-auto color-black">Need more information?</h4>
                                               <p class="pb-4">Please contact your Infineum Account Manager to discuss your requirements or use our contact form.</p>
                                                <button (click) = "navigateToContact()" class="btn btn-primary btn-ghost-inverse m-auto" type="button">CONTACT</button>
                                           </div>
                                       </div>
                            
                            
                            </div>  
                        </div>-->
                <!-- product documents END Page content -->
                </div>
            </div>
        </div>
    </div>
</div>