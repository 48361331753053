<div class="container __toast-container">
    <div class="row" [ngStyle]="{'display': __styleToaster}">
      <div class="col-lg-12">
        <div class="alert alert-success __toaster-border" role="alert">
            {{'customUpdatePassword.successfullSms' | cxTranslate}}
          <button type="button" class="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
    </div>
  </div>
</div>
<div class="container p-0">
    <div class="row">
        <div class="col-12 p-0">
            <div class="card">
                <!--<h4 class="card-title mb-3 mt-1">Update Password</h4>-->
                <div class="card-body">
                    <form novalidate="" ng-reflect-form="[object Object]" class="ng-untouched ng-pristine ng-invalid">
                        <div class="form-group">
                            <label class="mt-2">{{'customUpdatePassword.currentPass' | cxTranslate}}<span style="margin-left:unset">{{'customUpdatePassword.changesReqiured' | cxTranslate}}</span></label>
                            <input [(ngModel)]="oldPwd" type="password" name="oldPassword" formcontrolname="oldPassword" class="form-control w-100 ng-untouched ng-pristine ng-invalid" ng-reflect-name="oldPassword" [type]="hide ? 'password' : 'text'">
                            <span (click) = "toggleHide()" class="__passwordShow">{{hideBtnText}}</span>
                            <div *ngIf="oldPwd=='' && isoldPwd" class="invalid-feedback1">
                                <div *ngIf="oldPwd=='' && isoldPwd" class="__errorMessage">
                                    {{'customUpdatePassword.currentPassRequired' | cxTranslate}}
                                </div>
                            </div>
                        </div><!-- .form-group -->
                        <div class="form-group">
                            <label class="mt-4">{{'customUpdatePassword.newPass' | cxTranslate}}</label>
                            <input type="password" [(ngModel)]="newPwd" name="newPassword" formcontrolname="newPassword" class="form-control w-100 ng-untouched ng-pristine ng-invalid" ng-reflect-name="newPassword" (keyup)="checkPassword()" [type]="hideNewPass ? 'password' : 'text'">
                            <span (click) = "toggleHideNewPass()" class="__passwordShow">{{hideBtnTextNewPass}}</span>
                            <div *ngIf="newPwd=='' && isnewPwd" class="invalid-feedback1">
                                <div *ngIf="newPwd=='' && isnewPwd" class="__errorMessage">
                                    {{'customUpdatePassword.newPassRequired' | cxTranslate}}
                                </div>
                            </div>
                        </div>
                        <div class="form-group __sizes-icon mt-4">
                            <label class="mb-2">{{'customUpdatePassword.thePassMust' | cxTranslate}}</label>
                            <p><span class="digit __digitText"><i class="far fa-circle"></i></span> {{'customUpdatePassword.startwithNumber' | cxTranslate}}</p>
                            <p><span class="upper __digitText"><i class="far fa-circle"></i></span> {{'customUpdatePassword.containCaps' | cxTranslate}}</p>
                            <p><span class="lower __digitText"><i class="far fa-circle"></i></span> {{'customUpdatePassword.containLetters' | cxTranslate}}</p>
                            <p><span class="min __digitText"><i class="far fa-circle"></i></span> {{'customUpdatePassword.beMin8Char' | cxTranslate}}</p>
                            <p><span class="symbol __digitText"><i class="far fa-circle"></i></span> {{'customUpdatePassword.containSpecialSymbol' | cxTranslate}}</p>
                        </div> 
                        <div class="form-group">
                            <label class="mt-4">{{'customUpdatePassword.confirmNewPass' | cxTranslate}}</label>
                            <input type="password" [(ngModel)]="confirmNewPwd" name="newPasswordConfirm" formcontrolname="newPasswordConfirm" class="form-control w-100 ng-untouched ng-pristine ng-invalid" ng-reflect-name="newPasswordConfirm" [type]="hideConfirmPass ? 'password' : 'text'">
                            <span (click) = "toggleHideConfirmPass()" class="__passwordShow">{{hideBtnTextConfirmPass}}</span>
                            <div *ngIf="confirmNewPwd=='' && isconfirmNewPwd" class="invalid-feedback1">
                                <div *ngIf="confirmNewPwd=='' && isconfirmNewPwd" class="__errorMessage">
                                    {{'customUpdatePassword.confirmNewPassRequired' | cxTranslate}}
                                </div>
                            </div>
                            <div *ngIf="isConfirmpassMatchErrorMessage">
                                <label class="__passMismatch">{{'customUpdatePassword.passmissmatch' | cxTranslate}}</label>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12 d-flex mt-4 justify-content-between">
                                <button class="btn btn-primary w-auto flex-grow-1 mw-100 mr-2 text-nowrap" (click)="updatePassword()">{{'customUpdatePassword.saveChanges' | cxTranslate}}</button>
                                <button class="btn shadow-none btn-ghost-inverse w-auto" (click)="naviateToHomePage()">{{'customUpdatePassword.cancel' | cxTranslate}}</button>
                                <!--<button (click)="updatePassword()" class="__password-update-btn" type="button">SAVE CHANGES
                                    <i aria-hidden="true" class="fa fa-chevron-right __btn-right-arrow"></i>
                                </button>
                                <button (click)="naviateToHomePage()" class="__password-update-cancel-btn">CANCEL</button>-->
                            </div>
                        </div>
                    </form>
                </div>
            </div><!--.card -->
        </div>
    </div>
</div><!-- .container -->
