import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CmsComponent } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-banner-calims',
  templateUrl: './banner-calims.component.html',
  styleUrls: ['./banner-calims.component.scss']
})
export class BannerCalimsComponent implements OnInit {

  ComponentData : {}
  firstTitle: string;
  secondTitle: string;
  text: string;
  buttonLink: string;
  buttonText: string;
  subscription: Subscription;

  constructor(protected component: CmsComponentData<CmsComponent>, private router: Router, private ref: ChangeDetectorRef) { 
    this.subscription = this.component.data$.subscribe(data => {
      this.ComponentData = data;
    });
    if(this.ComponentData["title"] != undefined){
      var title = this.ComponentData["title"].split(" ");
      this.firstTitle = title[0];
      this.secondTitle = title[1];
    }
    else{
      this.firstTitle = "CLAIMS";
      this.secondTitle = "SEARCH";
    }
    this.text = this.ComponentData["text"] != undefined ? this.ComponentData["text"] : "Pinpoint the precise product formulation matching a claim with our powerful search";
    this.buttonLink = this.ComponentData["link"] && this.ComponentData["link"]["url"] != undefined ? this.ComponentData["link"]["url"] : "/claimsList";
    this.buttonText = this.ComponentData["link"] && this.ComponentData["link"]["linkName"] != undefined ? this.ComponentData["link"]["linkName"] : "START";
    // this.ref.detectChanges();
  }

  ngOnInit(): void {
  }
  
  navigateToClaimList(url) {
    this.router.navigate([url]);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
