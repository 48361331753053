import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { CmsBannerComponent, CmsComponent, CmsService, CMSTabParagraphContainer } from '@spartacus/core';
import {Router} from "@angular/router"
import { CmsComponentData } from '@spartacus/storefront';

import { AppService } from '../app.services';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-latests-news',
  templateUrl: './latests-news.component.html',
  styleUrls: ['./latests-news.component.scss']
})
export class LatestsNewsComponent implements OnInit {
  
  ComponentData: CmsComponent;
  title: string;
  endTitle: string;
  newsComponentList: any = [];
  subscription1: Subscription;
  subscription2: Subscription;

  constructor(public componentData: CmsComponentData<CmsComponent>, protected component: CmsComponentData<CmsComponent>, private cmsService: CmsService, private appService: AppService, private ref: ChangeDetectorRef, private router: Router) { 
  }

  ngOnInit(): void {

    this.title = "LATEST FROM INFINEUM";
    this.endTitle = "INSIGHT";

    this.newsComponentList = [];
    this.subscription1 = this.componentData.data$.subscribe(data1 => {
      this.ComponentData = data1;
      // console.log("this.ComponentData:::::::::;;",this.ComponentData)
      let gridBoxList = this.ComponentData['newsComponents'].split(" ");
      this.newsComponentList = [];
      let uniqueList: any = [...new Set(gridBoxList)];
      this.ref.detectChanges();
      uniqueList.map((gridBox) => {
        this.subscription2 = this.cmsService.getComponentData(gridBox.trim()).subscribe((data) => {
          // console.log("news data::::::::::::::::::::::",data)
          this.newsComponentList.push({
            image: data['media'] && data['media']["url"] && this.appService.getApiDomainUrl() + data['media']["url"],
            title: data["link"] && data["link"]["linkName"],
            text: data["text"],
            href: data["link"] && data["link"]["url"],
            uid: data["uid"]
          })
          let tempArr = [...new Map(this.newsComponentList.map((item) => [item["uid"], item])).values()];
          let tempArr1 = [];

          uniqueList.map((list) => {

            for(let i=0; i<tempArr.length; i++){

            if(list.trim()==tempArr[i]["uid"]){

              tempArr1.push(tempArr[i])

              break;

            }

          }

          })

          this.newsComponentList = tempArr1;
          this.ref.detectChanges();
        })
        
      })
    
    
    })






    // this.component.data$.subscribe(data => {
    //   this.ComponentData = data;

    //   if(this.ComponentData["title"] != undefined){
    //     this.title = this.ComponentData["title"].substring(0, this.ComponentData["title"].lastIndexOf(" "));
    //     this.endTitle = this.ComponentData["title"].substring(this.ComponentData["title"].lastIndexOf(" ")+1);
    //   }
    //   else{
    //     this.title = "LATEST FROM INFINEUM";
    //     this.endTitle = "INSIGHT";
    //   }
    //   console.log("LatestsNewsComponent this.ComponentData::::::::",this.ComponentData)
    // }); 
  }
  ngOnDestroy() {
    this.subscription1.unsubscribe();
    this.subscription2.unsubscribe();
  }

}
