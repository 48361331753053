<div class="container"><div class="row">
    <div id="accordion" class="col-12">
      <div class="card __first-cart">
        <div class="card-header _checkout-collapse-one align-items-center __ponterDisabled" data-toggle="collapse" data-target="#collapseOne">
          {{'customCheckOut.tabslide1' | cxTranslate}}
        </div>
        <div id="collapseOne" class="collapse show" data-parent="#accordion">
          <div class="card-body">
            <div class="row" *ngIf="!accordionFirstSavedData">
                <div class="col-lg-12 col-md-12">
                    <label for="title" class="__checkout-first-page-field-title">{{'customCheckOut.forTheAttention' | cxTranslate}}</label>
                </div>
            </div>

            <!-- start accordian first saved data -->
            <div class="row" *ngIf="accordionFirstSavedData">
              <div class="col-12 col-md-9 pt-3 pb-3">
                <label class="accordion-saved-lable-first">{{checkoutFormOne.value.firstName}} {{checkoutFormOne.value.lastName}}</label>
                <span *ngIf="checkoutFormOne.value.savedAddress" class="accordion-saved-text-first">{{formattedSavedAddr}}</span>
                <span *ngIf="!checkoutFormOne.value.savedAddress" class="accordion-saved-text-first">
                  {{checkoutFormOne.value.companyName  + (checkoutFormOne.value.companyName ? "," : "") +  checkoutFormOne.value.address.line1 + (checkoutFormOne.value.address.line1 ? "," : "") + checkoutFormOne.value.address.line2 + (checkoutFormOne.value.address.line2 ? "," : "") + checkoutFormOne.value.address?.city + (checkoutFormOne.value.address?.city ? "," : "") + checkoutFormOne.value.address?.state + (checkoutFormOne.value.address?.state ? "," : "") + checkoutFormOne.value.country }}
                </span>
                
              </div>
              <div class="col-12 col-md-3">
                <button (click) = "__editSavedAddress()" style="cursor: pointer;" class="btn btn-primary btn-ghost-inverse btn-simple" type="button">{{'customCheckOut.change' | cxTranslate}}</button>
              </div>
            </div>
<!-- End accordian first saved data -->
            <form [formGroup]="checkoutFormOne" (ngSubmit)="onContinue()" *ngIf="!accordionFirstSavedData">
                <div class="row __all-form-field-text-style">
                <!-- <div class="col-lg-7">
                <div class="row"> -->
                  <div class="col-lg-6">
                    <label for="firstName" class="control-label required">{{'customCheckOut.firstName' | cxTranslate}}</label>
                    <input type="text" formControlName="firstName" class="form-control">
                    <div *ngIf="checkoutFormOne.value.firstName=='' && isfirstName" class="invalid-feedback1">
                      <div *ngIf="checkoutFormOne.value.firstName=='' && isfirstName" class="__errorMessage">
                        {{'customCheckOut.firstNameRequired' | cxTranslate}}
                      </div>
                  </div>
                  </div>
                  <div class="col-lg-6">
                    <label for="firstName" class="control-label required">{{'customCheckOut.lastName' | cxTranslate}}</label>
                    <input type="text" formControlName="lastName" class="form-control">
                    <div *ngIf="checkoutFormOne.value.lastName=='' && isLastName" class="invalid-feedback1">
                      <div *ngIf="checkoutFormOne.value.lastName=='' && isLastName" class="__errorMessage">
                        {{'customCheckOut.lastNameRequired' | cxTranslate}}
                      </div>
                  </div>
                  </div>
                  
                    <div class="col-lg-12 __phone_section">
                        <label for="phoneNumber" class="control-label required">{{'customCheckOut.phoneNumber' | cxTranslate}}</label>
                        <span class="__phone_label_section">{{'customCheckOut.incaseYourRequest' | cxTranslate}}</span>
                        <input type="text" formControlName="phoneNumber" class="form-control">
                        <div *ngIf="checkoutFormOne.value.phoneNumber=='' && isphoneNumber" class="invalid-feedback1">
                          <div *ngIf="checkoutFormOne.value.phoneNumber=='' && isphoneNumber" class="__errorMessage">
                            {{'customCheckOut.phoneNumberRequred' | cxTranslate}}
                          </div>
                      </div>
                      </div>
            
                
                  <div class="col-lg-12">
                      <label for="email" class="control-label required">{{'customCheckOut.email' | cxTranslate}}</label>
                      <input type="email" formControlName="email" class="form-control">
                      <div *ngIf="checkoutFormOne.value.email=='' && isemail" class="invalid-feedback1">
                        <div *ngIf="checkoutFormOne.value.email=='' && isemail" class="__errorMessage">
                          {{'customCheckOut.emailRequired' | cxTranslate}}
                        </div>
                        <div *ngIf="fval.email.errors?.pattern" class="__errorMessage">
                          {{'customCheckOut.thisIsNotValidEmail' | cxTranslate}}
                      </div>
                    </div>
                    <div *ngIf="fval.email.errors?.pattern" class="invalid-feedback1">
                      <div *ngIf="fval.email.errors?.pattern" class="__errorMessage">
                        {{'customCheckOut.thisIsNotValidEmail' | cxTranslate}}
                    </div>
                  </div>
                    </div>
                  
                </div>
                <!-- new addres template fields -->
                <div *ngIf="addNewAddressTemplate">
                  <div class="row">
                    <div class="col-lg-12 col-md-12">
                        <h2 for="title" class="__checkout-first-page-field-title">{{'customCheckOut.addNewAddress' | cxTranslate}}</h2>
                    </div>
                    </div>
                    <div class="row">
                    <div class="col-lg-12 form-group">
                        <label for="companyName" class="control-label required">{{'customCheckOut.companyName' | cxTranslate}}</label>
                        <input type="text" formControlName="companyName" class="form-control">
                        <div *ngIf="checkoutFormOne.value.companyName=='' && iscompanyName" class="invalid-feedback1">
                          <div *ngIf="checkoutFormOne.value.companyName=='' && iscompanyName" class="__errorMessage">
                            {{'customCheckOut.companyNameRequired' | cxTranslate}}
                          </div>
                      </div>
                      </div>
                    </div>
                    <div class="row" formGroupName="address">
                      <div class="col-lg-12 form-group">
                        <label for="line1" class="control-label required">{{'customCheckOut.addressLine1' | cxTranslate}}</label>
                        <input type="text" formControlName="line1" class="form-control">
                        <div *ngIf="checkoutFormOne.value.address.line1=='' && isline1" class="invalid-feedback1">
                          <div *ngIf="checkoutFormOne.value.address.line1=='' && isline1" class="__errorMessage">
                            {{'customCheckOut.addressLineRequired' | cxTranslate}}
                          </div>
                      </div>
                      </div>
                      <div class="col-lg-12 form-group">
                        <label for="line2">{{'customCheckOut.addressLine2' | cxTranslate}}</label>
                        <input type="text" formControlName="line2" class="form-control">
                      </div>
                      <div class="col-lg-12 form-group">
                        <label for="city" class="control-label required">{{'customCheckOut.city' | cxTranslate}}</label>
                        <input type="text" formControlName="city" class="form-control">
                        <div *ngIf="checkoutFormOne.value.address.city=='' && iscity" class="invalid-feedback1">
                          <div *ngIf="checkoutFormOne.value.address.city=='' && iscity" class="__errorMessage">
                            {{'customCheckOut.cityRequired' | cxTranslate}}
                          </div>
                      </div>
                      </div>
                      <div class="col-lg-12">
                        <div class="form-group">
                            <label class="__addnew-address-btn required" for="countrySelect">{{'customCheckOut.country' | cxTranslate}}</label>
                            <select formControlName="country" class="form-control" (change)="onChange($event.target.value)">
                              <option value="" selected disabled hidden>{{'customCheckOut.pleaseSelect' | cxTranslate}}</option>
                              <option *ngFor="let country of countryList"
                              [ngValue] = "country.isocode"
                              >{{country.name}}</option>
                            </select>
                            <div *ngIf="checkoutFormOne.value.address.country=='' && iscountry" class="invalid-feedback1">
                              <div *ngIf="checkoutFormOne.value.address.country=='' && iscountry" class="__errorMessage">
                                {{'customCheckOut.countryRequired' | cxTranslate}}
                              </div>
                          </div>
                          </div>
                        </div>
                    <div class="col-lg-6 form-group">
                      <label for="state" class="control-label ">{{'customCheckOut.state' | cxTranslate}}</label>
                      <!-- <input type="text" formControlName="state" class="form-control"> -->
                      <select formControlName="state" class="form-control" (change)="onChangeState($event.target.value)">
                        <option value="" selected disabled hidden>{{'customCheckOut.pleaseSelect' | cxTranslate}}</option>
                        <option *ngFor="let region of regionDetails"
                        [ngValue] = "region.name"
                        >{{region.name}}</option>
                      </select>
                      <!-- <div *ngIf="checkoutFormOne.value.address.state=='' && isstate" class="invalid-feedback1">
                        <div *ngIf="checkoutFormOne.value.address.state=='' && isstate" class="__errorMessage">
                          State is required
                        </div>
                    </div> -->
                    </div>
                    <div class="col-lg-6 form-group">
                      <label for="pinCode" class="control-label required">{{'customCheckOut.zipCode' | cxTranslate}}</label>
                      <input type="text" formControlName="pinCode" class="form-control" (keypress)="keyPressNumbers($event)">
                      <div *ngIf="checkoutFormOne.value.address.pinCode=='' && ispinCode" class="invalid-feedback1">
                        <div *ngIf="checkoutFormOne.value.address.pinCode=='' && ispinCode" class="__errorMessage">
                          {{'customCheckOut.pinCodeRequired' | cxTranslate}}
                        </div>
                    </div>
                    </div>
                  </div>
                <!-- <div class="row">
                <div class="col-lg-12">
                    <div class="form-group">
                        <label class="__addnew-address-btn" for="countrySelect">Country</label>
                        <select formControlName="country" class="form-control" (change)="onChange($event.target.value)">
                          <option value="" selected disabled hidden>Please select</option>
                          <option *ngFor="let country of countryList"
                          [ngValue] = "country.isocode"
                          >{{country.name}}</option>
                        </select>
                      </div>
                    </div>
                </div> -->

                <div class="row">
                    <div class="col-lg-12">
                        <div class="form-check">
                            <label>
                                <input 
                                formControlName="checkBox" 
                                type="checkbox" 
                                name="checkBox" 
                                [value]="" 
                                /><span class="allign_middle">{{'customCheckOut.savedAddressForFuture' | cxTranslate}}</span>
                            </label>
                         </div> 
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-5">
                        <button type="submit" [disabled]="!checkoutFormOne.valid" class="rounded-pill __use-this-add-btn">{{'customCheckOut.useThisAddress' | cxTranslate}}
                          <!-- <button type="submit" class="rounded-pill __use-this-add-btn">USE THIS ADDRESS -->
                            <i aria-hidden="true" class="fa fa-chevron-right __btn-right-arrow"></i>
                        </button> 
                    </div>
                    <div class="col-lg-7">
                        <button type="submit" class="rounded-pill __use-this-add-cancel-btn" (click) = "__cancelAddTemplate()">{{'customCheckOut.cancel' | cxTranslate}}
                            <!-- <i aria-hidden="true" class="fa fa-chevron-right __btn-right-arrow"></i> -->
                        </button> 
                    </div>
                </div>
                </div>
                <div *ngIf="checkoutFormOneTemplate">
                <div class="row">
                <div class="col-lg-12">
                    <div class="form-group">
                        <label class="__addnew-address-btn required" for="countrySelect">{{'customCheckOut.country' | cxTranslate}} <a (click)="addNewAddress()"><img src="../../assets/plus-sign.png"> {{'customCheckOut.addNewAddresss' | cxTranslate}}</a></label>
                        <select formControlName="country" class="form-control" (change)="onChange($event.target.value)">
                          <option value="" selected disabled hidden>{{'customCheckOut.filterByCountry' | cxTranslate}}</option>
                          <option *ngFor="let country of countryList"
                          [ngValue] = "country.isocode.toString()"
                          >{{country.name}}</option>
                        </select>
                        <div *ngIf="checkoutFormOne.value.country=='' && iscountry" class="invalid-feedback1">
                          <div *ngIf="checkoutFormOne.value.country=='' && iscountry" class="__errorMessage">
                            {{'customCheckOut.countryRequired' | cxTranslate}}
                          </div>
          
                      </div>
                      </div>
                    </div>
                </div>
                <div class="row" *ngIf="savedAddressList">
                    <div class="col-lg-12">
                        <div class="form-check __saved-address-radio" *ngIf="savedB2bUnit.length > 0">
                            <label *ngFor="let item of savedB2bUnit">
                                <input 
                                formControlName="savedAddress" 
                                type="radio" 
                                name="savedAddress" 
                                [value]="item.uid"
                                />{{item.name ? item.name + ", " + item.formattedAddress : item.formattedAddress}}
                            </label>
                         </div> 
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-lg-6">
                        <button type="submit" [disabled]="!checkoutFormOne.valid" class="btn btn-primary">{{'customCheckOut.continue' | cxTranslate}}</button> 
                    </div>
                </div>
              </div>
                <!-- </div>
                <div class="col-lg5">List</div>
            </div> -->
              </form>
          </div>
        </div>
      </div>
      <div class="card __second-cart">
        <div class="card-header _checkout-collapse-one __disabled-property-accordion" [ngClass]="calculateClasses()" data-toggle="collapse" data-target="#collapseTwo">
          {{'customCheckOut.tabslide2' | cxTranslate}}
            </div>
        <div id="collapseTwo" class="collapse" [ngClass]="{'show':__accordionShow}" data-parent="#accordion">
          <div class="card-body">
            <!-- start accordian second saved data -->
            <div class="row" *ngIf="accordionSecondSavedData">
              <div class="col-12 col-md-9 pt-3 pb-3">
                <label class="accordion-saved-lable-first">{{'customCheckOut.documentationText' | cxTranslate}}</label>
                <span class="accordion-saved-text-first">{{documents}}</span>
                <hr class="__cart-hr">
                <label class="accordion-saved-lable-first">{{'customCheckOut.instractionText' | cxTranslate}}</label>
                <span class="accordion-saved-text-first">{{tab2checkoutcheck.value.comment}}</span>
              </div>
              <div class="col-md-3">
                <button (click) = "__editSavedAddressAccordion2()" class="btn btn-primary btn-ghost-inverse btn-simple" type="button">{{'customCheckOut.change' | cxTranslate}}</button>
              </div>
            </div>
<!-- End accordian second saved data -->
          <div *ngIf="!accordionSecondSavedData">
            <div class="row">
                <div class="col-lg-12 col-md-12">
                    <label for="title2" class="__checkout-first-page-field-title">{{'customCheckOut.whatDocumentationDoYouNeed' | cxTranslate}}</label>
                </div>
            </div>
            <form [formGroup]="tab2checkoutcheck" (ngSubmit)="tab2Submit()">
                <div class="row __all-form-field-text-style">
                <!-- <div class="col-lg-7">     
                <div class="row"> -->
                    <div class="col-lg-12">
                        <div class="form-check">
                            <label formArrayName="orders" *ngFor="let order of ordersFormArray.controls; let i = index">
                                <input type="checkbox" [formControlName]="i">
                                {{ordersData[i].name}}
                              </label>
                         </div> 
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12">
                        <div>
                            <label class="__checkout-first-page-field-title">{{'customCheckOut.additionalInstraction' | cxTranslate}} <span class="__optionalText">{{'customCheckOut.optional' | cxTranslate}}</span></label>
                            <!-- <br>     -->
                            <p class="__optionalText2" >{{'customCheckOut.forExamplePleaseEnterDetails' | cxTranslate}}</p>
                            <textarea class="__textarea-tab2" formControlName="comment"></textarea>
                         </div> 
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-lg-5">
                        <button type="submit" class="btn btn-primary">{{'customCheckOut.continue' | cxTranslate}}</button> 
                    </div>
                </div>
                <!-- </div>
                <div class="col-lg5">List</div>
            </div> -->
              </form>
          </div>
        </div>
        </div>
      </div>
      <div class="card __third-cart">
        <div class="card-header _checkout-collapse-one __disabled-property-accordion" [ngClass]="calculateClassesReview()" data-toggle="collapse" data-target="#collapseThree">
          {{'customCheckOut.tabslide3' | cxTranslate}}
            </div>
        <div id="collapseThree" class="collapse" [ngClass]="{'show':__accordionShowReview}" data-parent="#accordion">
          <div class="card-body">
            <form [formGroup]="Tab3FormRequestReview" (ngSubmit)="requestReview()">
                <div class="row __all-form-field-text-style">
                <!-- <div class="col-lg-7">
                <div class="row"> -->
                    <div class="col-lg-12">
                        <div class="form-check">
                            <label>
                                <input 
                                formControlName="checkBoxTab3" 
                                type="checkbox" 
                                name="checkBoxTab3" 
                                [value]="" 
                                /><span>{{'customCheckOut.byPlacingOrder' | cxTranslate}}<a class="__term-condition" style="cursor:pointer" (click)="navigatePrivacy()">{{'customCheckOut.termsCondition' | cxTranslate}}</a></span>
                            </label>
                         </div> 
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-5">
                        <button [disabled]="!Tab3FormRequestReview.valid" type="submit" class="btn btn-primary">{{'customCheckOut.sendRequest' | cxTranslate}}</button> 
                    </div>
                </div>
                <!-- </div>
                <div class="col-lg5">List</div>
            </div> -->
              </form>
            
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
  <!-- <div class="container"> -->
     <!-- checkout flow finish template for request and review -->
     <!-- <div class="card __checkout-finish-flow">
      <div class="card-body">
          <div class="__checkout-finish-cart">
              <img src="../../assets/accept.png">
              <br>
              <hr/>
              <br>
              <label>REQUEST <span>SENT</span></label>
              <br>
              <span class="__checkout-finish-cart-text1">Thank you. Your request will be reviewed by our team.</span>
              <br>
              <span class="__checkout-finish-cart-text2">SAMPLE REQUEST NUMBER 23456</span>
              <br>
              <a href="">Review and track sample request in My Account</a>
              <br>
              <button type="submit" class="rounded-pill __finish-goto-home-btn">HOME
                  <i aria-hidden="true" class="fa fa-chevron-right __btn-right-finish-arrow"></i>
              </button>
          </div>
          <div class="row align-items-center">
            <div class="col-lg-6 col-md-6">
                <label class="__cart-title">SHIPPING ADDRESS</label>
                
                <hr class="__cart-hr">
            </div>
            <div class="col-lg-6 col-md-6">
              <label class="__cart-title">SUMMARY</label>
              
              <hr class="__cart-hr">
          </div>
          </div>
          <div class="row">
              <div class="col-lg-6 col-md-6">
                  <div class="card-body __last-cart-checkout">
                  <label>Title</label>
                  <span>text 1</span><br>
                  <span>text 2</span><br>
                  <span>text 3</span>
                  </div>
              </div>
              <div class="col-lg-6 col-md-6">
                <div class="__cart-list">
                  <div class="row align-items-center">
                  <div class="col-lg-4 col-md-4">
                      <div class="__cart-imag-bg">
                      <img style="cursor:pointer" class="__cart-image" src="../../assets/cart-list-image.png" alt="">
                      </div>
                  </div>
                  <div class="col-lg-4 col-md-4">
                      <label style="cursor:pointer" class="__cart-section-title">Infineum P6660</label>
                  </div>
                  <div class="col-lg-4 col-md-4">
                      <label class="__cart-section-qty">0.5 Liter</label>
                  </div>
          </div>
          </div>
              </div>
          </div>
      </div>
  </div>
  </div> -->