import { Component, Inject, OnInit } from '@angular/core';
 declare var $: any;

interface FilterStructure {
  count : number
        name : string
        query : {
            query : {
                value : string
            },
            url : string
        },
        selected: false
}

export interface DialogData {
  values :
    Array<FilterStructure>
}

@Component({
  selector: 'app-dialog-filter-claim',
  templateUrl: './dialog-filter-claim.component.html',
  styleUrls: ['./dialog-filter-claim.component.scss']
})
export class DialogFilterClaimComponent implements OnInit {

  filterInput: string;
  filters : Array<FilterStructure>;
  private values : string;

  constructor( ) {
    // this.filters = data.values;
    this.values = "";
  }
  ngOnInit(): void {
  }

  selectFilter(link : string){
    // this.dialogRef.close(link);
  }

  onKey(event: any) {
    this.values = event.target.value;
    // this.filters = this.data.values.filter(filter => filter.name.toLowerCase().includes(this.values.toLowerCase()));
  }

  clearFilters(){
    this.values = "";
    $(".filterInput").val("");
    // this.filters = this.data.values;
  }

}
