import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, FormGroup, UntypedFormArray, UntypedFormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslationService } from '@spartacus/core';
import { Observable } from 'rxjs';

import { ProductDetailsService } from '../APIcall/product-details.service';
import { UserServiceService } from '../APIcall/user-service.service';

@Component({
  selector: 'app-custom-checkout',
  templateUrl: './custom-checkout.component.html',
  styleUrls: ['./custom-checkout.component.scss']
})
export class CustomCheckoutComponent implements OnInit {
savedAddressData = [];
countryData = [];
addNewAddressTemplate : boolean = false;
checkoutFormOneTemplate : boolean = true;
savedAddressList : boolean = false;

shippingUnits: any = [];
formattedSavedAddr: any = '';
savedB2bUnit: any = [];
countryList: any = [];
documents: any = '';
user: any = {};
shippingAddress: {};
regionDetails: any = [];
currentCartId: any = '';
termsChecked: boolean = false;
checkoutFormOne: any = {};
accordionFirstSavedData: boolean = false;
accordionSecondSavedData: boolean = false;
accordionDisabled: boolean = true;
__accordionEnabled: boolean = false;
__accordionShow: boolean = false;
__pointerDisabled: boolean = false;
__accordionShowReview: boolean = false;
__accordionEnabledReview: boolean = false;
isLastName: boolean = false;
isfirstName: boolean = false;
iscompanyName: boolean = false;
isphoneNumber: boolean = false;
isemail: boolean = false;
isline1: boolean = false;
iscity: boolean = false;
ispinCode: boolean = false;
iscountry: boolean = false;
isstate: boolean = false;
iscountryOuter: boolean = false;
//msdsTextTrans: string = ''
emailPattern = "^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$";
msdsTextTrans$: Observable<string> = this.translation.translate('customCheckOut.materialMSDS')
pdsTextTrans$: Observable<string> = this.translation.translate('customCheckOut.productPDS')
customerSpecTextTrans$: Observable<string> = this.translation.translate('customCheckOut.customerSpec')
certificateTextTrans$: Observable<string> = this.translation.translate('customCheckOut.certificateAnlysis')

ordersData: any = [];
  constructor(private translation: TranslationService, private fb: UntypedFormBuilder, private productDetailsService: ProductDetailsService, private ref: ChangeDetectorRef, private userServiceService: UserServiceService, private router: Router) {
    // this.savedAddressData = this.getSavedAddress();
    // this.countryData = this.getCountry();
    // this.addCheckboxes();
    
   }

   ngOnInit(): void {

    this.productDetailsService.changeBreadcrumb({"breadcumTitle" : "checkout", "categoryName": "", "productName": ""});
    // this.getCountries();
    
    this.userServiceService.getUser().then((res) => {
      this.user = this.userServiceService.getUserData();
      this.getShippingUnits(this.user.email);
      this.getCurrentCart();
    }).catch((error) => {
      console.log("error in get user:::::::::::",error);
    })
    this.addCheckboxes();
    this.savedAddressList = true;
    // if(localStorage.getItem('tab1values')) {
    //   this.checkoutFormOne.value = localStorage.getItem('tab1values');
    // } else {
      
    // }
    this.checkoutFormOneInit();
   
  }
  get fval() {
    return this.checkoutFormOne.controls;
  }
   checkoutFormOneInit(){
    this.checkoutFormOne = this.fb.group({
      firstName : [''],
      lastName : [''],
      savedAddress : [''],
      country : [''],
      companyName : [''],
      phoneNumber : [''],
      email: ['', [ Validators.email, Validators.pattern(this.emailPattern)]],
      checkBox : [false],
      address: this.fb.group({
        line1: [''],
        line2: [''],
        city: [''],
        country : [''],
        state: [''],
        pinCode: ['']
      })
    });
   }

tab2checkoutcheck = this.fb.group({
  orders: new UntypedFormArray([]),
  comment: [null]
});
Tab3FormRequestReview = this.fb.group({
  checkBoxTab3 : [false, Validators.requiredTrue],
  })

onContinue(){
  this.isLastName = false;
  this.iscountry = false;
  this.iscompanyName = false;
  this.isphoneNumber = false;
  this.isemail = false;
  this.isline1 = false;
  this.iscity = false;
  this.ispinCode = false;
  this.isstate = false;
  this.isfirstName = false;
  this.iscountryOuter = false;
  // this.accordionFirstSavedData = true;
  // this.__accordionEnabled = true;
  // this.__accordionShow = true;
  // this.__pointerDisabled = true;
  // console.log(this.checkoutFormOne.value);
  // this.calculateClasses();
  if(this.checkoutFormOne.value.lastName == ''){
    
    this.isLastName = true;
  }
  if(this.checkoutFormOne.value.firstName == ''){
    
    this.isfirstName = true;
  }
  if(this.checkoutFormOne.value.country == ''){
    
    this.iscountryOuter = true;
  }
  if(this.checkoutFormOne.value.companyName == ''){
    
    this.iscompanyName = true;
  }
  if(this.checkoutFormOne.value.phoneNumber == ''){
    
    this.isphoneNumber = true;
  }
  if(this.checkoutFormOne.value.email == ''){
    
    this.isemail = true;
  }
  if(this.checkoutFormOne.value.address.line1 == ''){
    
    this.isline1 = true;
  }
  if(this.checkoutFormOne.value.address.city == ''){
    
    this.iscity = true;
  }
  if(this.checkoutFormOne.value.address.pinCode == ''){
    
    this.ispinCode = true;
  }
  if(this.checkoutFormOne.value.address.country == ''){
    
    this.iscountry = true;
  }
  // if(this.checkoutFormOne.value.address.state == ''){
    
  //   this.isstate = true;
  // }
  if(this.addNewAddressTemplate ==true && (this.isLastName == true || this.iscountry == true || this.iscompanyName == true
    || this.isline1 == true || this.isphoneNumber == true || this.isemail == true || this.ispinCode == true
    || this.iscity == true || this.isfirstName == true)
    ){
    return
  }
  else if(this.addNewAddressTemplate ==false && (this.isLastName == true || this.iscountryOuter == true || this.isfirstName == true)
    ){
    return
  }
  console.log(this.checkoutFormOne.value);
  this.setShippingInfo(this.checkoutFormOne.value);
  localStorage.setItem('tab1values',JSON.stringify(this.checkoutFormOne.value));
  this.saveShippingAddress();
}
__editSavedAddress(){
  //this.checkoutFormOneInit();
  this.accordionFirstSavedData = false;
}
__editSavedAddressAccordion2(){
  //this.checkoutFormOneInit();
  this.accordionSecondSavedData = false;
}
getCurrentCart() {
  this.productDetailsService.gertCurrentCartData(this.user.email).then((cart) => {
    this.currentCartId = cart['code'];
    
  })
}
// useThisAddress(){
//   console.log(this.checkoutFormOne.value);
//   this.setShippingInfo(this.checkoutFormOne.value);
//   //this.saveShippingAddress();
  
// }
setShippingInfo(formValue) {
  if(this.addNewAddressTemplate == false) {
    this.shippingAddress = {
      "firstName": formValue.firstName,
      "lastName": formValue.lastName,
      "shipToUnit": formValue.savedAddress,
      "phone": formValue.phoneNumber,
      "email": formValue.email
    }
    this.setSavesAddrForDisplayInTab();
  } else if(formValue.address.state) {
    this.shippingAddress = {
      "cellphone": formValue.phoneNumber,
      "companyName": formValue.companyName,
      "country": {
        "isocode": formValue.address.country,
        "name": this.getCountryName(formValue.address.country)
      },
      "defaultAddress": true,
      "district": "",
      "email": formValue.email,
      "firstName": formValue.firstName,
      "formattedAddress": "",
      "id": "",
      "lastName": formValue.lastName,
      "line1": formValue.address.line1,
      "line2": formValue.address.line2,
      "phone": formValue.phoneNumber,
      "postalCode": formValue.address.pinCode,
      "region": {
        "countryIso": formValue.address.country,
        "isocode": this.getRegionDetails(formValue.address.state) ? this.getRegionDetails(formValue.address.state)['isocode'] : '',
        "isocodeShort": this.getRegionDetails(formValue.address.state) ? this.getRegionDetails(formValue.address.state)['isocodeShort'] : '',
        "name": formValue.address.state
      },
      "shipToUnit": "",
      "shippingAddress": true,
      "title": "",
      "titleCode": "",
      "town": formValue.address.city,
      "visibleInAddressBook": formValue.checkBox 
    }  
    this.setSavesAddrForDisplayInTab();
  } else {
    this.shippingAddress = {
      "cellphone": formValue.phoneNumber,
      "companyName": formValue.companyName,
      "country": {
        "isocode": formValue.address.country,
        "name": this.getCountryName(formValue.address.country)
      },
      "defaultAddress": true,
      "district": "",
      "email": formValue.email,
      "firstName": formValue.firstName,
      "formattedAddress": "",
      "id": "",
      "lastName": formValue.lastName,
      "line1": formValue.address.line1,
      "line2": formValue.address.line2,
      "phone": formValue.phoneNumber,
      "postalCode": formValue.address.pinCode,
      // "region": {
      //   "countryIso": formValue.address.country,
      //   "isocode": this.getRegionDetails(formValue.address.state) ? this.getRegionDetails(formValue.address.state)['isocode'] : '',
      //   "isocodeShort": this.getRegionDetails(formValue.address.state) ? this.getRegionDetails(formValue.address.state)['isocodeShort'] : '',
      //   "name": formValue.address.state
      // },
      "shipToUnit": "",
      "shippingAddress": true,
      "title": "",
      "titleCode": "",
      "town": formValue.address.city,
      "visibleInAddressBook": formValue.checkBox 
    }
  }
  
  console.log(this.shippingAddress);
}
setSavesAddrForDisplayInTab() {
  if(this.shippingAddress && this.shippingAddress["shipToUnit"]) {
    for(let i=0; i<this.savedB2bUnit.length; i++){
      if(this.savedB2bUnit[i]['uid'] == this.shippingAddress['shipToUnit']) {
        this.formattedSavedAddr = this.savedB2bUnit[i]['name'] ? this.savedB2bUnit[i]['name'] + ", " + this.savedB2bUnit[i]['formattedAddress'] : this.savedB2bUnit[i]['formattedAddress'];
        this.ref.detectChanges();
        break;
      }else {
        this.formattedSavedAddr = '';
        this.ref.detectChanges();
      }
    }
    // this.savedB2bUnit.map((unit) => {
    //   if(unit['uid'] == this.shippingAddress['shipToUnit']) {
    //     this.formattedSavedAddr = unit['formattedAddress'];
    //     this.ref.detectChanges();
    //   }else {
    //     this.formattedSavedAddr = '';
    //     this.ref.detectChanges();
    //   }
    // })
    console.log("this.formattedSavedAddr:::::::::;;",this.formattedSavedAddr)
  }
}
getCountryName(countryIsoCode) {
  this.countryList.map((country) => {
    if(country['isocode'] == countryIsoCode) {
      return country['name'];
    }
  })
}

getRegionDetails(regionName) {
  let tempObj = {};
  this.regionDetails.map((region) => {
    if(regionName == region.name) {
      tempObj = region;
    }
  })
  return tempObj;
}

saveShippingAddress() {
  this.productDetailsService.saveShippingAddress(this.user.email, this.currentCartId, this.shippingAddress).then((res) => {
    console.log("save shipping address res::::::::::;",res);
    this.accordionFirstSavedData = true;
    this.__accordionEnabled = true;
    this.__accordionShow = true;
    this.__pointerDisabled = true;
    console.log(this.checkoutFormOne.value);
    this.calculateClasses();
    this.ref.detectChanges();
  }).catch((error) => {
    console.log("error in save shipping address:::::::::",error)
  })
}

getSavedAddress() {
  return this.savedB2bUnit;
}

addNewAddress(){
  this.addNewAddressTemplate = true;
  this.checkoutFormOneTemplate = false;
  this.countryList = [];
  this.regionDetails = [];
  this.isLastName = false;
  this.iscountry = false;
  this.iscompanyName = false;
  this.isphoneNumber = false;
  this.isline1 = false;
  this.iscity = false;
  this.ispinCode = false;
  this.isstate = false;
  this.isfirstName = false;
  // this.checkoutFormOne.country = [""];
  // this.ref.detectChanges();
  this.getCountries();
}
__cancelAddTemplate = () =>{
  this.checkoutFormOneTemplate = true;
  this.addNewAddressTemplate = false;
  this.isfirstName = false;
  this.isLastName = false;
  this.iscountryOuter = false;
  this.iscountry = false;
  this.checkoutFormOneInit();
  // this.userServiceService.getUser().then((res) => {
  //   this.user = this.userServiceService.getUserData();
  //   this.getShippingUnits(this.user.email);
  //   this.getCurrentCart();
  // }).catch((error) => {
  //   console.log("error in get user:::::::::::",error);
  // }) 
  this.getShippingUnits(this.user.email);
}
onChange(countryIsoCode) {
  console.log("onchange country::::::::;",countryIsoCode);
  let countryIso = countryIsoCode.split(':')[1].trim();
  if(this.addNewAddressTemplate == false) {
    this.savedB2bUnit = [];
    
    this.shippingUnits['b2bUnits'].map((b2bUnit) => {
      if(b2bUnit['addresses'] && b2bUnit['addresses'][0] && b2bUnit['addresses'][0]['country'] && b2bUnit['addresses'][0]['country']['isocode'] == countryIso) {
        this.savedB2bUnit.push({formattedAddress: b2bUnit['addresses'][0]['formattedAddress'], uid: b2bUnit['uid'], name: b2bUnit['name']});
        this.ref.detectChanges();
        console.log("this.savedB2bUnit::::::::;;",this.savedB2bUnit)
      }
    })
    
  } else {
    this.productDetailsService.getRegions(countryIso).then((regionDetails) => {
      this.regionDetails = regionDetails["regions"];
      this.ref.detectChanges();
    })
  }
  
}
// ordersData = [
//   { id: 100, name: 'Material Safety Data Sheet (MSDS)' },
//   { id: 200, name: 'Product Data Sheet (PDS)' },
//   { id: 300, name: 'Customer Spec' },
//   { id: 400, name: 'Certificate of Analysis (if available)' }
// ];

get ordersFormArray() {
  return this.tab2checkoutcheck.controls.orders as UntypedFormArray;
}
private addCheckboxes() {
  this.msdsTextTrans$.subscribe(trans => {
    //this.msdsTextTrans = trans
    this.ordersData.push({ id: 100, name: trans })
    //this.ref.detectChanges();
    this.pdsTextTrans$.subscribe(trans => {
      //this.msdsTextTrans = trans
      this.ordersData.push({ id: 200, name: trans })
      //this.ref.detectChanges();
      this.customerSpecTextTrans$.subscribe(trans => {
        //this.msdsTextTrans = trans
        this.ordersData.push({ id: 300, name: trans })
        //this.ref.detectChanges();
        this.certificateTextTrans$.subscribe(trans => {
          //this.msdsTextTrans = trans
          this.ordersData.push({ id: 400, name: trans })
          //this.ref.detectChanges();
          this.ordersData.forEach(() => this.ordersFormArray.push(new UntypedFormControl(false)));
          console.log("testingfor trans::::::::::::", this.ordersData, trans)
        })
      })
    })
  }) 
}
tab2Submit(){
  
  console.log("tab 2 submit::::::::",this.tab2checkoutcheck.value)
  let tab2Data = this.tab2checkoutcheck.value;
  this.documents = '';
  tab2Data.orders.map((order,ind) => {
    if(order) {
      if(this.documents != '') {
        this.documents = this.documents + "," + this.ordersData[ind]['name'];
      } else {
        this.documents = this.documents + this.ordersData[ind]['name'];
      }
      
    }
  })
  let obj = {"comment": this.tab2checkoutcheck.value.comment,
              "documents": this.documents,
              "name": this.shippingAddress["firstName"] + " " + this.shippingAddress["lastName"],
              "phone": this.shippingAddress["phone"],
              "email": this.shippingAddress["email"]};
  this.productDetailsService.addComments(this.user.email, this.currentCartId, obj).then((res) => {
    console.log("add comment res:::::::::::::", res);
    this.accordionSecondSavedData = true;
    this.calculateClassesReview();
    //this.__pointerDisabled = true;
    this.__accordionShowReview = true;
    this.__accordionEnabledReview = true;
    this.ref.detectChanges();
  }).catch((error) => {
    console.log("error in ad comment::::::::::::",error);
  })
  
}
requestReview(){
  this.termsChecked = true;
  // this.__accordionEnabled = true;
  // this.__accordionShow = true;
  // this.calculateClasses()
  this.productDetailsService.placeOrder(this.user.email, this.currentCartId, this.termsChecked).then((res) => {
    console.log("place order response::::::::::::", res);
    this.productDetailsService.updateCart("0");
    
    let orderObj = {
      shippingaddress: this.shippingAddress,
      orderNo: res["code"],
      formattedSavedAddr: this.formattedSavedAddr
    }
    localStorage.setItem("order", JSON.stringify(orderObj));
    this.router.navigate(['/order-confirmation']);
  }).catch((error) => {
    console.log("error in placing order:::::::", error);
  })
}
  

  getCountries() {
    this.productDetailsService.getCountries().then((res) => {
      this.countryList = res && res['countries'];
      this.countryList.sort((a,b) =>a.name.localeCompare(b.name));
      // this.countryList.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
      this.ref.detectChanges();
    }).catch((err) => {
      console.log("error in fetching country:::::::::",err);
    })
  }
  getShippingUnits(userId) {
    this.productDetailsService.getShippingUnits(userId).then((shippingUnits) => {
      console.log("shipping units::::::::::::::::::",shippingUnits);
      let b2bUnitCountry = [];
      this.shippingUnits = shippingUnits;
      shippingUnits['b2bUnits'].map((b2bUnit) => {
        if(b2bUnit['addresses'] && b2bUnit['addresses'][0] && b2bUnit['addresses'][0]['country'] && b2bUnit['addresses'][0]['country']['name'] && b2bUnit['addresses'][0]['country']['isocode']) {
          b2bUnitCountry.push(b2bUnit['addresses'][0]['country']);
        }
        
      })
    
      this.countryList = [...new Map(b2bUnitCountry.map((item) => [item["name"], item])).values()];
      this.countryList.sort((a,b) =>a.name.localeCompare(b.name));
      this.ref.detectChanges();
      console.log("unique::::::::::::",this.countryList)
    }).catch((error) => {
      console.log("error in get shipping units::::::::::::",error);
    })
  }

  calculateClasses(){
    return {
      '__enabled-property-accordion': this.__accordionEnabled,
      'show': this.__accordionShow,
      '__ponterDisabled': this.__pointerDisabled
    };
  }
  calculateClassesReview(){
    return {
      '__enabled-property-accordion': this.__accordionEnabledReview,
      'show': this.__accordionShowReview,
      '__ponterDisabled': this.__pointerDisabled
    };
  }

  keyPressNumbers(event) {
    //var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    // if ((charCode < 48 || charCode > 57)) {
    //   event.preventDefault();
    //   return false;
    // } else {
    //   return true;
    // }
    var inp = String.fromCharCode(event.keyCode);

    if (/[a-zA-Z0-9 ]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }
  onChangeState(value){

  }

  navigatePrivacy() {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['/termsAndConditions'])
    );

    window.open(url, '_blank');
  }

}
