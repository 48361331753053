<div class="container __toast-container">
    <div class="row" [ngStyle]="{'display': __styleToaster}">
      <div class="col-lg-12">
        <div class="alert alert-success __toaster-border" role="alert">
            {{'customUpdatePassword.successfullyReset' | cxTranslate}}
          <button type="button" class="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
    </div>
  </div>
  </div>
<div class="container">
    <div class="row">
        <!-- <div class="col-md-3"></div> -->
        <div class="col-md-12 __update-pass-border">
        <form novalidate="" ng-reflect-form="[object Object]" class="ng-untouched ng-pristine ng-invalid">
            <!-- <label>
                <span class="label-content">Current Password <span>(required for changes)</span></span>
                <input [(ngModel)]="oldPwd" type="password" name="oldPassword" formcontrolname="oldPassword" class="form-control ng-untouched ng-pristine ng-invalid" ng-reflect-name="oldPassword" [type]="hide ? 'password' : 'text'">
                <span (click) = "toggleHide()" class="__passwordShow">{{hideBtnText}}</span>
            </label>
            <div *ngIf="oldPwd=='' && isoldPwd" class="invalid-feedback1">
                <div *ngIf="oldPwd=='' && isoldPwd" class="__errorMessage">
                    Current password is required
                </div>

            </div> -->
            <label style="margin-top: 20px;">
                <span class="label-content">{{'customUpdatePassword.newPass' | cxTranslate}}</span>
                <input type="password" [(ngModel)]="newPwd" name="newPassword" formcontrolname="newPassword" class="form-control ng-untouched ng-pristine ng-invalid" ng-reflect-name="newPassword" (keyup)="checkPassword()" [type]="hideNewPass ? 'password' : 'text'">
                <span (click) = "toggleHideNewPass()" class="__passwordShow">{{hideBtnTextNewPass}}</span>
            </label>
            <div *ngIf="newPwd=='' && isnewPwd" class="invalid-feedback1">
                <div *ngIf="newPwd=='' && isnewPwd" class="__errorMessage">
                    {{'customUpdatePassword.newPassRequired' | cxTranslate}}
                </div>

            </div>
            <div class="__sizes-icon" style="margin-top: 20px;">
                <p class="__digitLable">{{'customUpdatePassword.thePassMust' | cxTranslate}}</p>
                <p><span class="digit __digitText"><i class="far fa-circle"></i></span> {{'customUpdatePassword.startwithNumber' | cxTranslate}}</p>
                <p><span class="upper __digitText"><i class="far fa-circle"></i></span> {{'customUpdatePassword.containCaps' | cxTranslate}}</p>
                <p><span class="lower __digitText"><i class="far fa-circle"></i></span> {{'customUpdatePassword.containLetters' | cxTranslate}}</p>
                <p><span class="min __digitText"><i class="far fa-circle"></i></span> {{'customUpdatePassword.beMin8Char' | cxTranslate}}</p>
                <p><span class="symbol __digitText"><i class="far fa-circle"></i></span> {{'customUpdatePassword.containSpecialSymbol' | cxTranslate}}</p>
            </div> 
            <label style="margin-top: 30px;">
                <span class="label-content">{{'customUpdatePassword.confirmNewPass' | cxTranslate}}</span>
                <input type="password" [(ngModel)]="confirmNewPwd" name="newPasswordConfirm" formcontrolname="newPasswordConfirm" class="form-control ng-untouched ng-pristine" ng-reflect-name="newPasswordConfirm" [type]="hideConfirmPass ? 'password' : 'text'">
                <span (click) = "toggleHideConfirmPass()" class="__passwordShow">{{hideBtnTextConfirmPass}}</span>
            </label>
            <div *ngIf="confirmNewPwd=='' && isconfirmNewPwd" class="invalid-feedback1">
                <div *ngIf="confirmNewPwd=='' && isconfirmNewPwd" class="__errorMessage">
                    {{'customUpdatePassword.confirmNewPassRequired' | cxTranslate}}
                </div>

            </div>
            <div *ngIf="isConfirmpassMatchErrorMessage">
                <label class="__passMismatch">{{'customUpdatePassword.passmissmatch' | cxTranslate}}</label>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <button (click)="updatePassword()" [disabled]="!disableButton()"  class="__password-update-btn" type="button">{{'customUpdatePassword.resetPass' | cxTranslate}}
                        <i aria-hidden="true" class="fa fa-chevron-right __btn-right-arrow"></i>
                    </button>
                    <!-- <button (click)="naviateToHomePage()" class="__password-update-cancel-btn">CANCEL
                    </button> -->
                </div>
                <!-- <div class="col-md-6">
                    <button class="__password-update-cancel-btn">CANCEL
                    </button>
                </div> -->
            </div>
        </form>
        </div>
        <!-- <div class="col-md-3"></div> -->
    </div>
</div>
