<app-loader></app-loader>
<cx-storefront></cx-storefront>

<!-- <ng-template cxOutletRef="ReturningCustomerLoginComponent" cxOutletPos="before">
    <h3 class="provaWelcome">Welcome Back</h3>
    <p class="help-block">Already have an account? Sign in to retrieve your account settings</p>
</ng-template>

<ng-template cxOutletRef="ReturningCustomerLoginComponent" cxOutletPos="after">
    <div class="checkbox">
        <label>
            Can't log in? Check out the <a href="/logonHelp">Logon Help</a> page
        </label>
    </div>
    <div class="checkbox">
        <label>
            By accessing you accept the <a href="/terms-and-conditions">terms and conditions</a> of the website
        </label>
    </div>
    <br><br>
    <div class="row alert alert-info">If you would like access to Infineum InForm, please contact your Infineum Account Manager</div>
</ng-template> -->

<!-- <ng-template cxOutletRef="ProductAddToCartComponent" cxOutletPos="after">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="addWhishlist"></div>
            </div>
        </div>
    </div>
</ng-template> -->


<ng-template cxOutletRef="FooterNavigationComponent" cxOutletPos="before">
    <div class="container footer__start">
        <div class="row">
            <div class="col-12 col-md-6 p-md-0">
                <div class="footer__tagline">{{'customAppComponent.performancs' | cxTranslate}}</div>
            </div>
            <div class="col-12 col-md-6 p-md-0">
                <div class="footer__logo">
                    <a href="/"><img src="../../assets/logo_footer.svg" /></a>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<!--<ng-template cxOutletRef="FooterNavigationComponent" cxOutletPos="after">-->
    <div class="footer__end">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="footer__legal">
                        {{'customAppComponent.infineumInterlockingRipple' | cxTranslate}} <br> {{'customAppComponent.infineumInternational' | cxTranslate}}
                    </div>
                    <div class="footer__links">
                        <ul class="list-unstyled list-inline">
                            <!-- <li><a href="https://www.infineuminsight.com/legal-notice/" target="_blank">LEGAL NOTICE</a></li>
                            <li><a href="https://www.infineuminsight.com/privacy-policy/" target="_blank">PRIVACY POLICY</a></li>
                            <li><a href="https://www.infineuminsight.com/cookies/" target="_blank">COOKIE POLICY</a></li> -->
                            <li><a style="cursor:pointer" (click)="navigateTermsAndConditions()" target="_blank">{{'customAppComponent.termsAndCondition' | cxTranslate}}</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
<!--</ng-template>-->
