import { HttpClient } from '@angular/common/http';
import { Component, Injectable, OnInit } from '@angular/core'; 
import { element } from 'protractor';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ProductDetailsService } from '../APIcall/product-details.service';
import { ProductWishlistService } from '../APIcall/product-wishlist.service';
import { ProductWishlist } from '../APIcall/productWishlist';
import { environment } from '../../environments/environment';
declare var $: any;

@Component({
  selector: 'app-whishlist',
  templateUrl: './whishlist.component.html',
  styleUrls: ['./whishlist.component.scss']
})
export class WhishlistComponent implements OnInit {

  uri = environment.baseUrl + "/infineumwebservices/v2";

  products: ProductWishlist[];
  userId : string = "";

  constructor(private http: HttpClient, private productService: ProductWishlistService) {
    this.products = [];
    const user = window.localStorage.getItem('spartacus⚿⚿auth');
    const json = JSON.parse(user);
    this.userId = json.userId;
  }

  ngOnInit(){
    this.getProduct(this.userId).then(() => {
      this.products = this.productService.getProductsData();
      this.displayProducts();
    }).catch(() => {
      this.displayProducts();
    });
  }

  async getProduct(user : string){
    await this.productService.getProduct(user);
  }

  displayProducts(){
    if(this.products.length == 0){
      var emptyList =
        "<div class='row'> \
            <div class='col-md-12'> \
                <p style='text-align: center; color: grey'> \
                    No items \
                </p> \
            </div> \
        </div>";
        $(".displayProducts").append(emptyList);
    }
    else{
      this.products.forEach(product => {
          var productDisplay =
            "<mat-card class='wishlistItem-" + product.id + "' style='margin-top: 20px;background-color: rgba(237, 237, 237, 0.3);transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);display: block;position: relative;padding: 16px;border-radius: 4px;color: rgba(0,0,0,.87);'> \
              <div class='row'> \
                <div class='col-md-3' style='display: flex;align-items: center;'> \
                    <img src=' " + product.img + "' style='max-height: 200px;max-width: 200px;'> \
                </div> \
                <div class='col-md-3' style='display: flex;align-items: center;'> \
                    <p>" + product.name + "</p> \
                </div> \
                <div class='col-md-3' style='display: flex;align-items: center;'></div> \
                <div class='col-md-3' style='text-align: center;display: flex;align-items: center;'> \
                    <p style='font-size: 15px;'>Remove from Saved Items<br> \
                        <a productId='" + product.id + "' class='deleteWishlist' style='color: #00A1DE'> \
                            <i class='far fa-trash-alt' style='font-size: 25px;margin-top: 10px;'></i> \
                        </a> \
                    </p> \
                </div> \
              </div> \
            </mat-card>"
          $(".displayProducts").append(productDisplay);
      });

      // call to delete wishlist
      $(".deleteWishlist").click((data) =>{
        var productId = $(data.currentTarget).attr('productId');
        this.deleteProduct(productId);
      });
    }
  }

  deleteProduct(id: string){
    this.deleteProductCall(this.userId, id).then(() => {
      alert("Product correctly removed");
      $(".wishlistItem-" + id).remove();
    }).catch(() => {
      alert("Error while removing product, retry after!");
    });
  }

  async deleteProductCall(user: string, productId : string){
    await this.productService.deleteProduct(productId, user);
  }

  ngAfterViewInit() {
    
  }
}  

