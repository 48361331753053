import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { CmsComponent, TranslationService } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable, Subscription } from 'rxjs';
declare var $: any;

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss']
})
export class VideoComponent implements OnInit {

  ComponentData : {}
  iframe: string
  videoText: string
  videoTitle: string
  videoTitleEnd: string
  subscription: Subscription;
  invantionText$: Observable<string> = this.translation.translate('customVideo.invationCamestry');
  //invantionText$: Observable<string> = this.translation.translate('customVideo.invationCamestry')
  constructor(private translation: TranslationService, protected component: CmsComponentData<CmsComponent>, private ref: ChangeDetectorRef) { 
   }

  ngOnInit(): void {
    
    this.subscription = this.component.data$.subscribe(data => {
      this.invantionText$.subscribe(trans => {
      this.ComponentData = data;
      var videoUrl = this.ComponentData['videoUrl'] != undefined ? this.ComponentData['videoUrl'] : "https://player.vimeo.com/video/566163718?h=d0e24793ae&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479";
      this.videoText = this.ComponentData['videoText'] != undefined ? this.ComponentData['videoText'] : trans;
      this.videoTitle = this.ComponentData['videoTitle'] != undefined ? this.ComponentData['videoTitle'].split(" ")[0] : "Driving";
      this.videoTitleEnd = this.ComponentData['videoTitle'] != undefined ? this.ComponentData['videoTitle'].split(" ")[1] : "Forces";
      this.ref.detectChanges(); 
      this.iframe =
        "<iframe src=" + videoUrl + " frameborder='0' allow='autoplay; fullscreen; picture-in-picture' allowfullscreen style='position:absolute;top:0;left:0;width:100%;height:100%;' title='Infineum - Driving Forces 2021'></iframe>";
    
      $(".iframe").append(this.iframe);
    })
    })

    
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
