import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfigModule, CmsConfig } from "@spartacus/core";
import { ProductSummaryComponent } from './product-summary/product-summary.component';
import { ProductImageComponent } from './product-image/product-image.component';
import { I18nModule } from '@spartacus/core';

@NgModule({
  declarations: [ProductSummaryComponent, ProductImageComponent],
  imports: [
    CommonModule,
    I18nModule,
    ConfigModule.withConfig({

      cmsComponents:{
        ProductSummaryComponent : {
          component : ProductSummaryComponent
        },
        ProductImagesComponent : {
          component : ProductImageComponent
        }
      }

    } as CmsConfig)
  ]
})
export class CustomPdpModule { }
