import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import{ ProductDetailsService } from '../APIcall/product-details.service';
import { UserServiceService } from '../APIcall/user-service.service';
import { AppService } from '../app.services';

@Component({
  selector: 'app-custom-cart',
  templateUrl: './custom-cart.component.html',
  styleUrls: ['./custom-cart.component.scss']
})
export class CustomCartComponent implements OnInit {

  user: any = {};
  cartChangeUrl: any = '';
  cartDetailsList: any =[];
  isEmptyCart: boolean = false;

  constructor(private router: Router, private productDetailsService: ProductDetailsService, private userService: UserServiceService, private appService: AppService, private ref: ChangeDetectorRef) { 

  }

  ngOnInit(): void {

    this.productDetailsService.changeBreadcrumb({"breadcumTitle" : "cartPage", "categoryName": "", "productName": ""});

    this.userService.getUser().then(() => {
      this.user = this.userService.getUserData();
      this.getCartDetails(this.user.email);
    })


    
  }

  getCartDetails(userId) {
    this.productDetailsService.gertCurrentCartData(userId).then((cartDetails) => {
      console.log("cartDetails:in cart page::::::::::", cartDetails)
      this.cartDetailsList = [];
      if(cartDetails && cartDetails['entries'].length > 0) {
        this.productDetailsService.updateCart(cartDetails['entries'].length);
        this.isEmptyCart = false;
        cartDetails['entries'].map((entry) => {
          let unitName = '';
          entry['product']['units'].map((unitVal) => {
            if(unitVal['code'] == entry['unit']) {
              unitName = unitVal['name'];
            }
          })

          this.cartDetailsList.push({"entryNumber" : entry['entryNumber'],
                                      "infineumQuantity" : entry['infineumQuantity'],
                                    "productName": entry['product']['name'],
                                    "categoryImage" : (entry['product']['productImage']) ? (this.appService.getApiDomainUrl()+entry['product']['productImage']) : '',
                                    "url" : entry['product']['url'],
                                    "unit" : unitName,
                                    "cartId" : cartDetails['code']
                                  });

        })
        this.ref.detectChanges();
        
      } else {
        this.isEmptyCart = true;
        this.ref.detectChanges();
        this.productDetailsService.updateCart(cartDetails['entries'].length);
        this.productDetailsService.changeBreadcrumb({"breadcumTitle" : "cartPage no data", "categoryName": "", "productName": ""});
        this.ref.detectChanges();
      }
    }).catch((err) => {
      console.log("errror in fetching cart:::::",err)
      this.isEmptyCart = true;
      this.ref.detectChanges();
    })
  }

  deleteCart(cartId, entryNumber) {
    this.productDetailsService.deleteCart(this.user.email, cartId, entryNumber).then((deleteRes) => {
      console.log("deleteRes::::::::::::::",deleteRes);
      this.getCartDetails(this.user.email);
      // this.ref.detectChanges();
    }).catch((error) => {
      console.log("error in delete cart::::::;",error)
    })
  }

  redirectPDP(url) {
    this.router.navigate([url]);
  }
  redirectHome() {
    this.router.navigate(['/']);
  }
  redirectWishlist() {
    this.router.navigate(['/wishlist']);
  }
  changeCart() {
    this.router.navigate([this.cartChangeUrl]);
  }
  redirectCheckout() {
    this.router.navigate(['/checkout/shipping-address']);
  }
}
