import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ProductDetailsService } from '../APIcall/product-details.service';
import { UserServiceService } from '../APIcall/user-service.service';

@Component({
  selector: 'app-mini-cart-component',
  templateUrl: './mini-cart-component.component.html',
  styleUrls: ['./mini-cart-component.component.scss']
})
export class MiniCartComponentComponent implements OnInit {

  miniCartQty: any = '0';
  user: any = {};
  constructor(private productDetailsService: ProductDetailsService, private router: Router, private ref: ChangeDetectorRef, private userServiceService: UserServiceService) { }

  ngOnInit(): void {

    this.productDetailsService.currentCart.subscribe((cartQty) => {
      if(cartQty) {
        this.miniCartQty = cartQty;
        this.ref.detectChanges();
      } else {
        if(this.user.email) {
          this.updateMiniCart();
        } else {
          this.userServiceService.getUser().then((res) => {
            this.user = this.userServiceService.getUserData();
            this.updateMiniCart();
          }).catch((error) => {
            console.log("error in get user:::::::::::",error);
          })
        }
        
        
      }
      
      // this.ref.detectChanges();
      console.log("mini cart qty::::::::::::::",this.miniCartQty)
    })
  }
  updateMiniCart() {
    this.productDetailsService.gertCurrentCartData(this.user.email).then((cart) => {
      this.miniCartQty = cart['entries'].length;
      this.ref.detectChanges();
    }).catch((err) => {
      console.log(err);
    })
  }

  redirectToCart() {
    this.router.navigate(['/cart']);
  }

}
