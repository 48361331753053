<div mat-dialog-content>
  <div class="container">
    <div class="card-title">
      <div class="row">
        <div class="col-md-10 title">
        </div>
        <div class="col-md-2">
          <button mat-dialog-close class="closeDialog">
            <img src="../../../assets/closeDialog.svg" style="color: #00A1DE; float: right;font-size: 30px;">
          </button>
        </div>
      </div>
      <div class="row" style="margin-bottom: 15px;">
        <div class="col-md-2 code">
          <span class="grey-card"> Oil Code </span>
        </div>
        <div class="col-md-2 treatRate">
          <span class="grey-card">Treat Rate </span>
        </div>
        <div class="col-md-2 vm">
          <span class="grey-card">VM</span>
        </div>
        <div class="col-md-2 grade">
          <span class="grey-card">Grade</span>
        </div>
        <div class="col-md-2 categoryDetails">
          <span class="grey-card">Category</span>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 stock">
          <span class="grey-card">Base Stock</span>
        </div>
      </div>
      <div class="row other">
        
      </div>
    </div>
    <div class="row" style="padding: 5px 10px;">
      <div class="col-md-12" style="border-bottom: 1px solid #A5ACAF;">
        <h3 style="padding-left: 20px;">CLAIMS</h3>
      </div>
    </div>
    <div class="row claimSpecs" style="padding: 10px 10px 20px 28px;">
    </div>
    <div class="card-bottom">
      <button class="clear" style="margin-right: 20px;" (click)="orderSample()">ORDER A SAMPLE</button>
      <button class="clear" (click)="requestInformation()">REQUEST INFORMATION</button>
    </div>
  </div>
</div>