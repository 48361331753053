import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ClaimSearchDetailModel } from 'src/app/APIcall/claimsearch.details.model';
import { ClaimsearchService } from 'src/app/APIcall/claimsearch.service';
import { UserServiceService } from '../../APIcall/user-service.service';
declare var $: any;

export interface DialogData {
  productCode: string,
  treatId : string
}

@Component({
  selector: 'app-dialog-data-claim',
  templateUrl: './dialog-data-claim.component.html',
  styleUrls: ['./dialog-data-claim.component.scss']
})
export class DialogDataClaimComponent implements OnInit {

  details : ClaimSearchDetailModel;
  userId: any = "";

  constructor(private dialogRef: MatDialogRef<DialogDataClaimComponent>, @Inject(MAT_DIALOG_DATA) private data: DialogData, private claimsearchService: ClaimsearchService, private router: Router, private userService : UserServiceService) {
  }

  ngOnInit(): void {
    this.getClaimDetails(this.userId).then(() => {
      this.details = this.claimsearchService.getClaimDetailsData(this.userId);
      this.printValues();
      this.printClaimSpecs();
    });
    this.userService.getUserDetails().then((user) => {
      this.userId = user["uid"];
    })
  }

  private printClaimSpecs(){
    this.details.claimSpecs.forEach(spec => {
      var icon = spec.claimType === "Core" ? "far fa-check-circle" : "far fa-edit";
      var styleColor = spec.claimType == "Core" ? "#00A1DE;" : "#FF6D22;"
      var specPrint =
        "<div class='col-md-4'> \
          <i class='"+ icon  +"' style='margin-right:5px;color:"+ styleColor +"'></i> "+ spec.claimSpecId +" \
        </div>";
      $(".claimSpecs").append(specPrint);
    });
  }

  private printValues(){
    var treatRate = this.details.treatRate != undefined ? this.details.treatRate : "-"
    $(".title").append("<span class='titleClaimDialog'> "+ this.details.productName +" </span>");
    $(".code").prepend(this.details.oilCode+" <br>");
    $(".treatRate").prepend(treatRate +" <br>");
    $(".grade").prepend(this.details.gradeName+" <br>");
    $(".vm").prepend(this.details.viscosityModifiers+" <br>");
    var category = "../assets/noitem.png";
    switch (this.details.segmentName) {
      case "Small Engine":
        category = "../assets/icons/Car.svg";
        break;
      case "Large Engine":
        category = "../assets/icons/Lorry.svg";
        break;
      case "Crankcase":
        category = "../assets/icons/Boat.svg";
        break;
      default:
        break;
    }
    $(".categoryDetails").prepend("<img src='"+ category +"' style='max-height: 20px;'><br>");
    $(".stock").prepend(this.details.baseStocks+" <br>");

    //other data
    if(this.details.engines != "" && this.details.engines != undefined){
      var element = 
      "<div class='col-md-6'> \
        "+ this.details.engines +"<br> \
        <span style='color: #A5ACAF;'>Engines</span> \
      </div>";
      $(".other").append(element);
    }
    if(this.details.pibs != "" && this.details.pibs != undefined){
      var element = 
      "<div class='col-md-6'> \
        "+ this.details.pibs +"<br> \
        <span style='color: #A5ACAF;'>PIB</span> \
      </div>";
      $(".other").append(element);
    }
    if(this.details.ppdIdName != "" && this.details.ppdIdName != undefined){
      var element = 
      "<div class='col-md-6'> \
        "+ this.details.ppdIdName +"<br> \
        <span style='color: #A5ACAF;'>PPD</span> \
      </div>";
      $(".other").append(element);
    }
    if(this.details.solvents != "" && this.details.solvents != undefined){
      var element = 
      "<div class='col-md-6'> \
        "+ this.details.solvents +"<br> \
        <span style='color: #A5ACAF;'>Solvent</span> \
      </div>";
      $(".other").append(element);
    }
    if(this.details.tbn != "" && this.details.tbn != undefined){
      var element = 
      "<div class='col-md-6'> \
        "+ this.details.tbn +"<br> \
        <span style='color: #A5ACAF;'>TBN</span> \
      </div>";
      $(".other").append(element);
    }
  }

  private async getClaimDetails(userId){
    await this.claimsearchService.getClaimDetails(this.data.productCode, this.data.treatId, userId);
  }

  orderSample(){
    alert("this functionality is not yet implemented!");
    //redirect to PDP with productCode -> by now productCode not provide
  }

  requestInformation(){
    this.dialogRef.close();
    this.router.navigate(['/contact']);
  }
}
