import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
declare var $: any;

@Component({
  selector: 'app-banner-home-page',
  templateUrl: './banner-home-page.component.html',
  styleUrls: ['./banner-home-page.component.scss']
})
export class BannerHomePageComponent implements OnInit {

  buttons: { image: string, title: string, text: string, href: string  }[];
  title: string;
  description: string;
  button_label: string;

  constructor(private router: Router) {
    this.buttons = [
      {
        image:"/assets/icons/Car.svg",
        title: "Passenger Car",
        text: "Lubricants",
        href: "#"
      }, {
        image:"/assets/icons/Lorry.svg",
        title: "Heavy Duty",
        text: "Lubricants",
        href: "#"
      }, {
        image:"/assets/icons/Bike.svg",
        title: "Small Engine",
        text: "Lubricants",
        href: "#"
      }, {
        image:"/assets/icons/Boat.svg",
        title: "Large Engine",
        text: "Lubricants",
        href: "#"
      }, {
        image:"/assets/icons/Fuel_Icon.svg",
        title: "Fuels",
        text: "",
        href: "#"
      }, {
        image:"/assets/icons/Cogs.svg",
        title: "Transmission",
        text: "Fluids",
        href: "#"
      }, {
        image:"/assets/icons/Components_Icon.svg",
        title: "Components",
        text: "",
        href: "#"
      }, {
        image:"/assets/icons/Droplets.svg",
        title: "Viscosity",
        text: "Modifiers",
        href: "#"
      }
    ];
    this.description = "";
  }

  ngOnInit(): void {
  }
  ngAfterViewInit(){
    $(document).ready(function(){
      const user = window.localStorage.getItem('spartacus⚿⚿auth');
      const json = JSON.parse(user);
      if(json.userId == "current"){
        $(".searchbox").show();
        $(".SiteLogin").show();
        $(".MiniCart").show();
        $(".NavigationBar").show();
      }
    });
  }

  navigateToClaimList() {
    this.router.navigate(['/claimsList']);
  }
}
