<button (click)="redirectToCart()" type="button" class="add-to-card-btn d-none d-lg-block">
    <img src='/assets/SamplesIcon.png'>
    <strong>{{miniCartQty}}</strong> {{'customMiniCart.samples' | cxTranslate}}
</button>
<button (click)="redirectToCart()" type="button" class="add-to-card-btn d-block d-lg-none mr-3">
    <img src='/assets/SamplesIcon.png'>
    <strong>{{miniCartQty}}</strong>
</button>
<!--<div class="container">
    <div class="row">
        <div class="col-lg-12">
            <button (click)="redirectToCart()" type="button" class="add-to-card-btn">
                <img src='assets/SamplesIcon.png'>
                <span>{{miniCartQty}}</span>
                SAMPLES</button>
        </div>
    </div>
</div>-->