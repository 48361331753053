import { ChangeDetectorRef, Component, OnInit, HostListener } from '@angular/core';
import { CmsBannerComponent, CmsComponent, CmsService, CMSTabParagraphContainer } from '@spartacus/core';
import { Router } from "@angular/router"
import { CmsComponentData } from '@spartacus/storefront';
import { AppService } from '../app.services';
import { fromEvent, Observable, Subscription } from 'rxjs';
import { OwlOptions } from 'ngx-owl-carousel-o';
import * as $ from 'jquery';

@Component({
  selector: 'app-product-carousel',
  templateUrl: './product-carousel.component.html',
  styleUrls: ['./product-carousel.component.scss'],
})

export class ProductCarouselComponent implements OnInit {

    customOptions: OwlOptions = {
        loop: true,
        center: false, 
        mouseDrag: false,
        touchDrag: false,
        pullDrag: false,
        dots: false,
        navSpeed: 700,
        autoHeight: false,
        autoWidth: true,
        navText: ['<img src="/assets/icons/PillArrowLeft.svg">', '<img src="/assets/icons/PillArrowRight.svg">'],
        responsive: {
          0: {
            items: 1
          },
          600: {
            items: 2
          },
          800: {
            items: 3
          }
        },
        nav: true,
      };

  ComponentData : {}
  carouselComponentList:any [];
  tempCarouselComponentList:any [];
  subscription1: Subscription;
  subscription2: Subscription;
  resizeObservable$: Observable<Event>;
  resizeSubscription$: Subscription;

  constructor(public componentData: CmsComponentData<CmsComponent>, protected component: CmsComponentData<CmsComponent>, private cmsService: CmsService, private appService: AppService, private ref: ChangeDetectorRef, private router: Router,  ) { 

  }
  ngOnInit(): void {

    this.carouselComponentList = [];
    this.subscription1 = this.componentData.data$.subscribe(data1 => {
      this.ComponentData = data1;
      let gridBoxList = this.ComponentData['carousels'].split(" ");
      this.carouselComponentList = [];
      let uniqueList: any = [...new Set(gridBoxList)];
      this.ref.detectChanges();
      uniqueList.map((gridBox) => {
        this.subscription2 = this.cmsService.getComponentData(gridBox.trim()).subscribe((data) => {
          // console.log("data:::::::::::::::::::::",data)
          this.carouselComponentList.push({
            image: data && data['media'] && data['media']["url"] && this.appService.getApiDomainUrl() + data['media']["url"],
            bottomSectionImage: data && data['bottomSectionImage'] && data['bottomSectionImage']["url"] && this.appService.getApiDomainUrl() + data['bottomSectionImage']["url"],
            title: data && data["title"],
            subTitle: data && data["subTitle"],
            text:data && data["text"],
            uid: data && data["uid"],
            url: data && data["link"] && data["link"]["url"]
          })
          let tempArr = [...new Map(this.carouselComponentList.map((item) => [item["uid"], item])).values()];
          let tempArr1 = [];

          uniqueList.map((list) => {

            for(let i=0; i<tempArr.length; i++){

          //tempArr.map((item)=>{

            if(list.trim()==tempArr[i]["uid"]){

              tempArr1.push(tempArr[i])

              break;

            }

          }

          //})

          })

          this.carouselComponentList = tempArr1;
          this.tempCarouselComponentList = tempArr1;
          this.ref.detectChanges();
        })
        // console.log("carouselComponentList::::::::::::::::::",this.carouselComponentList)
      })
    })
    
    this.resizeObservable$ = fromEvent(window, 'resize')
    this.resizeSubscription$ = this.resizeObservable$.subscribe( evt => {
      console.log('event: ', evt);
      // this.reloadCarouselData();
    })
  }

  navigateToProduct(url) {
    if(url) {
      this.router.navigate([url]);
    } 
  }
  
  reloadCarouselData() {
    // TBD: Need to figure out how to reinitialize the Owl Carousel
  }
  
  ngOnDestroy() {
    this.subscription1.unsubscribe();
    this.subscription2.unsubscribe();
    this.resizeSubscription$.unsubscribe();
  }

}
