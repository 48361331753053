import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppService } from '../app.services'
declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class ProductListService {


  constructor(private http: HttpClient, private appService: AppService) { 
   
  }

  

  async getSearchProductList(pageSize, currentPage, sort, selectedFilterArr, userId) {
    var searchProductName = "";
    var i = 0;
    for(let element of window.location.pathname.split("/")){
      if(element == 'search'){
        searchProductName = window.location.pathname.split("/")[i+1];
        if(selectedFilterArr.length > 0) {
          searchProductName = searchProductName + ":relevance";
        }
        selectedFilterArr.map((filterVal) => {
          searchProductName = searchProductName + ":packageType_filter:" + filterVal;
        })
        break;
      } else if(element == 'c'){
        searchProductName = ":relevance:allCategories:" + window.location.pathname.split("/")[i+1];
        selectedFilterArr.map((filterVal) => {
          searchProductName = searchProductName + ":packageType_filter:" + filterVal;
        })
        break;
      }
      i++;
    }
    return await new Promise((resolve, reject) => {
      return this.http
      .get(this.appService.getApiUrl() + `/users/${userId}/products/search?fields=products(FULL),facets,breadcrumbs,pagination(DEFAULT),sorts(DEFAULT),freeTextSearch,currentQuery&query=${searchProductName}&pageSize=${pageSize}&currentPage=${currentPage}&sort=${sort}`)
      .subscribe(response => {
        try{
         resolve(response);
        }
        catch(e){
          reject(e);
        }  
      })
    });
  }

  
}
