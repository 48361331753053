<div mat-dialog-content>
    <div class="card-title container">
        <div class="row" style="align-items: center;">
            <div class="col-md-10">
                <span style="color: white; font-size: 20px;">SELECT CLAIM</span>
            </div>
            <div class="col-md-2">
                <button mat-dialog-close class="closeDialog">
                    <img src="../../../assets/closeFilterDialog.svg" style="float: right;font-size: 30px;">
                </button>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <input type="text" class="filterInput" placeholder="TYPE TO FILTER" (keyup)="onKey($event)">
            </div>
        </div>
        <div class="row" style="margin-top: 10px;">
            <div class="col-md-12">
                <p class="claims">CLAIMS</p>
            </div>
        </div>
        <div class="row" style="padding: 0px 10px 10px 10px;">
            <div class="col-md-12" style="padding: 0 5px;">
                <div class="filtersContainer">
                    <div class="row" style="padding: 0; margin: 0;">
                        <div class="col-md-4" *ngFor="let filter of filters" style="margin-top: 10px;">
                            <a class="filterLink" (click)="selectFilter(filter.query.query.value)" [routerLink]="[]" [ngClass]="{'active': filter.selected==true}">
                                {{ filter.name }} ({{ filter.count }})
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="card-bottom">
                <button class="clear" (click)="clearFilters()">CLEAR FILTERS</button>
            </div>
        </div>
    </div>
  </div>