import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { rejects } from 'assert';
import { attachmentDetails } from './attachmentDetails';
import { ClaimSearchProductModel } from './claimsearch.product.model';
import { ProductModel } from './product.model';
import { ProductMsdsModel } from './product.msds.model';
import { ProductPdsModel } from './product.pds.model';
import { ProviderModel } from './provider.model';
import { AppService } from '../app.services';
import { fromEvent, Observable, of, BehaviorSubject } from 'rxjs';
declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class ProductDetailsService {

  private product: ProductModel;
  private MSDS : ProductMsdsModel;
  private pdfStringMSDS : string;
  private PDS : Array<ProductPdsModel>;
  private pdfStringPDFPDS : string;
  private MSDScountries : Array<string>;
  private MSDSlanguages : Array<string>;
  private claimProduct : ClaimSearchProductModel;

 

  constructor(private http: HttpClient, private appService: AppService) { 
    this.product = new ProductModel();
    this.MSDS = new ProductMsdsModel();
    this.pdfStringMSDS = "";
    this.PDS = new Array<ProductPdsModel>();
    this.pdfStringPDFPDS = "";
    this.MSDScountries = new Array<string>();
    this.MSDSlanguages = new Array<string>();
    this.claimProduct = new ClaimSearchProductModel();
  }
  public productDetail: any  ={};
  private productDetailSource = new BehaviorSubject<any>(this.productDetail);
  currentProduct = this.productDetailSource.asObservable();
  changeProduct(product) {
    this.productDetailSource.next(product);
  }
  searchCategoryName: any = '';
  searchType: any = '';
  breadcrumbDetails: any = {};
  private breadcrumbDetailSource = new BehaviorSubject<any>(this.breadcrumbDetails);
  currentBreadcrumb = this.breadcrumbDetailSource.asObservable();
  changeBreadcrumb(breadcrumb) {
    console.log("in change breadcrumb:::::::",breadcrumb)
    this.breadcrumbDetailSource.next(breadcrumb);
  }
  cartDetails: any = '';
  private cartDetailSource = new BehaviorSubject<any>(this.cartDetails);
  currentCart = this.cartDetailSource.asObservable();
  updateCart(cart) {
    console.log("updateCart::::::::::",cart)
    this.cartDetailSource.next(cart);
  }
  getProductData(): ProductModel{
    return this.product;
  }

  getMSDSData(): ProductMsdsModel{
    return this.MSDS;
  }

  getPDSData(): Array<ProductPdsModel>{
    return this.PDS;
  }

  getPDFMSDSData(): string{
    return this.pdfStringMSDS;
  }

  getPDFPDSData(): string{
    return this.pdfStringPDFPDS;
  }

  getMSDSfilterCountry() : Array<string>{
    return this.MSDScountries.sort((a, b) => a.localeCompare(b));
  }

  getMSDSfilterLanguage() : Array<string>{
    return this.MSDSlanguages.sort((a, b) => a.localeCompare(b));
  }

  getClaimsProductData(userId) : ClaimSearchProductModel{
    return this.claimProduct;
  }

  async getProduct(userId) {
    var productName = "";
    var i = 0;
    for(let element of window.location.pathname.split("/")){
      if(element == 'p'){
        productName = window.location.pathname.split("/")[i+1];
        break;
      }else if(element == 'product'){
        productName = window.location.pathname.split("/")[i+1];
        break;
      }
      i++;
    }
    
    return await new Promise((resolve, reject) => {
      return this.http
      .get(this.appService.getApiUrl() + `/users/${userId}/products/` + productName + "?fields=code,name,summary,price(formattedValue,DEFAULT),images(galleryIndex,FULL),baseProduct,purchasable,baseOptions(DEFAULT),variantOptions(DEFAULT),variantType,averageRating,stock(DEFAULT),description,availableForPickup,url,numberOfReviews,manufacturer,categories(FULL),priceRange,multidimensional,tags,DEFAULT,classifications")
      .subscribe(response => {
        try{
          this.product.categoryName = response['categories'] && response['categories'][0] && response['categories'][0]['name'];
          this.product.code = response['code'];
          this.product.densityAt15C = response['densityAt15C'];
          this.product.densityAt60F = response['densityAt60F'];
          this.product.description = response['description'];
          // if(this.product.description != undefined){
          //     this.product.description = this.product.description.substring(3,this.product.description.length-4);
          // }
          this.product.formulaNumber = response['formulaNumber'];
          this.product.grossWeight = response['grossWeight'];
          this.product.maxHandlingTemperature = response['maxHandlingTemperature'];
          this.product.maxStorageTemperature = response['maxStorageTemperature'];
          this.product.minHandlingTemperature = response['minHandlingTemperature'];
          this.product.minStorageTemperature = response['minStorageTemperature'];
          this.product.multidimensional = response['multidimensional'];
          this.product.name = response['name'];
          this.product.netWeight = response['netWeight'];
          this.product.numberOfReviews = response['numberOfReviews'];
          this.product.packageType = response['packageType'];
          this.product.materialCode = response['materialCode'];
          this.product.summary = response['summary'];
          this.product.recommendedRetestInterval = response['recommendedRetestInterval'];
          this.product.packageTypeImage = response['packageTypeImage'];
          this.product.categoryImage = response['categoryImage'];
          this.product.globalCommodityCode = response['globalCommodityCode'];

          // this.productDetail.next(this.product);
          // this.changeProduct(this.product);
          $("cx-page-slot.BottomHeaderSlot > cx-breadcrumb > nav").append(`<span><a>${this.product.name}</a></span>`);
          resolve(true);
        }
        catch(e){
          reject(e);
        }  
      })
    });
  }

   getProductDetail(userId) {
    var productName = "";
    var i = 0;
    for(let element of window.location.pathname.split("/")){
      if(element == 'p'){
        productName = window.location.pathname.split("/")[i+1];
        break;
      }else if(element == 'product'){
        productName = window.location.pathname.split("/")[i+1];
        break;
      }
      i++;
    }
     this.http
      .get(this.appService.getApiUrl() + `/users/${userId}/products/` + productName + "?fields=code,name,summary,price(formattedValue,DEFAULT),images(galleryIndex,FULL),baseProduct,purchasable,baseOptions(DEFAULT),variantOptions(DEFAULT),variantType,averageRating,stock(DEFAULT),description,availableForPickup,url,numberOfReviews,manufacturer,categories(FULL),priceRange,multidimensional,tags,DEFAULT,classifications")
      .subscribe(response => {
        // debugger;
        try{
          this.product.categoryName = response['categories'] && response['categories'][0] && response['categories'][0]['name'];
          this.product.code = response['code'];
          this.product.densityAt15C = response['densityAt15C'];
          this.product.densityAt60F = response['densityAt60F'];
          this.product.description = response['description'];
          // if(this.product.description != undefined){
          //     this.product.description = this.product.description.substring(3,this.product.description.length-4);
          // }
          this.product.formulaNumber = response['formulaNumber'];
          this.product.grossWeight = response['grossWeight'];
          this.product.maxHandlingTemperature = response['maxHandlingTemperature'];
          this.product.maxStorageTemperature = response['maxStorageTemperature'];
          this.product.minHandlingTemperature = response['minHandlingTemperature'];
          this.product.minStorageTemperature = response['minStorageTemperature'];
          this.product.multidimensional = response['multidimensional'];
          this.product.name = response['name'];
          this.product.netWeight = response['netWeight'];
          this.product.numberOfReviews = response['numberOfReviews'];
          this.product.packageType = response['packageType'];
          this.product.materialCode = response['materialCode'];
          this.product.summary = response['summary'];
          this.product.recommendedRetestInterval = response['recommendedRetestInterval'];
          this.product.packageTypeImage = response['packageTypeImage'];
          this.product.categoryImage = response['categoryImage'];

          this.changeProduct(this.product);

          this.breadcrumbDetails['categoryUrl'] = response['categories'] && response['categories'][0] && response['categories'][0]['url'];
          this.changeBreadcrumb(this.breadcrumbDetails);
          $("cx-page-slot.BottomHeaderSlot > cx-breadcrumb > nav").append(`<span><a>${this.product.name}</a></span>`);
          // resolve(this.product);
        }
        catch(e){
          // reject(e);
        }  
      })
  }
  // getProductDetail(): Observable<any> {
  //   return this.productDetail.asObservable();
  // }

  async getMSDS(code, userId){
    return await new Promise((resolve, reject) => {
      return this.http
      .get(this.appService.getApiUrl() + `/users/${userId}/products/document/msdsList?materialNumber=` + code)
      .subscribe((response) => {
        try{
          this.MSDS = new ProductMsdsModel();
          var root = response['root'];
          this.MSDS.materialNumber = root['materialNumber'];
          var attachmentDetails = root['attachmentDetails'];
          attachmentDetails.forEach(element => {
            var details : attachmentDetails = {
              dmsKey : element['dmsKey'],
              supplyingCompany : element['supplyingCompany'],
              language : element['language'],
              country : element['country'],
              date : element['date']
            };
            this.MSDS.attachmentDetails.push(details);
            if(!this.MSDScountries.includes(element['country'])){
              this.MSDScountries.push(element['country']);
            }
            if(!this.MSDSlanguages.includes(element['language'])){
              this.MSDSlanguages.push(element['language']);
            }
          });
          resolve(true);
        }
        catch(e){
          reject(true);
        }
      },
      (error) => {
        console.log("Error 401 in MSDS Service::::",error);
        //this.MSDS = new ProductMsdsModel();
        reject(true);
      }
      );
    });
  }

  async getPDFPDS(guid, userId){
    return await new Promise((resolve, reject) => {
      return this.http
      .get(this.appService.getApiUrl() + `/users/${userId}/products/document/pds?GUID=`+ window.btoa(guid))
      .subscribe(response => {
        try{
          this.pdfStringPDFPDS = response['ZIM_PDS_BASE64_DOC_RFC.Response']['EV_BASE64'];
          resolve(true);
        }
        catch(e){
          reject(true);
        }
      });
    });
  }

  async getPDFMSDS(materialNumber, dmskey, matrix, userId){
    return await new Promise((resolve, reject) => {
      return this.http
      .get(this.appService.getApiUrl() + `/users/${userId}/products/document/msds?materialNumber=`+ materialNumber +"&dmsKey="+ dmskey +"&MAKTX="+ matrix)
      .subscribe(response => {
        try{
          this.pdfStringMSDS = response && response['Z_RetrieveSafetyDataSheetResponse']['Response']['Docdata'];
          resolve(true);
        }
        catch(e){
          console.log("Error in getPDFMSDS:::::::",e)
          reject(true);
        }
      });
    });
  }

  async getPDS(name, userId){
    return await new Promise((resolve, reject) => {
      return this.http
      .get(this.appService.getApiUrl() + `/users/${userId}/products/document/pdsList?package=` + name)
      .subscribe((response) => {
        try{
          this.PDS = new Array<ProductPdsModel>();
          // debugger; 
          var item = response['ZIM_PDS_DOC_HDR_CP_RFC.Response']['ET_PDSDOCHDR']['item'];  
          if(item.length > 0) {
            item.forEach(element => {
              var pds : ProductPdsModel = new ProductPdsModel();
              pds.GUID = element['GUID'];
              pds.OBJECT_KEY = element['OBJECT_KEY'];
              pds.MATNR = element['MATNR'];
              pds.MAKTX = element['MAKTX'];
              pds.PDSCUST = element['PDSCUST'];
              pds.MAINHDRTXT = element['MAINHDRTXT'];
              pds.SECHDRTXT = element['SECHDRTXT'] != undefined ? element['SECHDRTXT'] : "*no name*";
              this.PDS.push(pds);
            });
          } else {
            var pds : ProductPdsModel = new ProductPdsModel();
            pds.GUID = item['GUID'];
            pds.OBJECT_KEY = item['OBJECT_KEY'];
            pds.MATNR = item['MATNR'];
            pds.MAKTX = item['MAKTX'];
            pds.PDSCUST = item['PDSCUST'];
            pds.MAINHDRTXT = item['MAINHDRTXT'];
            pds.SECHDRTXT = item['SECHDRTXT'] != undefined ? item['SECHDRTXT'] : "*no name*";
            this.PDS.push(pds);
          }
          
          resolve(true);
        }
        catch(e){
          console.log("error in pds:::::::",e)
          reject(true);
        }
      },
      (error) => {
        console.log("Error 401 in PDS Service::::",error);
        //this.MSDS = new ProductMsdsModel();
        reject(true);
      }
      );
    });
  }

  async getClaimsProduct(materialCode : string, userId){
    return await new Promise((resolve, reject) => {
      return this.http
      .get(this.appService.getApiUrl() + `/users/${userId}/claims/` + materialCode)
      // .get(this.appService.getApiUrl() + "/claims/search?fields=claims%2Cfacets%2Cbreadcrumbs%2Cpagination(DEFAULT)%2Csorts(DEFAULT)%2CfreeTextSearch%2CcurrentQuery&query=:relevance:productCode:000000000010074078&currentPage=0&pageSize=10")
      .subscribe((response) => {
        try{
          console.log(response);
          this.claimProduct = response as ClaimSearchProductModel;
          resolve(true);
        }
        catch(e){
          console.log(e);
          reject(true);
        }
      },
      (error) => {
        console.log("Error 401 in claims Service::::",error);
        //this.MSDS = new ProductMsdsModel();
        reject(true);
      }
      );
    });
  }

  async getProductDocuments(userId){
   
    return await new Promise((resolve, reject) => {
      return this.http
      .get(this.appService.getApiUrl() + `/users/${userId}/products/document/sharepointInfo?materialName=`+ this.product.name)
      .subscribe((response) => {
        try{
          //this.pdfStringPDFPDS = response['ZIM_PDS_BASE64_DOC_RFC.Response']['EV_BASE64'];
          resolve(response);
        }
        catch(e){
          reject(e);
        }
      },
      (error) => {
        console.log("Error 401 in Product doc Service::::",error);
        //this.MSDS = new ProductMsdsModel();
        reject(true);
      }
      );
    });
  }

  async getCategoryBreadcrumbDetails(category: any, userId) {
    
    return await new Promise((resolve, reject) => {
      return this.http
      .get(this.appService.getApiUrl() + `/users/${userId}/categoryInfo/${category}?fields=FULL`)
      .subscribe(response => {
        try{

          resolve(response);
        }
        catch(e){
          reject(e);
        }  
      })
    });
  }

  async getCategoryByCode(category: any, userId) {
    
    return await new Promise((resolve, reject) => {
      return this.http
      .get(this.appService.getApiUrl() + `/users/${userId}/categoryInfo/getCategoriesDetails?categoryCodes=${category}&fields=FULL`)
      .subscribe(response => {
        try{

          resolve(response);
        }
        catch(e){
          reject(e);
        }  
      })
    });
  }

  
  setBreadcrumbDetail(userId) {
    this.searchCategoryName = '';
    this.searchType = '';
    this.breadcrumbDetails = {};
    this.checkForCategoryOrProduct(); 
    if(this.searchType == 'CATEGORY') {
      this.getCategoryBreadcrumbDetails(this.searchCategoryName, userId).then((res) => {
        this.breadcrumbDetails['categoryImage'] = res && res['image'] && res['image']['url'];
        this.breadcrumbDetails['categoryName'] = res && res['name'];
        this.breadcrumbDetails['searchType'] = this.searchType;
        this.changeBreadcrumb(this.breadcrumbDetails);

      }).catch((err) => {
        console.log("error in getting breadcrumb details::::::::::",err);
      })
    } else if(this.searchType == 'PRODUCT') {
        this.currentProduct.subscribe((data) => {
     
          this.breadcrumbDetails['categoryImage'] = data?.categoryImage;
          this.breadcrumbDetails['categoryName'] = data?.categoryName;
          this.breadcrumbDetails['productName'] = data?.name;
          this.breadcrumbDetails['searchType'] = this.searchType;
          this.changeBreadcrumb(this.breadcrumbDetails);
                        
          
        })
    }else if(this.searchType == 'SEARCH') {
      this.breadcrumbDetails['categoryName'] = "Search results";
      this.breadcrumbDetails['productName'] = "Search results";
      this.breadcrumbDetails['searchType'] = this.searchType;
      this.changeBreadcrumb(this.breadcrumbDetails);
    }
    
  }

  checkForCategoryOrProduct() {
    let productName: any = "";
    let i = 0;
    for(let element of window.location.pathname.split("/")){
      if(element == 'p'){
        this.searchType = 'PRODUCT';
        productName = window.location.pathname.split("/")[i+1];
        break;
      }else if(element == 'product'){
        this.searchType = 'PRODUCT';
        productName = window.location.pathname.split("/")[i+1];
        break;
      }else if(element == 'c'){
        this.searchType = 'CATEGORY';
        productName = window.location.pathname.split("/")[i+1];
        this.searchCategoryName = productName;
        break;
      }else if(element == 'search'){
        this.searchType = 'SEARCH';
        productName = window.location.pathname.split("/")[i+1];
        break;
      }
      i++;
    }
  }

  
  async addToCart(user: any, cartId: any, productCode: any, quantity: any, unit:any){
    return await new Promise((resolve, reject) => {
      try{
        return this.http
        .post(this.appService.getApiUrl() + `/orgUsers/${user}/carts/${cartId}/entries?code=${productCode}&quantity=${quantity}&unit=${unit}`, {})
        .subscribe((response) => {
          // this.updateCart(response);
            resolve(response);
        });
      }
      catch(e){
        console.log("error in add to cart:::::::::;",e);
        reject(e);
      }
    });
  }

  async gertCurrentCartData(user: any) {
    
    return await new Promise((resolve, reject) => {
      return this.http
      .get(this.appService.getApiUrl() + `/users/${user}/carts/current?fields=FULL`)
      .subscribe((response) => {
        try{
          console.log("gertCurrentCart response::::::::::",response)
          resolve(response);
        }
        catch(e){
          console.log("error in gertCurrentCart::::::::::::",e)
          reject(e);
        }  
      }, (error) => {
        console.log("error in current cart::::::::::::",error);
        reject(error);
      })
    });
  }
  
  async gertCurrentCart(user: any) {
    
    return await new Promise((resolve, reject) => {
      return this.http
      .get(this.appService.getApiUrl() + `/users/${user}/carts/current?fields=FULL`)
      .subscribe((response) => {
        try{
          console.log("gertCurrentCart response::::::::::",response)
          resolve(response);
        }
        catch(e){
          console.log("error in gertCurrentCart::::::::::::",e)
          reject(e);
        }  
      }, (error) => {
        console.log("error in current cart::::::::::::",error);
        if(error && error['error']['errors'][0]['message'] == 'No cart created yet.') {
          this.createCart(user).then((createCartData) => {
            console.log("create cart data::::::::::::",createCartData);
            resolve(createCartData);
          }).then((cerateCartError) => {
            reject(cerateCartError);
          })
        }
      })
    });
  }

  async createCart(user: any) {
    
    return await new Promise((resolve, reject) => {
      return this.http
      .post(this.appService.getApiUrl() + `/users/${user}/carts`,{})
      .subscribe(response => {
        try{

          resolve(response);
        }
        catch(e){
          reject(e);
        }  
      })
    });
  }

  async deleteCart(userId, cartId, entryNumber) {
    return await new Promise((resolve, reject) => {
      return this.http
      .delete(this.appService.getApiUrl() + `/orgUsers/${userId}/carts/${cartId}/removeEntry/${entryNumber}`)
      .subscribe(response => {
        try{

          resolve(response);
        }
        catch(e){
          reject(e);
        }  
      })
    });
  }

  async getCountries() {
    
    return await new Promise((resolve, reject) => {
      return this.http
      .get(this.appService.getApiUrl() + `/countries?fields=FULL&type=SHIPPING`)
      .subscribe((response) => {
        try{
          console.log("getCountries response::::::::::",response)
          resolve(response);
        }
        catch(e){
          console.log("error in getCountries::::::::::::",e)
          reject(e);
        }  
      })
    });
  }

  async getShippingUnits(userId) {
    
    return await new Promise((resolve, reject) => {
      return this.http
      .get(this.appService.getApiUrl() + `/users/${userId}/shippingUnits?fields=FULL`)
      .subscribe((response) => {
        try{
          console.log("getShippingUnits response::::::::::",response)
          resolve(response);
        }
        catch(e){
          console.log("error in getShippingUnits::::::::::::",e)
          reject(e);
        }  
      })
    });
  }

  async getRegions(countryIsoCode) {
    
    return await new Promise((resolve, reject) => {
      return this.http
      .get(this.appService.getApiUrl() + `/countries/${countryIsoCode}/regions?fields=FULL`)
      .subscribe((response) => {
        try{
          console.log("getRegions response::::::::::",response)
          resolve(response);
        }
        catch(e){
          console.log("error in getRegions::::::::::::",e)
          reject(e);
        }  
      })
    });
  }

  async saveShippingAddress(userId, cartId, addressObj) {
    
    return await new Promise((resolve, reject) => {
      return this.http
      .post(this.appService.getApiUrl() + `/users/${userId}/carts/${cartId}/addShippingInformation`,addressObj)
      .subscribe(response => {
        try{
          console.log("shipping address added:::::::",response)
          resolve(response);
        }
        catch(e){
          reject(e);
        }  
      })
    });
  }

  async addComments(userId, cartId, comment) {
    
    return await new Promise((resolve, reject) => {
      return this.http
      .post(this.appService.getApiUrl() + `/users/${userId}/carts/${cartId}/addComments`,comment)
      .subscribe(response => {
        try{
          console.log("addComments response::::::",response)
          resolve(response);
        }
        catch(e){
          console.log("error in addComments:::::::",e)
          reject(e);
        }  
      })
    });
  }

  async placeOrder(userId, cartId, termsChecked) {
    
    return await new Promise((resolve, reject) => {
      return this.http
      .post(this.appService.getApiUrl() + `/orgUsers/${userId}/orders?cartId=${cartId}&termsChecked=${termsChecked}&fields=FULL`,{})
      .subscribe(response => {
        try{
          console.log("placeOrder response::::::",response)
          resolve(response);
        }
        catch(e){
          console.log("error in placeOrder:::::::",e)
          reject(e);
        }  
      })
    });
  }

  async getOrderHistory(user, orderType, selectedProduct, selectedLocation, selectedSort, searchBoxSapmleReqNo, currentPage, pageSize) {
    
    return await new Promise((resolve, reject) => {
      return this.http
      .get(this.appService.getApiUrl() + `/users/${user}/orgOrders?orderType=${orderType}&fields=FULL&b2bUnitCode=${selectedLocation}&productCode=${selectedProduct}&purchasedOrderNumber=${searchBoxSapmleReqNo}&currentPage=${currentPage}&pageSize=${pageSize}&sort=${selectedSort}`)
      .subscribe((response) => {
        try{
          console.log("getSampleOrder response::::::::::",response)
          resolve(response);
        }
        catch(e){
          console.log("error in getSampleOrder::::::::::::",e)
          reject(e);
        }  
      })
    });
  }

  async getOrderDetails( orderCode, orderType, userId) {
    
    return await new Promise((resolve, reject) => {
      return this.http
      .get(this.appService.getApiUrl() + `/users/${userId}/userOrder/${orderCode}?orderType=${orderType}&fields=FULL`)
      .subscribe((response) => {
        try{
          console.log("getOrderDetails response::::::::::",response)
          resolve(response);
        }
        catch(e){
          console.log("error in getOrderDetails::::::::::::",e)
          reject(e);
        }  
      })
    });
  }

  async getOrderDocuments(user, orderCode) {
    
    return await new Promise((resolve, reject) => {
      return this.http
      .get(this.appService.getApiUrl() + `/users/${user}/orders/document/archiveId?salesOrderId=${orderCode}&fields=FULL`)
      .subscribe((response) => {
        try{
          console.log("getOrderDocuments response::::::::::",response)
          resolve(response);
        }
        catch(e){
          console.log("error in getOrderDocuments::::::::::::",e)
          reject(e);
        }  
      })
    });
  }

  async downloadPdfDoc(archiveDoc, archiveId, user) {
    
    return await new Promise((resolve, reject) => {
      return this.http
      .get(this.appService.getApiUrl() + `/users/${user}/orders/document/PDFDocument?archiveDoc=${archiveDoc}&archiveId=${archiveId}&userId=${user}&fields=FULL`)
      .subscribe((response) => {
        try{
          console.log("getOrderDocuments response::::::::::",response)
          resolve(response);
        }
        catch(e){
          console.log("error in getOrderDocuments::::::::::::",e)
          reject(e);
        }  
      })
    });
  }

  async sendFeedback(userId, payload) {
    
    return await new Promise((resolve, reject) => {
      return this.http
      .post(this.appService.getApiUrl() + `/users/${userId}/account/support-ticket/add-support-ticket`,payload)
      .subscribe(response => {
        try{
          console.log("send feedback response::::::",response)
          resolve(response);
        }
        catch(e){
          console.log("error in send feedback:::::::",e)
          reject(e);
        }  
      })
    });
  }

}
