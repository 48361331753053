import { ChangeDetectorRef, Component, OnInit } from '@angular/core';

import { UserServiceService } from '../APIcall/user-service.service';
import { ProductDetailsService } from '../APIcall/product-details.service';

// TODO:Spartacus - Many methods from 'UserService' were removed, for more details please look into 4.0 migration documentation.
import { TranslationService } from '@spartacus/core';
import { UserPasswordFacade } from '@spartacus/user/profile/root';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
declare var $: any;
@Component({
  selector: 'app-custom-reset-password',
  templateUrl: './custom-reset-password.component.html',
  styleUrls: ['./custom-reset-password.component.scss']
})
export class CustomResetPasswordComponent implements OnInit {

  registerForm: UntypedFormGroup;
  hide: boolean = true;
  submitted = false;
  hideBtnText: string = '';
  hideNewPass: boolean = true;
  hideBtnTextNewPass: string = '';
  hideConfirmPass: boolean = true;
  hideBtnTextConfirmPass: string = '';
  user: any = {};
  newPwd: any = "";
  oldPwd: any = "";
  confirmNewPwd: any = "";
  isnewPwd: boolean = false;
  isoldPwd: boolean = false;
  isconfirmNewPwd: boolean = false;
  isConfirmpassMatchErrorMessage: boolean = false;
  __styleToaster: string = "none";
  userToken: any = "";
  passShowText: string = '';
  passHideText: string = '';
  passShowText$: Observable<string> = this.translation.translate('customUpdatePassword.show')
  passHideText$: Observable<string> = this.translation.translate('customUpdatePassword.hide')
  constructor(private translation: TranslationService, private route: ActivatedRoute, private ref: ChangeDetectorRef, private formBuilder: UntypedFormBuilder, private userService: UserServiceService, protected userServiceOob: UserPasswordFacade, private productDetailsService: ProductDetailsService, private router: Router) { }

  ngOnInit(): void {
    this.productDetailsService.changeBreadcrumb({ "breadcumTitle": "reset-password", "categoryName": "", "productName": "" });

    this.registerForm = this.formBuilder.group({
      oldPassword: ['', [Validators.required]]
    })

    this.route.queryParamMap.subscribe((params) => {
      console.log(params['params']['token']);
      this.userToken = params['params']['token'];
      this.ref.detectChanges();


    });
    this.passShowText$.subscribe(trans => {
      this.passShowText = trans
      this.hideBtnText = trans
      this.hideBtnTextNewPass = trans
      this.hideBtnTextConfirmPass = trans
    })
    this.passHideText$.subscribe(trans => {
      this.passHideText = trans
    })

  }
  get fval() {
    return this.registerForm.controls;
  }
  toggleHide() {
    this.hide = !this.hide;
    if (!this.hide) {
      this.hideBtnText = this.passHideText;
    } else {
      this.hideBtnText = this.passShowText;
    }
  }
  toggleHideNewPass() {
    this.hideNewPass = !this.hideNewPass;
    if (!this.hideNewPass) {
      this.hideBtnTextNewPass = this.passHideText;
    } else {
      this.hideBtnTextNewPass = this.passShowText;
    }
  }
  toggleHideConfirmPass() {
    this.hideConfirmPass = !this.hideConfirmPass;
    if (!this.hideConfirmPass) {
      this.hideBtnTextConfirmPass = this.passHideText;
    } else {
      this.hideBtnTextConfirmPass = this.passShowText;
    }
  }
  //check password
  /*
  •	Minimum length 8 characters 
  •	Must contains lower case characters
  •	Must contains upper case characters
  •	Must contains at least two symbols
  •	Must start with a number 
  */
  checkPassword() {
    var psw = $("input[name=newPassword]").val();
    if (psw.match(/^\d/)) {
      $(".digit").empty();
      $(".digit").html("<i class='far fa-check-circle'></i>");
      $(".digit").css("color", "#1E9D8B");
    }
    else {
      $(".digit").empty();
      $(".digit").html("<i class='fa fa-exclamation-circle'></i>");
      $(".digit").css("color", "#00A1DE");
    }

    if (psw.match(/[A-Z]/)) {
      $(".upper").empty();
      $(".upper").html("<i class='far fa-check-circle'></i>");
      $(".upper").css("color", "#1E9D8B");
    }
    else {
      $(".upper").empty();
      $(".upper").html("<i class='fa fa-exclamation-circle'></i>");
      $(".upper").css("color", "#00A1DE");
    }

    if (psw.match(/[a-z]/)) {
      $(".lower").empty();
      $(".lower").html("<i class='far fa-check-circle'></i>");
      $(".lower").css("color", "#1E9D8B");
    }
    else {
      $(".lower").empty();
      $(".lower").html("<i class='fa fa-exclamation-circle'></i>");
      $(".lower").css("color", "#00A1DE");
    }

    if (!(psw.length < 8)) {
      $(".min").empty();
      $(".min").html("<i class='far fa-check-circle'></i>");
      $(".min").css("color", "#1E9D8B");
    }
    else {
      $(".min").empty();
      $(".min").html("<i class='fa fa-exclamation-circle'></i>");
      $(".min").css("color", "#00A1DE");
    }

    //TODO: at least two symbols, not one
    if ((psw.match(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/g) || []).length > 1) {
      $(".symbol").empty();
      $(".symbol").html("<i class='far fa-check-circle'></i>");
      $(".symbol").css("color", "#1E9D8B");
    }
    else {
      $(".symbol").empty();
      $(".symbol").html("<i class='fa fa-exclamation-circle'></i>");
      $(".symbol").css("color", "#00A1DE");
    }
  }
  disableButton() {
    var psw = $("input[name=newPassword]").val();
    var psw = $("input[name=newPassword]").val();

    // Check if the password starts with a number
    var startsWithNumber = /^\d/.test(psw);

    // Check if the password contains at least one uppercase letter
    var hasUppercase = /[A-Z]/.test(psw);

    // Check if the password contains at least one lowercase letter
    var hasLowercase = /[a-z]/.test(psw);

    // Check if the password is at least 8 characters long
    var isLongEnough = psw.length >= 8;

    // Check if the password contains at least two symbols
    var symbols = psw.match(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/g);
    var hasTwoSymbols = symbols && symbols.length >= 2;

    // Combine all conditions
    var isValid = startsWithNumber && hasUppercase && hasLowercase && isLongEnough && hasTwoSymbols;

    return isValid;
  }
  updatePassword() {
    // this.isoldPwd = false;
    this.isnewPwd = false;
    this.isconfirmNewPwd = false;
    //this.__styleToaster = "block"
    // this.submitted = true;
    // if (this.registerForm.invalid) {
    //   return;
    // }
    // if(this.oldPwd == ''){
    //   this.isoldPwd = true;
    // }
    if (this.newPwd == '') {
      this.isnewPwd = true;
    }
    if (this.confirmNewPwd == '') {
      this.isconfirmNewPwd = true;
    }
    if (this.newPwd !== this.confirmNewPwd && this.confirmNewPwd != '') {
      this.isConfirmpassMatchErrorMessage = true;
      return
    } else {
      this.isConfirmpassMatchErrorMessage = false;
    }
    if (this.isnewPwd == true || this.isconfirmNewPwd == true) {
      return
    }

    console.log("update pwd::::::::", this.newPwd)
    this.userServiceOob.reset(this.userToken, this.newPwd).subscribe((res) => {
      console.log("pwd reset successfully::", res)
      this.__styleToaster = "block"
      setTimeout(() => {
        this.__styleToaster = "none"
        this.ref.detectChanges();
        this.naviateToLoginPage();
      }, 8000);
      this.ref.detectChanges();
      //this.naviateToHomePage();
    });
     this.userService.updatePassword(this.user.uid, this.newPwd, this.oldPwd).then((res) => {
       console.log("response from update pwd:::::::::::::", res);
     }).catch((err) => {
       console.log("error in update pwd::::::::::",err)
     })
  }

  naviateToLoginPage() {
    this.router.navigate(['/login']);
  }

}
