<div class="container">
    <div class="row align-items-center">
        <div class="col-6">
            <label class="__cart-title">{{'customOrderSummary.summary' | cxTranslate}}</label>
            
            <!-- <hr class="__cart-hr"> -->
        </div>
        <div class="col-6">
            <button (click)="navigateTocart()" class="btn btn-primary btn-ghost-inverse btn-simple float-right" type="button">{{'customOrderSummary.editCard' | cxTranslate}}</button>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-12 col-md-12">
            <hr class="__cart-hr">
        </div>
    </div>
    <div *ngFor="let cart of cartDetailsList" class="__cart-list">
        <div class="row align-items-center">
        <div class="col-3">
            <div class="__cart-imag-bg text-center">
                <img style="cursor:pointer" class="__cart-image" src="{{cart.categoryImage}}" alt="">
            </div>
        </div>
        <div class="col-5">
            <label style="cursor:pointer" class="__cart-section-title">{{cart.productName}}</label>
        </div>
        <div class="col-3">
            <label class="__cart-section-qty">{{cart.infineumQuantity + " " + cart.unit}}</label>
        </div>
</div>
</div>

</div>