import { Component, OnInit, NgZone, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { ProductDetailsService } from '../../APIcall/product-details.service';
import { UserServiceService } from '../../APIcall/user-service.service';

@Component({
  selector: 'app-product-image',
  templateUrl: './product-image.component.html',
  styleUrls: ['./product-image.component.scss']
})
export class ProductImageComponent implements OnInit {

  productTitle: any = '';
  productSummary: any = '';
  productDescription: any = '';
  userId: any = "";

  constructor(private productDetailsService: ProductDetailsService, private zone:NgZone, private ref: ChangeDetectorRef, private router: Router, private userService: UserServiceService) { 
    
  }

  ngOnInit(): void {
    this.userService.getUserDetails().then((user) => {
      this.userId = user["uid"];
      this.productDetailsService.getProductDetail(this.userId);
    })
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.productDetailsService.setBreadcrumbDetail(this.userId);
    
    this.productDetailsService.currentProduct.subscribe((data) => {
     
    // this.zone.runOutsideAngular(() => { // <== added
      this.productTitle = data?.name;
      this.productSummary = data?.summary;
      this.productDescription = data?.description;
      this.ref.detectChanges();
    // });
        
             
      
    })
  }

}
