<!-- <div class="container mw-100">
    <div class="row">
        <div class="col-12 banner">
            <div class="m-auto w-100">
                <h1 class="title">{{ title }}</h1>
            </div>
        </div>
    </div>
</div> -->

<div class="container">
    <div class="page page-help">
        <div class="row">
            <div class="col-12">
                <div class="card m-0">
                  <div class="card-body">
                      <h3 class="card-title text-left">{{'customHelp.topic' | cxTranslate}}</h3>
                        <ul class="list-unstyled">
                            <li>
                                <a class="card-link" (click)="scrollTo('topic1')">{{'customHelp.accessingInfineum' | cxTranslate}}</a>
                            </li>
                            <li>
                                <a class="card-link" (click)="scrollTo('topic2')">{{'customHelp.usingInfineum' | cxTranslate}}</a>
                            </li>
                        </ul>
                  </div>
                </div>
            </div>
            <!-- TOPICS -->
            <div class="col-12">
                <section class="topic" id="topic1">
                    <h3>{{'customHelp.accessingInfineum' | cxTranslate}}</h3>
                    <div *ngIf="!isUserLoggedIn" class="faq">
                        <h4 class="question">{{'customHelp.question1' | cxTranslate}}</h4>
                        <div class="answer">{{'customHelp.answer1' | cxTranslate}} 
                            <a style="cursor:pointer" class="link" (click)="navigateToLogin()">{{'customHelp.theSignIn' | cxTranslate}}</a>
                        </div>
                    </div>
                    <div *ngIf="!isUserLoggedIn" class="faq">
                        <h4 class="question">{{'customHelp.mySignInCre' | cxTranslate}}</h4>
                        <div class="answer">{{'customHelp.answer2' | cxTranslate}}</div>
                    </div>
                    <div *ngIf="isUserLoggedIn" class="faq">
                        <h4 class="question">{{'customHelp.question3' | cxTranslate}}</h4>
                        <div class="answer">{{'customHelp.answer3' | cxTranslate}}<a style="cursor:pointer" class="link" (click)="navigateToChangePassword()">{{'customHelp.updatePass' | cxTranslate}}</a>{{'customHelp.forwordSlash' | cxTranslate}}<a style="cursor:pointer" class="link" (click)="navigateToPersonalInfo()">{{'customHelp.personalInfoA' | cxTranslate}}</a>{{'customHelp.answer3OptionText' | cxTranslate}}</div>
                    </div>
                    <div *ngIf="isUserLoggedIn" class="faq">
                        <h4 class="question">{{'customHelp.question4' | cxTranslate}}</h4>
                        <div class="answer">{{'customHelp.answer4' | cxTranslate}}<a style="cursor:pointer" class="link" (click)="navigateToContact()">{{'customHelp.notify' | cxTranslate}}</a>{{'customHelp.answer41' | cxTranslate}}
                        </div>
                    </div>
                    <div *ngIf="isUserLoggedIn" class="faq">
                        <h4 class="question">{{'customHelp.question5' | cxTranslate}}</h4>
                        <div class="answer"><a style="cursor:pointer" class="link" (click)="navigateToContact()">{{'customHelp.contactUs' | cxTranslate}}</a>{{'customHelp.answer5' | cxTranslate}} 
                            
                        </div>
                    </div>
                    <div *ngIf="isUserLoggedIn" class="faq">
                        <h4 class="question">{{'customHelp.question6' | cxTranslate}}</h4>
                        <div class="answer">{{'customHelp.answer6' | cxTranslate}}                        </div>
                    </div>
                    <div *ngIf="isUserLoggedIn" class="faq">
                        <h4 class="question">{{'customHelp.question7' | cxTranslate}}</h4>
                        <div class="answer">{{'customHelp.answer7' | cxTranslate}}                        </div>
                    </div>
                    <!-- Scroll to top arrow -->
                    <app-scroll-to-top></app-scroll-to-top>
                </section>
                <section class="topic" id="topic2">
                    <h3>{{'customHelp.usingInfineum' | cxTranslate}}</h3>
                    <div class="faq">
                        <h4 class="question">{{'customHelp.question8' | cxTranslate}}</h4>
                        <div class="answer">{{'customHelp.answer8' | cxTranslate}} 
                            <!-- <a [routerLink]="[]" class="link" (click)="navigateToLogin()">the sign in page</a>.     -->
                        </div>
                    </div>
                    <div class="faq">
                        <h4 class="question">{{'customHelp.question9' | cxTranslate}}</h4>
                        <div class="answer">{{'customHelp.answer9' | cxTranslate}}</div>
                    </div>
                    <div class="faq">
                        <h4 class="question">{{'customHelp.question10' | cxTranslate}}</h4>
                        <div class="answer">{{'customHelp.answer10' | cxTranslate}}</div>
                    </div>
                    <div class="faq">
                        <h4 class="question">{{'customHelp.question11' | cxTranslate}}</h4>
                        <div class="answer">{{'customHelp.answer11' | cxTranslate}} 
                            <!-- <a [routerLink]="[]" class="link" (click)="navigateToLogin()">the sign in page</a>. -->
                        </div>
                    </div>
                    <!-- <div class="faq">
                        <h4 class="question">I have forgotten my password how can I reset it?</h4>
                        <div class="answer">Please use the password reset function on 
                            <a [routerLink]="[]" class="link" (click)="navigateToLogin()">the sign in page</a>.    
                        </div>
                    </div> -->
                    <!-- Scroll to top arrow -->
                    <app-scroll-to-top></app-scroll-to-top>
                </section>
                <button type="submit" (click)="backClicked()" class="btn btn-primary btn-back mt-5">{{'customHelp.back' | cxTranslate}}</button>
            </div>
        </div>
    </div><!-- .page -->
</div>