
<div class="progress-loader" [hidden]="!loading">
  <div class="col-md-12" style="text-align: center;">
    <div class="loader-animation">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>
  </div>
</div>