import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslationService } from '@spartacus/core';
import { Observable } from 'rxjs';

import { ProductDetailsService } from '../APIcall/product-details.service';
import { UserServiceService } from '../APIcall/user-service.service';

declare var $: any;

@Component({
  selector: 'app-custom-order-history',
  templateUrl: './custom-order-history.component.html',
  styleUrls: ['./custom-order-history.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomOrderHistoryComponent implements OnInit {

  user:any = {};
  orderType: any = "";
  productFilter: any = [];
  locationfilter: any = [];
  sorts: any = [];
  orderList: any = [];
  selectedProductCode: any = "";
  selectedProductName: any = "";
  selectedLocationCode: any = "";
  selectedLocationName: any = "";
  selectedSort: any = "";
  totalResults: any = 0;
  searchBoxSapmleReqNo: any = "";
  currentPage: any = 1;
  pageSize: any = 10;
  totalPagesCount: any = 0;
  totalPages: any = [];
  start: any = 0;
  current: any = 0;
  productNo: any = "";
  __crossBtn: boolean = false;
  orderTypeErrorText: string = '';
  currentPagePagination: any = "";
  searchFilter: any = "";
  selectedDateFilter: any = "";
  selectedLocationFilter: any = "";
  selectedProductFilter: any = "";
  returnOrderType: any = "";
  orderHistoryTabList: any = [];
  standardText$: Observable<string> = this.translation.translate('customOrderHistory.standardText');
  sampleText$: Observable<string> = this.translation.translate('customOrderHistory.sampleText');
  productFilterText$: Observable<string> = this.translation.translate('customOrderHistory.productText');
  locationFilterText$: Observable<string> = this.translation.translate('customOrderHistory.shipToLocation');
  sortByDateText$: Observable<string> = this.translation.translate('customOrderHistory.date');
  sortByInfineumText$: Observable<string> = this.translation.translate('customOrderHistory.infineumOrderNumber');
  standardTabText$: Observable<string> = this.translation.translate('customOrderHistory.standard_sales_order');
  sampleTabText$: Observable<string> = this.translation.translate('customOrderHistory.request_sample');
  constructor(private route: ActivatedRoute, private translation: TranslationService, private productDetailsService: ProductDetailsService, private userService: UserServiceService, private ref: ChangeDetectorRef, private router: Router) { }

  ngOnInit(): void {
    this.orderHistoryTabList = [];
    this.productDetailsService.changeBreadcrumb({"breadcumTitle" : "order-history", "categoryName": "", "productName": ""});
    
    this.route.queryParamMap.subscribe((params) => {
      console.log(params['params']['code']);
      this.returnOrderType = params['params']['orderType'];
      this.currentPagePagination = params['params']['page'];
      this.searchFilter = params['params']['search']
      this.selectedDateFilter = params['params']['sort']
      this.selectedLocationFilter = params['params']['loc']
      this.selectedProductFilter = params['params']['product']
      if(this.returnOrderType == "standard"){
        $("#standardTab").click();
        this.orderType = "standard";
      }else if(this.returnOrderType == "sample"){
        $("#sampleTab").click();
        this.orderType = "sample";
      } else {
        this.orderType = "standard";
      }
      this.selectedProductCode = this.selectedProductFilter && this.selectedProductFilter == "PRODUCT" ? "" : (this.selectedProductFilter ? this.selectedProductFilter : "");
      this.selectedLocationCode = this.selectedLocationFilter && this.selectedLocationFilter == "SHIP-TO LOCATION" ? "" :  (this.selectedLocationFilter ? this.selectedLocationFilter : "");
      this.selectedSort = this.selectedDateFilter ? this.selectedDateFilter : "";
      this.searchBoxSapmleReqNo = this.searchFilter ? this.searchFilter : "";
      this.currentPage = this.currentPagePagination ? parseInt(this.currentPagePagination) : 1;
      
      this.ref.detectChanges();
      
    });
    console.log("In order History initial geting params values:::::::::", 
    this.currentPagePagination,
    this.searchFilter,
    this.selectedDateFilter,
    this.selectedLocationFilter,
    this.selectedProductFilter,
    this.returnOrderType
    )
    this.standardText$.subscribe(trans => {
      this.orderTypeErrorText = trans
    })
    // this.sampleText$.subscribe(trans => {
    //   this.orderTypeErrorText = trans
    // })
    
    this.userService.getUserDetails().then((user) => {
      this.user = user;
      this.getOrderHistory(this.user.uid, this.selectedProductCode, this.selectedLocationCode, this.selectedSort, this.searchBoxSapmleReqNo, this.currentPage-1, this.pageSize)
    }).catch((err) => {
      console.log("error in getting user details:::",err)
    })
    $(".numberPage"+this.currentPage).removeClass("activeArrow");
    $("#clear").css("display", "none");
    this.standardTabText$.subscribe(trans => {
      if(this.orderType == "standard"){
        this.orderHistoryTabList.push({name : trans, selected : true, val : "#tab1"})
      }else{
        this.orderHistoryTabList.push({name : trans, selected : false, val : "#tab1"})
      }
    })
    this.sampleTabText$.subscribe(trans => {
      if(this.orderType == "sample"){
        this.orderHistoryTabList.push({name : trans, selected : true, val : "#tab2"})
      }else{
        this.orderHistoryTabList.push({name : trans, selected : false, val : "#tab2"})
      }
    })
  }

  tabChange(orderType) {
    if(orderType == 'standard') {
      this.standardText$.subscribe(trans => {
        this.orderTypeErrorText = trans
      })
    } else if(orderType == 'sample') {
      
      this.sampleText$.subscribe(trans => {
        this.orderTypeErrorText = trans
      })
    }
    this.orderType = orderType;
    this.productFilter = [];
    this.locationfilter = [];
    this.sorts = [];
    this.orderList = [];
    this.selectedProductCode = "";
    this.selectedLocationCode = "";
    this.productFilterText$.subscribe(trans => {
      this.selectedProductName = trans;
    })
    this.locationFilterText$.subscribe(trans => {
      this.selectedLocationName = trans;
    })
    
    
    this.selectedSort = "";
    this.searchBoxSapmleReqNo = "";
    this.productNo = "";
    this.currentPage = 1;
    this.totalResults = 0;
    this.ref.detectChanges();
    this.getOrderHistory(this.user.uid, this.selectedProductCode, this.selectedLocationCode, this.selectedSort, this.searchBoxSapmleReqNo, this.currentPage-1, this.pageSize);
  }

  getOrderHistory(userEmail, selectedProductCode, selectedLocationCode, selectedSort, searchBoxSapmleReqNo, currentPage, pageSize) {
    this.productFilter = [];
    this.locationfilter = [];
    this.sorts = [];
    this.orderList = [];
    this.start = 0;
    this.current = 0;
    // this.totalResults = 0;
    this.ref.detectChanges();
    this.productDetailsService.getOrderHistory(userEmail, this.orderType, selectedProductCode, selectedLocationCode, selectedSort, searchBoxSapmleReqNo, currentPage, pageSize).then((res) => {
      console.log("res from order history::::::::",res);
      $(".numberPage"+this.currentPage).addClass("activeArrow");
      if(res['infineumFacets']) {
        res['infineumFacets'].map((facet) => {
          if(facet['name'] == 'purchasedProducts') {
            this.productFilter = facet['values'];
            this.productFilter.sort((a,b) =>a.name.localeCompare(b.name));
          } else if(facet['name'] == 'shipToSoldToUnits') {
            this.locationfilter = facet['values'];
            this.locationfilter.sort((a,b) =>a.name.localeCompare(b.name));
          }
        })
        this.productFilterText$.subscribe(trans => {
          this.productFilter.unshift({"name": trans, "code" : ""});
          for(let i=0; i<this.productFilter.length; i++){
            if(this.productFilter[i].code == this.selectedProductCode){
              this.selectedProductName = this.productFilter[i].name;
              break;
            }
          }
        })
        this.locationFilterText$.subscribe(trans => {
          this.locationfilter.unshift({"name": trans, "code" : ""});
          for(let i=0; i<this.locationfilter.length; i++){
            if(this.locationfilter[i].code == this.selectedLocationCode){
              this.selectedLocationName = this.locationfilter[i].name;
              break;
            }
          }
        })
        
        this.ref.detectChanges();
        console.log("this.productFilter:::::::::::",this.productFilter)
        console.log("this.locationfilter:::::::::::",this.locationfilter)
        
      }
      if(res['sorts'].length > 0) {
        res['sorts'].map((sort) => {
          if(sort['code'] == 'byDate') {
            this.sortByDateText$.subscribe(trans => {
              sort['code'] = trans;
            })
            
          } else if(sort['code'] == 'byOrderNumber') {
            this.sortByInfineumText$.subscribe(trans => {
              sort['code'] = trans;
            })
            
          }
          this.sorts.push(sort);
          this.ref.detectChanges();
        })
        for(let i=0; i<this.sorts.length; i++){
          if(this.sorts[i].code == this.selectedDateFilter){
            this.sorts[i].selected = true;
            break;
          }
        }
        // this.sorts = res['sorts'];
      } else {
        this.sorts = [];
        this.ref.detectChanges();
      }
      this.productNo = this.searchBoxSapmleReqNo;
      res['orders'].map((order) => {
        if(order['requestedDeliveryDate']) {
          let tempDate = new Date(order['requestedDeliveryDate']);
          // const today = new Date();
          const yyyy = tempDate.getFullYear();
          let mm: any = tempDate.getMonth() + 1; // Months start at 0!
          let dd: any = tempDate.getUTCDate();
  
          if (dd < 10) dd = '0' + dd;
          if (mm < 10) mm = '0' + mm;
  
          order['requestedDeliveryDate'] = dd + '/' + mm + '/' + yyyy;
        }
        
      })
      this.orderList = res['orders'];
      this.totalResults = res['pagination']['totalResults'];
      this.totalPages = [];
      this.totalPagesCount = res["pagination"]["totalPages"];
      let pageCount = res["pagination"]["totalPages"];
      for(let i=0; i<pageCount; i++) {
        this.totalPages.push(i+1);
      }
      this.start = res["pagination"]['currentPage'] != 0 ? ((res["pagination"]['currentPage']) * res["pagination"]['pageSize']) + 1 : 1;
         
      if(this.start + res["pagination"]['pageSize'] > res["pagination"]['totalResults']){
        this.current = res["pagination"]["totalResults"];
      }
      else{
        this.current = (this.start - 1) + res["pagination"]['pageSize'];
      }
      console.log("this.start:::::::",this.start);
      console.log("this.current::::::::::",this.current)
      console.log("this.orderList::::",this.orderList)
      this.ref.detectChanges();
    }).catch((err) => {
      this.totalResults = 0;
      console.log("error in get order history:::::",err);
    })
    setTimeout(() => {
      $(".numberPage"+this.currentPage).addClass("activeArrow");
      this.activeArrows(this.currentPage);
    },1000)
  }

  selectProductFilter (event: any) {
    this.currentPage = 1;
    this.selectedProductCode = event.target.value;
    this.productFilter.map((product) => {
      if(product.code == event.target.value) {
        this.selectedProductName = product.name;
        this.ref.detectChanges();
      }
    })
    this.getOrderHistory(this.user.uid, this.selectedProductCode, this.selectedLocationCode, this.selectedSort, this.searchBoxSapmleReqNo, this.currentPage-1, this.pageSize);
    console.log("this.selectedProductCode:::::::::::",this.selectedProductCode);
    // this.getSearchProductList(this.itemsPerPage, this.currentPage-1, this.selectedSort, this.selectedFilterArr);
  }

  selectLocationFilter (event: any) {
    this.currentPage = 1;
    this.selectedLocationCode = event.target.value;
    this.locationfilter.map((location) => {
      if(location.code == event.target.value) {
        this.selectedLocationName = location.name;
        this.ref.detectChanges();
      }
    })
    this.getOrderHistory(this.user.uid, this.selectedProductCode, this.selectedLocationCode, this.selectedSort, this.searchBoxSapmleReqNo, this.currentPage-1, this.pageSize);
    console.log("this.selectedLocationCode:::::::::::",this.selectedLocationCode);
    // this.getSearchProductList(this.itemsPerPage, this.currentPage-1, this.selectedSort, this.selectedFilterArr);
  }

  selectSort (event: any) {
    // this.selectedSort = event.target.value;
        this.sortByDateText$.subscribe(dateTrans => {
        this.sortByInfineumText$.subscribe(orderTrans => {
          if(event.target.value == dateTrans) {
            this.selectedSort = 'byDate'
          } else if(event.target.value == orderTrans) {
            this.selectedSort = 'byOrderNumber'
          }
        })
      })
   
    this.currentPage = 1;
    this.ref.detectChanges();
    this.getOrderHistory(this.user.uid, this.selectedProductCode, this.selectedLocationCode, this.selectedSort, this.searchBoxSapmleReqNo, this.currentPage-1, this.pageSize);
    console.log("this.selectedSort:::::::::::",this.selectedSort);
    // this.getSearchProductList(this.itemsPerPage, this.currentPage-1, this.selectedSort, this.selectedFilterArr);
  }

  onEnterSampleReqNumber(reqNo: any) {
    this.currentPage = 1;
    this.searchBoxSapmleReqNo = reqNo;
    this.getOrderHistory(this.user.uid, this.selectedProductCode, this.selectedLocationCode, this.selectedSort, this.searchBoxSapmleReqNo, this.currentPage-1, this.pageSize);
    this.__crossBtn = false; // Clear x
  }
  __resetValue() {
    this.productNo = "";
    this.__crossBtn = false; 
    if(this.searchBoxSapmleReqNo!=""){
      //$('input[type=search]').on('search', function () {
        this.searchBoxSapmleReqNo = "";
        this.getOrderHistory(this.user.uid, this.selectedProductCode, this.selectedLocationCode, this.selectedSort, this.searchBoxSapmleReqNo, this.currentPage-1, this.pageSize);
    //});
    }
  
  }
  __crossBtnVisiblity(value){
    if(value){
      this.__crossBtn = true; 
    }else{
      this.__crossBtn = false; 
    }
  }
  changePage(pageNumber) {
    $(".numberPage"+this.currentPage).removeClass("activeArrow");
    if(this.currentPage != pageNumber){
      this.currentPage = pageNumber;
      // this.getSearchProductList(this.itemsPerPage, this.currentPage - 1, this.selectedSort, this.selectedFilterArr);
      this.getOrderHistory(this.user.uid, this.selectedProductCode, this.selectedLocationCode, this.selectedSort, this.searchBoxSapmleReqNo, this.currentPage-1, this.pageSize);
    }
    this.currentPage = pageNumber;
    this.activeArrows(this.currentPage);
    
    $(".numberPage"+this.currentPage).addClass("activeArrow");
    
  }
  changePageBack() {
    $(".numberPage"+this.currentPage).removeClass("activeArrow");
    this.currentPage = this.currentPage -1;
    this.activeArrows(this.currentPage);
    // this.getSearchProductList(this.itemsPerPage, this.currentPage - 1, this.selectedSort, this.selectedFilterArr);
    this.getOrderHistory(this.user.uid, this.selectedProductCode, this.selectedLocationCode, this.selectedSort, this.searchBoxSapmleReqNo, this.currentPage-1, this.pageSize);
    $(".numberPage"+this.currentPage).addClass("activeArrow");
  }
  changePageNext() {
    $(".numberPage"+this.currentPage).removeClass("activeArrow");
    this.currentPage = this.currentPage + 1;
    this.activeArrows(this.currentPage);
    // this.getSearchProductList(this.itemsPerPage, this.currentPage - 1, this.selectedSort, this.selectedFilterArr);
    this.getOrderHistory(this.user.uid, this.selectedProductCode, this.selectedLocationCode, this.selectedSort, this.searchBoxSapmleReqNo, this.currentPage-1, this.pageSize);
    $(".numberPage"+this.currentPage).addClass("activeArrow");
  }
  private activeArrows(currentPage : number){
    if(currentPage == 1 && (currentPage) == this.totalPagesCount){
      $(".backArrow").prop("disabled", true);
      $(".arrow").prop("disabled", true);
    }
    else if(currentPage == 1){
      $(".backArrow").prop("disabled", true);
      $(".arrow").prop("disabled", false);
    }
    else if((currentPage) == this.totalPagesCount){
      $(".backArrow").prop("disabled", false);
      $(".arrow").prop("disabled", true);
    } 
    else{
      $(".backArrow").prop("disabled", false);
      $(".arrow").prop("disabled", false);
    }
  }

  navigateToHomePage() {
    this.router.navigate(['/']);
  }

  navigateToOrderDetails(orderCode, orderType) {
    this.router.navigate(['/my-account/order'], { queryParams: { code: orderCode, type: orderType,
    product: this.selectedProductCode, loc: this.selectedLocationCode,
    sort: this.selectedSort, search: this.searchBoxSapmleReqNo, page: this.currentPage
    } });
    console.log("params values::::::::", this.selectedProductName, this.selectedLocationName,
   this.selectedSort, this.searchBoxSapmleReqNo, this.currentPage, this.orderType)
  }
  getPage(page:any) {
    this.currentPage = page;
    this.currentPage = page;
    window.scrollTo(0, 0);
    this.ref.detectChanges();
    this.getOrderHistory(this.user.uid, this.selectedProductCode, this.selectedLocationCode, this.selectedSort, this.searchBoxSapmleReqNo, this.currentPage-1, this.pageSize);
  }
  onChange(value) {
    //$('.tab-content .tab-pane:not(' + value + ')').removeClass('active');
   // $('.tab-content ' + value).tab('show');
    if (value == '#tab1') {
        this.tabChange('standard');
        $("#tab2").removeClass('active')
        $("#tab1").addClass('active')
    } else if (value == '#tab2') {
        this.tabChange('sample');
        $("#tab1").removeClass('active')
        $("#tab2").addClass('active')
    } else {
        
    }
  }

}
