<!-- <div class="container mw-100">
    <div class="row">
        <div class="col-12 banner">
            <div class="m-auto w-100">
                <h1 class="title">{{ title }}</h1>
            </div>
        </div>
    </div>
</div> -->

<div class="container">
    <div class="page-namepage">
        <div class="row">
            <div class="col-12">
                <div class="page-content">
                    <!-- CONTENT -->
                    <p><span>{{'customStaticPage.p1' | cxTranslate}}</span></p>

                    <video class="namepage-video" controls>
                        <source src="" type="video/mp4">
                    </video>

                    <p>{{'customStaticPage.p2' | cxTranslate}}</p>

                    <ul class="list-namepage">
                        <li>{{'customStaticPage.l1' | cxTranslate}}</li>
                        <li>{{'customStaticPage.l2' | cxTranslate}}</li>
                        <li>{{'customStaticPage.l3' | cxTranslate}}</li>
                        <li>{{'customStaticPage.l4' | cxTranslate}}</li>
                    </ul>

                    <button type="button" class="btn btn-primary mt-5 m-auto" >{{'customStaticPage.videoTranscript' | cxTranslate}}</button>

                    <h2>{{'customStaticPage.subHeading' | cxTranslate}}</h2>

                    <p>{{'customStaticPage.p3' | cxTranslate}}</p>

                    <ul class="list-namepage number">
                        <li>{{'customStaticPage.l1' | cxTranslate}}</li>
                        <li>{{'customStaticPage.l2' | cxTranslate}}</li>
                        <li>{{'customStaticPage.l3' | cxTranslate}}</li>
                        <li>{{'customStaticPage.l4' | cxTranslate}}</li>
                    </ul>   
                    
                    <video class="namepage-video" controls>
                        <source src="" type="video/mp4">
                    </video>
       
                </div>
            </div>
        </div>
    </div><!-- .page -->
</div>

