import { attachmentDetails } from "./attachmentDetails";

export class ProductMsdsModel {
    materialNumber : string;
    attachmentDetails : Array<attachmentDetails>

    constructor(){
        this.materialNumber = "";
        this.attachmentDetails = new Array<attachmentDetails>();
    }
}