import { NgModule } from '@angular/core';
import { RouterModule, Routes } from "@angular/router";
import { ClaimsearchService } from './APIcall/claimsearch.service';
import { AppComponent } from './app.component';
import { BannerProductComponent } from './banner-product/banner-product.component';
import { ClaimSearchComponent } from './claim-search/claim-search.component';
import { CustomerSpecificationComponent } from './customer-specification/customer-specification.component';

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forRoot([
      {
        path: 'customer-specification/:formula/:name',
        component: CustomerSpecificationComponent,
        // resolve: {
        //   claimSearch: ClaimsearchService
        // }
      }
    ], {
    anchorScrolling: 'enabled',
    initialNavigation: 'enabledBlocking',
    scrollPositionRestoration: 'enabled'
})
  ],
  // providers: [ClaimsearchService]
})
export class AppRoutingModule { }
