import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslationService } from '@spartacus/core';
import { Observable } from 'rxjs';

import { ProductDetailsService } from '../APIcall/product-details.service';
import { UserServiceService } from '../APIcall/user-service.service';
import { AppService } from '../app.services';

declare var $: any;

@Component({
  selector: 'app-custom-order-details',
  templateUrl: './custom-order-details.component.html',
  styleUrls: ['./custom-order-details.component.scss']
})
export class CustomOrderDetailsComponent implements OnInit {

  user: any = {};
  orderDetails: any = {};
  apiDomainUrl: any = "";
  unit: any = "";
  formattedAddress: any = [];
  attachmentList: any = [];
  orderAttachmentDoc: any = [];
  invoiceAttachmentDoc: any = [];
  shippingAttachmentDoc: any = [];
  orderType: any = "";
  btnTxt: any = ""
  trackingNo: any = "";
  trackingUrl: any = "";
  downloadBtnText: string = ''
  currentPagePagination: any = ""
  searchFilter: any = ""
  selectedDateFilter: any = ""
  selectedLocationFilter: any = ""
  selectedProductFilter: any = ""
  downloadBtnText$: Observable<string> = this.translation.translate('customOrderDetails.capsDownload')
  constructor(private translation: TranslationService, private route: ActivatedRoute, private productDetailsService: ProductDetailsService, private userService: UserServiceService, private appService: AppService, private ref: ChangeDetectorRef, private router: Router) { }

  ngOnInit(): void {


    this.productDetailsService.changeBreadcrumb({"breadcumTitle" : "order-details", "categoryName": "", "productName": ""});

    this.apiDomainUrl = this.appService.getApiDomainUrl();
    this.route.queryParamMap.subscribe((params) => {
      console.log(params['params']['code']);
      this.orderType = (params['params']['type'] == 'sample') ? "Sample Request" : "Standard Sales Order";
      this.currentPagePagination = params['params']['page'];
      this.searchFilter = params['params']['search']
      this.selectedDateFilter = params['params']['sort']
      this.selectedLocationFilter = params['params']['loc']
      this.selectedProductFilter = params['params']['product']
      this.ref.detectChanges();
      this.userService.getUserDetails().then((user) => {
        this.user = user;
        this.getOrderDetails(params['params']['code'], params['params']['type'], this.user["uid"]);
        if(this.orderType == 'Standard Sales Order'){
          this.getOrderDocuments(this.user['uid'], params['params']['code']);
        }
      })
      
    });
    this.downloadBtnText$.subscribe(trans => {
      this.downloadBtnText  = trans
      this.btnTxt = trans
    })
    console.log("geting params values", 
    this.currentPagePagination,
    this.searchFilter,
    this.selectedDateFilter,
    this.selectedLocationFilter,
    this.selectedProductFilter,
    )
  }

  getOrderDetails(orderCode, orderType, userId) {
    this.productDetailsService.getOrderDetails( orderCode, orderType, userId).then((orderDetail) => {
      this.orderDetails = orderDetail;
      this.ref.detectChanges();
      this.orderDetails['entries'].map((entry) => {
        if(entry && entry['product'] && entry['product']['productImage']) {
          entry['product']['productImage'] = this.apiDomainUrl + entry['product']['productImage'];
        }
        if(entry && entry['product'] && entry['product']['units']) {
          entry['product']['units'].map((unit) => {
            if(unit['code'] == entry['unit']) {
              // this.unit = unit['name'];
              entry['selectedUnit'] = unit['name'];
            }
          })
        }
        //debugger;
        if(this.orderType == 'Sample Request'){
          this.getOrderDocuments(this.user['uid'], this.orderDetails["referenceOrderNo"]);
        }
        this.ref.detectChanges();
      })
      let tempDate = new Date(this.orderDetails['requestedDeliveryDate']);
      
      const yyyy = tempDate.getFullYear();
      let mm: any = tempDate.getMonth() + 1; // Months start at 0!
      let dd: any = tempDate.getUTCDate();

      if (dd < 10) dd = '0' + dd;
      if (mm < 10) mm = '0' + mm;

      if(this.orderDetails['requestedDeliveryDate']){
        this.orderDetails['requestedDeliveryDate'] = dd + '/' + mm + '/' + yyyy;
      }
      if(this.orderDetails['shippedTo'] && this.orderDetails['shippedTo']['addresses'] && this.orderDetails['shippedTo']['addresses'][0] && this.orderDetails['shippedTo']['addresses'][0]['formattedAddress']) {
        this.formattedAddress = this.orderDetails['shippedTo']['addresses'][0]['formattedAddress'].split(",");
      } else if(this.orderDetails['deliveryAddress'] && this.orderDetails['deliveryAddress']['formattedAddress']) {
        this.formattedAddress = this.orderDetails['deliveryAddress']['formattedAddress'].split(",");
      }
      
      this.ref.detectChanges();
      console.log("this.formattedAddress:::::::::::::",this.formattedAddress)
    })
    this.ref.detectChanges();
  }

  getOrderDocuments(userEmail, orderCode) {
    this.orderAttachmentDoc = [];
    this.invoiceAttachmentDoc = [];
    this.shippingAttachmentDoc = [];
    this.attachmentList = [];
    this.trackingNo = "";
    this.trackingUrl = "";
    this.productDetailsService.getOrderDocuments(userEmail, orderCode).then((orderDocuments) => {
      console.log("orderDocuments:::::::::::::",orderDocuments);
      if(orderDocuments && orderDocuments['Z_IV_GET_CUSTPORTAL_ORDER_GOS.Response'] && orderDocuments['Z_IV_GET_CUSTPORTAL_ORDER_GOS.Response']['ET_ATTACHMENTS']) {
        this.attachmentList = orderDocuments['Z_IV_GET_CUSTPORTAL_ORDER_GOS.Response']['ET_ATTACHMENTS']['item'];
        console.log("this.attachmentList:::::::", this.attachmentList)
        if(this.orderType == 'Sample Request'){
          this.trackingNo = this.attachmentList && this.attachmentList["TRACKING_ID"];
          this.trackingUrl = this.attachmentList && this.attachmentList["TRACKING_URL"];
          this.ref.detectChanges();
        }
        if(Array.isArray(this.attachmentList)) {
          this.attachmentList && this.attachmentList.map((attachment) => {
            if(attachment['PORTAL_GROUP'] == 'ORDER') {
              this.orderAttachmentDoc.push(attachment);
            } else if(attachment['PORTAL_GROUP'] == 'INVOICE') {
              this.invoiceAttachmentDoc.push(attachment);
            } else if(attachment['PORTAL_GROUP'] == 'SHIPPING') {
              this.shippingAttachmentDoc.push(attachment);
            }
            if(this.orderType == 'Sample Request'){
              this.trackingNo = attachment && attachment["TRACKING_ID"];
              this.trackingUrl = attachment && attachment["TRACKING_URL"];
              this.ref.detectChanges();
            }
          })
          this.ref.detectChanges();
        } else {
          if(this.attachmentList['PORTAL_GROUP'] == 'ORDER') {
            this.orderAttachmentDoc.push(this.attachmentList);
          } else if(this.attachmentList['PORTAL_GROUP'] == 'INVOICE') {
            this.invoiceAttachmentDoc.push(this.attachmentList);
          } else if(this.attachmentList['PORTAL_GROUP'] == 'SHIPPING') {
            this.shippingAttachmentDoc.push(this.attachmentList);
          }
          if(this.orderType == 'Sample Request'){
            this.trackingNo = this.attachmentList && this.attachmentList["TRACKING_ID"];
            this.trackingUrl = this.attachmentList && this.attachmentList["TRACKING_URL"];
            this.ref.detectChanges();
          }
          this.ref.detectChanges();
        }
        
      }
    })
    this.ref.detectChanges();
  }

  downloadPdf(desc, archiveDoc, archiveId, event) {
    this.btnTxt = "LOADING...";
    $(event.currentTarget).html("Loading...");
    this.ref.detectChanges();
    let component = $(event.currentTarget);
    this.productDetailsService.downloadPdfDoc(archiveDoc, archiveId, this.user.uid).then((doc) => {
      console.log("doc download::::::::::::::::",doc);
      if(doc && doc["Z_IV_GET_CUSTPORTAL_ATTACHMENT.Response"] && doc["Z_IV_GET_CUSTPORTAL_ATTACHMENT.Response"]["EV_CONTB64"]) {
        let downloadLink = document.createElement('a');
        
        downloadLink.href = "data:application/octet-stream;base64," + doc["Z_IV_GET_CUSTPORTAL_ATTACHMENT.Response"]["EV_CONTB64"];
        downloadLink.download = desc.trim().replace(" ", "-") + ".pdf";
        downloadLink.style.display = "none";
        document.body.appendChild(downloadLink);
        component.html(this.downloadBtnText);
        // $(event.currentTarget).html("Download");
        downloadLink.click();
        document.body.removeChild(downloadLink);
        this.btnTxt = this.downloadBtnText;
        this.ref.detectChanges();
      } else {
        console.log("Download pdf err");
        component.html(this.downloadBtnText);
        this.ref.detectChanges();
      }
    }).catch((err) => {
      component.html(this.downloadBtnText);
      this.ref.detectChanges();
      console.log("error in download pdf doc:::::::::::::::",err);
    })
  }

  naviateToOrderHistory() {
    this.router.navigate(['/my-account/orders'], {queryParams:{
      orderType : this.orderType == "Sample Request" ? "sample" : "standard",
      page : this.currentPagePagination,
      search : this.searchFilter,
      sort : this.selectedDateFilter,
      loc : this.selectedLocationFilter,
      product : this.selectedProductFilter,

    }});
  }

  openTrackingUrl(trackingUrl) {
    const url = trackingUrl;

    window.open(url, '_blank');
  }
  
  onChange(value) {
    $('.tab-content .tab-pane:not(' + value + ')').removeClass('active');
    $('.tab-content ' + value).tab('show');
  }
  // openTrackingUrl(trackingUrl) {

  // }

}
