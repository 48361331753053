import { Injectable } from '@angular/core';
import { GlobalMessageService, GlobalMessageType } from '@spartacus/core';

@Injectable({
  providedIn: 'root'
})
export class CustomGlobalMessageService extends GlobalMessageService {
  override add(message: string, type: GlobalMessageType): void {
    // Filter out the "No cart created yet." error message
    if (message !== 'No cart created yet.') {
      super.add(message, type);
    }
  }
}