import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ProductWishlistService } from '../APIcall/product-wishlist.service';
import { ProductDetailsService } from '../APIcall/product-details.service';
import { AppService } from '../app.services';
import { UserServiceService } from '../APIcall/user-service.service';

@Component({
  selector: 'app-custom-wish-list',
  templateUrl: './custom-wish-list.component.html',
  styleUrls: ['./custom-wish-list.component.scss']
})
export class CustomWishListComponent implements OnInit {
  products: any =[];
  userId: string ='';
  domainUrl: string = '';
  emptyErrorPage: boolean = false;
  loader : boolean = false;
  constructor(private wishlistItemService: ProductWishlistService, private ref: ChangeDetectorRef, private appService: AppService, private productDetailsService: ProductDetailsService,  private router: Router, private UserService: UserServiceService) {
    const user = window.localStorage.getItem('spartacus⚿⚿auth');
    const json = JSON.parse(user);
    // this.userId = json.userId;
  }

  ngOnInit(): void {
    this.domainUrl = this.appService.getApiDomainUrl();
    this.UserService.getUserDetails().then((user) => {
      this.userId = user["uid"];
      this.getProductList();
    }).catch((err) => {
      console.log("err:::::::::::::",err)
    })
    
    this.productDetailsService.changeBreadcrumb({"breadcumTitle" : "wishlist", "categoryName": "", "productName": ""});
    // this.products.forEach(item => {
    //   if(item && item.id===undefined){
    //     this.emptyErrorPage = true;
    //   }
    // });
    
  }
  getProductList(){
    console.log("wishlist component::::::::::::",this.userId)
    this.wishlistItemService.getProduct(this.userId).then((data) => {
      // let data = this.wishlistItemService.getProductsData();
      this.products = data;
      if(!data) {
        this.emptyErrorPage = true;
      }
      console.log(this.products)
      this.ref.detectChanges(); 
    }).catch((e) => {
      this.emptyErrorPage = true;
      this.ref.detectChanges();
    });
  }

  // async getProduct(user : string){
  //   await this.wishlistItemService.getProduct(user);
  // }
  deleteProduct(id: string){
    this.loader = true;
    this.deleteProductCall(this.userId, id).then(() => {
     //this.emptyErrorPage = true;
     
     console.log(this.products)
     this.ref.detectChanges();
     this.getProductList();
     this.loader = false;
    }).catch((e) => {
      console.log("Error while removing product, retry after!");
    });
  }

  async deleteProductCall(user: string, productId : string){
    await this.wishlistItemService.deleteProduct(productId, user);
  }

  redirectHome(){
    this.router.navigate(['/']);
  }
  redirectPDP(url, code) {
    this.productDetailsService.changeProduct({"code" : code});
    this.router.navigate([url]);
  }
}
