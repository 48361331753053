import { HttpClient } from '@angular/common/http';
import { NONE_TYPE } from '@angular/compiler';
import { Component, ɵɵelementContainerStart } from '@angular/core';
import { checkForActionTypeUniqueness } from '@ngrx/store/src/runtime_checks';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ProductDetailsService } from './APIcall/product-details.service';
import { ProductWishlistService } from './APIcall/product-wishlist.service';
import { environment } from '../environments/environment';
import { AuthService, LanguageService, TranslationService } from '@spartacus/core';
import { Location, PopStateEvent } from "@angular/common";
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { AppService } from './app.services';

import { CmsComponentData } from '@spartacus/storefront';
import { CmsBannerComponent, CmsComponent, CmsService, CMSTabParagraphContainer } from '@spartacus/core';

import {
  NgcCookieConsentService,
  NgcNoCookieLawEvent, 
  NgcStatusChangeEvent,
} from "ngx-cookieconsent";
import { Observable, Subscription } from "rxjs";

declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  title = 'InfineumSpa';
  private user: string = "";
  private lastPoppedUrl: string;
  private yScrollStack: number[] = [];
  private uri = environment.baseUrl + "/infineumwebservices/v2";
  currentYear: any = "";
  isLoggedIn:boolean = false;
  prvSelectedLang: any = "";
  //keep refs to subscriptions to be able to unsubscribe later
  private popupOpenSubscription: Subscription;
  private popupCloseSubscription: Subscription;
  private initializeSubscription: Subscription;
  private statusChangeSubscription: Subscription;
  private revokeChoiceSubscription: Subscription;
  private noCookieLawSubscription: Subscription;

  subscription2: Subscription;
  agreeText$: Observable<string> = this.translation.translate('customTermCondition.agreeText')
  acceptText$: Observable<string> = this.translation.translate('customTermCondition.acceptCookies')
  cookiePolicyText$: Observable<string> = this.translation.translate('customTermCondition.acceptPolicyLink')
  constructor(private translation: TranslationService, private languageService: LanguageService, private cmsService: CmsService, private router: Router, private activatedRoute: ActivatedRoute, private location: Location, private authService:AuthService, private http: HttpClient, private deviceService: DeviceDetectorService, private productService: ProductWishlistService, private productDetailService: ProductDetailsService, private appService: AppService, private ccService: NgcCookieConsentService) {
    const user = window.localStorage.getItem('spartacus⚿⚿auth');
    const json = JSON.parse(user);
    this.user = json.userId;    
  }

  makeHttpCall(){
    this.http.get(this.uri + "/infineum/en/USD/")
      .subscribe((r) => {
        console.log(r);        
      });
  }

  ngAfterViewInit() {
    $(document).ready(async () => {
      this.authService.isUserLoggedIn().subscribe((isLoggedIn) => {
        console.log("isLoggedIn::::::::",isLoggedIn)
        //this.isUser = isLoggedIn;
        //this.ref.detectChanges();
        this.isLoggedIn = isLoggedIn;
        if(isLoggedIn){
          for(let element of window.location.pathname.split("/")){
            if(element == 'login'){
              console.log("22222222222222222222222222")
              // this.router.navigate(['/']);
            }
          }
          $('body').addClass('authenticated').removeClass('anonymous');
          // this.router.navigate(['/']);
          //$("cx-page-slot > cx-login").removeClass("hideLogin");
          // $("cx-page-slot > cx-login").addClass("showLogin");
          $("cx-page-slot > cx-login").show();
        }else{
          if(window.location.pathname.indexOf('/login/pw/change') == -1) {
            this.router.navigate(['/login']);
          }
          if(window.location.pathname.indexOf('/terms-and-conditions') != -1) {
            this.router.navigate(['/terms-and-conditions']);
          }
          $('body').addClass('anonymous').removeClass('authenticated');
          //$("cx-page-slot > cx-login").addClass("hideLogin");
          //$("cx-page-slot > cx-login").removeClass("showLogin");
          $("cx-page-slot > cx-login").hide();
        }
      })
      // $("cx-page-slot.Summary > cx-product-images").empty();

      // if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      //   // true for mobile device
      //   console.log("mobile device");
      // } else {
      //   // false for not mobile device
      //   console.log("not mobile device");
      // }

      // $(document).on("click", "[data-state=closed]", function () { $(this).data('state', 'opened').attr('data-state', 'opened'); });

      // var myVar = setInterval(function () {
      //   if ($("div.cx-login-greet").length) {

      //     $(`
      //     <form class="ng-valid">
      //     <button></button>
      //     </form>
      //     `).insertBefore("ng-template");

      //     //add to card button
      //     $("form.ng-valid > button").empty();
      //     $("form.ng-valid > button").append("<img src='assets/SamplesIcon.png' class='sampleImg' style='margin-right: 15px;max-height: 100%;'>");
      //     $("form.ng-valid > button").append("<span>Add to Sample request</span>");

      //     //$("cx-page-slot.HeaderLinks").append( "<a href='/claimsList' class='siteLoginLink'>Claim Search</a> <a href='/login' class='signOut'> Sign out </a>");
      //     $("label.searchbox > input").attr("placeholder", "Search for product name...");

      //     $("div.cx-login-greet").prependTo("cx-page-slot.HeaderLinks");
      //     $("cx-link.claimSearchHeaderLink").prependTo("cx-page-slot.HeaderLinks");

      //     var account = $("cx-page-slot.HeaderLinks > cx-navigation > cx-navigation-ui.flyout > nav > h5").html();
      //     if (account !== undefined) {
      //       $("cx-page-slot.HeaderLinks > cx-navigation > cx-navigation-ui.flyout > nav > h5").html(account.substring(3, 11));
      //     }
      //     var welcome = $("div.cx-login-greet").html();
      //     if (welcome !== undefined) {
      //       $("div.cx-login-greet").html("Welcome " + welcome.substring(4, welcome.length));
      //     }

      //     clearInterval(myVar);
      //   }
      // }, 1000);

      // this.checkProductWishlist();

      //$("cx-mini-cart > a").prepend("<img src='assets/SamplesIcon.png' class='sampleImg'>");
      //$("cx-mini-cart > a").append("<span class='sampleButtonText'> SAMPLES </span>");
      // $("cx-page-slot.Footer > cx-site-context-selector").appendTo("cx-footer-navigation > cx-navigation-ui");

      // $("cx-footer-navigation").append("<div class='container' style='padding-left: 0px;'></div>");
      // $("cx-footer-navigation > cx-navigation-ui").appendTo("cx-footer-navigation > div.container");

      //logOut
    //   $(document).on('click', '.signOutLink > cx-generic-link > a', function () {
    //     window.localStorage.removeItem('spartacus⚿⚿auth');
    //     window.localStorage.removeItem('claimsInquiry');
    //     window.location.reload();
    //   });

    //   // listener for wishlist button
    //   $(document).on('click', '.addProduct', (data) => {
    //     var productId = $(data.currentTarget).attr('productId');
    //     this.addProduct(productId);
    //   });

    //   $(document).on('click', '.deleteProduct', (data) => {
    //     var productId = $(data.currentTarget).attr('productId');
    //     this.deleteProduct(productId);
    //   });

    //   //check for header component show
    //   const login = window.localStorage.getItem('spartacus⚿⚿auth');
    //   const loginUser = JSON.parse(login);
    //   if (loginUser.userId == "anonymous") {
    //     $("header").prepend("<div class='logoHeader'></div>");
    //     $("cx-page-slot.SiteLogo").appendTo(".logoHeader");
    //     $("cx-page-layout.header").hide();
    //   }
    //   else {
    //     $("header").prepend("<div class='logoHeader'></div>");
    //     $("cx-page-slot.SiteLogo").appendTo(".logoHeader");
    //   }
    });
  }

  // checkProductWishlist() {
  //   var url: string[] = window.location.href.split("/");
  //   var isProduct = url[url.length - 2];
  //   if (isProduct == "p") {
  //     var productCode = url[url.length - 1];
  //     var wishlist = "";
  //     this.checkProduct(this.user, productCode).then(() => {
  //       if (this.productService.getCheckProductData()) {
  //         // loaded in wishlist
  //         wishlist =
  //           "<a class='deleteProduct' productId='" + productCode + "' style='color: #37424A'> \
  //           <i class='fas fa-star whishlist'></i> <span>Save</span>\
  //           </a>";
  //       }
  //       else {
  //         //not loaded in wishlist
  //         wishlist =
  //           "<a class='addProduct' productId='" + productCode + "' style='color: #37424A'> \
  //             <i class='far fa-star whishlist'></i> <span>Save</span>\
  //           </a>";
  //       }
  //       $("div.addWhishlist").append(wishlist);
  //     }).catch(() => {
  //       wishlist =
  //         "<a class='addProduct' productId='" + productCode + "' style='color: #37424A'> \
  //           <i class='far fa-star whishlist'></i> <span>Save</span>\
  //         </a>";

  //       $("div.addWhishlist").append(wishlist);
  //     });
  //   }
  // }

  // async deleteProduct(productCode: string) {
  //   this.deleteProductCall(this.user, productCode).then(() => {
  //     alert("Product deleted from the wishlist");
  //     var wishlist =
  //       "<a class='addProduct' productId='" + productCode + "' style='color: #37424A'> \
  //         <i class='far fa-star whishlist'></i> <span>Save</span>\
  //       </a>";
  //     $("a.deleteProduct").remove();
  //     $("div.addWhishlist").append(wishlist);
  //   }).catch(() => {
  //     alert("Error while deleting product, retry after!");
  //   });
  // }

  // async addProduct(productCode: string) {
  //   this.addProductCall(this.user, productCode).then(() => {
  //     alert("Product added to the wishlist");
  //     var wishlist =
  //       "<a class='deleteProduct' productId='" + productCode + "' style='color: #37424A'> \
  //         <i class='fas fa-star whishlist'></i> <span>Save</span>\
  //       </a>";
  //     $("a.addProduct").remove();
  //     $("div.addWhishlist").append(wishlist);
  //   }).catch(() => {
  //     alert("Error while adding product, retry after!");
  //   });
  // }

  // async deleteProductCall(user: string, productCode: string) {
  //   await this.productService.deleteProduct(productCode, user);
  // }

  // async addProductCall(user: string, productCode: string) {
  //   await this.productService.addProduct(productCode, user);
  // }

  // async checkProduct(user: string, productCode: string) {
  //   await this.productService.checkProduct(user, productCode);
  // }

  ngOnInit(): void {
    this.prvSelectedLang = document.documentElement.lang;
    // this.activatedRoute.queryParams.subscribe(params => {
      // if (params.asm === "true") {
        // this.appService.setAsmMode(params.asm);
        this.appService.getUserFromAsm();
      // }

    // });

    this.currentYear = new Date().getFullYear();
    this.location.subscribe((ev:PopStateEvent) => {
      this.lastPoppedUrl = ev.url;
    });
    this.router.events.subscribe((ev:any) => {
      if (ev instanceof NavigationStart) {
          if (ev.url != this.lastPoppedUrl)
              this.yScrollStack.push(window.scrollY);
      } else if (ev instanceof NavigationEnd) {
          if (ev.url == this.lastPoppedUrl) {
              this.lastPoppedUrl = undefined;
              window.scrollTo(0, this.yScrollStack.pop());
          } else
              window.scrollTo(0, 0);
      }
    });

    // subscribe to cookieconsent observables to react to main events
    this.popupOpenSubscription = this.ccService.popupOpen$.subscribe(() => {
      // Can be used by running this.ccService.getConfig()
    });

    this.popupCloseSubscription = this.ccService.popupClose$.subscribe(() => {
      // Can be used by running this.ccService.getConfig()
      localStorage.setItem("cookie-accept", "false");
    });

    // this.initializeSubscription = this.ccService.initialize$.subscribe((event: NgcInitializeEvent) => {
    //   // Can be used by running this.ccService.getConfig()
    // }
    // );

    this.statusChangeSubscription = this.ccService.statusChange$.subscribe((event: NgcStatusChangeEvent) => {
      // Can be used by running this.ccService.getConfig()
    }
    );

    this.revokeChoiceSubscription = this.ccService.revokeChoice$.subscribe(() => {
      // Can be used by running this.ccService.getConfig()
    }
    );

    this.noCookieLawSubscription = this.ccService.noCookieLaw$.subscribe((event: NgcNoCookieLawEvent) => {
      // Can be used by running this.ccService.getConfig()
    }
    );

    // this.translateService.addLangs(['en', 'fr']);
    // this.translateService.setDefaultLang('en');

    let act_lang = "";
   
    this.languageService.getActive().subscribe((lang) => {
      act_lang = lang;
      if(document.documentElement.lang != this.prvSelectedLang) {
          location.reload();
        }
      console.log("active lang::::::::::::::",act_lang)
    })
    this.agreeText$.subscribe(transAgree => {
      this.acceptText$.subscribe(transAccept => {
        this.cookiePolicyText$.subscribe(transPolicy => {
        this.ccService.getConfig().content = this.ccService.getConfig().content || {} ;

        // Override default messages with the translated ones

        //this.ccService.getConfig().content.header = data['cookie.header'];

        this.ccService.getConfig().content.message = transAccept;

        this.ccService.getConfig().content.dismiss = transAgree;
        //this.ccService.getConfig().content.link = data['cookie.link'];

        this.ccService.getConfig().content.link =transPolicy;
 

        this.ccService.destroy();//remove previous cookie bar (with default messages)

        this.ccService.init(this.ccService.getConfig()); // update config with translated messages
      })
      })  
    })

  }

  // navigateTermsAndConditions() {
  //   this.router.navigate(['/terms-and-conditions']);
  // }
  navigateTermsAndConditions() {
    const url = this.router.serializeUrl(
      this.isLoggedIn ? this.router.createUrlTree(['/termsAndConditions']) : this.router.createUrlTree(['/terms-and-conditions'])
    );

    window.open(url, '_blank');
  }
  ngOnDestroy() {
    // unsubscribe to cookieconsent observables to prevent memory leaks
    this.popupOpenSubscription.unsubscribe();
    this.popupCloseSubscription.unsubscribe();
    this.initializeSubscription.unsubscribe();
    this.statusChangeSubscription.unsubscribe();
    this.revokeChoiceSubscription.unsubscribe();
    this.noCookieLawSubscription.unsubscribe();
    this.subscription2.unsubscribe();
  }
}
