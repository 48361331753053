<div *ngIf="noClaimData && !isPDP" class="no-results claims-search p-0 text-center">
    <div class="row">
        <!-- <div class="col-12 text-center">
            <div class="message">
               <h1 class="border-top-blue">No <span class="text-bold">CLAIMS</span></h1>
               <p class="lead">There are no claims available.</p>
            </div>
        </div> -->
        <div class="col-12 text-center bg-white">
            <div class="card card-info bg-white box-shadow-none text-center pt-0">
               <div class="card-body-claims-home">
                   <div class="card-icon">
                       <img src="/assets/information.svg">
                   </div>
                   <h4 class="m-auto color-black">{{'claims.needmoreInfo' | cxTranslate}}</h4>
                   <p class="pb-4 m-auto">{{'claims.pleaseContactYourInfineum' | cxTranslate}}</p>
                    <button (click) = "navigateToContact()" class="btn btn-primary btn-ghost-inverse m-auto" type="button">{{'claims.contact' | cxTranslate}}</button>
               </div>
           </div>
       </div>
   </div>
</div>


<div *ngIf="noClaimData && isPDP" class="no-results">
    <div class="row">
        <div class="col">
            <h4>{{'claims.__claims' | cxTranslate}}</h4>
        </div>
    </div>
    <div class="row">
        <div class="col-12 col-md-6">
           <div class="card card-info card-alert card-warning box-shadow-none">
                <div class="card-body">
                    <img src='assets/pdpClaim.svg'>
                    <p>{{'claims.noClaimsData' | cxTranslate}}</p>
                    <p>{{'claims.noClaimsDataSecond' | cxTranslate}}</p>       
                </div>
            </div>
        </div>
        <div class="col-12 col-md-6">
            <div class="card card-info bg-white box-shadow-none text-center pt-0">
               <div class="card-body __hide-display-flex">
                   <div class="card-icon">
                       <img src="/assets/information.svg">
                   </div>
                   <h4 class="m-auto color-black">{{'claims.needmoreInfo' | cxTranslate}}</h4>
                   <p class="pb-4">{{'claims.pleaseContactYourInfineum' | cxTranslate}}</p>
                    <button (click) = "navigateToContact()" class="btn btn-primary btn-ghost-inverse m-auto" type="button">{{'claims.contact' | cxTranslate}}</button>
               </div>
           </div>
       </div>
   </div>
</div>

<div *ngIf="!noClaimData" class="container-fluid" [ngClass]="isPDP ? 'p-0' : 'bg-lightestgrey'">
    <div class="container">
        <div class="row filters">
            <div class="col-12 col-lg-3 filter">
                <div class="filter-wrapper">
                    <h4>{{'claims.filter' | cxTranslate}}</h4>
                    <button (click)="clearAllFilter()"  class="btn shadow-none btn-ghost-inverse">{{'claims.clear_all_btn_txt' | cxTranslate }}</button>
                </div>
            </div>
            <div class="col-12 col-lg-9 filter">
                <div class="filter-wrapper">
                    <div class="sort">
                        <!-- <div class="filter-results" *ngIf="claimsData?.pagination?.totalResults">
                            <h4 class="d-inline mr-0">{{'claims.recentlyUpdatedClaims' | cxTranslate}} </h4>
                            <span>{{claimsData?.pagination?.totalResults}}  {{'claims.__results' | cxTranslate}}</span>
                        </div> -->
                        <h4 class="light">{{'claims.__sortBy' | cxTranslate}}</h4>
                        <select (change)="selectChangeHandler($event)">
                            <option *ngFor="let sort of sorts" [value]="sort.code" [selected]="sort.selected">{{ sort.name }}</option>
                        </select>
                    </div>
                </div>
            </div>
        </div><!-- .filters -->
        <div class="row results">
            <div class="col-12 col-md-3 categories">
                <div *ngFor="let filter of showFilter" class="row" style="margin:auto;"> 
                    <div class="card w-100 __category  filter-segmentName" [ngClass]="{'closed': filter?.facetValueName?.length == 0}">
                        <h5 class="card-header">
                            <a href="javascript: void(0)" (click)="openClaimFilterModal(longContentFilter,filter)" class="" filtername="segmentName">
                                {{filter.displayName}}
                            </a>
                        </h5>
                        <div class="card-body">
                            <div *ngIf="filter?.facetValueName.length > 0" class="__claim-used-facet filter-segmentName __filter-select-border">                 
                                <div class="col-md-12" *ngIf="filter.displayName != ('claims.category' | cxTranslate)">                   
                                  <button *ngFor="let facetValue of filter?.facetValueName; let ind = index;"  class="arrow-segmentName filter-button" (click)="removeSelectedFilter(filter.removeQuery[ind]?.query?.value)">                     
                                    <!-- <img src="../../assets/filterArrow.svg">                    -->
                                    <div class="__used-filterName">{{facetValue}} 
                                    </div> 
                                    <a class="removeFilter" filtername="Crankcase" filterclass="segmentName">               
                                      <i class="fas fa-times-circle disableFilter">
                                      </i>             
                                    </a>
                                  </button>                 
                                </div>
                                <div class="col-md-12" *ngIf="filter.displayName == ('claims.category' | cxTranslate)">                   
                                  <button *ngFor="let facetValue of displayCategoryFacetName; let ind = index;"  class="arrow-segmentName filter-button" (click)="removeSelectedFilter(filter.removeQuery[ind]?.query?.value)">                     
                                    <!-- <img src="../../assets/filterArrow.svg">                    -->
                                    <div class="__used-filterName"><img class="categoryImg" src="{{facetValue.catImageUrl}}"> 
                                    </div> 
                                    <a class="removeFilter" filtername="Crankcase" filterclass="segmentName">               
                                      <i class="fas fa-times-circle disableFilter">
                                      </i>             
                                    </a>
                                  </button>                 
                                </div>               
                              </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- TILES SECTION -->
            <div class="col-12 col-md-9">
                <div class="row tiles">
                    <div *ngFor="let claims of claimsData.claims | paginate: { id: 'server', itemsPerPage: pageSize, currentPage: currentPage, totalItems: totalResults }" class="col col-12 col-md-6" [ngClass]="isPDP ? 'col-xl-4' : 'col-xl-3'">
                        <div class="card">
                              <div class="card-header">
                                  <div>
                                        <h4 class="card-title">{{claims.productName}}</h4>
                                        <h5>{{claims.oilCode}}</h5>
                                        <p class="text-muted">{{'claims.oilCode' | cxTranslate}}</p>
                                  </div>
                                  <div>
                                      <a href="javascript: void(0)" (click)="openClaimSearchModal(longContent, claims)" class="stretched-link"><img src="../../assets/circleArrow.svg" class="blueInform"></a>
                                  </div>
                              </div>
                              <div class="card-body">
                                  <div class="items">
                                        <div class="item w-50">
                                            <span>{{claims.treatRate}}</span>
                                            <p class="text-muted">{{'claims.treatRate' | cxTranslate}}</p>
                                        </div>
                                        <div class="item">
                                            <img class="categoryImg" src="{{claims.categoryImageUrl}}">
                                            <p class="text-muted">{{'claims.category' | cxTranslate}}</p>
                                        </div>
                                  </div>
                                  <div class="items">
                                        <div class="item w-50">
                                            <span>{{claims.viscosityModifiers}}</span>
                                            <p class="text-muted">{{'claims.vm' | cxTranslate}}</p>
                                        </div>
                                        <div class="item">
                                            <span>{{claims.gradeName}}</span>
                                            <p class="text-muted">{{'claims.grade' | cxTranslate}}</p>
                                        </div>
                                  </div>
                                  <div class="items">
                                        <div class="item w-100">
                                            <span>{{claims.baseStocks}}</span>
                                            <p class="text-muted">{{'claims.baseStock' | cxTranslate}}</p>
                                        </div>
                                  </div>
                                  
                                  
                              </div>
                                
                              <div class="card-footer">
                                <span class="text-bold">{{claims.claimsSpecsCount}}</span> {{'claims.__claims' | cxTranslate}}
                              </div>
                        </div>
                </div>
            </div>
            <!-- PAGINATION -->
            <div class="page-pagination">
                <pagination-controls class="my-pagination" [previousLabel]="''" [nextLabel]="''" (pageChange)="getPage($event)" id="server"></pagination-controls>
                <span class="numberResults">{{start}} - {{current}} {{'claims.of' | cxTranslate}} {{claimsData?.pagination?.totalResults}} {{'claims.__results' | cxTranslate}}</span>
            </div>
        </div><!-- .results -->
    </div>
</div>
        
<!-- Claims modal template -->
<ng-template #longContent let-modal>
      <!-- Modal Header -->
      <div class="modal-header">
        <h4 class="modal-title">{{displaySingleClaimData.productName}}</h4>
        <button type="button" class="close" data-dismiss="modal" (click)="modal.dismiss('Cross click')"><img  src="../../../assets/closeDialog.svg"></button>
        <div class="row info w-100">
            <div class="col col-4 col-md-2">
                <h5>{{displaySingleClaimData.oilCode}}</h5>
                <p class="text-muted">{{'claims.oilCode' | cxTranslate}}</p>
            </div>
            <div class="col col-4 col-md-2">
                <h5>{{displaySingleClaimData.treatRate}}</h5>
                <p class="text-muted">{{'claims.treatRate' | cxTranslate}}</p>
            </div>
            <div class="col col-4 col-md-2">
                <h5>{{displaySingleClaimData.viscosityModifiers}}</h5>
                <p class="text-muted">{{'claims.vm' | cxTranslate}}</p>
            </div>
            <div class="col col-4 col-md-2">
                <h5>{{displaySingleClaimData.gradeName}}</h5>
                <p class="text-muted">{{'claims.grade' | cxTranslate}}</p>
            </div>
            <div class="col col-4 col-md-2">
                <img src="{{displaySingleClaimData?.categoryImageUrl}}">
                <p class="text-muted">{{'claims.category' | cxTranslate}}</p>
            </div>
        </div>
        <div class="row info w-100">
            <div class="col col-4 col-md-2">
                <h5>{{displaySingleClaimData.baseStocks}}</h5>
                <p class="text-muted">{{'claims.baseStock' | cxTranslate}}</p>
            </div>
            <div class="col col-4 col-md-2" *ngIf="displaySingleClaimData.boosters">
                <h5>{{displaySingleClaimData.boosters}}</h5>
                <p class="text-muted">{{'claims.booster' | cxTranslate}}</p>
            </div>
            <div class="col col-4 col-md-2"  *ngIf="displaySingleClaimData.ppdIdName">
                <h5>{{displaySingleClaimData.ppdIdName}}</h5>
                <p class="text-muted">{{'claims.__ppd' | cxTranslate}}</p>
            </div>
            <div class="col col-4 col-md-2"  *ngIf="displaySingleClaimData.fuelTypes">
                <h5>{{displaySingleClaimData.fuelTypes}}</h5>
                <p class="text-muted">{{'claims.fuelType' | cxTranslate}}</p>
            </div>
            <div class="col col-4 col-md-2"  *ngIf="displaySingleClaimData.solvents">
                <h5>{{displaySingleClaimData.solvents}}</h5>
                <p class="text-muted">{{'claims.solvent' | cxTranslate}}</p>
            </div>
            
            <div class="col col-4 col-md-2" *ngIf="displaySingleClaimData.ashValue">
                <h5>{{displaySingleClaimData.ashValue}}</h5>
                <p class="text-muted">{{'claims.ash' | cxTranslate}}</p>
            </div>
            <div class="col col-4 col-md-2" *ngIf="displaySingleClaimData.tbn">
                <h5>{{displaySingleClaimData.tbn}}</h5>
                <p class="text-muted">{{'claims.tbn' | cxTranslate}}</p>
            </div>
            <div class="col col-4 col-md-2"  *ngIf="displaySingleClaimData.pibs">
                <h5>{{displaySingleClaimData.pibs}}</h5>
                <p class="text-muted">{{'claims.pibId' | cxTranslate}}</p>
            </div>
        </div>
      </div>

      <!-- Modal body -->
      <div class="modal-body">
            <div class="claims">
                <div class="row">
                    <div class="col-12">
                        <h5>{{'claims.__claims' | cxTranslate}}</h5>
                    </div>
                    <div *ngFor="let claimSpec of claimDetails.claimSpecs" class="col-12 col-md-4">
                      <div *ngIf="claimSpec.claimType == 'Complete'">
                        <i class="far fa-check-circle color-blue mr-2">
                        </i> {{claimSpec.claimSpecId}} 
                      </div>
                      <div *ngIf="claimSpec.claimType!= 'Complete'">
                        <i class="far fa-edit mr-2 color-orange">
                        </i> {{claimSpec.claimSpecId}} 
                      </div>      
                    </div>
                </div>
            </div><!-- .claims -->
      </div>

      <!-- Modal footer -->
      <div class="modal-footer">
        <button (click)="navigateToPDP(displaySingleClaimData?.productCode)" class="btn shadow-none btn-ghost border-blue text-white mw-100 w-auto">{{'claims.orderSample' | cxTranslate}}</button>
        <button (click)="navigateContactWithProduct(displaySingleClaimData?.productName)" class="btn shadow-none btn-ghost border-blue text-white mw-100 w-auto">{{'claims.requestInformation' | cxTranslate}}</button>
        <button (click)="printClaims(displaySingleClaimData, claimDetails.claimSpecs)" class="btn shadow-none btn-ghost border-blue text-white mw-100 w-auto">{{'claims.print' | cxTranslate}}</button>
      </div>
</ng-template>

<!-- Filter modal template -->
<div class="container">
  <ng-template #longContentFilter let-modal>
      <div class="__card-title-filter">
        <div class="row">
          <div class="col-md-10">
            <span class="__titleClaimDialog-text-filter text-uppercase">{{'claims.select' | cxTranslate}}{{displayFiltertype}} <!--{{displayFiltertype}}--> 
            </span>
          </div>
          <div  class="col-md-2">
            <button class="__claim-dialog-close-btn" aria-label="Close" (click)="modal.dismiss('Cross click')" type="button">
              <img  src="../../../assets/closeFilterDialog.svg">
            </button>
          </div>
        </div>
      </div>
      <div class="row" style="padding: 12px 8px;">
        <div class="col-md-12">
          <input type="text" placeholder="{{'claims.typeFiter' | cxTranslate}}" class="__filterInput" (keyup)="onKey($event)">
        </div>
      </div>
      <div class="row" style="margin-top: 5px; padding: 0px 8px;">
        <div class="col-md-12">
          <p class="__claims-filter">{{displayFiltertype}}
          </p>
        </div>
      </div>
      <div  class="row" style="padding: 0px 18px 10px 18px;">
        <div class="col-md-12" style="padding: 0 5px;">
          <div class="__filtersContainer">
            <div class="row" style="padding: 0; margin: 0;">
              <div *ngFor="let claimFilter of filteredList" class="col-md-4" style="margin-top: 10px;">
                <a class="__filterLink" (click)="filterClaim(claimFilter)"> {{displayFiltertype == ('claims.category' | cxTranslate) ? claimFilter.displayCategoryName : claimFilter.name}} 
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    <div class="row" style="padding: 5px 10px;">
      <div class="col-md-12">
        <!-- <h3 style="padding-left: 20px;">CLAIMS
        </h3> -->
      </div>
    </div>
    <div class="__filter-modal-footer">
        <button (click)="clearFilters()" class="btn shadow-none btn-ghost-inverse mr-2">{{'claims.clearFilter' | cxTranslate}}</button>
    </div>
  </ng-template>
</div>

