<div class="container">
    <div class="order-details card bg-white">
        <div class="tabs">
            <!-- DESKTOP -->
            <ul class="nav nav-tabs d-none d-md-flex">
                <li class="nav-item">
                  <a (click)="tabChange('standard')" data-toggle="tab" class="nav-link active" id="standardTab" href="#tab1">{{'customOrderHistory.standard_sales_order' | cxTranslate}}</a>
                </li>
                <li class="nav-item">
                  <a (click)="tabChange('sample')" data-toggle="tab" class="nav-link" id="sampleTab" href="#tab2">{{'customOrderHistory.request_sample' | cxTranslate}}</a>
                </li>
            </ul>
            <!-- MOBILE -->
            <select class="d-block d-md-none" (change)="onChange($event.target.value)">
                <option *ngFor="let item of orderHistoryTabList" [selected] = "item.selected" [value]="item.val">{{item.name}}</option>
                <!-- <option [selected] = "selectedTab" value="#tab2">{{'customOrderHistory.request_sample' | cxTranslate}}</option> -->
            </select>
        </div>
        <div class="tab-content">
            <!-- STANDARD SALES ORDERS -->
            <div class="tab-pane active order-details-tab" id="tab1">
                <!-- NO RESULTS -->
                <div class="row" *ngIf="totalResults == 0">
                    <div class="col-12 text-center my-5">
                        <div class="message">
                           <h1 class="border-top-blue">{{'customOrderHistory.no' | cxTranslate}}<span class="text-bold">{{orderTypeErrorText}}{{'customOrderHistory.order' | cxTranslate}}</span></h1>
                           <p class="lead mb-5">{{'customOrderHistory.weHaveNoOrderForThisAccount' | cxTranslate}}</p>
                           <button (click) = "navigateToHomePage()" class="btn btn-primary m-auto" type="button">{{'customOrderHistory.continue' | cxTranslate}}</button>
                        </div>
                    </div>
                </div>
                <div class="row" *ngIf="totalResults > 0">
                    <div class="col-12">
                        <div class="filters">
                            <div class="search-group">
                                <input (ngModelChange) = "__crossBtnVisiblity(box1.value)"  [(ngModel)]="productNo" type="text" placeholder="{{'customOrderHistory.search_po_number' | cxTranslate}}" #box1 (keyup.enter)="onEnterSampleReqNumber(box1.value)" >
                                <div (click)="__resetValue()" id="clear" class="clear" *ngIf="__crossBtn"></div>
                                <img src="/assets/SearchIconSolid.svg" (click)="onEnterSampleReqNumber(box1.value)">
                            </div>
                            <select class="__product-select-filter text_uppercase" (change)="selectProductFilter($event)">
                                <option class="text_uppercase" *ngFor="let filterval of productFilter" [value]="filterval.code" [selected]="filterval.name == selectedProductName">{{ filterval.name }}</option>
                            </select>
                            <select class="__product-select-filter text_uppercase" (change)="selectLocationFilter($event)">
                               <option class="text_uppercase" *ngFor="let filterval of locationfilter" [value]="filterval.code" [selected]="filterval.name == selectedLocationName">{{ filterval.name }}</option>
                            </select>
                            <div class="sort">
                                <label>{{'customOrderHistory.sort_by' | cxTranslate}}</label>
                                <select class="__product-select-filter text_uppercase" (change)="selectSort($event)">
                                <!-- <option value="" disabled selected>DATE</option> -->
                                    <option class="text_uppercase" *ngFor="let sort of sorts" [value]="sort.code" [selected]="sort.selected == true">{{ sort.code }}</option>
                                </select>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                            <label class="total-results">{{totalResults}} {{'customOrderHistory.oder_found' | cxTranslate}}</label>
                        </div>
                    </div>
                    <div class="col-12 p-0" *ngIf="totalResults > 0">
                        <table class='styled'>
                            <thead>
                                <th>{{'customOrderHistory.yourPoNumber' | cxTranslate}}</th>
                                <th>{{'customOrderHistory.infineumOrderNumber' | cxTranslate}}</th>
                                <th>{{'customOrderHistory.shipToLocation' | cxTranslate}}</th>
                                <th>{{'customOrderHistory.oderStatus' | cxTranslate}}</th>
                                <th>{{'customOrderHistory.estimatedDelevery' | cxTranslate}}</th>
                            </thead>
                            <tbody>
                                <tr *ngFor="let order of orderList | paginate: { id: 'server', itemsPerPage: 10, currentPage: currentPage, totalItems: totalResults }" (click)="navigateToOrderDetails(order.code,'standard')">
                                    <td id='supplyingCompany' data-label="Your PO number">{{order.purchaseOrderNumber}}<span *ngIf="!order.purchaseOrderNumber">&nbsp;</span></td>
                                    <td id='country' data-label="Infineum Order Number">{{order.referenceOrderNo}}<span *ngIf="!order.referenceOrderNo">&nbsp;</span></td>
                                    <td id='language' data-label="Ship To Location">{{order.shippedTo?.addresses && order.shippedTo?.addresses[0].formattedAddress ? order.shippedTo?.name + ", " + order.shippedTo?.addresses[0].formattedAddress : order.deliveryAddress?.formattedAddress ? order.deliveryAddress?.companyName && order.deliveryAddress?.companyName + ', '+ order.deliveryAddress?.formattedAddress : ""}}</td>
                                    <td id='date' data-label="Order Status" *ngIf="order.status == 'Cancelled' || order.status == 'CANCELLED'">{{'customOrderHistory.Cancelled' | cxTranslate}}</td>
                                    <td id='date' data-label="Order Status" *ngIf="order.status == 'Confirmed' || order.status == 'CONFIRMED'">{{'customOrderHistory.Confirmed' | cxTranslate}}</td>
                                    <td id='date' data-label="Order Status" *ngIf="order.status == 'Dispatched' || order.status == 'DISPATCHED'">{{'customOrderHistory.Dispatched' | cxTranslate}}</td>
                                    <td id='date' data-label="Order Status" *ngIf="order.status == 'Invoiced' || order.status == 'INVOICED'">{{'customOrderHistory.Invoiced' | cxTranslate}}</td>
                                    <td id='date' data-label="Order Status" *ngIf="order.status == 'Pending' || order.status == 'PENDING'">{{'customOrderHistory.Pending' | cxTranslate}}</td>
                                    <td id='date' data-label="Order Status" *ngIf="!order.status"><span *ngIf="!order.status">&nbsp;</span></td>
                                    
                                    <td id='date' data-label="Estimated Delivery Date">{{order.requestedDeliveryDate}}<span *ngIf="!order.requestedDeliveryDate">&nbsp;</span></td>
                                </tr>
                            </tbody>
                        </table>
                        <!-- PAGINATION -->
                        <div class="page-pagination">
                            <pagination-controls class="my-pagination" [previousLabel]="''" [nextLabel]="''" (pageChange)="getPage($event)" id="server"></pagination-controls>
                            <div class="numberResults">{{start}} - {{current}} {{'customProductList.of' | cxTranslate}} {{totalResults}} {{'customProductList.paginationResults' | cxTranslate}}</div>
                        </div>
                    </div><!-- .col -->
                </div><!-- .row -->
            </div><!-- .tab-pane -->
        </div>
        <!-- SAMPLE REQUEST -->
        <div class="tab-pane order-details-tab" id="tab2">
            <!-- NO RESULTS -->
            <div class="row" *ngIf="totalResults == 0">
                <div class="col-12 text-center my-5">
                    <div class="message">
                       <h1 class="border-top-blue">{{'customOrderHistory.no' | cxTranslate}}<span class="text-bold">{{orderTypeErrorText}}{{'customOrderHistory.order' | cxTranslate}}</span></h1>
                       <p class="lead mb-5">{{'customOrderHistory.weHaveNoOrderForThisAccount' | cxTranslate}}</p>
                       <button (click) = "navigateToHomePage()" class="btn btn-primary m-auto" type="button">{{'customOrderHistory.continue' | cxTranslate}}</button>
                    </div>
                </div>
            </div>
            <div class="row" *ngIf="totalResults > 0">
                <div class="col-12" >
                    <div class="filters">
                        <div class="search-group">
                            <input (ngModelChange) = "__crossBtnVisiblity(box.value)" [(ngModel)]="productNo" type="text" placeholder="{{'customOrderHistory.searchSampleRequestNumber' | cxTranslate}}" #box (keyup.enter)="onEnterSampleReqNumber(box.value)" >
                            <div (click)="__resetValue()" id="clear" class="clear" *ngIf="__crossBtn"></div>
                            <img src="/assets/SearchIconSolid.svg" (click)="onEnterSampleReqNumber(box.value)">
                        </div>
                        <select class="__product-select-filter text_uppercase" (change)="selectProductFilter($event)">
                            <option class="text_uppercase" *ngFor="let filterval of productFilter" [value]="filterval.code" [selected]="filterval.name == selectedProductName">{{ filterval.name }}</option>
                        </select>
                        <select class="__product-select-filter text_uppercase" (change)="selectLocationFilter($event)">
                           <option class="text_uppercase" *ngFor="let filterval of locationfilter" [value]="filterval.code" [selected]="filterval.name == selectedLocationName">{{ filterval.name }}</option>
                        </select>
                        <div class="sort">
                            <label>{{'customOrderHistory.sort_by' | cxTranslate}}</label>
                            <select class="__product-select-filter text_uppercase" (change)="selectSort($event)">
                                <option class="text_uppercase" *ngFor="let sort of sorts" [value]="sort.code" [selected]="sort.selected == true">{{ sort.code }}</option>
                            </select>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                        <label class="total-results">{{totalResults}} {{'customOrderHistory.oder_found' | cxTranslate}}</label>
                    </div>
                </div>
                <div class="col-12 p-0">
                    <table class='styled'>
                        <thead>
                            <th>{{'customOrderHistory.sampleRequestNumber' | cxTranslate}}</th>
                            <th>{{'customOrderHistory.infineumOrderNumber' | cxTranslate}}</th>
                            <th>{{'customOrderHistory.shipToLocation' | cxTranslate}}</th>
                            <th>{{'customOrderHistory.oderStatus' | cxTranslate}}</th>
                            <th>{{'customOrderHistory.estimatedDelevery' | cxTranslate}}</th>
                        </thead>
                        <tbody>
                            
                            <tr *ngFor="let order of orderList | paginate: { id: 'server', itemsPerPage: 10, currentPage: currentPage, totalItems: totalResults }" (click)="navigateToOrderDetails(order.code,'sample')">
                                <td id='supplyingCompany' data-label="SAMPLE REQUEST NUMBER">{{order.purchaseOrderNumber}}<span *ngIf="!order.purchaseOrderNumber">&nbsp;</span></td>
                                    <td id='country' data-label="Infineum Order Number">{{order.referenceOrderNo}}<span *ngIf="!order.referenceOrderNo">&nbsp;</span></td>
                                    <td id='language' data-label="Ship To Location">{{order.shippedTo?.addresses && order.shippedTo?.addresses[0].formattedAddress ? order.shippedTo?.name + ", " + order.shippedTo?.addresses[0].formattedAddress : order.deliveryAddress?.formattedAddress ? order.deliveryAddress?.companyName && order.deliveryAddress?.companyName + ', '+ order.deliveryAddress?.formattedAddress : ""}}</td>
                                    <td id='date' data-label="Order Status" *ngIf="order.status == 'Cancelled' || order.status == 'CANCELLED'">{{'customOrderHistory.Cancelled' | cxTranslate}}</td>
                                    <td id='date' data-label="Order Status" *ngIf="order.status == 'Confirmed' || order.status == 'CONFIRMED'">{{'customOrderHistory.Confirmed' | cxTranslate}}</td>
                                    <td id='date' data-label="Order Status" *ngIf="order.status == 'Dispatched' || order.status == 'DISPATCHED'">{{'customOrderHistory.Dispatched' | cxTranslate}}</td>
                                    <td id='date' data-label="Order Status" *ngIf="order.status == 'Invoiced' || order.status == 'INVOICED'">{{'customOrderHistory.Invoiced' | cxTranslate}}</td>
                                    <td id='date' data-label="Order Status" *ngIf="order.status == 'Pending' || order.status == 'PENDING'">{{'customOrderHistory.Pending' | cxTranslate}}</td>
                                    <td id='date' data-label="Order Status" *ngIf="!order.status"><span *ngIf="!order.status">&nbsp;</span></td>
                                    <td id='date' data-label="Estimated Delivery Date">{{order.requestedDeliveryDate}}<span *ngIf="!order.requestedDeliveryDate">&nbsp;</span></td>
                            </tr>
                        </tbody>
                    </table>
                    <!-- PAGINATION -->
                    <div class="page-pagination">
                        <pagination-controls class="my-pagination" [previousLabel]="''" [nextLabel]="''" (pageChange)="getPage($event)" id="server"></pagination-controls>
                        <div class="numberResults">{{start}} - {{current}} {{'customProductList.of' | cxTranslate}} {{totalResults}} {{'customProductList.paginationResults' | cxTranslate}}</div>
                    </div>
                </div><!-- .col -->
            </div>
        </div><!-- tab-pane -->
    </div><!-- .tab-content -->
    </div>
</div><!-- .container -->



<!--
<div class="container">    
    <div class="row __tab-header">
        <div class="col-lg-12 col-md-12">
            <ul class="nav nav-tabs">
                <li class="nav-item">
                  <a (click)="tabChange('standard')" data-toggle="tab" class="nav-link active __margin-left" href="#first10">{{'customOrderHistory.standard_sales_order' | cxTranslate}}</a>
                </li>
                <li class="nav-item __style-second-tab">
                  <a (click)="tabChange('sample')" data-toggle="tab" class="nav-link" href="#second10">{{'customOrderHistory.request_sample' | cxTranslate}}</a>
                </li>
              </ul>
        </div>
    </div>
    <!-- this is a standard sales order data -->
<!--    <div class="tab-content">
        <div  id="first10" class="tab-pane active">
            <div >
                <div class="row align-items-center">
                    <div class="col-lg-3 col-md-3">
                        <div class="__search-border">
                        <input (ngModelChange) = "__crossBtnVisiblity(box1.value)"  [(ngModel)]="productNo" type="text" placeholder="{{'customOrderHistory.search_po_number' | cxTranslate}}" #box1 (keyup.enter)="onEnterSampleReqNumber(box1.value)" >
                        <div (click)="__resetValue()" id="clear" *ngIf="__crossBtn"></div>
                        <img src="../../assets/Search-Icon.png" (click)="onEnterSampleReqNumber(box1.value)">
                    </div>
                    </div>
                    <div class="col-lg-3 col-md-3">
                        <select class="__product-select-filter text_uppercase" (change)="selectProductFilter($event)">
                            <option class="text_uppercase" *ngFor="let filterval of productFilter" [value]="filterval.code" [selected]="filterval.name == selectedProductName">{{ filterval.name }}</option>
                          </select>
                    </div>
                    <div class="col-lg-3 col-md-3">
                        <select class="__product-select-filter text_uppercase" (change)="selectLocationFilter($event)">
                            <option class="text_uppercase" *ngFor="let filterval of locationfilter" [value]="filterval.code" [selected]="filterval.name == selectedLocationName">{{ filterval.name }}</option>
                          </select>
                    </div>
                    <div class="col-lg-1 col-md-1">
                        <label class="__sortBy-text">{{'customOrderHistory.sort_by' | cxTranslate}}</label>
                    </div>
                    <div class="col-lg-2 col-md-2">
                        <select class="__product-select-filter text_uppercase" (change)="selectSort($event)">
                            <option class="text_uppercase" *ngFor="let sort of sorts" [value]="sort.code" [selected]="sort.selected == true">{{ sort.code }}</option>
                          </select>
                    </div>
                </div>
                <div *ngIf="totalResults > 0">
                <div class="row">
                    <div class="col-lg-12 col-md-12">
                    <hr class="__hr-line"/>
                    <label class="__resultsText">{{totalResults}} {{'customOrderHistory.oder_found' | cxTranslate}}</label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12 col-md-12">
                        <div>
                            
                            
                            
                            
                            <table class='styled'>
                                <thead class='__headerMSDStable'>
                                    <th>{{'customOrderHistory.yourPoNumber' | cxTranslate}}</th>
                                    <th class='__titleMSDS'>{{'customOrderHistory.infineumOrderNumber' | cxTranslate}}</th>
                                    <th class='__titleMSDS'>{{'customOrderHistory.shipToLocation' | cxTranslate}}</th>
                                    <th class='__titleMSDS'>{{'customOrderHistory.oderStatus' | cxTranslate}}</th>
                                    <th class='__titleMSDS'>{{'customOrderHistory.estimatedDelevery' | cxTranslate}}</th>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let order of orderList | paginate: { id: 'server', itemsPerPage: 10, currentPage: currentPage, totalItems: totalResults }" (click)="navigateToOrderDetails(order.code,'standard')" style="cursor:pointer">
                                        <td id="supplyingCompany">{{order.purchaseOrderNumber}}</td>
                                        <td id='country' class='elementMSDS'>{{order.referenceOrderNo}}</td>
                                        <td id='language' class='elementMSDS' >{{order.shippedTo?.addresses && order.shippedTo?.addresses[0].formattedAddress ? order.shippedTo?.name + ", " + order.shippedTo?.addresses[0].formattedAddress : order.deliveryAddress?.formattedAddress ? order.deliveryAddress?.companyName && order.deliveryAddress?.companyName + ', '+ order.deliveryAddress?.formattedAddress : ""}}</td>
                                        <td id='date' class='elementMSDS'>{{order.status}}</td>
                                        <td id='date' class='elementMSDS'>{{order.requestedDeliveryDate}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <!-- footer section -->
            <!-- <div class="row align-items-center">
                <div class="col-lg-12 col-md-12">
            
                    <div class="row pages __pagination-search">
                        <div class="col-md-12 selector">
                            <button class="backArrow" (click)="changePageBack()"> <img src="../../assets/circleArrow.svg"> </button>
                            <div class="numbers">
                              <div class="otherPages" *ngFor="let page of totalPages">
                                <button (click)="changePage(page)" class="pn active firstNumberPaginator numberPage{{page}}" pagenumber="0">{{page}}</button>
                
                              </div>
                            </div>
                            <button class="arrow" (click)="changePageNext()"> <img src="../../assets/circleArrow.svg"> </button>
                            
                            <span class="numberResults">{{start}} - {{current}} of {{totalResults}} results</span>
                        </div>
                    </div>
                </div>
            </div> -->
<!--            <div class="row pages __pagination-search">
                <!-- <div class="col-md-2"></div> -->
 <!--               <div class="col-md-12 selector">
            <div class="has-text-centered">
                <pagination-controls class="my-pagination" [previousLabel]="''"
                [nextLabel]="''" (pageChange)="getPage($event)" id="server"></pagination-controls>
                
            </div>
            <p class="numberResults-order-history">{{start}} - {{current}} {{'customOrderHistory.of' | cxTranslate}} {{totalResults}} {{'customOrderHistory.result' | cxTranslate}}</p>
            </div>
            </div>
        </div>
            </div>
            <div *ngIf="totalResults == 0">
                <div class="cart">
                    <div class="cart-body __cart-body-empty-cart">
                        <hr class="__cart-empty-hr">
                        <label class="cart-empty-title2">{{'customOrderHistory.no' | cxTranslate}} <span class="cart-empty-title text_uppercase">{{orderType}} {{'customOrderHistory.order' | cxTranslate}}</span></label><br>
                        <span class="cart-empty-text">{{'customOrderHistory.weHaveNoOrderForThisAccount' | cxTranslate}}</span>
                        <br>
                        <button (click)="navigateToHomePage()" class="contineu-browesing-btn" type="button">{{'customOrderHistory.continue' | cxTranslate}}
                            <i aria-hidden="true" class="fa fa-chevron-right __btn-right-arrow"></i>
                        </button>
                    </div>
                </div>
            </div>
    
</div>
<!-- </div> -->
<!-- this is sample request  -->
<!-- <div class="tab-content"> -->
<!--    <div id="second10" class="tab-pane">
        <div >
            <div class="row align-items-center">
                <div class="col-lg-3 col-md-3">
                    <div class="__search-border-request">
                    <input (ngModelChange) = "__crossBtnVisiblity(box.value)" [(ngModel)]="productNo" type="text" placeholder="{{'customOrderHistory.searchSampleRequestNumber' | cxTranslate}}" #box (keyup.enter)="onEnterSampleReqNumber(box.value)" >
                    <div (click)="__resetValue()" id="clear" *ngIf="__crossBtn"></div>
                    <img src="../../assets/Search-Icon.png" (click)="onEnterSampleReqNumber(box.value)" style="cursor: pointer;">
                </div>
                </div>
                <div class="col-lg-3 col-md-3">
                    <select class="__product-select-filter text_uppercase" (change)="selectProductFilter($event)">
                        <option class="text_uppercase" *ngFor="let filterval of productFilter" [value]="filterval.code" [selected]="filterval.name == selectedProductName">{{ filterval.name }}</option>
                      </select>
                </div>
                <div class="col-lg-3 col-md-3">
                    <select class="__product-select-filter text_uppercase" (change)="selectLocationFilter($event)">
                        <option class="text_uppercase" *ngFor="let filterval of locationfilter" [value]="filterval.code" [selected]="filterval.name == selectedLocationName">{{ filterval.name }}</option>
                      </select>
                </div>
                <div class="col-lg-1 col-md-1">
                    <label class="__sortBy-text">{{'customOrderHistory.sort_by' | cxTranslate}}</label>
                </div>
                <div class="col-lg-2 col-md-2">
                    <select class="__product-select-filter text_uppercase" (change)="selectSort($event)">
                        <option class="text_uppercase" *ngFor="let sort of sorts" [value]="sort.code" [selected]="sort.selected == true">{{ sort.code }}</option>
                      </select>
                </div>
            </div>
            <div *ngIf="totalResults > 0">
            <div class="row">
                <div class="col-lg-12 col-md-12">
                <hr class="__hr-line"/>
                <label class="__resultsText">{{totalResults}} {{'customOrderHistory.oder_found' | cxTranslate}}</label>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12 col-md-12">
                    <div>
                        <table class='order-hostory'>
                            <thead class='__headerMSDStable'>
                                <th class='__titleMSDS'>{{'customOrderHistory.sampleRequestNumber' | cxTranslate}}</th>
                                <th class='__titleMSDS'>{{'customOrderHistory.infineumOrderNumber' | cxTranslate}}</th>
                                <th class='__titleMSDS'>{{'customOrderHistory.shipToLocation' | cxTranslate}}</th>
                                <th class='__titleMSDS'>{{'customOrderHistory.oderStatus' | cxTranslate}}</th>
                                <th class='__titleMSDS'>{{'customOrderHistory.estimatedDelevery' | cxTranslate}}</th>
                            </thead>
                            <tbody>
                                <tr *ngFor="let order of orderList | paginate: { id: 'server', itemsPerPage: 10, currentPage: currentPage, totalItems: totalResults }" (click)="navigateToOrderDetails(order.code, 'sample')" style="cursor:pointer">
                                    <td id='supplyingCompany' class='elementMSDS'>{{order.purchaseOrderNumber}}</td>
                                    <td id='country' class='elementMSDS'>{{order.referenceOrderNo}}</td>
                                    <td id='language' class='elementMSDS' >{{order.shippedTo?.addresses && order.shippedTo?.addresses[0].formattedAddress ? order.shippedTo?.name + ", " + order.shippedTo?.addresses[0].formattedAddress : order.deliveryAddress?.formattedAddress ? order.deliveryAddress?.companyName && order.deliveryAddress?.companyName + ', '+ order.deliveryAddress?.formattedAddress : ""}}</td>
                                    <td id='date' class='elementMSDS'>{{order.status}}</td>
                                    <td id='date' class='elementMSDS'>{{order.requestedDeliveryDate}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        <!-- footer section -->
        <!-- <ul class="meal-list">
            <li *ngFor="let meal of orderList | async | paginate: { id: 'server', itemsPerPage: 10, currentPage: currentPage, totalItems: totalResults }">
                {{ meal }}
            </li>
        </ul> -->
<!--        
        <div class="row pages __pagination-search">
            <div class="col-md-12 selector">
        <div class="has-text-centered">
            <pagination-controls class="my-pagination" [previousLabel]="''"
            [nextLabel]="''" (pageChange)="getPage($event)" id="server"></pagination-controls>
        </div>
        <p class="numberResults-order-history">{{start}} - {{current}} {{'customOrderHistory.of' | cxTranslate}} {{totalResults}} {{'customOrderHistory.result' | cxTranslate}}</p>
        </div>
        </div>
    </div>
        </div>
<div *ngIf="totalResults == 0">
    <div class="cart">
        <div class="cart-body __cart-body-empty-cart">
            <hr class="__cart-empty-hr">
            <label class="cart-empty-title2">{{'customOrderHistory.no' | cxTranslate}} <span class="cart-empty-title text_uppercase">{{orderType}} {{'customOrderHistory.order' | cxTranslate}}</span></label><br>
            <span class="cart-empty-text">{{'customOrderHistory.weHaveNoOrderForThisAccount' | cxTranslate}}</span>
            <br>
            <button (click)="navigateToHomePage()" class="contineu-browesing-btn" type="button">{{'customOrderHistory.continue' | cxTranslate}}
                <i aria-hidden="true" class="fa fa-chevron-right __btn-right-arrow"></i>
            </button>
        </div>
    </div>
</div>   
</div>
</div>-->
<!-- error page if no data -->