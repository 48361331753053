<div class="container-fluid about">
    <div class="container">
      <div class="col-md-10 text">
          <h3>Legal Notice</h3>
          The information contained on this website (“Site”) is based upon data believed to be reliable at the time of posting and relates only to the matters specifically mentioned on the Site.
          Although Infineum has used reasonable skill and care in the preparation of this information, in the absence of any overriding obligations arising under a specific contract, no representation, warranty (express or implied), or guarantee is made as to the suitability, accuracy, reliability or completeness of the information.
          Nothing in this Site shall reduce the user's responsibility to satisfy itself as to the suitability, accuracy, reliability, and completeness of such information for its particular use, and there is no warranty against intellectual property infringement.
          Infineum shall not be liable for any loss, damage or injury that may occur from the use of this information other than in the event of death or personal injury to any person caused by Infineum’s negligence.<br>
          <br>
          Links to third party websites on this Site are provided solely for your convenience.<br>
          Infineum does not control and is not responsible for the content of those third party websites.<br>
          If you decide to access any of those websites, you do so entirely at your own risk.<br>
          <br>
          Please also refer to our Privacy Policy.<br>
          <br>
          <h3>Privacy Policy</h3>
          Infineum International Limited controls the information relating to you (“Personal Information”) that is collected on this Site. Our policy on the collection and use of personal information is outlined below. We process your Personal Information in compliance with the privacy and data protection laws in force in the European Economic Area (“EEA”) and United Kingdom (“UK”).<br>
          <br>
          We collect, store and use your Personal Information (including name, job title, business telephone number and business e-mail address) for the following purposes:<br>
            <ul>
              <li>To provide a service requested by you</li>
            </ul>
          We use your Personal Information to ensure accuracy in responding to requests for literature, product information and/or other information or material relating to Infineum and our publication Insight, where you have requested to receive these.<br>
            <ul>
              <li>To meet our business needs</li>
            </ul>
          The Personal Information we collect is also used by us for internal purposes such as improving our products and promotions and learning about our customers’ needs.<br>
          <br>
          You can visit this Site without revealing much information about yourself.  However, our servers automatically log technical data about visitors, for purposes of security, and for use in the analysis of the performance of our Site.<br>
          <br>
          <h3>Sharing Personal Information</h3>
          In the course of collecting and using the aforementioned Personal Information, we may need to provide your Personal Information to other Infineum entities within the Infineum group of companies ("Infineum Affiliates"), and/or to third parties, who provide services to us, for instance to manage our databases or mailings, solely for the purposes of carrying out the collection, storage and use set forth above. In addition, and in that regard, we may need to transfer your Personal Information to countries outside of the European Union, including the United States, and your Personal Information may be used in those countries for the purposes set out in this policy.<br>
          <br>
          When sharing your Personal Information with entities located in countries outside the EEA, UK or Switzerland that do not provide for an equivalent level of data protection, we will put in place contractual guarantees in accordance with applicable data protection law.<br>
          <br>
          <h3>Cookies</h3>
          We use cookies on this Site. Please see our Cookie Policy for further details.<br>
          <br>
          <h3>Data Integrity</h3>
          We take reasonable steps to ensure that the Personal Information we collect is relevant for the purposes for which it is to be used and that the Personal Information is reliable for its intended use and is accurate, complete and current. However, in some cases we must rely on individual users to provide and maintain accurate Personal Information. You are responsible for making sure that your information is up-to-date and accurate when you provide it to us and where our services offer you the tools to maintain your information. If you would like to review or change the details with which you have supplied us, please contact us.<br>
          <br>
          <h3>Links to Other Internet Web Sites</h3>
          We have implemented technology and policies with the underlying objective of protecting your privacy from unauthorised access and improper use and will update these measures as new technology becomes available. However, we cannot be responsible for the privacy policies and practices of other web sites even if you access them using links from our Site. Accordingly, we recommend that you check the policy of each site you visit. In addition, if you linked to our Site from a third party web site or vice versa, we cannot be responsible for the privacy policies and practices of the owners or operators of the third party web site from which you linked to our Site and recommend that you check the policy of that third party web site and contact the owner or operator of such web site if you have any concerns of questions.<br>
          <br>
          <h3>Your Rights</h3>
          Subject to restrictions under the privacy and data protection laws in force in the EEA and UK, you have the right to request from us access, rectification, erasure, restriction and discontinuance of processing, and portability of your Personal Information and not to be subject to automated individual decision-making.  Such requests can be addressed to the contact set out below.<br>
          <br>
          <h3>Retention of Personal Information</h3>
          We will only retain Personal Information for as long as we are required to do so by applicable law(s), or for as long as necessary for the purpose(s) for which it is processed.  We will delete Personal Information when it is no longer needed and, in any case, upon expiration of the maximum storage term set forth in applicable law.<br>
          <br>
          <h3>Changes to This Privacy Policy</h3>
          If we make any material changes to this policy, we will post the revised Privacy Policy and highlight the changes. You should check this Site periodically to see if any recent changes to this policy have been made.  You can also tell if this Privacy Policy has changed recently by reviewing the Effective Date posted at the beginning of this Privacy Policy.<br>
          <br>
          <h3>Contact Us</h3>
          If you have any questions about this Privacy Policy, our privacy practices or any concerns or complaints about our use of your Personal Information, you can contact the Infineum UK Data Protection Representative at <a href="mailto:UKHR@Infineum.com">UKHR&#64;Infineum.com</a>.<br>
          <br>
          If you think that your concern has not been adequately addressed, you may have a right to lodge a complaint with the Supervisory Authority for privacy in your country.<br>
          <br>
          Infineum International Limited<br>
          Milton Hill Business and Technology Centre<br>
          P.O. Box 1, Abingdon Oxfordshire OX13 6BB.  England<br>
          Registered in England No. 3623664<br>
          Tel:  <a href="tel:+441235549500">+44 1235 54 9500</a><br>
          <br>
          <h3>Cookie Policy</h3>
          <span>Your privacy - how cookies are used by Infineum International Limited (“Infineum”)</span><br>
          <br>
          Infineum uses small amounts of data stored on your computer called 'cookies' to collect information about how visitors use the website. You can control what cookies, if any, are stored on your computer. Find out more about the cookies we use below.
          You can change your preferences at any time by visiting the cookies settings page.<br>
          <br>
          <h3>What is a cookie?</h3>
          A cookie is a small amount of data sent to your computer or mobile phone from a website. It often includes a unique identifier and is stored on your device's hard drive. Infineum does not use cookies to collect personal data about you.<br>
          <br>
          <h3>Services that need cookies to work</h3>
          Some services need to store a cookie on your computer for them to work. These cookies are removed when you finish using the service. If cookies are blocked then these services will not work<br>
          <br>
          Controlling what cookies are stored on your computer<br>
          <br>
          You can set your devices to:<br>
          <ul>
            <li>Accept all cookies</li>
            <li>Tell you when a cookie is being received</li>
            <li>Not receive cookies</li>
          </ul>
          Each browser is different, so check the 'Help' menu of your browser to learn how to change your cookie preferences.<br>
          <br>
          <h3>Rejecting cookies</h3>
          If you want to restrict or block the cookies set by Infineum you can do this through your browser settings. The 'Help' menu in your browser should explain how you can do this, as well as how to delete cookies from your computer.<br>
          <br>
          <h3>Mobile phones - rejecting cookies</h3>
          For information about how to reject cookies on your mobile phone's browser, see your handset manual.<br>
          <br>
          <h3>Necessary cookies</h3>
          These are cookies our website needs to function normally. They enable things like security, network management, and accessibility. You can turn them off by changing your browser settings, but this may affect how well the website works.<br>
          <br>
          <h3>Analytics cookies</h3>
          Infineum.com uses Google Analytics, a web analytics service provided by Google, Inc. Google Analytics sets a cookie in order to evaluate your use of the site.<br>
          <br>
          Google stores the information collected by the cookie on servers in the United States. Google may also transfer this information to third parties where required to do so by law, or where such third parties process the information on Google's behalf. Google will not associate your IP address with any other data held by Google. By using the site, you consent to the processing of data about you by Google in the manner and for the purposes set out above.<br>
          The specific Google analytics cookies witnessed on Infineum.com are:<br>
          <table>
            <thead>
                <th style="width: 20%;">Cookies for:</th>
                <th style="width: 20%;">Name:</th>
                <th>Purpose:</th>
            </thead>
            <tbody>
              <tr>
                <td></td>
                <td>_gat_UA-11394374-1</td>
                <td></td>
              </tr>
              <tr>
                <td></td>
                <td>_dc_gtm_UA-11394374-1</td>
                <td>
                </td>
              </tr>
              <tr>
                <td></td>
                <td>_ga</td>
                <td></td>
              </tr>
              <tr>
                <td></td>
                <td>_gaexp</td>
                <td></td>
              </tr>
              <tr>
                <td style="vertical-align: initial;">Google Analytics</td>
                <td style="vertical-align: initial;">_gid</td>
                <td style="vertical-align: initial;">
                  These cookies are used to collect information about how visitors use our website. We use the information to compile reports and to help us improve the website. The cookies collect information in a way that does not directly identify anyone, including the number of visitors to the website and blog, where visitors have come to the website from and the pages they visited.
                </td>
              </tr>
            </tbody>
          </table>
          <br>
          <h3>Functional cookies</h3>
          These allow the website to remember choices you make, such as your settings, to give you better functionality and a more personalised experience.<br>
          <br>
          None reported in addition to strictly necessary cookies.<br>
          <br>
          <br>
          <h3>Third-party cookies</h3>
          These are placed on your device by a third party website ( i.e. Vimeo or YouTube). If you turn them off, those features of the website will not be available.
          <ul>
            <li>Vimeo: cookies required for Vimeo video-based content to function correctly. Read about types of cookie used by Vimeo.</li>
          </ul>
      </div>
    </div>
  </div>