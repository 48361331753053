import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ProductDetailsService } from '../APIcall/product-details.service'

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.scss']
})
export class PrivacyComponent implements OnInit {

  constructor(private productDetailsService: ProductDetailsService, private location: Location) { }

  ngOnInit(): void {
    this.productDetailsService.changeBreadcrumb({"breadcumTitle" : "terms-and-conditions", "categoryName": "", "productName": ""});
  }


  backClicked() {
    this.location.back();
  }
}
