<div class="container mw-100">
    <div class="row">
        <div class="col-lg-8 col-md-8">
            <label class="__fingerTips">{{'customHeaderFaq.fingerTipsFaq' | cxTranslate}}</label>
        </div>
        <div class="col-lg-4 col-md-4">
            <a class="__faq" (click)="navigateHelp()">{{'customHeaderFaq.helpFaq' | cxTranslate}}</a>
        </div>
    </div>
</div>
