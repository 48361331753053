<!--<div class="container">
  <div class="row">
    <div class="col-12">
      <h1>{{productTitle}}</h1>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <p class="productContent">{{productSummary}}</p>
      <p class="productContent">{{productDescription}}</p>
    </div>
  </div>
</div>-->

<div class="product-image-updated">
    <h1>{{productTitle}}</h1>
    <p class="lead">{{productSummary}}</p>
    <p class="lead">{{productDescription}}</p>
</div>