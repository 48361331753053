<ul class="nav">
    <li *ngIf="claimsTitle"><a (click) = "headerLinksNav(claimsUrl)">{{claimsTitle}}</a>
        <!--<img class="__line-image" src="../../assets/Line-cart.png">-->
    </li>
    <li><a>{{'customHeaderLink.welcome' | cxTranslate}} {{displayUserName}}</a>
        <!--<img class="__line-image" src="../../assets/Line-cart.png">-->
    </li>
    <li class="dropdown">
        <a>{{myAccountTitle}}</a>
        <ul class="dropdown">
            <li  *ngFor="let item of myAccount" class="dropdown-item"><a (click) = "headerLinksNav(item.url)">{{item.title}}</a></li>
            <!-- <li class="dropdown-item"><a href="#">Saved Items</a></li>
            <li class="dropdown-item"><a href="#">Personal Details</a></li> -->
        </ul>
        <!--<img class="__line-image" src="../../assets/Line-cart.png">-->
    </li>
    <li *ngIf="signOutTitle"><a (click) = "headerLinksNav(signOutUrl)">{{signOutTitle}}</a></li>
</ul>