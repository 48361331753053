import { AfterViewInit, ChangeDetectorRef, Component, Inject, OnInit, ɵɵstylePropInterpolate2 } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ChangeNextPageContext } from '@spartacus/core/src/routing/store/actions/router.action';
import { element } from 'protractor';
import { ClaimSearchModel } from '../APIcall/claimsearch.model';
import { ClaimsearchService } from '../APIcall/claimsearch.service';
import { DialogDataClaimComponent } from './dialog-data-claim/dialog-data-claim.component';
import { DialogFilterClaimComponent } from './dialog-filter-claim/dialog-filter-claim.component';
import { UserServiceService } from '../APIcall/user-service.service';
declare var $: any;

interface filterStructure {
  category : boolean
  multiSelect : boolean
  name : string
  priority : number
  values : [
      {
          count : number
          name : string
          query : {
              query : {
                  value : string
              },
              url : string
          },
          selected: false
      }
  ]
  visible : boolean
}

@Component({
  selector: 'app-claim-search',
  templateUrl: './claim-search.component.html',
  styleUrls: ['./claim-search.component.scss'],
  // providers: [ClaimsearchService]
})
export class ClaimSearchComponent implements OnInit {

  userID: any = "";
  public result: number;
  private showFilter: Array<{name: string, show: boolean}>;
  private data: ClaimSearchModel;
  private baseFilters : Array<string>;
  isMobile : boolean;
  productCode: any = "";
  noClaimsErr: boolean = false;
  pageSize: number = 12;
  constructor(public dialog: MatDialog, private claimsearchService: ClaimsearchService, private ref: ChangeDetectorRef, private userService: UserServiceService) {
    this.result = 3;
    this.baseFilters = ["materialName", "segmentName", "oilCode", "viscosityModifier", "gradeName", "claimspec"];
    this.showFilter = new Array<{name: string, show: boolean}>();
    this.data = undefined;
    this.isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  }

  private openDialog(productCode : string, treatId : string) {
    this.dialog.open(DialogDataClaimComponent, {
      data: {
        productCode: productCode,
        treatId : treatId
      }
    });
  }

  private openDialogFiltri(filter: string){
    var values = this.data.facets.filter(element => element.name === filter)[0].values;
    $(".filter-"+filter).addClass("selectedFilterRow");
    $(".arrow-"+filter).empty();
    $(".arrow-"+filter).append("<img src='../../assets/filterArrowWhite.svg'>");
    const dialogRef = this.dialog.open(DialogFilterClaimComponent, {
      data: {
        values : values
      }
    });
    dialogRef.afterClosed().subscribe(
      data => 
        this.addDataAfterClose(data, filter)
    );
  }

  private addDataAfterClose(data: any, filter: string){
    $(".filter-"+filter).removeClass("selectedFilterRow");
    $(".arrow-"+filter).empty();
    $(".arrow-"+filter).append("<img src='../../assets/filterArrow.svg'>");
    data != "" ? this.filterSelected(data) : ""
  }

  private showFilters(filter: string){
    if($("div."+filter).hasClass("hide") || $("div."+filter).css("display") == "none"){
      $("div."+filter).removeClass("hide");
      $("div."+filter).show();
    }
    else{
      $("div."+filter).hide();
    }
  }

  public loadClaims(from : string, filter: string, action : string){
    if(from === "filterClaims" || from === "clearFilter" || from === "removeClaims"){
      $(".filters").empty();
      $(".selected").empty();
      $(".claimsList").empty();
      $(".otherPages").empty();
      $(".numberResults").empty();
      $(".errorMessage").remove();
    }
    if(from == "oninit" && localStorage.getItem("claimsInquiry") != undefined){
      this.data = JSON.parse(localStorage.getItem("claimsInquiry")) as ClaimSearchModel
      if(from == "oninit" || (from === "removeClaims" && this.claimsearchService.getLastFilter() === ":") || from === "clearFilter"){
        this.sortFilters();
      }
      this.data.facets.forEach(filter => {
        this.showFilter.push({
          name: filter.name,
          show: filter.visible,
        });
      });
      if(this.data.claims.length < 1){
        $(".update").hide();
        $(".pages").hide();
        var noItems =
            "<div class='col-md-12 errorMessage'> \
              <h1> Sorry, your search returned no results.</h1><br> \
              <h1 class='contacUsError'> Contact us about this specification and let us help you find the product you need </h1> \
              <a class='contactUs' href='/contact'> Go to Contact Us Page </a> \
            </div>";
        $(".paginator").append(noItems);
      }
      else{
        this.displayFilters(from);
        this.displayClaims();
      }
    }
    else{
      var pag: number = 0;
      if(this.data != undefined && filter === ""){
        pag = this.data.pagination.currentPage;
      }
      this.getClaims(filter, action, pag, this.userID).then(() => {
        this.data = this.claimsearchService.getClaimsData(this.userID);
        // localStorage.setItem("claimsInquiry", JSON.stringify(this.data));
        if(from == "oninit" || (from === "removeClaims" && this.claimsearchService.getLastFilter() === ":") || from === "clearFilter"){
          this.sortFilters();
        }
        this.data.facets.forEach(filter => {
          this.showFilter.push({
            name: filter.name,
            show: filter.visible,
          });
        });
        if(this.data.claims.length < 1){
          $(".update").hide();
          $(".pages").hide();
          var noItems =
              "<div class='col-md-12 errorMessage'> \
                <h1> Sorry, your search returned no results.</h1><br> \
                <h1 class='contacUsError'> Contact us about this specification and let us help you find the product you need </h1> \
                <a class='contactUs' href='/contact'> Go to Contact Us Page </a> \
              </div>";
              if(this.productCode == ""){
                $(".paginator").append(noItems);
                $(".claims-search-container").show();
              }else{
                // this.noClaimsErr = true;
                this.claimsearchService.changeClaims(true);
                $(".claims-search-container").hide();
                this.ref.detectChanges();
              }
          // $(".paginator").append(noItems);
        }
        else{
          this.claimsearchService.changeClaims(false);
          this.displayFilters(from);
          this.displayClaims();
        }
      }).catch(() => {
        console.log("Error");
        $(".update").hide();
        $(".pages").hide();
        var noItems =
            "<div class='col-md-12 errorMessage'> \
              <h1> Sorry, your search returned no results.</h1><br> \
              <h1 class='contacUsError'> Contact us about this specification and let us help you find the product you need </h1> \
              <a class='contactUs' href='/contact'> Go to Contact Us Page </a> \
            </div>";
        if(this.productCode == ""){
          $(".paginator").append(noItems);
          $(".claims-search-container").show();
        }else{
          // this.noClaimsErr = true;
          this.claimsearchService.changeClaims(true);
          $(".claims-search-container").hide();
          this.ref.detectChanges();
        }
        // $(".paginator").append(noItems);
      });
    }
  }

  ngOnInit(): void {
    // console.log("Claim search component::::::::::::::::::")
    // this.claimsearchService.currentClaims.subscribe((claim) => {
    //   console.log("claim subsribed from tab:::::::::",claim);
    // })
    this.userService.getUserDetails().then((user) => {
      this.userID = user["uid"];
    })
    this.productCode = "";
    var i = 0;
    for(let element of window.location.pathname.split("/")){
      if(element == 'p'){
        this.productCode = window.location.pathname.split("/")[i+1];
        break;
      }else if(element == 'product'){
        this.productCode = window.location.pathname.split("/")[i+1];
        break;
      }
      i++;
    }
    if(!this.isMobile){
      $(".claimsList").css("padding-left", "15px");
    }
    if(this.productCode != "") {
      window.localStorage.removeItem('claimsInquiry');
      let claimFilter = ":relevance:productCode:" + this.productCode;
      this.loadClaims("oninit",claimFilter , "add");
    } else {
      this.loadClaims("oninit","" , "add");
    }
    // this.ref.detectChanges();
  }

  private async getClaims(filter: string, action: string, page: number, userId){
    await this.claimsearchService.getClaims(filter, action, page, this.pageSize, userId,"");
  }

  ngAfterViewInit(){
    $(document).ready(function(){
      const user = window.localStorage.getItem('spartacus⚿⚿auth');
      const json = JSON.parse(user);
      if(json.userId == "current"){
        $(".searchbox").show();
        $(".SiteLogin").show();
        $(".MiniCart").show();
        $(".NavigationBar").show();
      }
    });
  }

  private displayFilters(from : string){
    var filters = this.data.facets;
    $(".filter-row").show();
    //display already selected filters 
    var lastCall = ":";
    this.data.breadcrumbs.forEach(element => {
      var banner = 
          "<div class='row' style='margin:auto;'> \
            <div class='category'> \
              <div class='col-md-10'> \
                <h1 class='filterName'>" + this.mapFilterName(element.facetName) + " </h1> \
              </div> \
              <div class='col-md-2'> \
                <button class='showFilters' filterName='"+ element.facetName +"'> \
                  <img src='../../assets/filterArrow.svg'> \
                </button> \
              </div> \
            </div> \
            <div class='col-md-12 filters-style " + element.facetName + "'> \
            </div> \
          </div>";
        $(".selected").append(banner);

        var filters =
          "<button class='clear filter-button' disabled> \
            <div class='selectedFilter' [innerHTML]='" + element.facetValueName + "'></div> \
            " + element.facetValueName + " \
            <a class='removeFilter' filterName='"+ element.facetValueName +"' filterClass='"+element.facetName+"'> \
              <i class='fas fa-times-circle disableFilter'></i> \
            </a> \
          </button>";
        $("." +element.facetName).append(filters);
        lastCall += ":"+element.facetName+":"+element.facetValueName;
    });
    this.claimsearchService.setLastFilter(lastCall);
    //dispaly other filters
    filters.forEach(element => {
      if(from === "filterClaims" || (from === "removeClaims" && lastCall === ":" && this.baseFilters.includes(element.name)) || this.baseFilters.includes(element.name)){
        var banner = 
          "<div class='row' style='margin:auto;'> \
            <a [routerLink]='' class='openDialogFiltri' filterName='"+ element.name +"'> \
              <div class='category categoryNotSelected filter-"+ element.name +"'> \
                <div class='col-md-10'> \
                    <h1 class='filterName'>" + this.mapFilterName(element.name) + " </h1> \
                </div> \
                <div class='col-md-2'> \
                  <button class='showFilters arrow-"+ element.name +"'> \
                    <img src='../../assets/filterArrow.svg'> \
                  </button> \
                </div> \
              </div> \
            </a> \
          </div>";
        $(".filters").append(banner);
      }
    });

    $(".showFilters").click((data) =>{
      var filterName = $(data.currentTarget).attr('filterName');
      this.showFilters(filterName);
    });

    $(".openDialogFiltri").click((data) =>{
      var filterName : string = $(data.currentTarget).attr('filterName') as string;
      this.openDialogFiltri(filterName);
    });

    $(".removeFilter").click((data) =>{
      var filterName = $(data.currentTarget).attr('filterName');
      var filterClass = $(data.currentTarget).attr("filterClass");
      this.loadClaims("removeClaims",":"+filterClass+":"+filterName, "delete");
    });
  }

  private displayClaims(){
    var claims = this.data.claims;
    $(".update").show();
    claims.forEach((singleClaim, index) => {
      var treatRate = singleClaim.treatRate != undefined ? singleClaim.treatRate : "-";
      var category = "";
      switch (singleClaim.segmentName) {
        case "Small Engine":
          category = "../assets/icons/Car.svg";
          break;
        case "Large Engine":
          category = "../assets/icons/Lorry.svg";
          break;
        case "Crankcase":
          category = "../assets/icons/Boat.svg";
          break;
        default:
          category = "../assets/noitem.png";
          break;
      }
      //mobile settings
      var widthCard = this.isMobile ? "" : "widthCard";
      var floatButton = this.isMobile ? "floatButton" : "";
      var maxHeight = this.isMobile ? "" : "maxHeight";

      var claimCard =
        "<div class='col-md-3 claimCardRow "+ widthCard +"'> \
          <div class='claimCardContainer'> \
            <div class='col-md-9 col-sm-6'> \
              <span class='titleClaim blueInform'>"+ singleClaim.productName +"</span><br> \
              "+ singleClaim.oilCode +"<br> \
            <span class='grey'>Oil code</span> \
            </div> \
            <div class='col-md-3 col-sm-6'> \
              <a [routerLink]='' class='openDialog "+ floatButton +"' productCode='"+ singleClaim.productCode +"' treatId='"+ singleClaim.treatId +"'> \
                <img src='../../assets/circleArrow.svg' class='blueInform'> \
              </a> \
            </div> \
          </div> \
          <div class='claimCardDetails'> \
            <div class='row rowDetails "+ maxHeight +"'> \
              <div class='col-md-6'> \
                "+ treatRate +"<br> \
                <span class='grey'>Treat Rate </span> \
              </div> \
              <div class='col-md-6'> \
                <img class='categoryImg' src='"+ category +"'><br> \
                <span class='grey'>Category </span> \
              </div> \
            </div> \
            <div class='row rowData "+ maxHeight +"'> \
              <div class='col-md-6'> \
                "+ singleClaim.viscosityModifiers +"<br> \
                <span class='grey'>VM</span> \
              </div> \
              <div class='col-md-6'> \
              "+ singleClaim.gradeName +"<br> \
                <span class='grey'>Grade</span> \
              </div> \
            </div> \
            <div class='row rowDetails "+ maxHeight +"'> \
              <div class='col-md-12'> \
                "+ singleClaim.baseStocks +"<br> \
                <span class='grey'>Base Stock</span> \
              </div> \
            </div> \
          </div> \
          <div class='containerSpecsCount'> \
            <span class='specsCount'>"+ singleClaim.claimsSpecsCount +"</span> claims \
          </div> \
        </div>";
        $(".claimsList").append(claimCard);
    });

    $(".paginator").show();

   var toPrint = new Array<number>();
   var faketotalPages = 8;
    switch (this.data.pagination.totalPages){
      case 1:
        toPrint.push(1);
        break;
      case 2:
        toPrint.push(1);
        toPrint.push(2);
        break;
      case 3:
        toPrint.push(1);
        toPrint.push(2);
        toPrint.push(3);
        break;
      default:
        toPrint.push(1);
        toPrint.push(2);
        toPrint.push(3);
        toPrint.push(this.data.pagination.totalPages);
        break;
    }
    this.printNumbers(toPrint);

    $(".openDialog").click((data) =>{
      var productCode = $(data.currentTarget).attr('productCode');
      var treatId = $(data.currentTarget).attr('treatId');
      this.openDialog(productCode, treatId);
    });
  }

  private printNumbers(toPrint: Array<number>){
    var margin = "";
    var active = "";
    toPrint.forEach((number, i) => {
      if(number == this.data.pagination.totalPages && this.data.pagination.totalPages > 3){
        var points =
          "<span class='points'>...</span>";
          $(".otherPages").append(points);
        margin = "";
      }
      else if(i == 0){
        margin = "firstNumberPaginator";
      }
      else{
        margin = "numberPaginator";
      }

      //verify active
      if(number == (this.data.pagination.currentPage+1)){
        active = "active";
      }
      else{
        active = "disable";
      }
      var display =
      "<button class='pn "+ active +" "+ margin +" numberPage-"+ (number -1) +"' pageNumber='"+ (number -1) +"'>"+ number +"</button>";
    $(".otherPages").append(display);
    });
    this.activeArrows(this.data.pagination.currentPage);

    var start = this.data.pagination.currentPage != 0 ? ((this.data.pagination.currentPage)*this.data.pagination.pageSize)+1 : 1;
    var current;
    if(start + this.data.pagination.pageSize > this.data.pagination.totalResults){
      current = this.data.pagination.totalResults;
    }
    else{
      current = (start-1) + this.data.pagination.pageSize;
    }
    $(".numberResults").append(start+"-"+current+" of "+this.data.pagination.totalResults+" results");

    $(".pn").click((data) =>{
      var pageNumber = $(data.currentTarget).attr('pageNumber');
      this.changePage(pageNumber);
    });
  }

  //change current page
  private changePage(pageNumber : number){
    $(".numberPage-"+this.data.pagination.currentPage).removeClass("activeArrow");
    $(".numberPage-"+this.data.pagination.currentPage).addClass("disableArrow");
    this.loadDifferentPage(pageNumber);
  }

  //switch to old page
  public oldPage(){
    if(this.data.pagination.currentPage != 0){
      $(".numberPage-"+this.data.pagination.currentPage).removeClass("activeArrow");
      $(".numberPage-"+this.data.pagination.currentPage).addClass("disableArrow");
      this.loadDifferentPage(this.data.pagination.currentPage-1);
    }
  }

  //switch to new page
  public newPage(){
    if((this.data.pagination.currentPage +1) < this.data.pagination.totalPages){
      $(".numberPage-"+this.data.pagination.currentPage).removeClass("activeArrow");
      $(".numberPage-"+this.data.pagination.currentPage).addClass("disableArrow");
      this.loadDifferentPage(this.data.pagination.currentPage+1);
    }
  }

  public filterSelected(filter: string){
    if(filter != undefined){
      this.loadClaims("filterClaims", filter, "add");
    }
  }
  
  private activeArrows(currentPage : number){
    if(currentPage == 0 && (currentPage+1) == this.data.pagination.totalPages){
      $(".backArrow").prop("disabled", true);
      $(".arrow").prop("disabled", true);
    }
    else if(currentPage == 0){
      $(".backArrow").prop("disabled", true);
      $(".arrow").prop("disabled", false);
    }
    else if((currentPage+1) == this.data.pagination.totalPages){
      $(".backArrow").prop("disabled", false);
      $(".arrow").prop("disabled", true);
    } 
    else{
      $(".backArrow").prop("disabled", false);
      $(".arrow").prop("disabled", false);
    }
  }

  private loadDifferentPage(pagenumber : number){
    this.claimsearchService.changePage(pagenumber, this.userID).then(() => {
      this.data = this.claimsearchService.getClaimsData(this.userID);
      // localStorage.setItem("claimsInquiry", JSON.stringify(this.data));
      $(".claimsList").empty();
      $(".otherPages").empty();
      $(".numberResults").empty();
      this.displayClaims();
      $(".numberPage-"+this.data.pagination.currentPage).removeClass("disableArrow");
      $(".numberPage-"+this.data.pagination.currentPage).addClass("activeArrow");
      this.activeArrows(this.data.pagination.currentPage);
    })
  }

  private mapFilterName(filter : string) : string{
    switch(filter){
      case "gradeName":
        return "Grades";
      case "ashPercentage":
        return "Ash";
      case "oilCode":
        return "Oil code";
      case "segmentName":
        return "Category";
      case "booster":
        return "Booster";
      case "materialName":
        return "Product Platform";
      case "viscosityModifier":
        return "Viscosity Modifier";
      case "fuelType":
        return "Fuel Type";
      case "engine":
        return "Engine";
      case "solvent":
        return "Solvent";
      case "baseNumber":
        return "TBN";
      case "claimspec":
        return "Claims";
      default:
        return filter;
    }
  }

  private sortFilters(){
    var sortedFilters : Array<filterStructure> = new Array<filterStructure>();
    if(this.data.facets.find(element => element.name === "segmentName") != undefined){
      sortedFilters.push(this.data.facets.find(element => element.name === "segmentName"));
    }
    if(this.data.facets.find(element => element.name === "claimspec")){
      sortedFilters.push(this.data.facets.find(element => element.name === "claimspec"));
    }
    /*if(this.data.facets.find(element => element.name === "materialName")){
      sortedFilters.push(this.data.facets.find(element => element.name === "materialName"));
    }*/
    if(this.data.facets.find(element => element.name === "gradeName")){
      sortedFilters.push(this.data.facets.find(element => element.name === "gradeName"));
    }
    if(this.data.facets.find(element => element.name === "oilCode")){
      sortedFilters.push(this.data.facets.find(element => element.name === "oilCode"));
    }
    if(this.data.facets.find(element => element.name === "viscosityModifier")){
      sortedFilters.push(this.data.facets.find(element => element.name === "viscosityModifier"));
    }
    this.data.facets = sortedFilters;
  }

}