import { Component, OnInit } from '@angular/core';
import { CmsBannerComponent, CmsComponent } from '@spartacus/core';
import {Router} from "@angular/router"
import { CmsComponentData } from '@spartacus/storefront';
import { AppService } from '../app.services'
import { Subscription } from 'rxjs';
declare var $: any;

@Component({
  selector: 'app-banner-product',
  templateUrl: './banner-product.component.html',
  styleUrls: ['./banner-product.component.scss']
})
export class BannerProductComponent implements OnInit {

  ComponentData : {}
  title: string;
  subTitle: string;
  text: HTMLElement;
  buttonLink: string;
  buttonText: string;
  backgroundImage: any = "";
  subscription: Subscription;
  constructor(protected component: CmsComponentData<CmsComponent>, private router: Router, private appService: AppService) {
     }

  toHTML (str): HTMLElement {
    var parser = new DOMParser();
    var doc = parser.parseFromString(str, 'text/html');
    return doc.body;
  };

  ngOnInit(): void {
    // this.getText();

    this.subscription = this.component.data$.subscribe(data => {
      this.ComponentData = data;
      this.title = this.ComponentData["title"] != undefined ? this.ComponentData["title"] : ""; //NEW
      this.subTitle = this.ComponentData["subTitle"] != undefined ? this.ComponentData["subTitle"] : "";
      this.text = this.ComponentData["text"] != undefined ? this.ComponentData["text"] : "";
      this.buttonLink = this.ComponentData["link"]["url"] != undefined ? this.ComponentData["link"]["url"] : "";
      this.buttonText = this.ComponentData["link"]["linkName"] != undefined ? this.ComponentData["link"]["linkName"] : "VIEW PRODUCT";
      this.backgroundImage = this.ComponentData["media"] && this.ComponentData["media"]["url"] && this.appService.getApiDomainUrl() + this.ComponentData["media"]["url"]; 
    })
    
  }
  public getText(): any {
    return this.component.data$.subscribe(data => {
      return this.ComponentData = data;
    })
  }

  ngAfterViewInit(){
    // $(document).ready(function(){
    //   $("cx-page-layout.header").show();
    // });
  }

  navigateToProductDetail() {
    this.router.navigate([this.buttonLink]);
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
