import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ProductDetailsService } from '../APIcall/product-details.service';
import { UserServiceService } from '../APIcall/user-service.service';
import { AppService } from '../app.services';

@Component({
  selector: 'app-checkout-order-summary',
  templateUrl: './checkout-order-summary.component.html',
  styleUrls: ['./checkout-order-summary.component.scss']
})
export class CheckoutOrderSummaryComponent implements OnInit {

  cartDetailsList:any = [];
  user: any = {};
  constructor(private router: Router, private productDetailsService: ProductDetailsService, private userService: UserServiceService, private appService: AppService, private ref: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.userService.getUser().then(() => {
      this.user = this.userService.getUserData();
      this.getCartDetails(this.user.email);
    })
  }

  getCartDetails(userId) {
    this.productDetailsService.gertCurrentCartData(userId).then((cartDetails) => {
      console.log("cartDetails:in cart page::::::::::", cartDetails)
      this.cartDetailsList = [];
      if(cartDetails && cartDetails['entries'].length > 0) {
        this.productDetailsService.updateCart(cartDetails['entries'].length);
        // this.isEmptyCart = false;
        cartDetails['entries'].map((entry) => {
          let unitName = '';
          entry['product']['units'].map((unitVal) => {
            if(unitVal['code'] == entry['unit']) {
              unitName = unitVal['name'];
            }
          })

          this.cartDetailsList.push({"entryNumber" : entry['entryNumber'],
                                      "infineumQuantity" : entry['infineumQuantity'],
                                    "productName": entry['product']['name'],
                                    "categoryImage" : (entry['product']['productImage']) ? (this.appService.getApiDomainUrl()+entry['product']['productImage']) : '',
                                    "url" : entry['product']['url'],
                                    "unit" : unitName,
                                    "cartId" : cartDetails['code']
                                  });

        })
        localStorage.setItem('checkoutCart', JSON.stringify(this.cartDetailsList));
        this.ref.detectChanges();
        
      }
    })
  }

  navigateTocart() {
    this.router.navigate(['/cart']);
  }

}
