<!-- <h1>Forgot password</h1> -->
<div class="container __toast-container">
  <div class="row" [ngStyle]="{'display': __styleToaster}">
    <div class="col-lg-12">
      <div class="alert alert-success __toaster-border" role="alert">
        {{'customForgotPass.forgotLinkSuccessfully' | cxTranslate}}
        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
  </div>
</div>
</div>
<div class="container forgot">
    <div class="card">
      <div class="card-body">
        <div class="whiteline"></div>
        <h4 class="card-title mb-3 mt-1">{{'customForgotPass.forgotYourPass' | cxTranslate}}</h4>
        <div class="style1 mt-2 mb-3">
          {{'customForgotPass.dontWoryItHappens' | cxTranslate}}
        </div>
        <form [formGroup]="forgotForm" (ngSubmit)="onSubmit()">
          <div class="form-group">
            <label mt-2 class="__text">{{'customForgotPass.email' | cxTranslate}}</label>
            <input name="" class="form-control w-100" formControlName="email" placeholder="" type="email"
              [ngClass]="{ 'is-invalid': submitted && fval.email.errors }">
            <div *ngIf="submitted && fval.email.errors" class="invalid-feedback">
              <div *ngIf="fval.email.errors.required" class="__errorMessage">{{'customForgotPass.emailRequired' | cxTranslate}}</div>
              <div *ngIf="fval.email.errors.pattern" class="__errorMessage">{{'customForgotPass.formateValidation' | cxTranslate}}</div>
            </div>
          </div>
          <div class="button-list">
            <button type="submit" class="btn btn-primary mw-50 mr-2">{{'customForgotPass.sendLink' | cxTranslate}}</button>
            <button (click)="naviateToLogin()" type="button" class="btn shadow-none btn-ghost-inverse w-auto">{{'customForgotPass.cancel' | cxTranslate}}</button>
          </div>
        </form>
      </div>
    </div>
  </div>