export class ClaimSearchProductModel {
    claims: [
        {
            baseStocks: string,
            claimSpecs: [
                {
                    claimOrg: string,
                    claimSpecId: string,
                    claimType: string
                }
            ],
            gradeName: string,
            oilCode: string,
            ppdIdName: string,
            productName: string,
            treatId: string,
            treatRate: string,
            viscosityModifiers: string
        }
    ]
}