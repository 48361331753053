import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, NgModule, OnInit } from '@angular/core';
import { ProviderModel } from '../APIcall/provider.model';
import { UserServiceService } from '../APIcall/user-service.service';
import { ProductDetailsService } from '../APIcall/product-details.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslationService } from '@spartacus/core';
import { Observable } from 'rxjs';
declare var $: any;

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
  //providers: [UserServiceService]
})
export class ContactComponent implements OnInit {

  showFeedback: boolean = true;
  showProduct: boolean = false;
  showSupport: boolean = false;
  
  formSubmitted: boolean = false;

  name: string;
  email: string;
  subject1: any = "";
  message1: any = "";

  subject2: any = "";
  message2: any = "";
  message3: any = "";

  errMsg1: boolean = false;
  errMsg2: boolean = false;
  errMsg3: boolean = false;
  errProductMsg: boolean = false;
  errOtherOption: boolean = false;
  accountCloseFlag: boolean = true;
  option1: string = '';
  option2: string = '';
  option3: string = '';
  option4: string = '';
  selectedReason: any = this.option1;
  otherOption: any = "";
  payload:any = {};
  productName: any = "";
  user : ProviderModel = new ProviderModel();

  selector;
  option1$: Observable<string> = this.translation.translate('customContact.radio1')
  option2$: Observable<string> = this.translation.translate('customContact.radio2')
  option3$: Observable<string> = this.translation.translate('customContact.radio3')
  option4$: Observable<string> = this.translation.translate('customContact.radio4')
  constructor(private translation: TranslationService, private userService: UserServiceService, private http: HttpClient, private productDetailsService:ProductDetailsService, private router: Router, private ref: ChangeDetectorRef, private route: ActivatedRoute) {
   }

  ngOnInit(): void {
    this.productName = "";
    this.route.queryParamMap.subscribe((params) => {
      this.productName = params['params'] && params['params']['name'];
      if(this.productName) {
        //$("#prodTab").click();
        $(document).ready(function() {  $('#prodTab')[0].click(); });
        this.subject2 = this.productName;
      }
      
      this.ref.detectChanges();
    })
    if(this.message1) {
      this.errMsg1 = false;
    }
    this.productDetailsService.changeBreadcrumb({"breadcumTitle" : "contact", "categoryName": "", "productName": ""});
    this.getUser().then(() => {
      this.user = this.userService.getUserData();
      $("#nameInform").val(this.user.name);
      $("#nameProduct").val(this.user.name);
      $("#nameSupport").val(this.user.name);
      $("#emailInform").val(this.user.email);
      $("#emailProduct").val(this.user.email);
      $("#emailSupport").val(this.user.email);
    });
    this.option1$.subscribe(trans => {
      this.option1 = trans
    })
    this.option2$.subscribe(trans => {
      this.option2 = trans
    })
    this.option3$.subscribe(trans => {
      this.option3 = trans
    })
    this.option4$.subscribe(trans => {
      this.option4 = trans
    })
  }

  async getUser(){
      await this.userService.getUser();
  }
  textAreaEmpty(){
    if(this.message1.trim().length === 0){ 
        this.errMsg1 = false;
        //this.ref.detectChanges();
    }
    if(this.message2.trim().length === 0){ 
      this.errMsg2 = false;
      //this.ref.detectChanges();
    }
    if(this.message3.trim().length === 0){ 
        this.errMsg3 = false;
        //this.ref.detectChanges();
    }
  }
  inputAreaEmpty(){
    if(this.subject2.trim().length === 0){ 
      this.errProductMsg = false;
      //this.ref.detectChanges();
      }
  }
  ngAfterViewInit(){
    $(document).ready(function(){
      const user = window.localStorage.getItem('spartacus⚿⚿auth');
      const json = JSON.parse(user);
      if(json.userId == "current"){
        $(".searchbox").show();
        $(".SiteLogin").show();
        $(".MiniCart").show();
        $(".NavigationBar").show();
      }
    });
  }
  
  clearValidations() {
      $('form').removeClass('was-validated');
      this.errMsg1 = false;
      this.errMsg2 = false;
      this.errMsg3 = false;
      this.errProductMsg = false;
      this.errOtherOption = false;
  }

  sendFeedback(subject, message, ticketCategory) {
    // Tab 1
    if (!message) {
        this.errMsg1 = true;
    }
    // Tab 2
    if(!this.message2) {
      this.errMsg2 = true;
    }
    if(!this.subject2) {
      this.errProductMsg = true;
    }
    // Tab 3
    if(!this.message3) {
      this.errMsg3 = true;
    }
    // Tab 4
    if(ticketCategory=="INFORM_CLOSEACCOUNT"){
      this.accountCloseFlag = false;
      if(this.selectedReason == this.option4 && this.otherOption == '') {
        this.accountCloseFlag = true;
        this.errOtherOption = true;
      }
    }
    if(this.errMsg1 && this.errMsg2 && this.errMsg3 && this.errProductMsg && this.accountCloseFlag ){
      return;
    }
    
    // this.formSubmitted = true;
    
    if(ticketCategory == "INFORM_CLOSEACCOUNT") {
        var tempReason = (this.otherOption != '') ? "Other: " + this.otherOption : this.selectedReason;
        this.payload = {
            "subject" :  "INFORM_CLOSEACCOUNT",
            "message" : tempReason,
            "ticketCategory" : "INFORM_CLOSEACCOUNT"
        }
    } else {
        this.payload = {
            "subject" : subject ? subject : ticketCategory,
            "message" : message ? message : ticketCategory,
            "ticketCategory" : ticketCategory
        }
    }
    console.log("payload:::::::::::::",this.payload);
    
    this.productDetailsService.sendFeedback(this.user.email, this.payload).then((res) => {
      console.log("send feedback res:::::::::::::", res);
      this.formSubmitted = true;
      this.ref.detectChanges();
    }).catch((err) => {
      console.log("error in send feedback:::::::::::;;", err);
    })
    
  }

  navigateHome() {
    this.router.navigate(['/']);
  }
  navigateHelp() {
    this.router.navigate(['/faq']);
  }

  radioClicked(event) {
      // Clear error
      this.errOtherOption = false;
    console.log("radio val:::::::::::::::::", event.target.value);
    if(!event.target.value) {
      this.selectedReason = `I don't use the site`;
    } else if(event.target.value == `Other (please specify)`) {
      this.selectedReason = this.otherOption ? this.otherOption : `Other (please specify)`;
    } else {
      this.selectedReason = event.target.value;
    }
  }
  
  onChange(value) {
    this.clearValidations();
    $('.tab-content .tab-pane:not(' + value + ')').removeClass('active');
    $('.tab-content ' + value).tab('show');
  }

}
