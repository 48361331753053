<div class="jumbotron jumbotron-fluid homepage" style="background-image: url({{backgroundImage}})">
  <div class="container d-flex">
    <h1>{{ title }}<br><span>{{ subTitle }}</span></h1>
    <p class="lead">{{ text }}</p>
    <button class="btn btn-primary btn-ghost align-self-start" (click)="navigateToProductDetail()">{{ buttonText }}</button>
  </div>
</div>



<!--
<div class="container-fluid banner" style="background-image: url({{backgroundImage}})" > 
    <div class="infbanner" :style="cssProps">
      <div class="col-md-4 m-auto">
        <div class="banner--info text-center">
          <div class="separator"></div>
          <h1>{{ title }}</h1>
          <h2>{{ subTitle }}</h2>
          <p class="intro">{{ text }}</p>
          <a class = "curserPointer" (click)="navigateToProductDetail()">
              <div class="button">
                <div class="col-md-10" style="padding-left: 0px;">
                  {{ buttonText }}
                </div>
                <div class="col-md-2">
                  <i class="fa fa-chevron-right" aria-hidden="true"></i>
                </div>
              </div>
          </a>
        </div>
      </div>
    </div>
</div>
-->