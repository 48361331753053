<div class="row" > 

    <div class="footer row" > 
      <div class="container" >
        <div class="row logobox">
          <div class="col-md-6 logoboxtitle">
            Performance you can rely on.
          </div>
          <div class="col-md-6 pull-right">
            <a href="/">
              <img src="../../assets/logo_footer.svg" class="pull-right" />
            </a>
          </div>
        </div>

        <div class="container">
            <div class="footercln col-md-3">
                <div class="clntitle">TOOLS</div>
                <ul>
                  <li><a href="#">Claims Search</a></li>
                  <li><a href="#">Calculator #1</a></li>
                  <li><a href="#">Calculator #2</a></li>
                </ul>
            </div>
            <div class="footercln col-md-3">
                <div class="clntitle">SALES &amp; SUPPORT</div>
                <ul>
                  <li><a href="#">Contact</a></li>
                  <li><a href="#">FAQ</a></li>
                </ul></div>
            <div class="footercln col-md-3">
                <div class="clntitle">OUR SITES</div>
                <ul>
                  <li><a href="#">Infineum.com</a></li>
                  <li><a href="#">Infineum Insight</a></li>
                  <li><a href="#">Linkedin</a></li>
                </ul></div>
            <div class="footercln col-md-3">
              <div class="changelang col-md-10 text-center pull-right">
                ENGLISH
              </div>
            </div>
        </div>
      </div>
    </div>
    
    <div class="legal row" > 
      <div class="container">
      <br>
      ‘INFINEUM’, the interlocking Ripple Device, the corporate mark comprising INFINEUM and the interlocking Ripple Device and 润英联 are trademarks of Infineum International Limited. © 2021 Infineum International Limited. All rights reserved
      <br>
      <br>LEGAL NOTICE | PRIVACY POLICY | COOKIE POLICY
      </div>
    </div>
  </div>