import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { AppService } from '../app.services'
import { ProductDetailsService } from '../APIcall/product-details.service';
declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class CustomerSpecificationService {


  constructor(private http: HttpClient, private appService: AppService, private productDetailsService: ProductDetailsService, private _Activatedroute:ActivatedRoute,
    private _router:Router) { 
   
  }

  

  async getCustomerSpec(formulaNumber, materialName,userId) {
    // let formulaNumber = '';
    // let materialName = '';
    // this.productDetailsService.currentProduct.subscribe((data) => {
    //   console.log("data::::::::::::",data)
    //   debugger;
    //   formulaNumber = data?.formulaNumber;
    //   materialName = data?.name
    // })
    return await new Promise((resolve, reject) => {
      return this.http
      .get(this.appService.getApiUrl() + `/users/${userId}/products/document/customerSpec?formulaNumber=${formulaNumber}&materialName=${materialName}`)
      .subscribe((response) => {
        try{
         resolve(response);
        }
        catch(e){
          reject(e);
        }  
      },
      
      (error) => {
        console.log("Error 401 in customer specification Service::::",error);
        //this.MSDS = new ProductMsdsModel();
        reject(true);
      })
    });
  }

  
}
