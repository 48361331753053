import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UserServiceService } from '../APIcall/user-service.service';

@Component({
  selector: 'app-forgot',
  templateUrl: './forgot.component.html',
  styleUrls: ['./forgot.component.scss']
})
export class ForgotComponent implements OnInit {
  forgotForm: UntypedFormGroup;
  emailPattern = "^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$";
  submitted = false;
  __styleToaster: string = "none";
  constructor(private ref: ChangeDetectorRef, private formBuilder: UntypedFormBuilder, private userService: UserServiceService, private router: Router) { }

  ngOnInit(): void {

    this.forgotForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email, Validators.pattern(this.emailPattern)]],
    })
  }


  get fval() {
    return this.forgotForm.controls;
  }

  onSubmit() {
    console.log('Entering form values ', this.forgotForm.value.email)
    this.submitted = true;
    if (this.forgotForm.invalid) {
      return;
    }
    this.userService.getAuthTokenForForgotPassword().then((token) => {
      this.userService.sendForgotPasswordLink(token, this.forgotForm.value.email).then((res) => {
        console.log("forgot password link sent successfully:::::::;",res)
        this.__styleToaster = "block"
          setTimeout(() => {
            this.__styleToaster = "none"
            this.ref.detectChanges();
            this.naviateToLogin();
          }, 8000);
        this.ref.detectChanges();
        //this.naviateToLogin();
      }).catch((err) => {
        console.log("error in forgot password:::::",err)
      }) 
    })

    
    // this.Httpservice.forgotapi(this.forgotForm.value.email).subscribe(res => {
    //   console.log('Resp++++++++++++',res)
    // })


  }
  naviateToLogin() {
    this.router.navigate(['/login']);
  }
}
