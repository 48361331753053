<div class="container">
    <div class="row">
        <div class="col-md-2"></div>
        <div class="col-md-8">
            <h1>Logon Help</h1>
            <p>Lorem ipsum</p>
        </div>
        <div class="col-md-2"></div>
    </div>
</div>
