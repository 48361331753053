<div class="container-fluid services" > 
    <div class="container infservices">
      <div class="row">
          <div class="servicewrp col-12 col-md-6 col-lg-3" style="padding: 5px;" *ngFor="let button of gridBoxComponentList">
            <div class="service" (click)="navigateLink(button.href)" style="cursor:pointer">
              <div class="row" style="margin-bottom: 20px;">
                <div class="col-md-12 __image-grid-height">
                  <img class="" src="{{button.image}}" />
                </div>
              </div>
              <div class="row" style="margin-bottom: 15px;">
                <div class="col-md-12">
                  <p class="pCard">
                    <span style="font-weight: 400;font-family: 'formata-regular', 'arial', sans-serif;">{{ button.title }}</span><br>
                    <!-- {{ button.text }} -->
                  </p>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <a><img src="../../assets/arrow.png" style="max-height: 70%;"/></a>
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>