<div class="container-fluid bg-blue breadcrumbs">
    <div class="row">
        <div class="col-12 text-center">
            <!--<div *ngIf="searchType">searchType {{searchType}}</div>-->
            <!-- PRODUCT -->
            <ul class="breadcrumbs" *ngIf="searchType == 'PRODUCT'">
                <li><a class="cursor_pointer" (click)="naviateToHomePage()">{{'customBreadcrumb.home' | cxTranslate}}</a></li>
                <li *ngIf="categoryName"><a  class="cursor_pointer" (click)="naviateToCategory()">{{categoryName}}</a></li>
                <li>{{productName}}</li>
            </ul>
            <!-- CATEGORY -->
            <ul class="breadcrumbs" *ngIf="searchType == 'CATEGORY'">
                <li><a class="cursor_pointer" (click)="naviateToHomePage()">{{'customBreadcrumb.home' | cxTranslate}}</a></li>
                <li *ngIf="categoryName"><a  class="cursor_pointer" (click)="naviateToCategory()">{{categoryName}}</a></li>
            </ul>
            <!-- SEARCH -->
            <ul class="breadcrumbs" *ngIf="searchType == 'SEARCH'">
                <li><a class="cursor_pointer" (click)="naviateToHomePage()">{{'customBreadcrumb.home' | cxTranslate}}</a></li>
                <li>{{'customBreadcrumb.searchResults' | cxTranslate}}</li>
            </ul>
            <!-- WISHLIST -->
            <ul class="breadcrumbs" *ngIf="breadcumTitle == 'wishlist'">
                <li><a class="cursor_pointer" (click)="naviateToHomePage()">{{'customBreadcrumb.home' | cxTranslate}}</a></li>
                <li>{{'customBreadcrumb.savedItems' | cxTranslate}}</li>
            </ul>
            <!-- CART PAGE-->
            <ul class="breadcrumbs" *ngIf="breadcumTitle == 'cartPage' || breadcumTitle == 'cartPage no data'">
                <li><a class="cursor_pointer" (click)="naviateToHomePage()">{{'customBreadcrumb.home' | cxTranslate}}</a></li>
                <li>{{'customBreadcrumb.samples' | cxTranslate}}</li>
            </ul>
            <!-- CHECKOUT -->
            <ul class="breadcrumbs" *ngIf="breadcumTitle == 'checkout'">
                <li><a class="cursor_pointer" (click)="naviateToHomePage()">{{'customBreadcrumb.home' | cxTranslate}}</a></li>
                <li>{{'customBreadcrumb.checkout' | cxTranslate}}</li>
            </ul>
            <!-- ORDER HISTORY -->
            <ul class="breadcrumbs" *ngIf="breadcumTitle == 'order-history'">
                <li><a class="cursor_pointer" (click)="naviateToHomePage()">{{'customBreadcrumb.home' | cxTranslate}}</a></li>
                <li>{{'customBreadcrumb.orderHistory' | cxTranslate}}</li>
            </ul>
            <!-- ORDER DETAILS -->
            <ul class="breadcrumbs" *ngIf="breadcumTitle == 'order-details'">
                <li><a class="cursor_pointer" (click)="naviateToHomePage()">{{'customBreadcrumb.home' | cxTranslate}}</a></li>
                <li><a class="cursor_pointer" (click)="naviateToOrderHistory()">{{'customBreadcrumb.orderHistory' | cxTranslate}}</a></li>
                <li>{{'customBreadcrumb.orderDetails' | cxTranslate}}</li>
            </ul>
            <!-- CHANGE PASSWORD   -->
            <ul class="breadcrumbs" *ngIf="breadcumTitle == 'change-password'">
                <li><a class="cursor_pointer" (click)="naviateToHomePage()">{{'customBreadcrumb.home' | cxTranslate}}</a></li>
                <li>{{'customBreadcrumb.changePass' | cxTranslate}}</li>
            </ul>
            <!-- TERMS AND CONDITIONS   -->
            <ul class="breadcrumbs" *ngIf="breadcumTitle == 'terms-and-conditions'">
                <li><a class="cursor_pointer" (click)="naviateToHomePage()">{{'customBreadcrumb.home' | cxTranslate}}</a></li>
                <li>{{'customBreadcrumb.termsCondition' | cxTranslate}}</li>
            </ul>
            <!-- CONTACT   -->
            <ul class="breadcrumbs" *ngIf="breadcumTitle == 'contact'">
                <li><a class="cursor_pointer" (click)="naviateToHomePage()">{{'customBreadcrumb.home' | cxTranslate}}</a></li>
                <li>{{'customBreadcrumb.contact' | cxTranslate}}</li>
            </ul>
            <!-- HELP   -->
            <ul class="breadcrumbs" *ngIf="breadcumTitle == 'help'">
                <li><a class="cursor_pointer" (click)="naviateToHomePage()">{{'customBreadcrumb.home' | cxTranslate}}</a></li>
                <li>{{'customBreadcrumb.helpFaq' | cxTranslate}}</li>
            </ul>
            <!-- UPDATE PROFILE   -->
            <ul class="breadcrumbs" *ngIf="breadcumTitle == 'updateProfile'">
                <li><a class="cursor_pointer" (click)="naviateToHomePage()">{{'customBreadcrumb.home' | cxTranslate}}</a></li>
                <li>{{'customBreadcrumb.personalDetails' | cxTranslate}}</li>
            </ul>
            <!-- ERROR   -->
            <ul class="breadcrumbs" *ngIf="breadcumTitle == 'error'">
                <li><a class="cursor_pointer" (click)="naviateToHomePage()">{{'customBreadcrumb.home' | cxTranslate}}</a></li>
                <li>{{'customBreadcrumb.error' | cxTranslate}}</li>
            </ul>
            <!-- PAGE NAME   -->
            <ul class="breadcrumbs" *ngIf="breadcumTitle == 'page-name'">
                <li><a class="cursor_pointer" (click)="naviateToHomePage()">{{'customBreadcrumb.home' | cxTranslate}}</a></li>
                <li>{{'customBreadcrumb.pageName' | cxTranslate}}</li>
            </ul>
        </div>
    </div>
    <!-- IMAGE -->
    <div class="row" *ngIf="searchType == 'CATEGORY' && categoryImage">
        <div class="col-md-12 text-center">
            <img class="breadcrumb-icon" src="{{categoryImage}}" />
        </div>
    </div>
    <!-- TITLE -->
    <div class="row">
        <div class="col-12 text-center">
            <h1 *ngIf="searchType == 'PRODUCT'" class="has-decoration">{{productName}}</h1>
            <h1 *ngIf="searchType == 'CATEGORY'">{{categoryName}}</h1>
            <h1 *ngIf="searchType == 'SEARCH'" class="has-decoration">{{'customBreadcrumb.searchResults' | cxTranslate}}</h1>
            <h1 *ngIf="breadcumTitle == claimSearchText" class="has-decoration">{{'customBreadcrumb.claimsSearch' | cxTranslate}}</h1>
            <h1 *ngIf="breadcumTitle == 'wishlist'" class="has-decoration">{{'customBreadcrumb.savedItems' | cxTranslate}}</h1>
            <h1 *ngIf="breadcumTitle == 'cartPage'" class="has-decoration">{{'customBreadcrumb.yourSampleRequest' | cxTranslate}}</h1>
            <h1 *ngIf="breadcumTitle == 'checkout'" class="has-decoration">{{'customBreadcrumb.checkout' | cxTranslate}}</h1>
            <h1 *ngIf="breadcumTitle == 'order-history'" class="has-decoration">{{'customBreadcrumb.orderHistory' | cxTranslate}}</h1>
            <h1 *ngIf="breadcumTitle == 'order-details'" class="has-decoration">{{'customBreadcrumb.orderDetails' | cxTranslate}}</h1>
            <h1 *ngIf="breadcumTitle == 'change-password'" class="has-decoration">{{'customBreadcrumb.changePass' | cxTranslate}}</h1>
            <!-- <label *ngIf="breadcumTitle == 'privacy'">PRIVACY POLICY</label> -->
            <h1 *ngIf="breadcumTitle == 'terms-and-conditions'" class="has-decoration">{{'customBreadcrumb.termsCondition' | cxTranslate}}</h1>
            <h1 *ngIf="breadcumTitle == 'contact'" class="has-decoration">{{'customBreadcrumb.contact' | cxTranslate}}</h1>
            <h1 *ngIf="breadcumTitle == 'help'" class="has-decoration">{{'customBreadcrumb.helpFaq' | cxTranslate}}</h1>
            <h1 *ngIf="breadcumTitle == 'updateProfile'" class="has-decoration">{{'customBreadcrumb.personalDetails' | cxTranslate}}</h1>
            <h1 *ngIf="breadcumTitle == 'error'" class="has-decoration">{{'customBreadcrumb.pageNotFound' | cxTranslate}}</h1>
            <h1 *ngIf="breadcumTitle == 'page-name'" class="has-decoration">{{'customBreadcrumb.pageName' | cxTranslate}}</h1>
        </div>
    </div>
</div>

<!--
<div class="container-fluid __breadcrum-container">
    <div class="row">
<div *ngIf="searchType == 'PRODUCT'" class="col-md-12 __breadcrum-nav"> <span *ngIf="categoryName"><span class="__breadcrum-nav cursorPointer" (click)="naviateToHomePage()">{{'customBreadcrumb.home' | cxTranslate}}</span><span class="__breadcrum-nav"> | </span><span (click)="naviateToCategory()" class="__breadcrum-nav cursorPointer">{{categoryName}}</span><span> | </span> <span class="__breadcrum-nav"> {{productName}} </span> </span> </div>
<div *ngIf="searchType == 'CATEGORY'" class="col-md-12 __breadcrum-nav"> <span *ngIf="categoryName"><span class="__breadcrum-nav cursorPointer" (click)="naviateToHomePage()">{{'customBreadcrumb.home' | cxTranslate}}</span><span class="__breadcrum-nav"> | </span><span class="__breadcrum-nav">{{categoryName}}</span></span></div>
<div *ngIf="searchType == 'SEARCH'" class="col-md-12 __breadcrum-nav"> <span class="__breadcrum-nav cursorPointer" (click)="naviateToHomePage()">{{'customBreadcrumb.home' | cxTranslate}}</span><span class="__breadcrum-nav"> | </span><span class="__breadcrum-nav">{{'customBreadcrumb.searchResults' | cxTranslate}}</span> </div>
<div *ngIf="breadcumTitle == 'wishlist'" class="col-md-12 __breadcrum-nav"> <span class="__breadcrum-nav cursorPointer" (click)="naviateToHomePage()">{{'customBreadcrumb.home' | cxTranslate}}</span><span class="__breadcrum-nav"> | </span><span class="__breadcrum-nav">{{'customBreadcrumb.savedItems' | cxTranslate}}</span> </div>
<div *ngIf="breadcumTitle == 'cartPage' || breadcumTitle == 'cartPage no data'" class="col-md-12 __breadcrum-nav"> <span class="__breadcrum-nav cursorPointer" (click)="naviateToHomePage()">{{'customBreadcrumb.home' | cxTranslate}}</span><span class="__breadcrum-nav"> | </span><span class="__breadcrum-nav">{{'customBreadcrumb.samples' | cxTranslate}}</span> </div>
<div *ngIf="breadcumTitle == 'checkout'" class="col-md-12 __breadcrum-nav"> <span class="__breadcrum-nav cursorPointer" (click)="naviateToHomePage()">{{'customBreadcrumb.home' | cxTranslate}}</span><span class="__breadcrum-nav"> | </span><span (click)="naviateToCart()" class="__breadcrum-nav cursorPointer">{{'customBreadcrumb.samples' | cxTranslate}}</span><span> | </span> <span class="__breadcrum-nav"> {{'customBreadcrumb.checkout' | cxTranslate}} </span> </div>
<div *ngIf="breadcumTitle == 'order-history'" class="col-md-12 __breadcrum-nav"> <span class="__breadcrum-nav cursorPointer" (click)="naviateToHomePage()">{{'customBreadcrumb.home' | cxTranslate}}</span><span class="__breadcrum-nav"> | </span><span class="__breadcrum-nav">{{'customBreadcrumb.orderHistory' | cxTranslate}}</span> </div>
<div *ngIf="breadcumTitle == 'order-details'" class="col-md-12 __breadcrum-nav"> <span class="__breadcrum-nav cursorPointer" (click)="naviateToHomePage()">{{'customBreadcrumb.home' | cxTranslate}}</span><span class="__breadcrum-nav"> | </span><span (click)="naviateToOrderHistory()" class="__breadcrum-nav cursorPointer">{{'customBreadcrumb.orderHistory' | cxTranslate}}</span><span> | </span> <span class="__breadcrum-nav"> {{'customBreadcrumb.orderDetails' | cxTranslate}} </span> </div>
<div *ngIf="breadcumTitle == 'change-password'" class="col-md-12 __breadcrum-nav"> <span class="__breadcrum-nav cursorPointer" (click)="naviateToHomePage()">{{'customBreadcrumb.home' | cxTranslate}}</span><span class="__breadcrum-nav"> | </span><span class="__breadcrum-nav">{{'customBreadcrumb.changePass' | cxTranslate}}</span> </div>
<!-- <div *ngIf="breadcumTitle == 'privacy'" class="col-md-12 __breadcrum-nav"> <span class="__breadcrum-nav cursorPointer" (click)="naviateToHomePage()">Home</span><span class="__breadcrum-nav"> | </span><span class="__breadcrum-nav">PRIVACY POLICY</span> </div> -->
<!--<div *ngIf="breadcumTitle == 'terms-and-conditions'" class="col-md-12 __breadcrum-nav"> <span class="__breadcrum-nav cursorPointer" (click)="naviateToHomePage()">{{'customBreadcrumb.home' | cxTranslate}}</span><span class="__breadcrum-nav"> | </span><span class="__breadcrum-nav">{{'customBreadcrumb.termsCondition' | cxTranslate}}</span> </div>
<div *ngIf="breadcumTitle == 'contact'" class="col-md-12 __breadcrum-nav"> <span class="__breadcrum-nav cursorPointer" (click)="naviateToHomePage()">{{'customBreadcrumb.home' | cxTranslate}}</span><span class="__breadcrum-nav"> | </span><span class="__breadcrum-nav">{{'customBreadcrumb.contact' | cxTranslate}}</span> </div>
<div *ngIf="breadcumTitle == 'help'" class="col-md-12 __breadcrum-nav"> <span class="__breadcrum-nav cursorPointer" (click)="naviateToHomePage()">{{'customBreadcrumb.home' | cxTranslate}}</span><span class="__breadcrum-nav"> | </span><span class="__breadcrum-nav">{{'customBreadcrumb.helpFaq' | cxTranslate}}</span> </div>
<div *ngIf="breadcumTitle == 'updateProfile'" class="col-md-12 __breadcrum-nav"> <span class="__breadcrum-nav cursorPointer" (click)="naviateToHomePage()">{{'customBreadcrumb.home' | cxTranslate}}</span><span class="__breadcrum-nav"> | </span><span class="__breadcrum-nav">{{'customBreadcrumb.personalDetails' | cxTranslate}}</span> </div>
<div *ngIf="breadcumTitle == 'error'" class="col-md-12 __breadcrum-nav"> <span class="__breadcrum-nav cursorPointer" (click)="naviateToHomePage()">{{'customBreadcrumb.home' | cxTranslate}}</span><span class="__breadcrum-nav"> | </span><span class="__breadcrum-nav">{{'customBreadcrumb.error' | cxTranslate}}</span> </div>
<div *ngIf="breadcumTitle == 'page-name'" class="col-md-12 __breadcrum-nav"> <span class="__breadcrum-nav cursorPointer" (click)="naviateToHomePage()">{{'customBreadcrumb.home' | cxTranslate}}</span><span class="__breadcrum-nav"> | </span><span class="__breadcrum-nav">{{'customBreadcrumb.pageName' | cxTranslate}}</span> </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <img class="__CategoryImage" *ngIf="searchType == 'CATEGORY' && categoryImage" src="{{categoryImage}}" />
            <hr *ngIf="searchType == 'CATEGORY' && categoryImage" class="__breadcrum-hr __CatHRLine">
            <hr *ngIf="searchType == 'CATEGORY' && !categoryImage" class="__breadcrum-hr ">
            <hr *ngIf="(searchType != 'CATEGORY') && (searchType == 'SEARCH' || searchType == 'PRODUCT')" class="__breadcrum-hr">
            <hr *ngIf="(breadcumTitle ) " class="__breadcrum-hr">
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <label *ngIf="searchType == 'PRODUCT'" class="__breadcrum-title">{{productName}}</label>
            <label *ngIf="searchType == 'CATEGORY'" class="__breadcrum-title">{{categoryName}}</label>
            <label *ngIf="searchType == 'SEARCH'" class="__breadcrum-title">{{'customBreadcrumb.searchResults' | cxTranslate}}</label>
            <label *ngIf="breadcumTitle == 'CLAIMS SEARCH'" class="__breadcrum-title">{{'customBreadcrumb.claimsSearch' | cxTranslate}}</label>
            <label *ngIf="breadcumTitle == 'wishlist'" class="__breadcrum-title">{{'customBreadcrumb.savedItems' | cxTranslate}}</label>
            <label *ngIf="breadcumTitle == 'cartPage'" class="__breadcrum-title">{{'customBreadcrumb.yourSampleRequest' | cxTranslate}}</label>
            <label *ngIf="breadcumTitle == 'checkout'" class="__breadcrum-title">{{'customBreadcrumb.checkout' | cxTranslate}}</label>
            <label *ngIf="breadcumTitle == 'order-history'" class="__breadcrum-title">{{'customBreadcrumb.orderHistory' | cxTranslate}}</label>
            <label *ngIf="breadcumTitle == 'order-details'" class="__breadcrum-title">{{'customBreadcrumb.orderDetails' | cxTranslate}}</label>
            <label *ngIf="breadcumTitle == 'change-password'" class="__breadcrum-title">{{'customBreadcrumb.changePass' | cxTranslate}}</label>
            <!-- <label *ngIf="breadcumTitle == 'privacy'" class="__breadcrum-title">PRIVACY POLICY</label> -->
<!--            <label *ngIf="breadcumTitle == 'terms-and-conditions'" class="__breadcrum-title">{{'customBreadcrumb.termsCondition' | cxTranslate}}</label>
            <label *ngIf="breadcumTitle == 'contact'" class="__breadcrum-title">{{'customBreadcrumb.contact' | cxTranslate}}</label>
            <label *ngIf="breadcumTitle == 'help'" class="__breadcrum-title">{{'customBreadcrumb.helpFaq' | cxTranslate}}</label>
            <label *ngIf="breadcumTitle == 'updateProfile'" class="__breadcrum-title">{{'customBreadcrumb.personalDetails' | cxTranslate}}</label>
            <label *ngIf="breadcumTitle == 'error'" class="__breadcrum-title">{{'customBreadcrumb.pageNotFound' | cxTranslate}}</label>
            <label *ngIf="breadcumTitle == 'page-name'" class="__breadcrum-title">{{'customBreadcrumb.pageName' | cxTranslate}}</label>
        </div>
    </div>
</div>
-->
