<div class="container-fluid claims" > 
  <div class="container h-100">
      <div class="row">
          <div class="col-12 col-md-9">
              <h1 class=""><strong>{{ firstTitle }}</strong> {{ secondTitle }}</h1>
              <p>{{text}}</p>
          </div>
          <div class="col-12 col-md-3 d-flex align-items-center">
              <a (click) = "navigateToClaimList(buttonLink)" class="btn btn-primary btn-ghost">{{buttonText}}</a>
          </div>
      </div>
  </div>
</div>