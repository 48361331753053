<!-- <div class="container mw-100">
    <div class="row">
        <div class="col-12 banner">
            <div class="m-auto w-100">
                <h1 class="title">{{ title }}</h1>
            </div>
        </div>
    </div>
</div> -->

<div class="container">
    <div class="page page-error404">
        <div class="row">
            <div class="col-12">
                <div class="page-content">
                    <!-- CONTENT -->

                    <div class="col">
                        <div class="text-center main-content">
                            <div class="col-12">
                                <img src="/assets/icons/Attention.svg" class="icon-attention">
                            </div>
                            <div class="col-12 text-center">
                                <h2 class="h1">{{'custom404Error.somthingWent' | cxTranslate}} <span>{{'custom404Error.wrong' | cxTranslate}}</span></h2>
                                <p class="intro">{{'custom404Error.thepageLooking' | cxTranslate}}</p>
                                
                                <div class="row __row-gap">
                                    <div class="col-12 col-md-12 col-lg-2"></div>
                                    <div class="col-12 col-md-12 col-lg-4">
                                    <button (click) = "navigateToHome()" type="button" class="btn left-btn btn-primary mt-5 m-auto" >{{'custom404Error.backToHome' | cxTranslate}}</button>
                                </div>
                                <div class="col-12 col-md-12 col-lg-4">
                                    <button (click) = "navigateToClaim()" type="button" class="btn btn-primary mt-5 m-auto" >{{'custom404Error.claimSearch' | cxTranslate}}</button>                                   
                                </div>
                                <div class="col-12 col-md-12 col-lg-2"></div>
                                </div>                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div><!-- .page -->
</div>
