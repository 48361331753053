import { Component, OnInit } from '@angular/core';
import { timeStamp } from 'console';
import { Router,ActivatedRoute } from '@angular/router';
import { CustomerSpecificationService } from '../APIcall/customer-specification.service';
import { UserServiceService } from '../APIcall/user-service.service';


@Component({
  selector: 'app-customer-specification',
  templateUrl: './customer-specification.component.html',
  styleUrls: ['./customer-specification.component.scss']
})
export class CustomerSpecificationComponent implements OnInit {

  customerSpecData: any = {};
  productSpec: any = [];
  prodAlternative:any = [];
  prodAlternativeArr:any = [];
  res:any = {};

  formulaNumber: any = '';
  name: any = '';
  printDate: any;
  printYear: any;
  constructor(private customerSpecificationService : CustomerSpecificationService, private _Activatedroute:ActivatedRoute,
    private _router:Router, private userServiceService: UserServiceService) { }

  ngOnInit(): void {

    this.userServiceService.getUserDetails().then((user) => {
      this._Activatedroute.paramMap.subscribe(params => { 
        console.log(params);
         this.formulaNumber = params.get('formula'); 
         this.name = params.get('name');  
         
         this.customerSpecificationService.getCustomerSpec(this.formulaNumber, this.name, user["uid"]).then((res) => {
          this.customerSpecData = res;
          this.res = res;
          this.productSpec = this.customerSpecData?.CustomerSpec?.CustomerSpecification?.Specifications?.ProductTests;
          console.log("productSpec:::::::",this.productSpec)
          // debugger; 
          try{
            let arr = this.res.CustomerSpec.CustomerSpecification.ApprovedAlternatives.ProductApprovedAlternate;
            // this.res.CustomerSpec.CustomerSpecification.ApprovedAlternatives.ProductApprovedAlternate.map((prdAprvAlt, index) => {
              for(let ind = 0; ind < arr.length; ind ++){
                if(this.prodAlternative.indexOf(arr[ind].property) == -1) {
                  this.prodAlternative.push(arr[ind].property)
                  this.prodAlternativeArr.push({"property" : arr[ind].property, "testMethod" : arr[ind].testMethod})
                } else {
                  this.prodAlternativeArr[this.prodAlternativeArr.length-1]["testMethod"] = this.prodAlternativeArr[this.prodAlternativeArr.length-1]["testMethod"] + "," + arr[ind].testMethod;
                }
              }
              
            // }
              
            }catch(err) {
              console.log("err::::::::::",err)
            }
            // console.log("prodAlternativeArr:::::::",this.prodAlternativeArr)
          }).catch((err) => {
            console.log("Error in customer specification::::::",err);
          })
          this.printDate = new Date().toLocaleDateString('en-GB', {
            day : 'numeric',
            month : 'short',
            year : 'numeric'
        }).split(' ').join('-');
  
        this.printYear = new Date().getFullYear();
     });
    
    })
    
    
      
      
  
  }
  
  //Print Page
  // printThisPage() {
  //   window.print();
  // }

  printThisPage() {
    let popupWin = window.open('', '_blank', 'width=930,height=600');
    let printContents = document.getElementById("print-window-content").innerHTML;
    const delay = 10;
    popupWin.document
      .write(`<html>
         ${document.querySelector('head').innerHTML}
        <body onload="window.print();closePopWin();">
        <div id="print-confirmation" class="page">
          <div class=" bg-white">
            <div class="">
              <div id="print-window">
                ${printContents}
              </div>
            </div>
          </div>
        </div>
        <script>
          function closePopWin() {
            window.onfocus = function(){ setTimeout(function(){ window.close(); }, ${delay});}
          }
        </script>
        </body></html>`);
      popupWin.document.close();
  }
}
