import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnInit, QueryList, ViewChildren } from '@angular/core';
import {NgxPaginationModule} from 'ngx-pagination';
import { ProductListService } from '../../APIcall/product-list.service';
import { ProductDetailsService } from '../../APIcall/product-details.service';
import { UserServiceService } from '../../APIcall/user-service.service';
import { SearchboxService } from '@spartacus/core'
import { Router } from '@angular/router';
import { AppService } from '../../app.services';
declare var $: any;

@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductListComponent implements OnInit {
  pFilters: string[];
  productCount: Number;
  config: any;
  collection:any=[];
  totalResult: any = 0;
  itemsPerPage: any = 10;
  currentPage: any = 1;
  productListData : any=[];
  p: number = 1;
  sorts: any = []; 
  totalPages: any = 1;
  totalPagesCount: any = 0;
  // [ {
  //   "code" : "Select",
  //   "selected" : false
  //   },
  //   {
  //   "code" : "name-asc",
  //   "selected" : true
  //   }, {
  //       "code" : "name-desc",
  //       "selected" : false
  //   }, {
  //       "code" : "newest",
  //       "selected" : false
  //   } ];
  selectedSort: any = '';
  selectedFilterArr: any = [];
  selectedElement: any = {};
  clearBtnClickedFlag: boolean = true;
  plpNoResultAvailable: boolean = false;
  loading: boolean = true;
  freeTextSearch: any = '';
  searchterm = 'ffff';
  categoryName: any = '';
  domainUrl: any = '';
  user: any = {};
  start: any = 0;
  current: any = 0;
  categorySearchShowContact: boolean = false;
  @ViewChildren("checkboxes") checkboxes: QueryList<ElementRef>;
 
  constructor(private productListService: ProductListService, private productDetailsService: ProductDetailsService, private router: Router, private ref: ChangeDetectorRef, private appService: AppService, private UserService: UserServiceService) { }

  ngOnInit(): void {
    // console.log("in PLP:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::")
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.domainUrl = this.appService.getApiDomainUrl();
    $(".numberPage"+this.currentPage).removeClass("activeArrow");
    
    this.UserService.getUserDetails().then((res) => {
      this.user = res;
      this.productDetailsService.setBreadcrumbDetail(this.user.uid);
      this.getSearchProductList(this.itemsPerPage, this.currentPage-1, this.selectedSort, this.selectedFilterArr, this.user.uid);
      this.ref.detectChanges();
    }).catch((err) => {
      console.log("error in get user details:::::",err);
    })
    
    $(".numberPage"+this.currentPage).addClass("activeArrow");
    // this.pFilters = ["Bulk", "Drums", "Tote/IBC"];
    this.pFilters = [];
    
    this.productDetailsService.currentBreadcrumb.subscribe((data) => {
      this.categoryName = data && data['categoryName'];
      if(data['searchType']=='CATEGORY'){
        this.categorySearchShowContact = true;
      }
      this.ref.detectChanges(); 
    }) 
  }
  
  ngAfterViewChecked(): void {
      this.loading = false;
  }

  pageChanged(event){
    // debugger
    this.config.currentPage = event;
    this.getSearchProductList(this.itemsPerPage, this.currentPage, this.selectedSort, this.selectedFilterArr, this.user.uid);
  }
  
  getSearchProductList(itemsPerPage, currentPage, selectedSort, selectedFilterArr, userId) {
    this.productListService.getSearchProductList(itemsPerPage, currentPage, selectedSort, selectedFilterArr, userId).then((data) => {
      this.pFilters = [];
      data["facets"] && data["facets"][0] && data["facets"][0]["values"].map((filterValue) => {
        this.pFilters.push(filterValue["name"]);
      })
      this.freeTextSearch = data["freeTextSearch"];
      this.productListData = data["products"];
      this.totalResult = data["pagination"]["totalResults"];
      if(this.clearBtnClickedFlag) {
        this.sorts = data["sorts"];
      }
      if(this.productListData.length==0){
        this.plpNoResultAvailable = true;
        this.loading = false;
      }
      
      // data["facets"]["values"].map((facet) => {
      //   this.pFilters.push(facet["name"]);
      // })
      this.productCount=this.totalResult;
      this.config = {
        itemsPerPage: this.itemsPerPage,
        currentPage: this.currentPage,
        totalItems: this.totalResult
      };
      this.totalPages = [];
      this.totalPagesCount = data["pagination"]["totalPages"];
      let pageCount = data["pagination"]["totalPages"];
      for(let i=0; i<pageCount; i++) {
        this.totalPages.push(i+1);
      }
      setTimeout(() => {
        $(".numberPage"+this.currentPage).addClass("activeArrow");
        this.activeArrows(this.currentPage);
      },1000)
      this.start = data["pagination"]['currentPage'] != 0 ? ((data["pagination"]['currentPage']) * data["pagination"]['pageSize']) + 1 : 1;
      if(this.start + data["pagination"]['pageSize'] > data["pagination"]['totalResults']){
        this.current = data["pagination"]["totalResults"];
      }
      else{
        this.current = (this.start - 1) + data["pagination"]['pageSize'];
      }
      this.ref.detectChanges();
      
    }).catch((error) => {
      console.log("Error in getSearchProductList:::",error);
    })
  }

  naviateToProductDetailpage(url) {
    this.router.navigate([url]);
  }

  onChange(filterVal: string, isChecked: boolean) {
    this.currentPage = 1;
    if (isChecked) {
      this.selectedFilterArr.push(filterVal);
    } else {
      // debugger;
      const index = this.selectedFilterArr.indexOf(filterVal);
      this.selectedFilterArr.splice(index, 1);
    }
    this.clearBtnClickedFlag = false;
    this.getSearchProductList(this.itemsPerPage, this.currentPage-1, this.selectedSort, this.selectedFilterArr, this.user.uid);
  }

  selectChangeHandler (event: any) {
    this.selectedSort = event.target.value;
    this.currentPage = 1;
    this.clearBtnClickedFlag = false;
    this.getSearchProductList(this.itemsPerPage, this.currentPage-1, this.selectedSort, this.selectedFilterArr, this.user.uid);
  }

  uncheckAll() {
    this.currentPage = 1;
    this.clearBtnClickedFlag = true;
    this.checkboxes.forEach((element) => {
      element.nativeElement.checked = false;
    });
    this.selectedFilterArr = [];
    this.getSearchProductList(this.itemsPerPage, this.currentPage -1, this.selectedSort, this.selectedFilterArr, this.user.uid);
   
    this.ref.detectChanges();
  }

  changePage(pageNumber) {
    $(".numberPage"+this.currentPage).removeClass("activeArrow");
    if(this.currentPage != pageNumber){
      this.currentPage = pageNumber;
      this.getSearchProductList(this.itemsPerPage, this.currentPage - 1, this.selectedSort, this.selectedFilterArr, this.user.uid);
    }
    this.currentPage = pageNumber;
    this.activeArrows(this.currentPage);
    
    $(".numberPage"+this.currentPage).addClass("activeArrow");
    
  }
  changePageBack() {
    $(".numberPage"+this.currentPage).removeClass("activeArrow");
    this.currentPage = this.currentPage -1;
    this.activeArrows(this.currentPage);
    this.getSearchProductList(this.itemsPerPage, this.currentPage - 1, this.selectedSort, this.selectedFilterArr, this.user.uid);
    $(".numberPage"+this.currentPage).addClass("activeArrow");
  }
  changePageNext() {
    $(".numberPage"+this.currentPage).removeClass("activeArrow");
    this.currentPage = this.currentPage + 1;
    this.activeArrows(this.currentPage);
    this.getSearchProductList(this.itemsPerPage, this.currentPage - 1, this.selectedSort, this.selectedFilterArr, this.user.uid);
    $(".numberPage"+this.currentPage).addClass("activeArrow");
  }

  private activeArrows(currentPage : number){
    if(currentPage == 1 && (currentPage) == this.totalPagesCount){
      $(".backArrow").prop("disabled", true);
      $(".arrow").prop("disabled", true);
    }
    else if(currentPage == 1){
      $(".backArrow").prop("disabled", true);
      $(".arrow").prop("disabled", false);
    }
    else if((currentPage) == this.totalPagesCount){
      $(".backArrow").prop("disabled", false);
      $(".arrow").prop("disabled", true);
    } 
    else{
      $(".backArrow").prop("disabled", false);
      $(".arrow").prop("disabled", false);
    }
  }

  getPage(page:any) {
    this.currentPage = page;
    this.currentPage = page;
    window.scrollTo(0, 0);
    this.ref.detectChanges();
    this.getSearchProductList(this.itemsPerPage, this.currentPage - 1, this.selectedSort, this.selectedFilterArr, this.user.uid);
  }
  redirectToClaim() {
    this.router.navigate(['/claimsList']);
  }
  navigateContact() {
    this.router.navigate(['/contact']);
  }

}
