import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslationService } from '@spartacus/core';
import {Observable, Subscription} from 'rxjs';
import { ProductDetailsService } from 'src/app/APIcall/product-details.service';
import { ProductWishlistService } from 'src/app/APIcall/product-wishlist.service';
import { UserServiceService } from 'src/app/APIcall/user-service.service';
import { AppService } from '../../app.services';
declare var $: any;
@Component({
  selector: 'app-product-summary',
  templateUrl: './product-summary.component.html',
  styleUrls: ['./product-summary.component.scss']
})
export class ProductSummaryComponent implements OnInit {
  units: any = [];
  displaySummaryFlag: boolean = false;
  sampleValues:any = {};
  selectedUnit: any = 'MLT';
  selectedSample:any = '';
  filterSample: any = [30,100,250,500];
  productCode: any = [];
  user: any = [];
  wishListBtnTxt:any = '';
  cartProductName:any = '';
  cartProductImageUrl:any = '';
  cartChangeUrl: any = '';
  cartQuantityAdded: any = '';
  cartId: any = '';
  entryNumber: any = '';
  subscription1: Subscription;
  transLateSave$: Observable<string> = this.translation.translate('customProductSummary.save')
  transLateSaved$: Observable<string> = this.translation.translate('customProductSummary.savedText')
  saveTextTranslated : String = '';
  transLateSaved : String = '';
  constructor(private translation: TranslationService, private modalService: NgbModal, private productWishlistService: ProductWishlistService, private userService: UserServiceService, private productDetailsService: ProductDetailsService, private router: Router, private ref: ChangeDetectorRef, private appService: AppService) { }

  ngOnInit(): void {
    this.selectedSample=30;
    this.units = [
      {
      "codeVal":"MLT",
      "code":"ML",
      "selected": true
      },
      {
      "codeVal":"LTR",
      "code":"LT",
      "selected": false
      },
      {
      "codeVal":"GRM",
      "code":"G",
      "selected": false
      },
      {
      "codeVal":"KGM",
      "code":"KG",
      "selected": false
      },
      {
      "codeVal":"QT",
      "code":"QT",
      "selected": false
      },
      {
      "codeVal":"UG6",
      "code":"UG6",
      "selected": false
      },
      {
      "codeVal":"PT",
      "code":"PT",
      "selected": false
      },
      {
      "codeVal":"LBR",
      "code":"LB",
      "selected": false
      }
    ];
    this.sampleValues = [
      {
        "code":"ML",
        "codeVal":"MLT",
        "value": [30,100,250,500],
        "selected" : false
      },
      {
        "code":"LT",
        "codeVal":"LTR",
        "value": [1,2.5,5],
        "selected" : false
      },
      {
        "code":"G",
        "codeVal":"GRM",
        "value": [100],
        "selected" : false
      },
      {
        "code":"KG",
        "codeVal":"KGM",
        "value": [1,2.5,5],
        "selected" : false
      },
      {
        "code":"QT",
        "codeVal":"QT",
        "value": [1,2,3],
        "selected" : false
      },
      {
        "code":"UG6",
        "codeVal":"UG6",
        "value": [1,2,3,4,5],
        "selected" : false
      },
      {
        "code":"PT",
        "codeVal":"PT",
        "value": [1],
        "selected" : false
      },
      {
        "code":"LB",
        "codeVal":"LBR",
        "value": [1,2,3,4,5],
        "selected" : false
      }]
      $("#wishListBtn").prop("disabled", true);
    this.userService.getUserDetails().then((res) => {
      this.user = res;
      this.displaySummary();
      //this.getWishlish(this.user.email);
      
    });
    this.transLateSave$.subscribe(itemTranslated => {
      this.saveTextTranslated = itemTranslated
    })
    this.transLateSaved$.subscribe(trans => {
      this.transLateSaved = trans
    })
    this.wishListBtnTxt = this.saveTextTranslated
  }
  setWishlist(){
    this.productWishlistService.checkWishListProduct(this.user.uid, this.productCode).then((isAdded) => {
      if(isAdded) {
        this.WishListAddedBtn();
      } else {
        this.WishListNotAddedBtn();
      }
    }).catch((err) => {
      console.log("error in check wishlist::::",err)
    })
  }
  displaySummary() {
    this.subscription1 = this.productDetailsService.currentProduct.subscribe((data) => {  
      if(data?.packageType == 'Bulk' && this.user['canPlaceSampleOrder']){
        this.displaySummaryFlag = true;
        this.ref.detectChanges();
      } else {
        this.displaySummaryFlag = false;
        this.ref.detectChanges();
      }
      if(data?.code){
        this.productCode = data.code
        this.setWishlist();
      }
    })
  }
//   async getUser(){
//     await this.userService.getUser();
// }
  selectChangeUnit (event: any) {
    this.filterSample = [];
    this.selectedUnit = event.target.value;
    console.log("this.selectedUnit :::::::::",this.selectedUnit);
    this.sampleValues.map((element) => {
      if(element.codeVal == this.selectedUnit){
        element.value.map((val) => {
          this.filterSample.push(val);
        })
        this.selectedSample = this.filterSample[0];
      };
    });
    console.log("filterSample:::::::::",this.filterSample);
  }

  selectChangeSample (event: any) {
    this.selectedSample = event.target.value;
    console.log("this.selectedSample :::::::::::",this.selectedSample);
  }

  addToCart(requestModelAddToCart) {
    this.productDetailsService.gertCurrentCart(this.user.uid).then((cart) => {
      console.log("cart::::::::::::::",cart);
      this.cartId = cart['code'];
      this.entryNumber = '';
      this.productDetailsService.addToCart(this.user.uid, cart['code'], this.productCode, this.selectedSample, this.selectedUnit).then((res) => {
        console.log("add to cart res::::::::::",res);
        this.entryNumber = res && res['entry'] && res['entry']['entryNumber'];
        this.cartProductName = res && res['entry'] && res['entry']['product'] && res['entry']['product']['name'];
        this.cartProductImageUrl = res && res['entry'] && res['entry']['product'] && res['entry']['product']['productImage'];
        if(this.cartProductImageUrl) {
          this.cartProductImageUrl = this.appService.getApiDomainUrl() + this.cartProductImageUrl;
        }
        let quantity = res && res['entry'] && res['entry']['infineumQuantity'];
        res && res['entry'] && res['entry']['product'] && res['entry']['product']['units'].map((unit) => {
          if(unit['code'] == res['entry']['unit']) {
            this.cartQuantityAdded = quantity + ' ' + unit['name']
          }
        })
        this.cartChangeUrl = res['entry']['product']['url'];
        this.updateMiniCart();
        this.modalService.open(requestModelAddToCart, { scrollable: false, size:"lg" });
      }).catch((err) => {
        console.log("err in add to care:::::",err);
      })
    }).catch((err) => {
      console.log("error in cart::::::::::::::",err);
    })
    // this.modalService.open(requestModelAddToCart, { scrollable: false, size:"lg" });
  }

  updateMiniCart() {
    this.productDetailsService.gertCurrentCartData(this.user.uid).then((cartDetails) => {
      this.productDetailsService.updateCart(cartDetails['entries'].length);
    })
  }


  deleteCart(cartId, entryNumber) {
    this.productDetailsService.deleteCart(this.user.uid, cartId, entryNumber).then((delCartRes) => {
      this.updateMiniCart();
      this.modalService.dismissAll("close modal");
    }).catch((err) => {
      this.modalService.dismissAll("close modal");
      console.log("error in deleting cart::::::::::",err);
    })
  }
  changeAddToCart() {
    this.router.navigate([this.cartChangeUrl]);
    this.modalService.dismissAll("close modal");
  }
  redirectToCartPage() {
    this.router.navigate(['/cart']);
    this.modalService.dismissAll("close modal");
  }
  redirectHome() {
    this.router.navigate(['/']);
    this.modalService.dismissAll("close modal");
  }
  redirectCheckout() {
    this.router.navigate(['/checkout/shipping-address']);
    this.modalService.dismissAll("close modal");
  }

  // getUnits() {
  //   return this.productDetailsService.getProduct().subscribe((data: any) => {
  //     this.units = data;
  //   })
  // }

  //add to wish list functionality
  addToWishList(){
    $("#wishListBtn").prop("disabled", true);
    this.productWishlistService.checkWishListProduct(this.user.uid, this.productCode).then((isAdded) => {
      if(isAdded) {
        this.productWishlistService.deleteProduct(this.productCode, this.user.uid).then((delResponse) => {
          this.WishListNotAddedBtn();
          console.log("Product has been removed from wishlist.")
        }).catch((delErr) => {
          console.log("Error in deleting wishlist.",delErr)
        })
      } else {
        this.addProductCall(this.user.uid, this.productCode).then(() => {
          this.WishListAddedBtn();
         }).catch((e) => {
           console.log("Error while adding wishlist product, ",e);
         });
      }
    })
   
  }
  async addProductCall(user: string, productCode: string) {
    await this.productWishlistService.addProduct(productCode, user);
  }

  // getWishList(){
  //   this.productWishlistService.getProduct(this.user.email).then((data: any) => {
  //     data.map((wishListData) => {
  //       if(wishListData['product']['code'] == this.productCode) {
  //         this.WishListAddedBtn();
  //       }
  //     })
      
  //   }).catch((e) => {
  //     console.log("error in get wishlist:::::::::",e);
      
  //   });
  // }

  WishListAddedBtn() {
    $("#wishListBtn").prop("disabled", false).addClass('added');
    this.wishListBtnTxt = this.transLateSaved;
    this.ref.detectChanges();
    $("#st1").removeClass("far");
    $("#st1").addClass("fa");
    $("#st1").css("color", "#00a1de");
  }
  WishListNotAddedBtn() {
    $("#wishListBtn").prop("disabled", false).removeClass('added');
    this.wishListBtnTxt = this.saveTextTranslated;
    this.ref.detectChanges();
    $("#st1").removeClass("fa");
    $("#st1").addClass("far");
    $("#st1").css("color", "");
  }
//    getWishlish(user: string) {
//     this.addToWishListService.getProduct(user).then((data: any) =>{
//       data.forEach((item)=>{
// if(item.product.code==this.productCode){
//   $(".__pdp-save_button").prop("disbaled",true);
// }else{
//   $(".__pdp-save_button").prop("disbaled",false);
// }
//       });
//       console.log(data)
//           });
//   }
  

  navigateToContact() {
    this.router.navigate(['/contact']);
  }
  ngOnDestroy() {
    this.subscription1.unsubscribe();
   }
}
