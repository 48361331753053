<div class="container">
    <div class="page page-contact">
        <!-- NOT SUBMITTED -->
        <!--<div *ngIf="formSubmitted == false">-->
        <div [hidden]="formSubmitted">
            <div class="text-center">
                <h2 class="page--title">{{'customContact.howCanWE' | cxTranslate}}<span>{{'customContact.help' | cxTranslate}}</span></h2>
                <p class="page--intro">{{'customContact.foraQuickAnswer' | cxTranslate}}<a style="cursor:pointer; color: #00A1DE;" (click)="navigateHelp()">{{'customContact.frequentlyAsked' | cxTranslate}}</a></p>
            </div> 
            <!-- FORMS -->
            <div class="card">
                <div class="card-body">
                    <h3 class="card-title text-center">{{'customContact.whatWouldYouLike' | cxTranslate}}</h3>
                    <!-- Tablet and desktop -->
                    <ul class="nav nav-pills d-none d-md-flex">
                        <li><a data-toggle="pill" href="#tab1" (click)="clearValidations()">{{'customContact.informWebsite' | cxTranslate}}</a></li>
                        <li><a id="prodTab" data-toggle="pill" href="#tab2" (click)="clearValidations()">{{'customContact.products' | cxTranslate}}</a></li>
                        <li><a data-toggle="pill" href="#tab3" (click)="clearValidations()">{{'customContact.helpSupport' | cxTranslate}}</a></li>
                        <li><a data-toggle="pill" href="#tab4" (click)="clearValidations()">{{'customContact.closeAccount' | cxTranslate}}</a></li>
                    </ul>
                    <!-- Mobile -->
                    <select class="d-block d-md-none" (change)="onChange($event.target.value)">
                        <option value="">- {{'customContact.pleaseSelect' | cxTranslate}} -</option>
                        <option value="#tab1">{{'customContact.informWebsite' | cxTranslate}}</option>
                        <option value="#tab2">{{'customContact.products' | cxTranslate}}</option>
                        <option value="#tab3">{{'customContact.helpSupport' | cxTranslate}}</option>
                        <option value="#tab4">{{'customContact.closeAccount' | cxTranslate}}</option>
                    </select>
                      <div class="tab-content">
                        <!-- TAB 1 -->
                        <div id="tab1" class="tab-pane fade in ">
                          <form class="needs-validation" novalidate>
                              <div class="form-group">
                                <label for="sitepage">{{'customContact.sitePage' | cxTranslate}} <span class="ml-0">{{'customContact.optional' | cxTranslate}}</span></label>
                                <input type="text" [(ngModel)] = "subject1" name="subject1" class="form-control w-100" id="subject1">
                              </div>
                              <div class="form-group">
                                <label for="exampleFormControlTextarea1">{{'customContact.message' | cxTranslate}}</label>
                                <textarea (ngModelChange)="textAreaEmpty()" [(ngModel)] = "message1" name="message1" class="form-control w-100" id="message1" rows="6" required [class.invalid]="errMsg1"></textarea>
                                <div *ngIf="errMsg1" class="invalid-feedback d-block">{{'customContact.thisFieldIsRequired' | cxTranslate}}</div>
                              </div>
                              <button type="submit" class="btn btn-primary mt-5" (click)="sendFeedback(subject1, message1, 'INFORM_ABOUTWEBSITE')">{{'customContact.send' | cxTranslate}}</button>
                            </form>
                        </div>
                        <!-- TAB 2 -->
                        <div id="tab2" class="tab-pane fade">
                          <form class="needs-validation" novalidate>
                              <div class="form-row">
                                  <div class="form-group col">
                                    <label for="exampleInputEmail1">{{'customContact.productName' | cxTranslate}}</label>
                                    <input (ngModelChange)="inputAreaEmpty()" type="text" [(ngModel)] = "subject2" name="subject2" class="form-control w-100" id="sitepage" [class.invalid]="errMsg1">
                                    <div *ngIf="errProductMsg" class="invalid-feedback d-block">{{'customContact.thisFieldIsRequired' | cxTranslate}}</div>
                                  </div>
                              </div>
                              <div class="form-row">
                                  <div class="form-group col">
                                    <label for="exampleFormControlTextarea1">{{'customContact.message' | cxTranslate}}</label>
                                    <textarea (ngModelChange)="textAreaEmpty()" [(ngModel)] = "message2" name="message2" class="form-control w-100" id="exampleFormControlTextarea1" rows="6"></textarea>
                                    <div *ngIf="errMsg2" class="invalid-feedback d-block">{{'customContact.thisFieldIsRequired' | cxTranslate}}</div>
                                  </div>
                              </div>
                              <button type="submit" class="btn btn-primary mt-5" (click)="sendFeedback(subject2, message2, 'INFORM_PRODUCTSENQUIRY')">{{'customContact.send' | cxTranslate}}</button>
                            </form>
                        </div>
                        <!-- TAB 3 -->
                        <div id="tab3" class="tab-pane fade">
                          <form class="needs-validation" novalidate>
                              <div class="form-group">
                                <label for="exampleFormControlTextarea1">{{'customContact.message' | cxTranslate}}</label>
                                <textarea (ngModelChange)="textAreaEmpty()" [(ngModel)] = "message3" name="message3" class="form-control w-100" id="exampleFormControlTextarea1" rows="6"></textarea>
                                <div *ngIf="errMsg3" class="invalid-feedback d-block">{{'customContact.thisFieldIsRequired' | cxTranslate}}</div>
                              </div>
                              <button type="submit" class="btn btn-primary mt-5" (click)="sendFeedback('', message3, 'INFORM_HELPSUPPORT')">{{'customContact.send' | cxTranslate}}</button>
                            </form>
                        </div>
                        <!-- TAB 4 -->
                        <div id="tab4" class="tab-pane fade">
                          <h4 class="m-0">{{'customContact.wereSorry' | cxTranslate}}</h4>
                          <p class="card-text">{{'customContact.whatIsTheRegion' | cxTranslate}}</p>
                          <form action="#">
                              <div class="form-check mb-0">
                                  <input (change)="radioClicked($event)" class="form-check-input" type="radio" name="closeAccountRadios" id="closeAccountRadios1" [value]="option1" checked>
                                  <label class="form-check-label" for="closeAccountRadios1">
                                    {{option1}}
                                  </label>
                                </div>
                                <div class="form-check mb-0">
                                  <input (change)="radioClicked($event)" class="form-check-input" type="radio" name="closeAccountRadios" id="closeAccountRadios2" [value]="option2">
                                  <label class="form-check-label" for="closeAccountRadios2">
                                    {{option2}}
                                  </label>
                                </div>
                                <div class="form-check mb-0">
                                  <input (change)="radioClicked($event)" class="form-check-input" type="radio" name="closeAccountRadios" id="closeAccountRadios3" [value]="option3">
                                  <label class="form-check-label" for="closeAccountRadios3">
                                    {{option3}}
                                  </label>
                                </div>
                                <div class="form-check mb-0">
                                  <input (change)="radioClicked($event)" class="form-check-input" type="radio" name="closeAccountRadios" id="closeAccountRadios4" [value]="option4">
                                  <label class="form-check-label" for="closeAccountRadios4">
                                    {{option4}}
                                  </label>
                                  <div class="row">
                                      <div class="col-12 col-md-8">
                                          <input type="text" [(ngModel)] = "otherOption" name="otherOption" class="form-control mt-3 w-100 " id="otherOption" placeholder="" required>
                                          <div *ngIf="errOtherOption" class="invalid-feedback d-block">{{'customContact.thisFieldIsRequired' | cxTranslate}}</div>
                                      </div>
                                  </div>
                                </div>
                                <button type="submit" class="btn btn-primary mt-5" (click)="sendFeedback('', '', 'INFORM_CLOSEACCOUNT')" [class.btn-spinner]="formSubmitted">{{'customContact.closeAccount' | cxTranslate}}</button>
                            </form>
                        </div>
                      </div>
                </div><!-- .card-body -->
            </div><!-- .card -->
        </div><!-- .ngIf -->
        <!-- FORM SUBMITTED SUCCESS MESSAGE -->
        <!--<div *ngIf="formSubmitted == true" class="row">-->
        <div class="row" [hidden]="!formSubmitted">
            <div class="col">
                <div class="text-center main-content">
                    <div class="col-12">
                        <div class="page--icon">
                            <img src="/assets/icons/Check.svg">
                        </div>
                    </div>
                    <div class="col-12 text-center">
                        <h2>{{'customContact.messageCaps' | cxTranslate}}<span>{{'customContact.sent2' | cxTranslate}}</span></h2>
                        <p class="intro">{{'customContact.thankYouForTacking' | cxTranslate}}</p>
                        <button type="button" class="btn btn-primary mt-5 m-auto" (click)="navigateHome()">{{'customContact.finished' | cxTranslate}}</button>
                    </div>
                </div>
            </div>
        </div><!-- .ngIf -->
    </div>
</div>