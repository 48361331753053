import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslationService } from '@spartacus/core';
import { Observable } from 'rxjs';

import { ClaimsearchService } from '../APIcall/claimsearch.service';
import { ProductDetailsService } from '../APIcall/product-details.service';
import { UserServiceService } from '../APIcall/user-service.service';
import { AppService } from '../app.services';
declare var $: any;

@Component({
  selector: 'app-custom-claim-search',
  templateUrl: './custom-claim-search.component.html',
  styleUrls: ['./custom-claim-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomClaimSearchComponent implements OnInit {
  closeResult: string;
  claimModal:boolean = false;
  // claimsFilterSequence : any = ['Product Platform', 'Claims', 'Viscosity Grades', 'Base Stocks', 'Viscosity Modifier', 'Booster', 'Oil Code', 'Claims Org', 'Category', 'Pour Point Depressant', 'Fuel Type', 'TBN', 'Engine', 'Ash', 'Solvent'];
  claimsFilterSequence : any = [];
  claimsData: any = {};
  showFilter: any = [];
  displaySingleClaimData: any = {};
  claimDetails: any = {};
  claimFilterValues: any = [];
  filteredList: any = [];
  filterParam: any = '';
  displayFiltertype: any = '';
  currentPage: any = 1;
  totalPagesCount: any = 0;
  totalPages: any = [];
  start: any = 0;
  current: any = 0;
  query: any = '';
  productCode: any = '';
  isPDP: boolean = false;
  filterObj: any = {};
  claimFilterPDP: any = '';
  claimDefaultFilter: any = '';
  noClaimData: boolean = false;
  totalResults: any = 0;
  pageSize: number = 12;
  userId: any = "";
  userName: any = ""; 
  sorts: any = []; 
  displayCategoryFacetName: any = [];
  selectedSort: any = "";
  defaultValue: any = "lowestTreatRate";
  claimSearchText: string = '';
  boosterText: string = '';
  productPlatformText: string = '';
  viscosityModifierText: string = '';
  engineText: string = '';
  solventText: string = '';
  baseNumberText: string = '';
  claimspecText: string = '';
  claimOrgText: string = '';
  baseStocksText: string = '';
  ppdIdNameText: string = '';
  fuelTypeText: string = '';
  gradeNameText: string = '';
  ashText: string = '';
  oilCodeText: string = '';
  categoryText: string = '';
  printBaseStock : string = '';
  printTreatRate : string = '';
  printBaseVm : string = '';
  printPPDT : string = '';
  printGrade : string = '';
  printProduct : string = '';
  claimSearchText$: Observable<string> = this.translation.translate('claims.claimSearchTextBread')
  boosterText$: Observable<string> = this.translation.translate('claims.booster')
  productPlatformText$: Observable<string> = this.translation.translate('claims.productPlatform')
  viscosityModifierText$: Observable<string> = this.translation.translate('claims.viscocityModifier')
  engineText$: Observable<string> = this.translation.translate('claims.enginText')
  solventText$: Observable<string> = this.translation.translate('claims.solvent')
  baseNumberText$: Observable<string> = this.translation.translate('claims.tbn')
  claimspecText$: Observable<string> = this.translation.translate('claims.__claims')
  claimOrgText$: Observable<string> = this.translation.translate('claims.claimsOrgText')
  baseStocksText$: Observable<string> = this.translation.translate('claims.baseStock2')
  ppdIdNameText$: Observable<string> = this.translation.translate('claims.pourPointDepressant')
  fuelTypeText$: Observable<string> = this.translation.translate('claims.fuelType')
  gradeNameText$: Observable<string> = this.translation.translate('claims.viscocityGrades')
  ashText$: Observable<string> = this.translation.translate('claims.ash')
  oilCodeText$: Observable<string> = this.translation.translate('claims.oilCode')
  categoryText$: Observable<string> = this.translation.translate('claims.category')
  printBaseStockText$: Observable<string> = this.translation.translate('claims.baseStock')
  printBaseVmText$: Observable<string> = this.translation.translate('claims.vm')
  printTreatRateText$: Observable<string> = this.translation.translate('claims.treatRate')
  printPPDText$: Observable<string> = this.translation.translate('claims.__ppd')
  printGradeText$: Observable<string> = this.translation.translate('claims.grade')
  printProductText$: Observable<string> = this.translation.translate('claims.product')
  constructor(private translation: TranslationService, private modalService: NgbModal, private claimsearchService: ClaimsearchService, private ref: ChangeDetectorRef, private productDetailsService: ProductDetailsService, private appService: AppService, private router: Router, private userService: UserServiceService) { }

  ngOnInit(): void {

    this.claimSearchText$.subscribe(trans => {
      this.claimSearchText = trans
    })
    this.boosterText$.subscribe(trans => {
      this.boosterText = trans
    })
    this.productPlatformText$.subscribe(trans => {
      this.productPlatformText = trans
    })
    this.viscosityModifierText$.subscribe(trans => {
      this.viscosityModifierText = trans
    })
    this.engineText$.subscribe(trans => {
      this.engineText = trans
    })
    this.solventText$.subscribe(trans => {
      this.solventText = trans
    })
    this.baseNumberText$.subscribe(trans => {
      this.baseNumberText = trans
    })
    this.claimspecText$.subscribe(trans => {
      this.claimspecText = trans
    })
    this.claimOrgText$.subscribe(trans => {
      this.claimOrgText = trans
    })
    this.baseStocksText$.subscribe(trans => {
      this.baseStocksText = trans
    })
    this.ppdIdNameText$.subscribe(trans => {
      this.ppdIdNameText = trans
    })
    this.fuelTypeText$.subscribe(trans => {
      this.fuelTypeText = trans
    })
    this.gradeNameText$.subscribe(trans => {
      this.gradeNameText = trans
    })
    this.ashText$.subscribe(trans => {
      this.ashText = trans
    })
    this.oilCodeText$.subscribe(trans => {
      this.oilCodeText = trans
    })
    this.categoryText$.subscribe(trans => {
      this.categoryText = trans;
      this.claimsFilterSequence = [this.productPlatformText, this.claimspecText, this.gradeNameText, this.baseStocksText, this.viscosityModifierText, this.boosterText, this.oilCodeText, this.claimOrgText, this.categoryText, this.ppdIdNameText, this.fuelTypeText, this.baseNumberText, this.engineText, this.ashText, this.solventText];
    })
    this.printBaseStockText$.subscribe(trans => {
      this.printBaseStock = trans
    })
    this.printBaseVmText$.subscribe(trans => {
      this.printBaseVm = trans
    })
    this.printGradeText$.subscribe(trans => {
      this.printGrade = trans
    })
    this.printPPDText$.subscribe(trans => {
      this.printPPDT = trans
    })
    this.printTreatRateText$.subscribe(trans => {
      this.printTreatRate = trans
    })
    this.printProductText$.subscribe(trans => {
      this.printProduct = trans
    })

    this.userService.getUserDetails().then((user) => {
      this.userId = user["uid"];
      this.userName = user["name"];

      this.currentPage = 1;
    $(".numberPage"+this.currentPage).removeClass("activeArrow");
    this.productCode = "";
    let i = 0;
    for(let element of window.location.pathname.split("/")){
      if(element == 'p'){
        this.productCode = window.location.pathname.split("/")[i+1];
        break;
      }else if(element == 'product'){
        this.productCode = window.location.pathname.split("/")[i+1];
        break;
      }
      i++;
    }
    if(this.productCode != "") {
      this.isPDP = true;
      this.claimFilterPDP = ":relevance:productCode:" + this.productCode;
      this.query = this.claimFilterPDP;
      this.loadClaims("oninit",this.claimFilterPDP , "add", this.currentPage - 1, this.pageSize, this.selectedSort);
    } else {
      this.productDetailsService.changeBreadcrumb({"breadcumTitle" :  this.claimSearchText, "categoryName": "", "productName": ""});
      this.isPDP = false;
      this.claimDefaultFilter = ":relevance:packageType:Bulk";
      this.query = this.claimDefaultFilter;
      this.loadClaims("oninit",this.claimDefaultFilter , "add", this.currentPage - 1, this.pageSize, this.selectedSort);
    }
    // this.loadClaims("oninit","" , "add", this.currentPage - 1);
    $(".numberPage"+this.currentPage).addClass("activeArrow");
    })
    
    
  }
  openClaimSearchModal(longContent, claims) {
    this.displaySingleClaimData = claims;
    console.log("this.displaySingleClaimData:::::::::::::::",this.displaySingleClaimData)

    this.getClaimDetails(claims.productCode, claims.treatId, this.userId).then(() => {
      this.claimDetails = this.claimsearchService.getClaimDetailsData(this.userId);
      this.claimDetails.claimSpecs.sort((a,b) => (a.claimSpecId > b.claimSpecId) ? 1 : ((b.claimSpecId > a.claimSpecId) ? -1 : 0));
      // this.printValues();
      // this.printClaimSpecs();
    });


    this.modalService.open(longContent, { scrollable: true, size:"lg" });
  }

  openClaimFilterModal(longContentFilter, filterObj) {
    this.filterObj = filterObj;
    this.claimFilterValues = filterObj.values;
    this.filteredList = this.claimFilterValues;
    this.displayFiltertype = filterObj.displayName;

    // this.getClaimDetails(claims.productCode, claims.treatId).then(() => {
    //   this.claimDetails = this.claimsearchService.getClaimDetailsData();
    //   // this.printValues();
    //   // this.printClaimSpecs();
    // });


    this.modalService.open(longContentFilter, { scrollable: true, size:"lg" });
  }

  filterClaim(claimFilterObj) {
    this.query = claimFilterObj.query.query.value;
    console.log("claimFilterObj:::::::::::::::",claimFilterObj)
    console.log("this.query::::::::::::::::::111:::::",this.query)
    // let filterType = query.split("::")[1].split(":")[0];
    // let filterStr = query.split("::")[1].split(":")[1];
    // if(this.filterParam) {
    //   this.filterParam = this.filterParam + ":" + filterType + ":" + filterStr;
    // } else {
    //   this.filterParam = "::" + filterType + ":" + filterStr;
    // }
    this.currentPage = 0;
    this.loadClaims("oninit", this.query, "add", this.currentPage, this.pageSize, this.selectedSort);
    this.modalService.dismissAll('close modal');
  }

  clearAllFilter() {
    this.currentPage = 0;
    if(this.productCode != '') {
      this.query = this.claimFilterPDP;
      this.loadClaims("oninit", this.claimFilterPDP, "add", this.currentPage, this.pageSize, this.selectedSort);
    } else {
      this.query = '';
      this.loadClaims("oninit", this.claimDefaultFilter, "add", this.currentPage, this.pageSize, this.selectedSort);
    }
    
    this.modalService.dismissAll('close modal');
  }

  clearFilters() {
    console.log("filterObj:::::::::::::",this.filterObj)
    console.log("this.query::::::::::",this.query)
    let tmpQry = '';
    this.claimsData.breadcrumbs.map((breadCrumb) => {
      if(this.productCode != '') {
        tmpQry = this.claimFilterPDP;
      }
      if(this.filterObj.name != breadCrumb.facetName) {
        if(tmpQry != '') {
          tmpQry = tmpQry+':'+breadCrumb.facetName+':'+breadCrumb.facetValueName;
        } else {
          tmpQry = tmpQry+'::'+breadCrumb.facetName+':'+breadCrumb.facetValueName;
        }
      }
    })
    this.query = tmpQry;
    this.currentPage = 0;
    this.loadClaims("oninit", this.query, "add", this.currentPage, this.pageSize, this.selectedSort);
    this.modalService.dismissAll('close modal');
  }

  removeSelectedFilter(query) {
    this.currentPage = 0;
    this.query = query;
    this.loadClaims("oninit", this.query, "add", this.currentPage, this.pageSize, this.selectedSort);
  }

  onKey(event: any) {
    let value = event.target.value;
    this.filteredList = this.claimFilterValues.filter(filter => filter.name.toLowerCase().includes(value.toLowerCase()));
  }

  loadClaims(from : string, filter: string, action : string, pag, pageSize, selectedSort){
    
      // let pag: number = 0;
      this.showFilter = [];
      this.getClaims(filter, action, pag, pageSize, this.userId, selectedSort).then(() => {
        this.claimsData = this.claimsearchService.getClaimsData(this.userId);
        // debugger
        this.sorts = this.claimsData["sorts"];
        // this.sorts.map((sort) => {
        //   if(sort["code"] == "lowestTreatRate") {
        //     sort["displayCode"] = sort["displayCode"]
        //   } else if(sort["code"] == "highestTreatRate") {
        //     sort["displayCode"] = sort["displayCode"]
        //   } else if(sort["code"] == "productName-asc") {
        //     sort["displayCode"] = sort["displayCode"]
        //   }
        // })
        if(this.claimsData && this.claimsData['claims'].length > 0) {
          this.noClaimData = false;
          this.ref.detectChanges();
          let claimCatImgCode = "";
          this.claimsData['claims'].map((claim) => {
            if(claimCatImgCode == "") {
              claimCatImgCode = claim['categoryImage'];
            } else {
              claimCatImgCode = claimCatImgCode + "," + claim['categoryImage'];
            }
          })
          this.productDetailsService.getCategoryByCode(claimCatImgCode, this.userId).then((categoryImageDetails : any) => {
            // debugger;
            console.log("categoryImageDetails::::::::::::::::",categoryImageDetails);
            this.claimsData['claims'].map((claim) => {
              categoryImageDetails && categoryImageDetails.map((catImg) => {
                if(claim['categoryImage'] == catImg['code']) {
                  claim['categoryImageUrl'] = catImg && catImg['image'] && catImg['image']['url'];
                  claim['categoryUrl'] = catImg && catImg['url'] ;
                  claim['categoryName'] = catImg && catImg['name'] ;
                  if(claim['categoryImageUrl']){
                    claim['categoryImageUrl']  = this.appService.getApiDomainUrl() + claim['categoryImageUrl'];
                  }
                }
              })
              // if(claimCatImgCode == "") {
              //   claimCatImgCode = claim['categoryImage'];
              // } else {
              //   claimCatImgCode = claimCatImgCode + "," + claim['categoryImage'];
              // }
            })
            
            this.ref.detectChanges();
          })
          this.ref.detectChanges();
            // this.totalPagesCount = this.claimsData.claims.length;
          
          this.totalPages = [];
          this.totalPagesCount = this.claimsData["pagination"]["totalPages"];
          let pageCount = this.claimsData["pagination"]["totalPages"];
          for(let i=0; i < pageCount; i++) {
            this.totalPages.push(i+1);
          }
          this.totalResults = this.claimsData["pagination"]["totalResults"];
          this.start = this.claimsData["pagination"]['currentPage'] != 0 ? ((this.claimsData["pagination"]['currentPage']) * this.claimsData["pagination"]['pageSize']) + 1 : 1;
         
          if(this.start + this.claimsData["pagination"]['pageSize'] > this.claimsData["pagination"]['totalResults']){
            this.current = this.claimsData["pagination"]["totalResults"];
          }
          else{
            this.current = (this.start - 1) + this.claimsData["pagination"]['pageSize'];
          }
  
          let claimsListFacet = [];
          this.claimsData.facets.map((facet) => {
            claimsListFacet.push(facet);
          });
          this.claimsData.breadcrumbs.map((facet) => {
            let isAdded = false;
            claimsListFacet.map((claimFacet) => {
              if(facet.facetName == claimFacet.name || facet.facetName == claimFacet.facetName) {
                if(!claimFacet["facetValueName"]) {
                  claimFacet["facetValueName"] = [];
                  claimFacet["facetValueName"].push(facet['facetValueName']);
                  claimFacet["removeQuery"] = [];
                  claimFacet["removeQuery"].push(facet['removeQuery']);
                }else{
                  claimFacet["facetValueName"].push(facet['facetValueName']);
                  claimFacet["removeQuery"].push(facet['removeQuery']);
                }
                // claimFacet["facetValueName"] = facet['facetValueName'];
                // claimFacet["removeQuery"] = facet['removeQuery'];
                isAdded = true;
              } 
            })
            if(!isAdded) {
              let obj = {
                "facetValueName" : [],
                "removeQuery" : []
              }
              obj["facetValueName"].push(facet['facetValueName']);
              obj["removeQuery"].push(facet['removeQuery']);
              obj["facetName"] = facet['facetName'];
              claimsListFacet.push(obj)
            }
            
          })
          // claimsListFacet.push(this.claimsData.breadcrumbs);
          claimsListFacet.forEach(filter => {
            let displayName = '';
            if(filter.name == 'gradeName' || filter.facetName == 'gradeName') {
              displayName = this.gradeNameText
            } else if(filter.name == 'ashPercentage' || filter.facetName == 'ashPercentage') {
              displayName = this.ashText
            } else if(filter.name == 'oilCode' || filter.facetName == 'oilCode') {
              displayName = this.oilCodeText
            } else if(filter.name == 'categoryFacet' || filter.facetName == 'categoryFacet') {
              displayName = this.categoryText;
              let filterCodeTemp = "";
              filter['values'] && filter['values'].map((filterCode) => {
                if(filterCodeTemp == "") {
                  filterCodeTemp = filterCode['name'];
                } else {
                  filterCodeTemp = filterCodeTemp + "," + filterCode['name'];
                }
              })

              filter['facetValueName'] && filter['facetValueName'].map((filterCode) => {
                if(filterCodeTemp == "") {
                  filterCodeTemp = filterCode;
                } else {
                  filterCodeTemp = filterCodeTemp + "," + filterCode;
                }
              })

              // let displayCategoryName = [];
              //debugger;
              if(filterCodeTemp) {
                
                this.productDetailsService.getCategoryByCode(filterCodeTemp, this.userId).then((categoryImageDetails : any) => {
                  // debugger;
                  this.displayCategoryFacetName = [];
                  console.log("categoryImageDetails::::::::::::::::",categoryImageDetails);
                  filter.values && filter.values.map((filterCode) => {
                    categoryImageDetails && categoryImageDetails.map((catImg) => {
                      if(filterCode['name'] == catImg['code']) {
                        
                        filterCode['displayCategoryName'] = catImg && catImg['name'] ;
                        // displayCategoryFacetName.push({catCode: catImg['code'], catName: catImg['name']});
                        // filter['displayCategoryFacetName']= displayCategoryFacetName;
                      }
                    })
                    
                  })
                  
                  filter['facetValueName'] && filter['facetValueName'].map((filterCode) => {
                    categoryImageDetails && categoryImageDetails.map((catImg) => {
                      if(filterCode == catImg['code']) {
                        let imgUrl = catImg && catImg['image'] && catImg['image']['url'];
                        
                        if(imgUrl){
                          imgUrl  = this.appService.getApiDomainUrl() + imgUrl;
                        }
                        this.displayCategoryFacetName.push({catCode: filterCode, catName: catImg['name'], catImageUrl: imgUrl});
                        // filter['displayCategoryFacetName']= displayCategoryFacetName;
                        this.ref.detectChanges();
                      }
                    })
                    
                  })
                  // setTimeout(()=> {
                  //   filter['displayCategoryFacetName']= displayCategoryFacetName;
                  // },100)
                  
                  // this.showFilter.push({
                  //   name: filter.name ? filter.name : filter.facetName,
                  //   displayName: displayName,
                  //   show: filter.visible,
                  //   values: filter.values,
                  //   facetValueName: filter.facetValueName ? filter.facetValueName : [],
                  //   displayCategoryFacetName: filter.displayCategoryFacetName ? filter.displayCategoryFacetName : [],
                  //   removeQuery: filter?.removeQuery
                  // });
                  // this.ref.detectChanges();
                  this.ref.detectChanges();
                })
              }
              
            } else if(filter.name == 'booster' || filter.facetName == 'booster') {
              displayName = this.boosterText
            } else if(filter.name == 'productName' || filter.facetName == 'productName') {
              displayName = this.productPlatformText
            } else if(filter.name == 'viscosityModifier' || filter.facetName == 'viscosityModifier') {
              displayName = this.viscosityModifierText
            } else if(filter.name == 'fuelType' || filter.facetName == 'fuelType') {
              displayName = this.fuelTypeText
            } else if(filter.name == 'engine' || filter.facetName == 'engine') {
              displayName = this.engineText
            } else if(filter.name == 'solvent' || filter.facetName == 'solvent') {
              displayName = this.solventText
            } else if(filter.name == 'baseNumber' || filter.facetName == 'baseNumber') {
              displayName = this.baseNumberText
            } else if(filter.name == 'claimspec' || filter.facetName == 'claimspec') {
              displayName = this.claimspecText
            } else if(filter.name == 'claimOrg' || filter.facetName == 'claimOrg') {
              displayName = this.claimOrgText
            } else if(filter.name == 'baseStocks' || filter.facetName == 'baseStocks') {
              displayName = this.baseStocksText
            } else if(filter.name == 'ppdIdName' || filter.facetName == 'ppdIdName') {
              displayName = this.ppdIdNameText
            } 
            // if(displayName != 'Category') {
              this.showFilter.push({
                name: filter.name ? filter.name : filter.facetName,
                displayName: displayName,
                show: filter.visible,
                values: filter.values,
                facetValueName: filter.facetValueName ? filter.facetValueName : [],
                // displayCategoryFacetName: filter.displayCategoryFacetName ? filter.displayCategoryFacetName : [],
                removeQuery: filter?.removeQuery
              });
              this.ref.detectChanges();
            // }
            // this.ref.detectChanges();
          });
          // this.showFilter.sort((a,b) => (a.displayName > b.displayName) ? 1 : ((b.displayName > a.displayName) ? -1 : 0));
          // this.showFilter.sort((a,b) =>a.displayName.localeCompare(b.displayName));
          let tmpArray = [];
          this.claimsFilterSequence.map((sequence) => {
            this.showFilter.map((filterNameObj) => {
              if(sequence.trim().toLowerCase() == filterNameObj.displayName.trim().toLowerCase()) {
                tmpArray.push(filterNameObj);
              }
            })
          })
          // console.log("tmpArray::::::::::::::::",tmpArray)
          this.showFilter = tmpArray;
         console.log("claimsData:::::", this.claimsData)
         console.log("showFilter:::::", this.showFilter)
         this.ref.detectChanges();
          // this.claimsearchService.changeClaims(false);
          // this.displayFilters(from);
          // this.displayClaims();
        } else {
          this.noClaimData = true;
          this.ref.detectChanges();
        }
        
      }).catch((e) => {
        this.noClaimData = true;
        this.ref.detectChanges();
        console.log("Error:::",e);
        
      });
      setTimeout(() => {
        $(".numberPage"+this.currentPage).addClass("activeArrow");
        this.activeArrows(this.currentPage);
      },1000)
    }

    navigateToPDP(productCode) {
      this.router.navigate(['/p/'+productCode]);
      this.modalService.dismissAll('close modal');
    }

    private async getClaims(filter: string, action: string, page: number, pageSize: number, userId, selectedSort){
      await this.claimsearchService.getClaims(filter, action, page, pageSize, userId, selectedSort);
    }

    private async getClaimDetails(productCode, treatId, userId){
      await this.claimsearchService.getClaimDetails(productCode, treatId, userId);
    }

  changePage(pageNumber) {
    console.log("this.query::::::::::::::::::changePage:::::",this.query)
    $(".numberPage"+this.currentPage).removeClass("activeArrow");
    if(this.currentPage != pageNumber){
      this.currentPage = pageNumber;
      if(this.query) {
        if(this.productCode != '') {
          this.loadClaims("oninit", this.query, "add", this.currentPage - 1, this.pageSize, this.selectedSort);
        } else {
          this.loadClaims("oninit", this.query, "add", this.currentPage - 1, this.pageSize, this.selectedSort);
        }
      } else if(this.productCode != '') {
        this.loadClaims("oninit", this.claimFilterPDP, "add", this.currentPage - 1, this.pageSize, this.selectedSort);
      } else {
        this.loadClaims("oninit", this.query, "add", this.currentPage - 1, this.pageSize, this.selectedSort);
      }

      // }
      
      // this.getSearchProductList(this.itemsPerPage, this.currentPage - 1, this.selectedSort, this.selectedFilterArr);

    }
    this.currentPage = pageNumber;
    this.activeArrows(this.currentPage);
    
    $(".numberPage"+this.currentPage).addClass("activeArrow");
    
  }
  changePageBack() {

    $(".numberPage"+this.currentPage).removeClass("activeArrow");
    this.currentPage = this.currentPage -1;
    this.activeArrows(this.currentPage);

    if(this.query) {
      if(this.productCode != '') {
        this.loadClaims("oninit", this.query, "add", this.currentPage - 1, this.pageSize, this.selectedSort);
      } else {
        this.loadClaims("oninit", this.query, "add", this.currentPage - 1, this.pageSize, this.selectedSort);
      }
    } else if(this.productCode != '') {
      this.loadClaims("oninit", this.claimFilterPDP, "add", this.currentPage - 1, this.pageSize, this.selectedSort);
    } else {
      this.loadClaims("oninit", this.query, "add", this.currentPage - 1, this.pageSize, this.selectedSort);
    }
    console.log("this.query::::::::::::::::::changePageBack:::::",this.query)
    
    // this.loadClaims("oninit", this.query, "add", this.currentPage - 1);
    // this.getSearchProductList(this.itemsPerPage, this.currentPage - 1, this.selectedSort, this.selectedFilterArr);
    $(".numberPage"+this.currentPage).addClass("activeArrow");
  }
  changePageNext() {

    $(".numberPage"+this.currentPage).removeClass("activeArrow");
    this.currentPage = this.currentPage + 1;
    this.activeArrows(this.currentPage);

    
    if(this.query) {
      if(this.productCode != '') {
        this.loadClaims("oninit", this.query, "add", this.currentPage - 1, this.pageSize, this.selectedSort);
      } else {
        this.loadClaims("oninit", this.query, "add", this.currentPage - 1, this.pageSize, this.selectedSort);
      }
    } else if(this.productCode != '') {
      this.loadClaims("oninit", this.claimFilterPDP, "add", this.currentPage - 1, this.pageSize, this.selectedSort);
    } else {
      this.loadClaims("oninit", this.query, "add", this.currentPage - 1, this.pageSize, this.selectedSort);
    }
    console.log("this.query::::::::::::::::::changePageNext:::::",this.query)
    
    // this.loadClaims("oninit", this.query, "add", this.currentPage - 1);
    // this.getSearchProductList(this.itemsPerPage, this.currentPage - 1, this.selectedSort, this.selectedFilterArr);
    $(".numberPage"+this.currentPage).addClass("activeArrow");
  }

  private activeArrows(currentPage : number){
    if(currentPage == 1 && (currentPage) == this.totalPagesCount){
      $(".__backArrow").prop("disabled", true);
      $(".__arrow").prop("disabled", true);
    }
    else if(currentPage == 1){
      $(".__backArrow").prop("disabled", true);
      $(".__arrow").prop("disabled", false);
    }
    else if((currentPage) == this.totalPagesCount){
      $(".__backArrow").prop("disabled", false);
      $(".__arrow").prop("disabled", true);
    } 
    else{
      $(".__backArrow").prop("disabled", false);
      $(".__arrow").prop("disabled", false);
    }
  }

  getPage(page:any) {
    this.currentPage = page;
    this.currentPage = page;
    window.scrollTo(0, 0);
    this.ref.detectChanges();
    if(this.query) {
      if(this.productCode != '') {
        this.loadClaims("oninit", this.query, "add", this.currentPage - 1, this.pageSize, this.selectedSort);
      } else {
        this.loadClaims("oninit", this.query, "add", this.currentPage - 1, this.pageSize, this.selectedSort);
      }
    } else if(this.productCode != '') {
      this.loadClaims("oninit", this.claimFilterPDP, "add", this.currentPage - 1, this.pageSize, this.selectedSort);
    } else {
      this.loadClaims("oninit", this.query, "add", this.currentPage - 1, this.pageSize, this.selectedSort);
    }
  }

  selectChangeHandler (event: any) {
    this.selectedSort = event.target.value;
    this.defaultValue = this.selectedSort;
    this.currentPage = 1;
    this.loadClaims("oninit", this.query, "add", this.currentPage - 1, this.pageSize, this.selectedSort);
    // this.getSearchProductList(this.itemsPerPage, this.currentPage-1, this.selectedSort, this.selectedFilterArr, this.user.uid);
  }

  navigateContactWithProduct(productName) {
    this.router.navigate(['/contact'], { queryParams: { name: productName } });
    this.modalService.dismissAll('close modal');
  }
  navigateToContact() {
    this.router.navigate(['/contact']);
    this.modalService.dismissAll('close modal');
  }

  getCategoryNameByCode(facetCategoryList, facetValue): any {
    console.log("show filter:::::::::::",this.showFilter)
    console.log("getCategoryNameByCode::::::::::::::",facetCategoryList, facetValue)
    facetCategoryList && facetCategoryList.map((element) => {
      if(element["catCode"] == facetValue){
        console.log("element['catName']:::::::::::",element['catName'])
        return element['catName'];
      }
    })
  }

  printClaims(displaySingleClaimData, claimDetails) {
    let printDate = new Date().toLocaleDateString('en-GB', {
      day : 'numeric',
      month : 'short',
      year : 'numeric'
  }).split(' ').join('-');
  let printYear = new Date().getFullYear();
  let claimsList = "";
  claimDetails && claimDetails.map((claimSpec) => {
    if(claimSpec && claimSpec["claimType"] == "Complete") {
      claimsList = claimsList + `<tr><td><span style="font-family: formata-regular;">${claimSpec.claimSpecId}</span>
      </td>
      <td style="padding-left:20px; "><i style="padding-left:20px; " class="far fa-check-circle color-blue mr-2">
      </i>
      </td>
</tr>
      
      
      

  `
    } else {
      claimsList = claimsList + `
      <tr><td><span style="font-family: formata-regular;">${claimSpec.claimSpecId}</span>
      </td>
      <td style="padding-left:20px; "><i style="padding-left:20px; " class="far fa-edit mr-2 color-orange">
      </i></td>
</tr>
      `
    }
  })

    let popupWin = window.open('', '_blank', 'width=930,height=600');
    let printContents = `<div id="print-window-content" style="margin: 0% 2% 0% 2%;">
    <div class="container" style="margin: 0%; padding: 0%;">
        <div class="row">
            <div class="col-6 col-md-8 col-lg-8">
                <span style="margin:0%; font-family: formata-medium;"><h2 style="font-family:formata-medium !important;text-transform: none;">Claims Detail</h2></span>
                
            </div>
                <div class="col-6 col-md-4 col-lg-4 icon-bottom icon-position">
                    <img src="/assets/Infineum_Color_Logo.jpg" style="height: 60px;"/><br>
                    <div style="font-family: formata-regular;">Printed On : <b style="font-family: formata-medium;">${printDate}</b></div>
                    <div style="font-family: formata-regular;">Printed By : <b style="font-family: formata-medium;">${this.userName}</b></div>
                    <div><b style="font-family: formata-regular;">Proprietary Information</b></div>
                
            </div>
            

        </div>
        
    </div>
    
    <div class="row __margin-table">
        <div class="col-12 col-md-12 col-lg-12">
    <table>
        <tr>
            <td><b style="font-family: formata-regular;">Product</b>
            </td>
            <td style="padding-left:20px; font-family: 'formata-regular';color: #00A1DE;">${displaySingleClaimData?.productName ? displaySingleClaimData?.productName : ""}</td>

        </tr>
        <tr>
            <td><b style="font-family: formata-regular;">Oil Code</b></td>
            <td style="padding-left:20px; font-family: 'formata-regular';">${displaySingleClaimData?.oilCode ? displaySingleClaimData?.oilCode : ""}</td>

        </tr>
        <tr>
            <td><b style="font-family: formata-regular;">Base Stock</b></td>
            
                <td style="padding-left:20px; font-family: 'formata-regular';">${displaySingleClaimData?.baseStocks ? displaySingleClaimData?.baseStocks : ""}</td>
          


        </tr>
        <tr>
            <td><b style="font-family: formata-regular;">VM</b>
            </td>
            <td style="padding-left:20px; font-family: 'formata-regular';">
            ${displaySingleClaimData?.viscosityModifiers ? displaySingleClaimData?.viscosityModifiers : ""}</td>

        </tr>
        <tr>
            <td><b style="font-family: formata-regular;">Grade</b>
            </td>
            <td style="padding-left:20px; font-family: 'formata-regular';">
            ${displaySingleClaimData?.gradeName ? displaySingleClaimData?.gradeName : ""}</td>

        </tr>
        <tr>
            <td><b style="font-family: formata-regular;">Treat Rate</b>
            </td>
            <td style="padding-left:20px; font-family: 'formata-regular';">
            ${displaySingleClaimData?.treatRate ? displaySingleClaimData?.treatRate : ""}</td>

        </tr>
        <tr>
            <td><b style="font-family: formata-regular;">PPD</b>
            </td>
            <td style="padding-left:20px; font-family: 'formata-regular';">
            ${displaySingleClaimData?.ppdIdName ? displaySingleClaimData?.ppdIdName : ""}</td>

        </tr><br/>
        <tr>
            <td><span style="font-family: formata-medium;font-size: 20px">Performance Claims</span>
            </td>
        </tr>
        ` + claimsList + `
        
        
    </table>
</div> 
</div>
    <br/>
    <div style="text-align:center;color:'#00a1de'; font-family: 'formata-regular';font-size: 12px;">© Copyright INFINEUM INTERNATIONAL LIMITED ${printYear} . All rights reserved.</div>
    <div style="text-align:center;color:'#00a1de'; font-family: 'formata-regular';font-size: 12px;">Infineum, 润英联 and the interlocking ripple device are Trade Marks of Infineum International Limited.</div>
</div>`;
    const delay = 10;
    popupWin.document
      .write(`<html>
         ${document.querySelector('head').innerHTML}
        <body onload="window.print();closePopWin();">
        <div id="print-confirmation" class="page">
          <div class=" bg-white">
            <div class="">
              <div id="print-window">
                ${printContents}
              </div>
            </div>
          </div>
        </div>
        <script>
          function closePopWin() {
            window.onfocus = function(){ setTimeout(function(){ window.close(); }, ${delay});}
          }
        </script>
        </body></html>`);
      popupWin.document.close();
  }

}
