<div class="container login">
    <div class="card">
        <div class="card-body">
            <div class="whiteline">
            </div>
            <h4 class="card-title mb-3 mt-1">{{'customLogin.signIn' | cxTranslate}}</h4>
            <!-- <label *ngIf="!isUserLoggedIn" class="__NotValidText">Your email or password has not been recognised. Please check CAPS Lock is not on and try again.</label> -->
            <form [formGroup]="registerForm" (ngSubmit)="onSubmit()">
                <div class="form-group">
                    <label class="__text">
                        {{'customLogin.email' | cxTranslate}}
                    </label>
                    <input name="" class="form-control" formControlName="Email" placeholder="" type="email"
                        [ngClass]="{ 'is-invalid': submitted && fval.Email.errors }">
                    <div *ngIf="submitted && fval.Email.errors" class="invalid-feedback">

                        <div *ngIf="fval.Email.errors.required" class="__errorMessage">
                            {{'customLogin.emailRequired' | cxTranslate}}
                        </div>
                        <div *ngIf="fval.Email.errors.pattern" class="__errorMessage">
                            {{'customLogin.thisIsNotValidFormate' | cxTranslate}}
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label class="__text">{{'customLogin.password' | cxTranslate}}</label>
                    <input class="form-control" formControlName="Password" placeholder=""
                        [ngClass]="{ 'is-invalid': submitted && fval.Password.errors }" [type]="hide ? 'password' : 'text'">
                        <span (click) = "toggleHide()" class="__passwordShow">{{hideBtnText}}</span>
                    <div *ngIf="submitted && fval.Password.errors" class="invalid-feedback">
                        <div *ngIf="fval.Password.errors.required" class="__errorMessage">
                            {{'customLogin.passwordRequired' | cxTranslate}}
                        </div>

                    </div>

                </div>

                <label (click)="naviateToForgotPassword()" class="forgot mt-3 mb-3">
                    {{'customLogin.forgotPassword' | cxTranslate}}
                </label>
                <div>
                    <button type="submit" class="btn btn-primary mw-100">{{'customLogin.signIn' | cxTranslate}}</button>
                </div>
                <label class="conditions mt-1"> {{'customLogin.byAccesing' | cxTranslate}} <u class="terms" (click)="navigateTermsAndConditions()">{{'customLogin.termAndCondition' | cxTranslate}}</u></label>

            </form>
        </div>
        <div class="card-footer">
            <div class="whiteline1 mt-4 mr-1">

            </div>
            <h2>{{'customLogin.newInfineum' | cxTranslate}}</h2>
            <p>
                {{'customLogin.ifYouWould' | cxTranslate}}
            </p>
        </div>
    </div>
</div>