<!-- NO RESULTS -->
<div class="container no-results" *ngIf="plpNoResultAvailable" [ngClass]="{'invisible' : loading==true}">
    <div class="row">
        <div class="col-12">
            <div class="jumbotron text-center bg-transparent">
              <h1 class="border-top-blue border-align-center">{{'customProductList.no' | cxTranslate}}<span class="text-bold">{{'customProductList.results' | cxTranslate}}</span></h1>
              <p class="lead">{{'customProductList.sorryWeCouldNot' | cxTranslate}} "{{freeTextSearch ? freeTextSearch : categoryName}}"</p>
                <div class="card">
                    <div class="card-body text-left">
                        <div class="card-title">{{'customProductList.searchTips' | cxTranslate}}</div>
                        <ul>
                            <li>{{'customProductList.noResiltsLi1' | cxTranslate}}</li>
                            <li>{{'customProductList.noResiltsLi2' | cxTranslate}}</li>
                            <li>{{'customProductList.noResiltsLi3' | cxTranslate}}</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- RESULTS -->
<div class="container-fluid bg-lightestgrey" *ngIf="productListData.length" [ngClass]="{'invisible' : loading==true}">
    <div class="container">
        <div class="row filters">
            <div class="col-12 col-md-3 filter">
                <div class="filter-wrapper">
                    <h4>{{'customProductList.filters' | cxTranslate}}</h4>
                    <button (click)="uncheckAll()" class="btn btn-ghost-inverse btn-sort">{{'customProductList.clearBtn' | cxTranslate}}</button>
                </div>
            </div>
            <div class="col-12 col-md-9 filter">
                <div class="filter-wrapper">
                    <div class="sort">
                        <div class="filter-results">
                            <h4 class="d-inline mr-0">{{'customProductList.products' | cxTranslate}} </h4>
                            <span>{{productCount}} {{'customProductList.resultsCaps' | cxTranslate}}</span>
                        </div>
                        <h4 class="light">{{'customProductList.sortBy' | cxTranslate}}</h4>
                        <select (change)="selectChangeHandler($event)" class="w-auto">
                            <option *ngFor="let sort of sorts" [value]="sort.code">{{ sort.name }}</option>
                        </select>
                    </div>
                </div>
            </div>
        </div><!-- .filters -->
        <div class="row results">
            <div class="col-12 col-md-3 categories">
                <div class="row m-auto"> 
                    <div class="card w-100 __category  filter-segmentName">
                        <h5 class="card-header">{{'customProductList.type' | cxTranslate}}</h5>
                        <div class="card-body">
                            <div *ngFor="let filter of pFilters, let i = index">
                                <span class="form-check">
                                    <input 
                                        type="checkbox" 
                                        class="form-check-input" 
                                        id="item-{{i}}" [value]="filter" 
                                        (change)="onChange(filter, $event.target.checked)" 
                                        #checkboxes
                                    >
                                    <label class="form-check-label" for="item-{{i}}">{{ filter }}</label>
                                </span>
                             </div>
                        </div>
                    </div>
                </div>
            </div><!-- .COL -->
            <!-- PRODUCTS -->
            <div class="col-12 col-md-9 products">
                <div class="card" *ngFor="let productList of productListData | paginate: { id: 'server', itemsPerPage: 10, currentPage: currentPage, totalItems: totalResult }" (click)="naviateToProductDetailpage(productList.url)">
                    <div class="card-header">
                        <div class="card-icon">
                            <img src= "{{domainUrl + productList.productImage}}" alt="{{productList.name}}">
                        </div>
                    </div>
                    <div class="card-body">
                        
                        <h4 (click)="naviateToProductDetailpage(productList.url)">{{productList.name}}</h4>
                        <p class="lead" *ngIf="productList.summary">{{productList.summary}}</p>
                        
                        
                        <p><strong>{{productList.packageType}}</strong></p>
                        <p>{{'customProductList.type' | cxTranslate}}</p>
                        
                        <div class="cta">
                            <button *ngIf= "productList.packageType == 'Bulk' && user.canPlaceSampleOrder" (click)="naviateToProductDetailpage(productList.url)" class="btn btn-icon icon-drop" type="button">{{'customProductList.add' | cxTranslate}}</button>
                            <a class="stretched-link" (click)="naviateToProductDetailpage(productList.url)">
                                <img src='assets/Arrow.svg'>  
                            </a>
                        </div>
                    </div>
                </div>
                <!-- PAGINATION -->
                <div class="page-pagination">
                    <pagination-controls class="my-pagination" [previousLabel]="''" [nextLabel]="''" (pageChange)="getPage($event)" id="server"></pagination-controls>
                    <span class="numberResults">{{start}} - {{current}} {{'customProductList.of' | cxTranslate}} {{totalResult}} {{'customProductList.paginationResults' | cxTranslate}}</span>
                </div>
            </div><!-- col -->
        </div><!-- .results -->
    </div>
</div>





<!--
<div class="container" *ngIf="productListData.length" [ngClass]="{'invisible' : loading==true}">
    <div class="row">
        <div class="col-lg-3 col-md-3">
            <div class="row">
                <div class="col-lg-5 col-md-5">
                    <label class="__filter-name __filter-text-font-size-md">FILTERS</label>
                </div>
                <div class="col-lg-7 col-md-7">
                    <button class="rounded-pill clear" type="button" (click)="uncheckAll()">CLEAR</button>
                </div>
            </div>
            <div class="row"><div class="col-lg-12 col-md-12"></div><hr class="__search-filter-hr"></div>
        </div>
        <div class="col-lg-9 col-md-9">
            <div class="row">
                <div class="col-lg-6 col-md-5">
                <label class="search-product-lable __filter-text-font-size-md">PRODUCTS: <span>{{productCount}} results</span></label>
                </div>
                <div class="col-lg-2 col-md-3">
                    <label class="sortBY __filter-text-font-size-md-sort">sort by</label>
                    </div>
                    <div class="col-lg-4 col-md-4">
                    <select class="__sort-filter" (change)="selectChangeHandler($event)">
                        <option *ngFor="let sort of sorts" [value]="sort.code">{{ sort.code }}</option>
                    </select>
                  </div>
            </div>
            <div class="row"><div class="col-lg-12 col-md-12"></div><hr class="__search-filter-hr-right"></div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-3 col-md-3">
            <div class="card search-filter-cart">
                <div class="card-header">
                    <label class="__filter-header-lable">TYPE</label>
                </div>
                <div class="filter-content">
                    <div class="card-body">
                        <div *ngFor="let filter of pFilters">
                            <label class="form-check">
                              <input class="form-check-input" type="checkbox" [value]="filter" (change)="onChange(filter, $event.target.checked)" #checkboxes type="checkbox"/>
                              <span class="form-check-label">
                                {{ filter }}
                              </span>
                            </label>
                        </div>
                    </div>
                </div>
        </div>
        </div>
        <div class="col-lg-9 col-md-9">
            <div class="row cart_list curserPointer" *ngFor="let productList of productListData | paginate: { id: 'server', itemsPerPage: 10, currentPage: currentPage, totalItems: totalResult }" (click)="naviateToProductDetailpage(productList.url)">
            <div class="col-lg-3 col-md-3 __cart-image-bg-color">
                <div class="cart_item_image"><img src= "{{domainUrl + productList.productImage}}" alt=""></div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="cart_item_name cart_info_col">
                    <div>
                        <label class="cart_item_title __filter-pdp-title-font-size-md curserPointer" (click)="naviateToProductDetailpage(productList.url)">{{productList.name}}</label>
                    </div>
                    <div>
                        <p class="cart_item_text-summary">{{productList.summary}}</p>
                    </div>

                    <div>
                        <label (click)="naviateToProductDetailpage(productList.url)" class="cart_item_title-packageType curserPointer">{{productList.packageType}}</label>
                    </div>
                    <div class="cart_item_text-type">Type</div>
                </div>
            </div>
            <div class="col-lg-2 col-md-2">
                <button *ngIf= "productList.packageType == 'Bulk' && user.canPlaceSampleOrder" (click)="naviateToProductDetailpage(productList.url)" class="search-list-right-btn __plp-add-btn-md" type="button">
                    <img src='assets/SamplesIcon.png' class='sampleImg'>
                    ADD
                </button>
            </div>
            <div class="col-lg-1 col-md-1">
                <a class="curserPointer" (click)="naviateToProductDetailpage(productList.url)">
                    <img src='assets/arrow.png' class='pplArraw __plp-arrow-btn-md'>  
                </a>
            </div>
        </div>
        <div class="row pages __pagination-search">
            <div class="col-md-12 selector">

        <div class="has-text-centered">
            <pagination-controls class="my-pagination" [previousLabel]="''"
            [nextLabel]="''" (pageChange)="getPage($event)" id="server"></pagination-controls>
        </div>
        <span class="numberResults">{{start}} - {{current}} of {{totalResult}} results</span>
</div>
</div>
    </div>
    </div>
</div>
<!-- testing end -->

<div class="container-fluid justify-content-center bg-white search-footer" *ngIf="categorySearchShowContact" [ngClass]="{'invisible' : loading==true}">
    <div class="container">
        <div class="row">
            <div class="col">
                <div class="card card-info bg-white box-shadow-none border-0 text-center pt-0">
                    <div class="card-body">
                        <div class="card-icon">
                            <img src="/assets/SearchIcon.svg">
                        </div>
                        <h2 class="h1 border-top-blue m-auto color-black">{{'customProductList.lookingFor' | cxTranslate}}<span class="text-bold">{{'customProductList.somthingSpec' | cxTranslate}}</span></h2>
                        <p class="pb-4">{{'customProductList.narrow' | cxTranslate}}</p>
                        <button  (click)="redirectToClaim()" type="button" class="btn btn-primary m-auto">{{'customProductList.claimSearch' | cxTranslate}}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- no results -->
<div class="container-fluid justify-content-center bg-white search-footer" *ngIf="!categorySearchShowContact" [ngClass]="{'invisible' : loading==true}">
        <div class="row">
            <div class="col">
                <div class="card card-info bg-white box-shadow-none border-0 text-center pt-0">
                    <div class="card-body">
                        <div class="card-icon">
                            <img src="/assets/SearchIcon.svg">
                        </div>
                        <h2 class="h1 border-top-blue m-auto color-black">{{'customProductList.canNotFind' | cxTranslate}}<span class="text-bold">{{'customProductList.whatAreLookingFor' | cxTranslate}}</span></h2>
                        <p class="pb-4">{{'customProductList.paraG1' | cxTranslate}}</p>
                        <button  (click) = "navigateContact()" type="button" class="btn btn-primary m-auto">{{'customProductList.contact' | cxTranslate}}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    
    
<!---    
    <div class="card card-border-claim">
        <div class="card-body text-center __margin-bottom-plp">
            <div class="row">
                <div class="col-md-12">
            <img src='assets/search-updated.png'>
            <div class="_hrLinePLP1">
                <hr>
            </div>
            </div>
            </div>
            <div class="row">
                <div class="col-md-12">
            <div class="claimSearchText">
                <label><span class="__plp-error-page-title-no-contact">Can’t find </span><span class="__plp-error-page-title-result-contact">what you’re looking for?</span></label>
                <p class="__plp-error-page-text-contact">Please contact your Infineum Account Manager to discuss your requirements or use our contact form.</p>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="clSearch-div">
                <button (click) = "navigateContact()" class="rounded-pill __search-contact-btn">CONTACT
                    <i aria-hidden="true" class="fa fa-chevron-right __btn-right-arrow"></i>
                </button>  
            </div>
      </div>
    </div>
</div>
</div> 
</div> -->
<!--
<div class="container-fluid justify-content-center __back-color" *ngIf="categorySearchShowContact" [ngClass]="{'invisible' : loading==true}">
    <div class="card card-border-claim">
      
        <div class="card-body text-center __margin-bottom-plp">
            <div class="row">
                <div class="col-md-12">
            <img src='assets/search-updated.png'>
            <div class="_hrLinePLP1">
                <hr>
            </div>
            </div>
            </div>
            <div class="row">
                <div class="col-md-12">
            <div class="claimSearchText">
                <label><span class="__plp-error-page-title-no-contact">Looking for </span><span class="__plp-error-page-title-result-contact">something specific?</span></label>
                <p class="__plp-error-page-text-contact">Narrow down your product options with our powerful Claims Search</p>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="clSearch-div">
                <button (click)="redirectToClaim()" class="rounded-pill __search-contact-btn">CLAIMS SEARCH
                    <i aria-hidden="true" class="fa fa-chevron-right __btn-right-arrow"></i>
                </button>  
            </div>
      </div>
    </div>
</div>
</div> 
</div>

-->
