<div class="container">
    <!-- checkout flow finish template for request and review -->
    <div class="card __checkout-finish-flow">
     <div class="card-body">
         <div class="__checkout-finish-cart">
             <img src="/assets/CircleCheck.svg">
             <br>
             <hr/>
             <label class="mt-2">{{'checkoutOrderConfirmation.request' | cxTranslate}}<span>{{'checkoutOrderConfirmation.sent' | cxTranslate}}</span></label>
             <br>
             <span class="__checkout-finish-cart-text1">{{'checkoutOrderConfirmation.thankYouRequest' | cxTranslate}}</span>
             <br>
             <span class="__checkout-finish-cart-text2">{{'checkoutOrderConfirmation.sampleRequestNumber' | cxTranslate}} {{checkoutDetails?.orderNo}}</span>
             <br>
             <a style="cursor:pointer" (click)="navigateOrderDetailsPage()">{{'checkoutOrderConfirmation.reviewAndTrack' | cxTranslate}}</a>
             <div class="mt-3 mb-3">
                <button (click)="navigateToHome()" type="submit" class="btn btn-primary m-auto">{{'checkoutOrderConfirmation.home' | cxTranslate}}</button>
             </div>
         </div>
         <div class="row align-items-center pt-3">
           <div class="col-lg-6 col-md-6">
               <label class="__cart-title">{{'checkoutOrderConfirmation.shippingAddress' | cxTranslate}}</label>
               
               <hr class="__cart-hr">
           </div>
           <div class="col-lg-6 col-md-6 d-none d-md-block">
             <label class="__cart-title">{{'checkoutOrderConfirmation.summary' | cxTranslate}}</label>
             <hr class="__cart-hr">
            </div>
         </div>
         <div class="row">
             <div class="col-lg-6 col-md-6">
                 <div class="card-body __last-cart-checkout">
                    <label>{{checkoutDetails?.shippingaddress?.firstName + " " + checkoutDetails?.shippingaddress?.lastName}}</label>
                    <span *ngIf="checkoutDetails?.shippingaddress?.shipToUnit">{{checkoutDetails?.formattedSavedAddr}}</span><br>
                    <span *ngIf="!(checkoutDetails?.shippingaddress?.shipToUnit)">
                        <span>{{checkoutDetails?.shippingaddress?.companyName}}</span><br>
                        <span>{{checkoutDetails?.shippingaddress?.line1}}</span><br>
                        <span>{{checkoutDetails?.shippingaddress?.line2}}</span><br>
                        <span>{{checkoutDetails?.shippingaddress?.town}}</span><br>
                        <span>{{checkoutDetails?.shippingaddress?.region?.name}}</span><br>
                        <span>{{checkoutDetails?.shippingaddress?.postalCode}}</span><br>
                        <span>{{checkoutDetails?.shippingaddress?.country?.name}}</span>
                    </span>
                 </div>
             </div>
             <div class="col-lg-6 col-md-6">
               <!-- <div class="__cart-list"> -->
               <div class="col-lg-6 col-md-6 d-block d-md-none p-0">
                 <label class="__cart-title">{{'checkoutOrderConfirmation.summary' | cxTranslate}}</label>
                 <hr class="__cart-hr">
                </div>
                 <div *ngFor="let cart of cartDetails" class="row align-items-center __cart-list no-gutters">
                 <div class="col-lg-3 col-md-3">
                     <div class="__cart-imag-bg p-3">
                     <img  class="__cart-image" src="{{cart.categoryImage}}" alt="">
                     </div>
                 </div>
                 <div class="col-lg-6 col-md-6" style="text-align: center;">
                     <label  class="__cart-section-title">{{cart.productName}}</label>
                 </div>
                 <div class="col-lg-3 col-md-3" style="text-align: center;">
                     <label class="__cart-section-qty">{{cart.infineumQuantity + " " + cart.unit}}</label>
                 </div>
            </div>
         <!-- </div> -->
             </div>
         </div>
     </div>
 </div>
 </div>