import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ProductDetailsService } from '../APIcall/product-details.service';

@Component({
  selector: 'app-error404',
  templateUrl: './error404.component.html',
  styleUrls: ['./error404.component.scss']
})
export class Error404Component implements OnInit {

  constructor(private productDetailsService:ProductDetailsService, private router: Router) { }

  ngOnInit(): void {

    this.productDetailsService.changeBreadcrumb({"breadcumTitle" : "error", "categoryName": "", "productName": ""});

  }
  
  title = 'Page not found';

  navigateToHome() {
    this.router.navigate(['/']);
  }
  navigateToClaim() {
    this.router.navigate(['/claimsList']);
  }

}