import { Component, OnInit } from '@angular/core';
import { CmsComponent } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-test-detail-product',
  templateUrl: './test-detail-product.component.html',
  styleUrls: ['./test-detail-product.component.scss']
})
export class TestDetailProductComponent implements OnInit {

  //cmsComp: CmsTestDetailProductComponent;

  ComponentData = {};
  constructor(
    protected component: CmsComponentData<CmsComponent>
  ) {}

  ngOnInit(): void {
    this.getText();
    console.log(this.ComponentData);
  }
  public getText(): any {
    return this.component.data$.subscribe(data => {
      return this.ComponentData = data;
    })
  }
}
