<div class="container claims-search-container" style="padding-bottom: 20px;padding-left: 35px;">
  <div class="row filter">
    <div class="col-md-2 filter-row" style="display: flex; align-items: center;">
      <div class="col-md-6">
        <h3 style="margin: 0; color: #37424A;">FILTERS</h3>
      </div>
      <div class="col-md-6" style="padding-right: 0;">
        <button class="clear" (click)="loadClaims('clearFilter', '', 'add')">CLEAR ALL</button>
      </div>
    </div>
    <div class="col-md-9 update">
        <div class="row" style="padding-left: 15px;">
          <div class="col-md-12">
            <!-- <h3 style="margin: 0; color: #37424A;">RECENTLY UPDATED CLAIMS: {{ result }} RESULTS</h3> -->
          </div>
      </div>
    </div>
  </div> 
  <div class="row paginator">
    <div class="col-md-2" style="margin-bottom: 50px;">
      <div class="selected"></div>
      <div class="filters"></div>
    </div>
    <div class="col-md-10">
      <div class="row claimsList" style="margin-bottom: 30px;"></div> <!-- [ngStyle]="{'padding-left': isMobile ? '0px' : '15px'}" -->
    </div>
  </div>
  <div class="row pages" style="padding-bottom: 20px; padding: 10px;">
    <div class="col-md-2"></div>
    <div class="col-md-10 selector">
        <button class="backArrow" (click)="oldPage()"> <img src="../../assets/circleArrow.svg"> </button>
        <div class="numbers">
          <!--button class="numbersPaginator numberPage-0 pn" pageNumber="0" style="background-color: #00A1DE;color: white;"> 1 </button-->
          <div class="otherPages"></div>
        </div>
        <button class="arrow" (click)="newPage()"> <img src="../../assets/circleArrow.svg"> </button>
        <!--1-3 of 3 results-->
        <span class="numberResults"></span>
    </div>
  </div>
</div>