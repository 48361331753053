// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// export const environment = {
//   production: false
// };


const D1_ENVIRONMENT = {
  production: false,
  baseUrl: 'https://api.dev.infineuminform.com',
  siteUrl: 'https://dev.infineuminform.com/infineum/en/USD',
  hybrisApiURL: '/infineumwebservices/v2/infineum',
  user: ''
};

export const environment = D1_ENVIRONMENT;


// const qa_ENVIRONMENT = {
// production: false,
// siteUrl: 'http://localhost:4200/infineum/en/USD',
// baseUrl: 'https://api.qa.infineuminform.com',
// hybrisApiURL: '/infineumwebservices/v2/infineum'
// };

// export const environment = qa_ENVIRONMENT;
// export const p1_environment = {
//   production: true,
//   siteUrl: 'https://infineuminform.com/infineum/en/USD',
//   baseUrl: 'https://api.infineuminform.com',
//   hybrisApiURL: '/infineumwebservices/v2/infineum'
// };

// export const environment = qa_ENVIRONMENT;
//  export const p1_environment = {
//    production: true,
//    siteUrl: 'https://infineuminform.com/infineum/en/USD',
//    baseUrl: 'https://api.infineuminform.com',
//    hybrisApiURL: '/infineumwebservices/v2/infineum'
// };

//   export const environment = p1_environment;
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
