import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppService } from '../app.services';
import { ProductModel } from '../APIcall/product.model';
import { ProductDetailsService } from '../APIcall/product-details.service';
import { ProductMsdsModel } from '../APIcall/product.msds.model';
import { ProductPdsModel } from '../APIcall/product.pds.model';
import { ClaimSearchProductModel } from '../APIcall/claimsearch.product.model';
import { ClaimsearchService } from '../APIcall/claimsearch.service';
import { CustomerSpecificationService } from '../APIcall/customer-specification.service';
import { ClaimSearchComponent } from '../claim-search/claim-search.component';
import { ProductDocument } from '../APIcall/product-document.model';
import { UserServiceService} from '../APIcall/user-service.service';
import { Router } from '@angular/router';
import { TranslationService } from '@spartacus/core';
import { Observable, Subscription } from 'rxjs';
declare var $: any;


@Component({
  selector: 'app-tab-product-details',
  templateUrl: './tab-product-details.component.html',
  styleUrls: ['./tab-product-details.component.scss']
})
export class TabProductDetailsComponent implements OnInit {

  product: ProductModel;
  MSDS: ProductMsdsModel;
  PDS: Array<ProductPdsModel>;
  pdfString: string;
  pdfStringPDS: string;
  elements: string[];
  units: string[];
  button: string[];
  firstMSDS: boolean;
  firstPDS: boolean;
  firstClaim: boolean;
  filterCountry: string;
  filterLanguage: string;
  selectedUnit: string;
  claims: ClaimSearchProductModel;
  productdocumentsdata: ProductDocument;
  customerSpecificationNoResults: boolean = false;
  customerSpecificationRecordCount: any = 0;
  MSDSProductList:any=[];
  //paging MSDS
  currentPage: number;
  pageSize: number;
  maxPages: number;
  showClaims: boolean = false;
  noResultClaim: boolean = false;

  noResultPDS: boolean= false;
  noResultProductDoc: boolean= false;
  productDocDetails : any = [];
  firstPDocument : boolean;
  firstCustSpec: boolean;
  prodDownloadBtnTxt : any = 'Download';
  msdsNoResults: boolean = false;
  domainUrl: any = '';
  categoryImage: any = '';
  packageTypeImage: any = '';
  countries: any = [];
  // selectedCountry: any = '';
  languages: any = [];
  // selectedlanguage: any = '';
  msdsAttachmentList: any = [];
  displayMsdsAttachmentList: any = [];
  userId: any = "";
  btnDownloadText: string = '';
  allCountriesText: string = '';
  allLanguagesText: string = '';
  productNameText: string = '';
  descriptionText: string = '';
  documentText: string = '';
  downlodBtnText$: Observable<string> = this.translation.translate('customTabProductDetails.download')
  allCountriesText$: Observable<string> = this.translation.translate('customTabProductDetails.allCountries')
  allLanguagesText$: Observable<string> = this.translation.translate('customTabProductDetails.allLanguages')
  productNameText$: Observable<string> = this.translation.translate('customTabProductDetails.productName')
  descriptionText$: Observable<string> = this.translation.translate('customTabProductDetails.descripText')
  documentText$: Observable<string> = this.translation.translate('customTabProductDetails.documentTxt')
  
  sub1: Subscription;
  constructor(private translation: TranslationService, private appService: AppService, private http: HttpClient, private productService: ProductDetailsService, private claimsearchService: ClaimsearchService, private ref: ChangeDetectorRef, private router: Router, private customerSpecificationService: CustomerSpecificationService, private userService: UserServiceService) {
    this.elements = [".technical-details", ".MSDS", ".PDS", ".Claims", ".customer-specifications", ".product-documents"];
    this.units = ["ML", "LT", "G", "KG", "QT", "UG6", "PT", "LB"];
    this.selectedUnit = "ML";
    this.firstMSDS = true;
    this.firstPDS = true;
    this.firstPDocument = true;
    this.firstCustSpec = true;
    this.firstClaim = true;
    this.product = new ProductModel();
    this.MSDS = undefined;
    this.PDS = new Array<ProductPdsModel>();
    this.pdfString = "";
    this.pdfStringPDS = "";
    this.filterCountry = "All countries";
    this.filterLanguage = "All languages";
    this.currentPage = 1;
    this.pageSize = 10;
    this.maxPages = 1;
    this.claims = new ClaimSearchProductModel();
    this.productdocumentsdata = new ProductDocument();
  }

  ngOnInit(): void {
    this.downlodBtnText$.subscribe(trans => {
      this.btnDownloadText = trans
      console.log("translated text::::::::::::", this.btnDownloadText)
    })
    this.sub1 = this.allCountriesText$.subscribe(item => {
      this.allCountriesText = item
      this.filterCountry = this.allCountriesText;
      console.log("translated text::::::::::::", this.allCountriesText)
    })
    this.allLanguagesText$.subscribe(lang => {
      this.allLanguagesText = lang
      this.filterLanguage = this.allLanguagesText;
      console.log("translated text::::::::::::", this.allLanguagesText)
    })
    this.productNameText$.subscribe(lang => {
      this.productNameText = lang
      
    })
    this.descriptionText$.subscribe(lang => {
      this.descriptionText = lang
      
    })
    this.documentText$.subscribe(lang => {
      this.documentText = lang
    
    })
    this.domainUrl = this.appService.getApiDomainUrl();
    this.userService.getUserDetails().then((user) => {
      this.userId = user["uid"];
      this.getProduct(user["uid"]).then(() => {
        this.product = this.productService.getProductData();
        this.categoryImage = this.product.categoryImage;
        this.packageTypeImage = this.product.packageTypeImage;
        this.ref.detectChanges();
        $("#category").html(this.product.categoryName);
        $("#densityAt15C").html("at 15 &deg;C " + this.product.densityAt15C + `<span *ngIf = ${this.product.densityAt15C}> kg/m3 </span>`);
        $("#densityAt60F").html("at 60 &deg;F " + this.product.densityAt60F + `<span *ngIf = ${this.product.densityAt60F}> lb/gal </span>`);
        $("#grossWeight").html("Gross " + this.product.grossWeight + `<span *ngIf = ${this.product.grossWeight}> kg </span>`);
        $("#maxHandlingTemperature").html("Handling " + this.product.maxHandlingTemperature);
        $("#maxStorageTemperature").html("Storage " + this.product.maxStorageTemperature);
        $("#minHandlingTemperature").html("Handling " + this.product.minHandlingTemperature);
        $("#minStorageTemperature").html("Storage " + this.product.minStorageTemperature);
        // $("#netWeight").html(this.product.netWeight);
        $("#netWeight").html("Net " + this.product.netWeight + `<span *ngIf = ${this.product.netWeight}> kg </span>`);
        $("#packageType").html(this.product.packageType);
        $("#recommendedRetestInterval").html(this.product.recommendedRetestInterval);
  
        let productNameBreak = this.product.name.split(' ');
        $("cx-page-slot.Summary > cx-product-images").html(`<hr>` + `<div class="titleOne">${productNameBreak[0]}</div>` + `<span class="titleTwo">${productNameBreak[1]}</span>`);
        $("cx-product-images").append(`<p class="productContent">${this.product.summary}.</p>`);
        $("cx-page-slot.Summary > cx-product-summary").html(`<div><h4>Request a sample</h4></div>`);
        $("cx-page-slot.Summary > cx-product-summary > div").append(`<hr>`);
        $("cx-page-slot.Summary > cx-product-summary > div").append(`<div><span>Size </span><input type="number"/> </div>`);
  
        // let unitDropdown = `<select name="selectedUnit" id="unit" class="form-control"></select>`;
        // $("cx-page-slot.Summary > cx-product-summary > div > div").append(unitDropdown);
        // var select = document.getElementById("unit");
  
      //   for(var i = 0; i < this.units.length; i++) {
      //     var opt = this.units[i];
      //     var el = document.createElement("option");
      //     el.textContent = opt;
      //     el.value = opt;
      //     select.appendChild(el);
      // }
  
        $("cx-page-slot.Summary > cx-product-summary > div").append(`
            <ng-template cxOutletRef="ProductAddToCartComponent" cxOutletPos="after">
              <div class="addWhishlist"></div>
            </ng-template>
        `);
      });
    })
    // load technical details
    
    
  }
  navigateToContact() {
    this.router.navigate(['/contact']);
  }
  //API calls
  async getProduct(user) {
    await this.productService.getProduct(user);
  }

  async getMSDS(code, userId) {
    await this.productService.getMSDS(code, userId);
  }

  async getMSDSPDF(materialNumber, dmskey, matrix) {
    await this.productService.getPDFMSDS(materialNumber, dmskey, matrix, this.userId);
  }

  async getPDS(name, userId) {
    await this.productService.getPDS(name, userId);
  }
  // async getProductDocuments(name) {
  //   await this.productService.getProductDocuments(name);
  // }

  async getPDSPDF(guid, userId) {
    await this.productService.getPDFPDS(guid, userId);
  }
  async getClaims(materialCode: string,userId) {
    await this.productService.getClaimsProduct(materialCode, userId);
  }

  // change tab
  change(n: number) {
    //MSDS tab
    if (n == 1) {
      if (this.firstMSDS) {
        this.loadMSDS();
        this.firstMSDS = false;
      }
    }
    //PDS tab
    else if (n == 2) {
      if (this.firstPDS) {
        this.loadPDS();
        this.firstPDS = false;
      }
    }
    //claims tab
    else if (n == 3) {
      if (this.firstClaim) {
        
        console.log("In claim tab:::::::::::::")
        // this.claimsearchService.changeClaims({"from": "product_detail_tab", "productCode": ""});
        // this.loadClaims();
        this.firstClaim = false;
        this.showClaims = true;
        this.ref.detectChanges();
        setTimeout(() => {
          // this.claimsearchService.currentClaims.subscribe((noResultClaim) => {
          //   this.noResultClaim = noResultClaim;
          //   this.ref.detectChanges();
          //   console.log("tab component:::::::",this.noResultClaim,noResultClaim)
          // })
          $(".loaderClaims").hide();
        },1000)
      }
    } else if(n == 4){
      if(this.firstCustSpec){
        this.getCustomerSpec();
        this.firstCustSpec = false;
      }
     
    }
    else if(n==5){
      if(this.firstPDocument){
        this.getProductDocuments(this.userId);
        this.firstPDocument = false;
      }
     
    }
    for (var i = 0; i < this.elements.length; i++) {
      if (i == n) {
        $("div" + this.elements[i]).show();
        $("span.tabhead" + this.elements[i]).addClass("active");
      }
      else {
        $("div" + this.elements[i]).hide();
        $("span.tabhead" + this.elements[i]).removeClass("active");
      }
    }
  }

  ngAfterViewInit() {
    $(document).ready(function () {
      const user = window.localStorage.getItem('spartacus⚿⚿auth');
      const json = JSON.parse(user);
      if (json.userId == "current") {
        $(".searchbox").show();
        $(".SiteLogin").show();
        $(".MiniCart").show();
        $(".NavigationBar").show();
      }
    });
  }

  loadPDFMSDS(materialNumber, dmskey, matrix, name, event) {
    $(event.currentTarget).html("Loading...");
    let component = $(event.currentTarget);
    this.getMSDSPDF(materialNumber, dmskey, matrix).then(() => {
      this.pdfString = this.productService.getPDFMSDSData();
      if (this.pdfString != "") {
        var downloadLink = document.createElement('a');
        downloadLink.href = "data:application/octet-stream;base64," + this.pdfString;
        downloadLink.download = matrix.trim() + " SDS.pdf";
        downloadLink.style.display = "none";
        document.body.appendChild(downloadLink);
        component.html(this.btnDownloadText);
        // $(event.currentTarget).html("Download");
        downloadLink.click();
        document.body.removeChild(downloadLink);
      }
      else {
       console.log("Error while retrieving document. Retry");
       component.html(this.btnDownloadText);
      }
    }).catch((e) => {
      console.log("Error while retrieving document. Retry::",e);
      component.html(this.btnDownloadText);
    });
  }

  loadPDFPDS(guid, name, component) {
    this.getPDSPDF(guid, this.userId).then(() => {
      this.pdfStringPDS = this.productService.getPDFPDSData();
      if (this.pdfStringPDS != "") {
        var downloadLink = document.createElement('a');
        downloadLink.href = "data:application/octet-stream;base64," + this.pdfStringPDS;
        downloadLink.download = (name ? name : this.product.name).trim().replace(" ", "-") + ".pdf";
        downloadLink.style.display = "none";
        document.body.appendChild(downloadLink);
        component.html(this.btnDownloadText);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      }
      else {
        console.log("Error while retrieving document. Retry");
        component.html(this.btnDownloadText);
      }
    }).catch((e) => {
      console.log("Error while retrieving document. Retry");
      component.html(this.btnDownloadText);
    });
  }

  getCountries(): Array<string> {
    return this.productService.getMSDSfilterCountry();
  }

  getLanguage(): Array<string> {
    return this.productService.getMSDSfilterLanguage();
  }

  loadMSDS() {
    $("#minus").prop("disabled", true);
    $("#plus").prop("disabled", false);
    this.getMSDS(this.product.materialCode, this.userId).then(() => {
      // get MDSD
      this.MSDS = this.productService.getMSDSData();
      this.msdsAttachmentList = this.MSDS.attachmentDetails;
      $(".loaderMSDS").hide();
      // no MSDS
      if (this.MSDS === undefined) {
        this.msdsNoResults = true;
        this.ref.detectChanges();
      }
      // display MSDS
      else {
        // show filters
        this.msdsNoResults = false;
        this.countries = [];
        this.countries = this.getCountries();
        if(this.countries.indexOf(this.allCountriesText) == -1) {
          this.countries.unshift(this.allCountriesText);
        } else {
          this.countries.splice(this.countries.indexOf(this.allCountriesText), 1);
          this.countries.unshift(this.allCountriesText);
        }
        this.languages = this.getLanguage();
        if(this.languages.indexOf(this.allLanguagesText) == -1) {
          this.languages.unshift(this.allLanguagesText);
        } else {
          this.languages.splice(this.languages.indexOf(this.allLanguagesText), 1);
          this.languages.unshift(this.allLanguagesText);
        }
        // this.languages.unshift(this.allLanguagesText);
        this.ref.detectChanges();
        
        this.maxPages = Math.round(this.MSDS.attachmentDetails.length / this.pageSize) < this.MSDS.attachmentDetails.length / this.pageSize ? Math.round(this.MSDS.attachmentDetails.length / this.pageSize) + 1 : Math.round(this.MSDS.attachmentDetails.length / this.pageSize);

        
        // display response attachmentDetails list
        this.displayMSDSRows();

      }
    }).catch(() => {
      $(".loaderMSDS").hide();
      
      this.msdsNoResults = true;
      this.ref.detectChanges();
    });
  }

  previousPageClickMsds() {
    this.currentPage = this.currentPage - 1;
    this.displayMSDSRows();
    if(this.currentPage == 1) {
      $("#minus").prop("disabled", true);
      $("#plus").prop("disabled", false);
    }
    if(this.currentPage < this.maxPages) {
      
      $("#plus").prop("disabled", false);
    }
  }
  nextPageClickMsds() {
    this.currentPage = this.currentPage + 1;
    this.displayMSDSRows();
    $("#minus").prop("disabled", false);
    if(this.currentPage == this.maxPages) {
      
      $("#plus").prop("disabled", true);
    }
  }
  selectCountry(event: any) {
    $("#minus").prop("disabled", true);
    $("#plus").prop("disabled", false);
    this.filterCountry = event.target.value;
    this.displayMSDSRows();
    this.currentPage = 1;
  }

  selectLanguage(event: any) {
    $("#minus").prop("disabled", true);
    $("#plus").prop("disabled", false);
    this.filterLanguage = event.target.value;
    this.displayMSDSRows();
    this.currentPage = 1;
  }

  displayMSDSRows() {
    // display response attachmentDetails list
    
    let tempCountryFilter = [];
    let tempLanguage = [];
    console.log("this.allCountriesText:::::::::::::",this.allCountriesText)
    this.MSDS.attachmentDetails.map((el) => {
      console.log("this.allCountriesText   this.allCountriesText")
      if(this.filterCountry == this.allCountriesText || el.country === this.filterCountry) {
        tempCountryFilter.push(el);
      }
    });
    tempCountryFilter.map((el) => {
      if(this.filterLanguage == this.allLanguagesText || el.language === this.filterLanguage) {
        tempLanguage.push(el);
        
      }
    })
    this.msdsAttachmentList = tempLanguage;
    this.maxPages = Math.round(this.msdsAttachmentList.length / this.pageSize) < this.msdsAttachmentList.length / this.pageSize ? Math.round(this.msdsAttachmentList.length / this.pageSize) + 1 : Math.round(this.msdsAttachmentList.length / this.pageSize);
    this.displayMsdsAttachmentList = tempLanguage.slice(((this.currentPage - 1) * this.pageSize) , (this.currentPage * this.pageSize));
    this.ref.detectChanges();
    if(this.maxPages == 1) {
      $("#minus").prop("disabled", true);
      $("#plus").prop("disabled", true);
    }
    
    
  }
  downloadMSDS(materialNumber, dmskey, matrix, name, event) {
    console.log("materialNumber, dmskey, matrix, name",materialNumber, dmskey, matrix, name);
    this.loadPDFMSDS(materialNumber, dmskey, matrix, name, event);

  }

  loadClaims() {
    this.getClaims(this.product.materialCode, this.userId).then(() => {
      this.claims = this.productService.getClaimsProductData(this.userId);
      $(".loaderClaims").hide();
      if (this.claims == undefined || this.claims.claims == undefined) {
        // var noResult =
        //   "<div class='container'> \
        //     <div class='row'> \
        //       <div class='col-md-12 noResult'> \
        //         <h1> No results </h1> \
        //       </div> \
        //     </div>";
        // $("div.claims-container").append(noResult);
        this.showClaims = false;
        this.ref.detectChanges();
      }
      else {
        this.showClaims = true;
        // this.ref.detectChanges();
        // $("table.clamis").show();
        // //print out claims
        
        // this.claims.claims.forEach(claim => {
        //   var treatRate = claim.treatRate != undefined ? claim.treatRate : "-";
        //   $(".treatRate").append("<td class='claimsElementPDP'> " + treatRate + "</td>");
        // });

        // this.claims.claims.forEach(claim => {
        //   $(".boosters").append("<td class='claimsElementPDP'> - </td>");
        // });

        // this.claims.claims.forEach(claim => {
        //   var baseStock = claim.baseStocks != undefined ? claim.baseStocks : "-";
        //   $(".baseStock").append("<td class='claimsElementPDP'> " + baseStock + "</td>");
        // });
        // this.claims.claims.forEach(claim => {
        //   var viscosityModifier = claim.viscosityModifiers != undefined ? claim.viscosityModifiers : "-";
        //   $(".viscosityModifier").append("<td class='claimsElementPDP'> " + viscosityModifier + "</td>");
        // });
        // this.claims.claims.forEach(claim => {
        //   var ppd = claim.ppdIdName != undefined ? claim.ppdIdName : "-";
        //   $(".ppd").append("<td class='claimsElementPDP'> " + ppd + "</td>");
        // });
        // this.claims.claims.forEach(claim => {
        //   var grade = claim.gradeName != undefined ? claim.gradeName : "-";
        //   $(".grade").append("<td class='claimsElementPDP'> " + grade + "</td>");
        // });
        // this.claims.claims.forEach(claim => {
        //   var oil = claim.oilCode != undefined ? claim.oilCode : "-";
        //   $(".oil").append("<td class='claimsElementPDP'> " + oil + "</td>");
        // });
      }
    }).catch((error) => {
      console.log("error in claim tab:::::::::::::",error);
      $(".loaderClaims").hide();
      // var noResult =
      //   "<div class='container'> \
      //       <div class='row'> \
      //         <div class='col-md-12 noResult'> \
      //           <h1> No results </h1> \
      //         </div> \
      //       </div>";
      // $("div.claims-container").append(noResult);
      this.showClaims = false;
      this.ref.detectChanges();
    });
  }

  loadPDS() {
    this.getPDS(this.product.name, this.userId).then(() => {
      this.PDS = this.productService.getPDSData();
      $(".loaderPDS").hide();

      if (this.PDS === undefined || this.PDS.length==0 ) {
        // var noResult =
        //   "<div class='container'> \
        //     <div class='row'> \
        //       <div class='col-md-12 noResult'> \
        //         <h1> No results </h1> \
        //       </div> \
        //     </div>";
        // $("div.PDS-container").append(noResult);
        this.noResultPDS = true;
        this.ref.detectChanges();
      }
      // display PDS
      else {
        // display base table
        var baseTable =
          "<table class='PDS styled'> \
              <thead class='headerPDStable'> \
              <th class='titlePDS' style= 'width: 0;'></th>\
                  <th class='titlePDS'>"+this.productNameText+"</th> \
                  <th class='titlePDS'>"+this.descriptionText+"</th> \
                  <th class='titlePDS'>"+this.documentText+"</th> \
              </thead> \
              <tbody> \
              </tbody> \
          </table>";
        $("div.PDS-container").append(baseTable);

        var i = 0;
        this.PDS.forEach(element => {
          var background = i % 2 != 0 ? "style='background-color:white;'" : "";
          var buttonPDS =
            "<tr " + background + " > \
            <td class='table-icon' style= 'width: 0;'><img src='assets/pdf.svg'</td> \
                <td id='productDocument' data-label='Product Document' class='elementPDS-D __pdsUppercase'>" + this.product.name + "&nbsp;</td> \
                <td id='productDescription' class='elementPDS' data-label='Product Description'>" + element.SECHDRTXT + "&nbsp;</td> \
                <td><button class='btn btn-primary btn-plain PDSbutton downloadButton' guid='"+ element.GUID + "' name='" + element.SECHDRTXT + "'>"+ this.btnDownloadText +"</button></td> \
            </tr>";
          $("table.PDS > tbody").append(buttonPDS);
          i++;
        });

        // call to download PDF
        $(".PDSbutton").click((data) => {
          var guid = $(data.currentTarget).attr('guid');
          var name = $(data.currentTarget).attr('name');
          $(data.currentTarget).html("Loading...");
          this.loadPDFPDS(guid, name, $(data.currentTarget));
        });
      }
    }).catch(() => {
      $(".loaderPDS").hide();
      // var noResult =
      //   "<div class='container'> \
      //       <div class='row'> \
      //         <div class='col-md-12 noResult'> \
      //           <h1> No results </h1> \
      //         </div> \
      //       </div>";
      // $("div.PDS-container").append(noResult);
      this.noResultPDS = true
      this.ref.detectChanges();
    });
  }

  getProductDocuments(userId) {
    this.productService.getProductDocuments(userId).then((response) => {
     // this.productdocumentsdata = this.productService.getProductDocuments();
      console.log(response)
      if(response){
        $(".loaderProductDocument").hide();
        this.productDocDetails = response['Details']
      }
      if(response['Details'][0]==null){
        this.noResultProductDoc=true
        this.ref.detectChanges();
      }else{
        this.noResultProductDoc=false
        this.ref.detectChanges();
      }
      // display PDS
     
    }).catch((e) => {
      this.noResultProductDoc=true
      $(".loaderProductDocument").hide();
      this.ref.detectChanges();
     console.log(e)
    });
  }
 async PDocDownload(pDetail, event){
  //  this.prodDownloadBtnTxt = "Loading...";
   let component = event.currentTarget;
   $(component).html("Loading...");
   this.downloadProductDocument(pDetail, event.currentTarget) ;
    
  }

  downloadProductDocument(pDetail, component) {
    return this.http
      .get(this.appService.getApiUrl() + `/users/${this.userId}/products/document/sharepointDoc?documentId=`+ pDetail.docID)
      .subscribe(response => {
        try{
          //this.pdfStringPDFPDS = response['ZIM_PDS_BASE64_DOC_RFC.Response']['EV_BASE64'];
          // resolve(response);
          if(response){
            console.log(response)
            var downloadLink = document.createElement('a');
            downloadLink.href = "data:application/octet-stream;base64," + response['xmlBytes'];
            downloadLink.download = response['fileName'].trim().replace(" ", "-");
            downloadLink.style.display = "none";
            document.body.appendChild(downloadLink);
            //component.html("Download");
            $(component).html(this.btnDownloadText);
            downloadLink.click();
            document.body.removeChild(downloadLink);
            // this.prodDownloadBtnTxt = "Download";
            
          } else {
            this.noResultProductDoc=true;
            $(component).html(this.btnDownloadText);
          }
        }
        catch(e){
          $(component).html(this.btnDownloadText);
          this.noResultProductDoc=true;
          // reject(e);
        }
        // $(component).html("Download");
      });
  }

  goToCustomerSpecificationLink() {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['/customer-specification',this.product.formulaNumber,this.product.name])
    );

    window.open(url, '_blank');
  }

  getCustomerSpec() {
    this.customerSpecificationService.getCustomerSpec(this.product.formulaNumber, this.product.name, this.userId).then((res) => {
      if(res && res["CustomerSpec"] && res["CustomerSpec"]["CustomerSpecification"] && res["CustomerSpec"]["CustomerSpecification"]["customerName"]) {
        this.customerSpecificationNoResults = false;
        $(".loaderCustomerSpec").hide();
        this.customerSpecificationRecordCount = 1;
        this.ref.detectChanges();
      } else {
        this.customerSpecificationNoResults = true;
        $(".loaderCustomerSpec").hide();
        this.customerSpecificationRecordCount = 0;
        this.ref.detectChanges();
      }
    }).catch((err) => {
      $(".loaderCustomerSpec").hide();
      this.customerSpecificationNoResults = true;
      this.customerSpecificationRecordCount = 0;
      this.ref.detectChanges();
      console.log("Error in customer specification::::::",err);
    })
  }
  
  onChange(value) {
    this.change(value);
  }

  ngOnDestroy (){
    this.sub1.unsubscribe();
  }
}
