
export class ClaimSearchDetailModel {
    ashValue: string
    baseStocks: string
    boosters: string
    claimSpecs: Array<
        {
            claimOrg: string
            claimSpecId: string
            claimType: string
        }>
    claimsSpecsCount: number
    engines: string
    fuelTypes: string
    gradeName: string
    oilCode: string
    pibs: string
    ppdIdName: string
    productName: string
    segmentName: string
    solvents: string
    tbn: string
    treatId: string
    treatRate: string
    viscosityModifiers: string
}