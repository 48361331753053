<!-- CLAIM SEARCH COMPONENT -->

<div class="container-fluid claims" > 
  <div class="col-md-12 infclaim">
    <div class="col-md-3"></div>
    <div class="infclaimtext col-md-5">
        <h1 class="claimTitle"><strong>CLAIMS</strong> SEARCH</h1>
        <p class="claimText">Pinpoint the precise product formulation matching a claim with our powerful</p>
    </div>
    <div class="infclaimtext col-md-4">
      <a (click) = "navigateToClaimList()" class="claimBannerLink cursorPointer">
        <div class="button">
            <div class="col-md-9" style="padding-left: 0px;">
              START
            </div>
            <div class="col-md-5">
              <i class="fa fa-chevron-right" aria-hidden="true" style="color: #37424A;"></i>
            </div>
        </div>
      </a>
    </div>
    <div class="col-md-2"></div>
  </div>
</div>

<!-- CARDS COMPONENTS -->

<div class="container-fluid services" > 
  <div class="container infservices">
    <div class="row">
        <div class="servicewrp col-md-3" style="padding: 5px;" *ngFor="let button of buttons">
          <div class="service">
            <div class="row" style="margin-bottom: 20px;">
              <div class="col-md-12">
                <img src="{{button.image}}" style="width: 100px; height: 50px;"/>
              </div>
            </div>
            <div class="row" style="margin-bottom: 15px;">
              <div class="col-md-12">
                <p class="pCard">
                  <span style="font-weight: 400;">{{ button.title }}</span><br>
                  {{ button.text }}
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <a href="{{button.href}}"><img src="../../assets/arrow.png" style="max-height: 70%;"/></a>
              </div>
            </div>
          </div>
        </div>
    </div>
  </div>
</div>