import { Component, OnInit, Inject, HostListener } from '@angular/core';
import { Location } from '@angular/common';
import { ProductDetailsService } from '../APIcall/product-details.service';
import { Router } from '@angular/router';
import { AuthService } from '@spartacus/core';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss'],
  providers: [
      { provide: Window, useValue: window }  
  ],
})
export class HelpComponent implements OnInit {

  isUserLoggedIn: boolean = false;
  constructor(private productDetailsService: ProductDetailsService, private location: Location, private router: Router, @Inject(Window) private window: Window,private authService: AuthService) { }

  ngOnInit(): void {
    this.productDetailsService.changeBreadcrumb({"breadcumTitle" : "help", "categoryName": "", "productName": ""});
    this.authService.isUserLoggedIn().subscribe((isLoggedIn) => {
      this.isUserLoggedIn = isLoggedIn;
    })
  }

  title = 'Help & FAQs';

  backClicked() {
    this.location.back();
  }
  navigateToLogin() {
    this.router.navigate(['/login']);
  }
  navigateToChangePassword() {
    this.router.navigate(['/my-account/update-password']);
  }
  navigateToPersonalInfo() {
    this.router.navigate(['/my-account/update-profile']);
  }
  scrollTo(element: any): void {
      (document.getElementById(element) as HTMLElement).scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
    }
    naviateToForgotPassword() {
      this.router.navigate(['/login/forgot-password']);
    }
    navigateToContact() {
      this.router.navigate(['/contact']);
    }
}
