import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ProviderModel } from './provider.model';
import { AppService } from '../app.services';

@Injectable({
  providedIn: 'root'
})
export class UserServiceService {

  private user: ProviderModel = new ProviderModel();

  constructor(private http: HttpClient, private appService: AppService) { }

  getUserData(): ProviderModel{
    return this.user;
  }

  async getUser() {
    let userId:any = "";
    if(this.appService.getAsmMode() == "true") {
      userId = this.appService.asmUserId;
    } else {
      userId = "current";
    }
    return await new Promise((resolve, reject) => {
      return this.http
            .get(this.appService.getApiUrl() + `/orgUsers/${userId}`)
            .subscribe(response => {
                this.user.name = response['name'];
                this.user.firstName = response['firstName'];
                this.user.lastName = response['lastName'];
                this.user.email = response['uid'];
                resolve(true);
            });
    });
  }
  async getUserDetails() {
    let userId:any = "";
    if(this.appService.getAsmMode() == "true") {
      userId = this.appService.asmUserId;
    } else {
      userId = "current";
    }
    return await new Promise((resolve, reject) => {
      return this.http
        .get(this.appService.getApiUrl() + `/orgUsers/${userId}`)
        .subscribe((response) => {
            
            resolve(response);
        },(error) => {
          reject(error);
        });
    });
  }

  async getUserProfile(userEmail) {
    let userId:any = "";
    if(this.appService.getAsmMode() == "true") {
      userId = this.appService.asmUserId;
    } else {
      userId = userEmail;
    }
    return await new Promise((resolve, reject) => {
      return this.http
        .get(this.appService.getApiUrl() + `/users/${userId}`)
        .subscribe((response) => {
            
            resolve(response);
        },(error) => {
          reject(error);
        });
    });
  }

  async getAuthTokenForForgotPassword() {
    return await new Promise((resolve, reject) => {
      return this.http
        .post(this.appService.getApiDomainUrl() + "/authorizationserver/oauth/token?client_id=mobile_android&client_secret=secret&grant_type=client_credentials",{})
        .subscribe((response) => {
          try{
            resolve(response);
          }catch(e){
            reject(e)
          }
            
        });
    });
  }
  async sendForgotPasswordLink(token,userId) {
    return await new Promise((resolve, reject) => {
      const headers = new HttpHeaders().set('Authorization', `Bearer ${token['access_token']}`);
      return this.http
        .post(this.appService.getApiUrl() + `/forgottenpasswordtokens?userId=${userId}`,{},{headers : headers})
        .subscribe(response => {
          try{
            console.log("sendForgotPasswordLink response::::::::",response);
            resolve(true);
          }catch(err) {
            console.log("error in sendForgotPasswordLink::::::::",err)
            reject(err);
          }
        });
    });
  }

  async loginUser(userEmail, password) {
    return await new Promise((resolve, reject) => {
      return this.http
        .post(this.appService.getApiDomainUrl() + `/authorizationserver/oauth/token?username=${userEmail}&password=${password}&client_id=mobile_android&client_secret=secret&grant_type=password`,{})
        .subscribe((response) => {
          try{
            resolve(response);
          }catch(e){
            reject(e)
          }
            
        });
    });
  }

  async updatePassword(user, newPwd, oldPwd) {
    return await new Promise((resolve, reject) => {
      return this.http
        .put(this.appService.getApiDomainUrl() + `/users/${user}/password?userId=${user}&new=${newPwd}&old=${oldPwd}`,{})
        .subscribe((response) => {
          try{
            resolve(response);
          }catch(e){
            reject(e)
          }
            
        });
    });
  }

  async updateProfile(user, payload) {
    return await new Promise((resolve, reject) => {
      return this.http
        .patch(this.appService.getApiUrl() + `/users/${user}`,payload)
        .subscribe((response) => {
          try{
            resolve(response);
          }catch(e){
            reject(e)
          }
            
        });
    });
  }

}
