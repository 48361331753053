import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AuthService, CmsBannerComponent, CmsComponent, CmsNavigationComponent, TranslationService } from '@spartacus/core';
import {Router} from "@angular/router"
import { NavigationNode, CmsComponentData, NavigationService } from '@spartacus/storefront';
import { Observable, Subscription } from 'rxjs';
declare var $: any;

import { UserServiceService } from '../APIcall/user-service.service';

@Component({
  selector: 'app-custom-header-links',
  templateUrl: './custom-header-links.component.html',
  styleUrls: ['./custom-header-links.component.scss']
})
export class CustomHeaderLinksComponent implements OnInit {
  ComponentData: any;
  claimsTitle: string ='';
  claimsUrl: any ='';
  signOutTitle: string ='';
  signOutUrl: any ='';
  displayUserName: string = '';
  myAccount: any = [];
  myAccountTitle: string = '';
  node$: Observable<NavigationNode> = this.service.getNavigationNode(this.componentData.data$);
  data$: Observable<CmsNavigationComponent> = this.componentData.data$;
  subscription: Subscription;
  transLate$: Observable<string> = this.translation.translate('customHeaderLink.claimSearch')
  transLate$$: Observable<string> = this.translation.translate('customHeaderLink.signOut')
  transLate$$$: Observable<string> = this.translation.translate('customHeaderLink.myAccount')
  constructor(private translation: TranslationService, private ref: ChangeDetectorRef, private userService: UserServiceService, protected component: CmsComponentData<CmsComponent>, protected service: NavigationService, protected componentData: CmsComponentData<CmsNavigationComponent>, private router: Router, public authService: AuthService) { }

  ngOnInit(): void {
    //  this.component.data$.subscribe(data => {
    //    this.ComponentData = data;
    //    console.log(this.ComponentData);
    // })
    this.subscription = this.node$.subscribe(links => {
      console.log(links)
      links.children.map((item) =>{
        this.transLate$.subscribe(trans => {
          this.transLate$$.subscribe(aa => {
            this.transLate$$$.subscribe(bb => {
        if(item['title']== trans){
          this.claimsTitle = item['title'];
          this.claimsUrl = item['url'];
          this.ref.detectChanges(); 
        }else if(item['title']== aa){
          this.signOutTitle = item['title'];
          this.signOutUrl = item['url'];
          this.ref.detectChanges(); 
        }
        else if(item['title'] == bb){
          this.myAccountTitle = item["title"];
          this.myAccount = item['children'];
          this.ref.detectChanges(); 
        }
      })
    })
    })
      })
    });

    this.userService.getUserDetails().then((user)=>{
      this.displayUserName = `${user['firstName']} ${user['lastName']}`;
      this.ref.detectChanges(); 
    }).catch((error)=>{
      console.log("user not printed", error)
    })
  }
  headerLinksNav(url) {
    console.log("url::::::::::::::::::::::;",url)
    if(url == '/logout') {
      console.log("in if cond:::::::::::",url)
      localStorage.removeItem('order');
      localStorage.removeItem('checkoutCart');
      localStorage.removeItem('userEmail');
      this.authService.coreLogout();
      // this.router.navigate(['/logout']);
    }else{
      this.router.navigate([url]);
    }
    
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
