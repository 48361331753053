import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService, TranslationService } from '@spartacus/core';
import { Observable, Subscription } from 'rxjs';
import { environment } from '../../environments/environment';

import { UserServiceService } from '../APIcall/user-service.service';
import { AppService } from '../app.services';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  registerForm: UntypedFormGroup;
  emailPattern = "^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$";
  submitted = false;
  hide : boolean = true;

  hideBtnText: string = '';
  passShowText: string = '';
  passHideText: string = '';
  isUserLoggedIn: boolean = true;
  subscription: Subscription;
  passShowText$: Observable<string> = this.translation.translate('customLogin.show')
  passHideText$: Observable<string> = this.translation.translate('customLogin.hide')
  constructor(private translation: TranslationService, private formBuilder: UntypedFormBuilder, private userService: UserServiceService, private router: Router, private authService: AuthService, private appService: AppService) { }

  ngOnInit(): void {

    this.registerForm = this.formBuilder.group({
      Email: ['', [Validators.required, Validators.email, Validators.pattern(this.emailPattern)]],
      Password:['',[Validators.required]]
  })

  this.passShowText$.subscribe(trans => {
    this.passShowText = trans
    this.hideBtnText = trans
  })
  this.passHideText$.subscribe(trans => {
    this.passHideText = trans
  })
  
 }

 toggleHide() {
   this.hide = !this.hide;
   if(!this.hide){
this.hideBtnText = this.passHideText;
   }else{
     this.hideBtnText = this.passShowText;
   }
 }

 get fval() {
  return this.registerForm.controls;
  }

  // get fvals(){
  //   return this.registerForm.controls;
  // }

  onSubmit(){
  console.log("Login Values +++++",this.registerForm.value)
    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    }
     this.authService.loginWithCredentials(this.registerForm.value.Email, this.registerForm.value.Password).then((res) => {
      console.log("logged in successfully:::::::::::")
      this.authService.isUserLoggedIn().subscribe((isLoggedIn) => {
        console.log("isLoggedIn::::::::",isLoggedIn)
        this.isUserLoggedIn = isLoggedIn;
        // this.appService.userId = this.registerForm.value.Email;
        localStorage.setItem("userEmail", this.registerForm.value.Email);
        if(isLoggedIn) {
          for(let element of window.location.pathname.split("/")){
            if(element == 'login'){
              console.log("1111111111111")
              this.router.navigate(['/']);
            }
          }
          // this.router.navigate(['/']);
        } else {
          if(window.location.pathname.indexOf('/login/pw/change') == -1) {
            this.router.navigate(['/login']);
          }
          // this.router.navigate(['/login']);
        }
        
      })
    }).catch((err) => {
      console.log("Error in login::::::::",err);
    })
    // this.userService.loginUser(this.registerForm.value.Email, encodeURIComponent(this.registerForm.value.Password)).then((token) => {
    //   console.log("Logged in user token:::::::",token)
    //   let formattedToken = {
    //     "token":{"access_token":token['access_token'],"granted_scopes":[token['scope']],"access_token_stored_at":"","expires_at":token['expires_in'],"token_type":token['token_type']},"userId":"current"}
    //   localStorage.setItem('spartacus⚿⚿auth', JSON.stringify(formattedToken));
    //     this.router.navigate(['/']);
    // }).catch((err) => {
    //   console.log("error in login component::::::::;",err)
    // })
  // alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.registerForm.value))

  }

  naviateToForgotPassword() {
    this.router.navigate(['/login/forgot-password']);
  }

  // navigateTermsAndConditions() {
  //   this.router.navigate(['/terms-and-conditions']);
  // }
  navigateTermsAndConditions() {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['/terms-and-conditions'])
    );

    window.open(url, '_blank');
  }
  // ngOnDestroy() {
  //   this.subscription.unsubscribe();
  // }
}
  