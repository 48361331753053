export class ClaimSearchModel {
    breadcrumbs : [
        {
            facetCode : string
            facetName : string
            facetValueCode : string
            facetValueName : string
            removeQuery : {
                query : {
                    value : string
                }
                url : string
            }
        }
    ]
    claims : [
        {
            ashValue: string
            baseStocks: string
            boosters: string
            claimsSpecsCount: number
            engines: string
            fuelTypes: string
            gradeName: string
            oilCode: string
            pibs: string
            ppdIdName: string
            productCode: string
            productName: string
            segmentName: string
            solvents: string
            tbn: string
            treatId: string
            treatRate: string
            viscosityModifiers: string
        }
    ]
    currentQuery : {
        query : {
            value : string
        }
        url : string
    }
    facets : Array<
        {
            category : boolean
            multiSelect : boolean
            name : string
            priority : number
            values : [
                {
                    count : number
                    name : string
                    query : {
                        query : {
                            value : string
                        },
                        url : string
                    },
                    selected: false
                }
            ]
            visible : boolean
        }
    >
    freeTextSearch : string
    pagination : {
        currentPage: number
        pageSize : number
        totalPages : number
        totalResults : number
    }
    sorts : [/* TODO */]
}