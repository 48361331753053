<div class="container">
    <div class="row text-center">
        <div class="col">
            <h3><strong>{{'customCarousel.product' | cxTranslate}}</strong>{{'customCarousel.showcase' | cxTranslate}}</h3>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <owl-carousel-o [options]="customOptions" class="owl-carousel-wrapper">
                <ng-container *ngFor="let product of carouselComponentList; let i = index;">
                  <ng-template carouselSlide [id]="product.uid">
                    <div class="card col p-0 shadow-none border-0">
                      <img class="card-img-top" src="{{ product.image }}" alt="{{ product.title }}">
                      <div class="card-body" style="background-image:url('{{ product.bottomSectionImage }}');">
                        <h5 class="card-eyebrow">{{ product.title }}</h5>
                        <h4 class="card-title"><a href="{{product.url}}" target="_blank" class="stretched-link">{{ product.subTitle }}</a></h4>
                        <p class="card-text">{{ product.text }}</p>
                      </div>
                    </div>
                  </ng-template>
                </ng-container>
            </owl-carousel-o>
        </div>
    </div>
</div>