import { Component, OnInit } from '@angular/core';
import { ProductDetailsService } from '../APIcall/product-details.service';

@Component({
  selector: 'app-namepage',
  templateUrl: './namepage.component.html',
  styleUrls: ['./namepage.component.scss']
})
export class NamePageComponent implements OnInit {

  constructor(private productDetailsService: ProductDetailsService) { }

  ngOnInit(): void {
    this.productDetailsService.changeBreadcrumb({"breadcumTitle" : "page-name", "categoryName": "", "productName": ""});
  }
  
  title = 'Page Name';

}
