<div class="container">
    <div *ngIf="!isEmptyCart" class="custom-cart">
        <div class="row">
            <div class="col-12">
                <h2 class="border-bottom">{{'customCartComponent.samples' | cxTranslate}}</h2>
            </div>
        </div>
        <div class="row cart-results">
            <div class="col-12">
                <div class="card-headings d-none d-md-flex">
                    <div class="label-group first">
                        <label>{{'customCartComponent.product' | cxTranslate}}</label>
                    </div>
                    <div class="label-group second">
                        <div class="spacer"></div>
                        <label>{{'customCartComponent.quantityRequest' | cxTranslate}}</label>
                        <label>{{'customCartComponent.remove' | cxTranslate}}</label>
                    </div>
                </div>
                <!-- PRODUCTS -->
                <div class="card" *ngFor="let cartDetail of cartDetailsList">
                    <div class="card-header">
                        <div class="card-icon">
                            <img (click)="redirectPDP(cartDetail.url)" src="{{cartDetail.categoryImage}}" alt="" role="button">
                        </div>
                    </div>
                    <div class="card-body">
                        <h4 (click) = "redirectPDP(cartDetail.url)" role="button">{{cartDetail.productName}}</h4>
                        <div class="cta">
                            <div class="quantity">{{cartDetail.infineumQuantity + " " + cartDetail.unit}}</div>
                            <div class="divider d-none d-lg-block"></div>
                            <button (click) = "redirectPDP(cartDetail.url)" class="btn btn-ghost-inverse btn-plain w-auto" type="button">{{'customCartComponent.change' | cxTranslate}}</button>
                        </div>
                        <div class="delete">
                            <img (click) = "deleteCart(cartDetail.cartId, cartDetail.entryNumber)" src="/assets/cart-delete.svg" role="button">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <label class="__cart-footer-title">{{'customCartComponent.youCanAddMore' | cxTranslate}} <a (click)="redirectHome()" role="button">{{'customCartComponent.continue' | cxTranslate}} <img src="/assets/Arrow.svg" width="14"></a></label>
                <div class="__cart-footer-text">{{'customCartComponent.infineumRequest' | cxTranslate}}</div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12 col-md-12 cta">
                <button (click)="redirectCheckout()" type="button" class="btn btn-primary">{{'customCartComponent.requestSamples' | cxTranslate}}</button>
            </div>
        </div><!-- .row -->
    </div><!-- .custom-cart -->
</div><!-- container -->

<!-- OLD CODE --> 
<!--       
<div *ngIf="!isEmptyCart" class="container __cart-container">
    <div class="row">
        <div class="col-lg-12 col-md-12">
            <label class="__cart-title">{{'customCartComponent.samples' | cxTranslate}}</label>
            <hr class="__cart-hr">
        </div>
    </div>
    <div class="row">
        <div class="col-lg-6 col-md-6">
            <label class="__cart-sub-title">{{'customCartComponent.product' | cxTranslate}}</label>
        </div>
        <div class="col-lg-4 col-md-4">
            <label class="__cart-sub-title">{{'customCartComponent.quantityRequest' | cxTranslate}}</label>
        </div>
        <div class="col-lg-2 col-md-2">
            <label class="__cart-sub-title">{{'customCartComponent.remove' | cxTranslate}}</label>
        </div>
    </div>
        <div *ngFor="let cartDetail of cartDetailsList" class="__cart-list">
    <div  class="row align-items-center">
        <div class="col-lg-2 col-md-2">
            <div class="__cart-imag-bg">
            <img (click) = "redirectPDP(cartDetail.url)" style="cursor: pointer;" class="__cart-image" src="{{cartDetail.categoryImage}}" alt="">
            </div>
        </div>
        <div class="col-lg-4 col-md-4">
            <label (click) = "redirectPDP(cartDetail.url)" style="cursor: pointer;" class="__cart-section-title">{{cartDetail.productName}}</label>
        </div>
        <div class="col-lg-2 col-md-2">
            <label class="__cart-section-qty">{{cartDetail.infineumQuantity + " " + cartDetail.unit}}</label>
        </div>
        
        <div class="col-lg-2 col-md-2">
            <img class="__cart-line-img" src="assets/Line-cart.png">
            <button (click) = "redirectPDP(cartDetail.url)" style="cursor: pointer;" class="__cart-section-btn" type="button">{{'customCartComponent.change' | cxTranslate}}</button>
        </div>
        <div class="col-lg-2 col-md-2">
            <img (click) = "deleteCart(cartDetail.cartId, cartDetail.entryNumber)" class="__cart-section-icon" src="assets/cart-delete.jpg">
        </div>
</div>
</div>
    <div class="row">
        <div class="col-lg-12 col-md-12">
            <label class="__cart-footer-title">{{'customCartComponent.youCanAddMore' | cxTranslate}} <a (click)="redirectHome()" style="cursor:pointer">{{'customCartComponent.continue' | cxTranslate}} <img src="../../assets/small-arrow.png"></a></label>
            <span class="__cart-footer-text">{{'customCartComponent.infineumRequest' | cxTranslate}}</span>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-12 col-md-12">
            <button (click)="redirectCheckout()" type="button" class="cart-footer-btn">{{'customCartComponent.requestSamples' | cxTranslate}}
                <i aria-hidden="true" class="fa fa-chevron-right __footer-btn-right-arrow"></i>
            </button>
        </div>
    </div>
</div>-->

<!-- Empty cart template -->
<div class="container-fluid justify-content-center bg-white search-footer no-results" *ngIf="isEmptyCart">
    <div class="row">
        <div class="col">
            <div class="card card-info bg-white box-shadow-none border-0 text-center pt-0 m-auto">
                <div class="card-body">
                    <div class="card-icon mb-4">
                        <img src="/assets/Warning.svg">
                    </div>
                    <h2 class="h1 border-top-blue m-auto color-black"><span class="text-bold">{{'customCartComponent.empty' | cxTranslate}}</span></h2>
                    <p class="pb-4">{{'customCartComponent.noSamplesAdded' | cxTranslate}}</p>
                    <div class="cta d-flex justify-content-center">
                        <button (click)="redirectWishlist()" class="btn btn-primary btn-ghost-inverse" type="button">{{'customCartComponent.viewSavedItems' | cxTranslate}}</button>
                        <button (click)="redirectHome()" class="btn btn-primary" type="button">{{'customCartComponent.contineBroCaps' | cxTranslate}}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Empty cart template -->
<!--
<div *ngIf="isEmptyCart" class="container">
    <div class="cart">
        <div class="cart-body __cart-body-empty-cart">
            <img src="assets/cart-empty.png">
            <hr class="__cart-empty-hr">
            <label class="cart-empty-title">{{'customCartComponent.empty' | cxTranslate}}</label><br>
            <span class="cart-empty-text">{{'customCartComponent.noSamplesAdded' | cxTranslate}}</span>
            <br>
            <button (click)="redirectWishlist()" class="view-saved-item-btn" type="button">{{'customCartComponent.viewSavedItems' | cxTranslate}}
                <i aria-hidden="true" class="fa fa-chevron-right __btn-right-arrow"></i>
            </button>
            <button (click)="redirectHome()" class="contineu-browesing-btn" type="button">{{'customCartComponent.contineBroCaps' | cxTranslate}}
                <i aria-hidden="true" class="fa fa-chevron-right __btn-right-arrow"></i>
            </button>
        </div>
    </div>
</div>
-->