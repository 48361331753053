import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-checkout-order-confirmation',
  templateUrl: './checkout-order-confirmation.component.html',
  styleUrls: ['./checkout-order-confirmation.component.scss']
})
export class CheckoutOrderConfirmationComponent implements OnInit {

  cartDetails: any = [];
  checkoutDetails: any = {};
  constructor(private router: Router) { }

  ngOnInit(): void {
    let orderConfirmationData = localStorage.getItem('order');
    let cartData = localStorage.getItem('checkoutCart');

    if(orderConfirmationData) {
      this.checkoutDetails = JSON.parse(orderConfirmationData);
      this.cartDetails = JSON.parse(cartData);
    } else {
      this.router.navigate(['/']);
    }

    console.log("order confirmation Data:::::::::::",orderConfirmationData);
    console.log("order cartData Data:::::::::::",cartData);

  }

  navigateToHome() {
    this.router.navigate(['/']);
  }
  navigateOrderDetailsPage() {
    this.router.navigate(['/my-account/order'], { queryParams: { code: this.checkoutDetails.orderNo, type: "sample" } });
  }
  

}
