export class ProductModel {
    categoryName: string;
    code : string;
    densityAt15C : string;
    densityAt60F : string;
    description : string;
    formulaNumber : string;
    grossWeight : string;
    maxHandlingTemperature : string;
    maxStorageTemperature : string;
    minHandlingTemperature : string;
    minStorageTemperature : string;
    multidimensional : boolean;
    name : string;
    netWeight : string;
    numberOfReviews : string;
    packageType : string;
    materialCode : string;
    summary: string;
    recommendedRetestInterval: string;
    packageTypeImage: any;
    categoryImage: any;
    globalCommodityCode: string;
}