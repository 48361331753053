import { Injectable, Injector } from '@angular/core';
import { retry, catchError, tap } from 'rxjs/operators';

import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse,
  HttpHeaders
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { AppService } from '../app.services';
import { InterceptorUtil, USE_CLIENT_TOKEN } from '@spartacus/core';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  modifiedUrl: any = "";
  constructor( private inj: Injector ) { }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
   
    
     const appService = this.inj.get(AppService);

    // appService.getUserFromAsm();

    if (request.url.indexOf('/infineumwebservices/v2/infineum/') > 0) {
      // console.log('appliction url running')
      let authToken;
      if((request.url.indexOf('userIdPlaceholder') != -1)){
        let reqUrl = request.url;
        this.modifiedUrl = reqUrl.replace('userIdPlaceholder', appService.getUserId());
      } else if((request.url.indexOf('/infineum/products/') != -1)){
        let reqUrl = request.url;
        this.modifiedUrl = reqUrl.replace('/infineum/products/', `/infineum/users/${appService.getUserId()}/products/`);
      } else if((request.url.indexOf('/infineum/orgUsers/') != -1)){
        let reqUrl = request.url;
        this.modifiedUrl = reqUrl.replace('/infineum/orgUsers/?', `/infineum/orgUsers/${appService.getUserId()}/?`);
      } else {
        this.modifiedUrl = request.url;
      }
      if(appService.getAsmMode() == "true" && (request.url.indexOf('/orgUsers') == -1) && (request.url.indexOf('/cms/pages') == -1) && (request.url.indexOf('/cms/components') == -1) ) {
        authToken = appService.asmToken["access_token"];
      } else {
        const auth = window.localStorage.getItem('spartacus⚿⚿auth');
        const json = JSON.parse(auth);
        authToken = json["token"]["access_token"];
      }


      // HttpHeader object immutable - copy values
      const headerSettings: { [name: string]: string | string[]; } = {};
      // Clone the request and replace the original headers with
      for (const key of request.headers.keys()) {
        headerSettings[key] = request.headers.getAll(key);
      }

      if (authToken) {
        headerSettings['authorization'] = `Bearer ${authToken}`;
      }

      const newHeader = new HttpHeaders(headerSettings);

      const duplicateRequest = request.clone({
        headers: newHeader,
        url: this.modifiedUrl
      });
      return next.handle(duplicateRequest)
        .pipe(
          tap((event: HttpEvent<any>) => {return event;},
          (error: HttpErrorResponse) =>{           
            if (error.status == 401) {
              console.log("Unauthorised:::::::::::::::::");
            }
            return next.handle(duplicateRequest);
          })
        )
    }
    else {
      return next.handle(request);
    }
  }

}
