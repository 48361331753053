import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { CmsBannerComponent, CmsComponent, CmsService, CMSTabParagraphContainer } from '@spartacus/core';
import {Router} from "@angular/router"
import { CmsComponentData } from '@spartacus/storefront';
declare var $: any;

import { AppService } from '../app.services';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-cards-grid',
  templateUrl: './cards-grid.component.html',
  styleUrls: ['./cards-grid.component.scss']
})
export class CardsGridComponent implements OnInit {
  ComponentData : {}
  buttons: { image: string, title: string, text: string, href: string  }[];
  gridBoxComponentList:any [];
  subscription1: Subscription;
  subscription2: Subscription;

  constructor(public componentData: CmsComponentData<CmsComponent>, protected component: CmsComponentData<CmsComponent>, private cmsService: CmsService, private appService: AppService, private ref: ChangeDetectorRef, private router: Router) { 
    this.buttons = [
      {
        image:"/assets/icons/Car.svg",
        title: "Passenger Car",
        text: "Lubricants",
        href: "#"
      }, {
        image:"/assets/icons/Lorry.svg",
        title: "Heavy Duty",
        text: "Lubricants",
        href: "#"
      }, {
        image:"/assets/icons/Bike.svg",
        title: "Small Engine",
        text: "Lubricants",
        href: "#"
      }, {
        image:"/assets/icons/Boat.svg",
        title: "Large Engine",
        text: "Lubricants",
        href: "#"
      }, {
        image:"/assets/icons/Fuel_Icon.svg",
        title: "Fuels",
        text: "",
        href: "#"
      }, {
        image:"/assets/icons/Cogs.svg",
        title: "Transmission",
        text: "Fluids",
        href: "#"
      }, {
        image:"/assets/icons/Components_Icon.svg",
        title: "Components",
        text: "",
        href: "#"
      }, {
        image:"/assets/icons/Droplets.svg",
        title: "Viscosity",
        text: "Modifiers",
        href: "#"
      }
    ];
  }

  ngOnInit(): void {
    this.gridBoxComponentList = [];
    this.subscription1 = this.componentData.data$.subscribe(data1 => {
      this.ComponentData = data1;
      // console.log("this.ComponentData:::::::::;;",this.ComponentData)
      let gridBoxList = this.ComponentData['gridBoxes'].split(" ");
      let uniqueList: any = [...new Set(gridBoxList)];
      this.gridBoxComponentList = [];
      this.ref.detectChanges();
      uniqueList.map((gridBox) => {
        this.subscription2 = this.cmsService.getComponentData(gridBox.trim()).subscribe((data) => {
          this.gridBoxComponentList.push({
            image: data['media'] && data['media']["url"] && this.appService.getApiDomainUrl() + data['media']["url"],
            title: data["link"] && data["link"]["linkName"],
            // text: "Lubricants",
            href: data["link"] && data["link"]["url"],
            uid: data["uid"]
          })
          let tempArr = [...new Map(this.gridBoxComponentList.map((item) => [item["uid"], item])).values()];
          let tempArr1 = [];

          uniqueList.map((list) => {

            for(let i=0; i<tempArr.length; i++){

          //tempArr.map((item)=>{

            if(list.trim()==tempArr[i]["uid"]){

              tempArr1.push(tempArr[i])

              break;

            }

          }

          //})

          })

          this.gridBoxComponentList = tempArr1;
          this.ref.detectChanges();
        })
        
      })
    
    
    })
     
  }

  navigateLink(url) {
    this.router.navigate([url]);
  }

  ngOnDestroy() {
    this.subscription1.unsubscribe();
    this.subscription2.unsubscribe();
  }

}
