import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { ClaimSearchDetailModel } from './claimsearch.details.model';
import { ClaimSearchModel } from './claimsearch.model';
import { AppService } from '../app.services';

@Injectable()
export class ClaimsearchService {

  claims: ClaimSearchModel;
  claimDetails : ClaimSearchDetailModel
  lastFilter : string

  constructor(private http: HttpClient, private appService: AppService) { 
    this.claims = new ClaimSearchModel();
    this.claimDetails = new ClaimSearchDetailModel();
  }

  // claimsFromAndProductCode:any = {"from": "", "productCode": ""};
  noResultClaim: boolean = false;
  private claimsFromSource = new BehaviorSubject<any>(this.noResultClaim);
  currentClaims = this.claimsFromSource.asObservable();
  changeClaims(claimFrom) {
    console.log("in change claim:::::::",claimFrom)
    this.claimsFromSource.next(claimFrom);
  }

  getClaimsData(user): ClaimSearchModel{
    return this.claims;
  }

  getClaimDetailsData(userId): ClaimSearchDetailModel{
    return this.claimDetails;
  }

  setLastFilter(lastCall: string){
    this.lastFilter = lastCall;
  }

  getLastFilter() : string{
    return this.lastFilter;
  }

  async changePage(page: number, userId){
    return await new Promise((resolve, reject) => {
      this.http
      .get(this.appService.getApiUrl() + `/users/${userId}/claims/search?fields=claims,facets,breadcrumbs,pagination(DEFAULT),sorts(DEFAULT),freeTextSearch,currentQuery&query=`+ this.lastFilter +"&currentPage="+ page +"&pageSize=10")
      .subscribe(response => {
        try{
          this.claims = response as ClaimSearchModel;
          resolve(true);
        }
        catch(e){
          reject(true);
        }
      })
    });
  }

  async getClaims(filter : string, action: string, page: number, pageSize: number, userId, selectedSort) {
    if(filter != "" && action === "add"){
      this.lastFilter = filter
    }
    else if(filter != "" && action === "delete"){
      filter = this.lastFilter.replace(filter, "");
      this.lastFilter = filter;
    }
    return await new Promise((resolve, reject) => {
      this.http
      .get(this.appService.getApiUrl() + `/users/${userId}/claims/search?fields=claims,facets,breadcrumbs,pagination(DEFAULT),sorts(DEFAULT),freeTextSearch,currentQuery&query=`+ filter +"&currentPage="+ page +"&pageSize="+pageSize+"&sort="+ selectedSort)
      .subscribe((response) => {
        try{
          this.claims = response as ClaimSearchModel;
          if(this.claims.claims.length < 1) {
            this.changeClaims(true);
          }
          resolve(true);
        }
        catch(e){
          reject(true);
        }
      },
      (error) => {
        console.log("Error 401 in claims Service::::",error);
        //this.MSDS = new ProductMsdsModel();
        reject(true);
      }
      )
    });
  }

  async getClaimDetails(materialCode : string, treadId : string, userId){
    return await new Promise((resolve, reject) => {
      this.http
      .get(this.appService.getApiUrl() + `/users/${userId}/claims/`+ treadId + "/"+ materialCode)
      .subscribe(response => {
        try{
          this.claimDetails = response as ClaimSearchDetailModel;
          resolve(true);
        }
        catch(e){
          reject(true);
        }
      })
    });
  }
}
