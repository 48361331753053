<div class="container">
    <div class="row">
        <div class="col">
            <button (click)="naviateToOrderHistory()" class="btn btn-primary btn-back mb-4">{{'customOrderDetails.orderHistoryBtn' | cxTranslate}}</button>
        </div>
    </div>
    <div class="order-details-top bg-white">
        <div class="row row-cards m-0">
            <div class="col-12 col-md-6 separator">
                <div class="card text-center shadow-none border-0 w-100">
                    <div class="card-body">
                        <h5 class="card-title">{{'customOrderDetails.infineumOrderNumber' | cxTranslate}}</h5>
                        <p class="card-text">{{orderDetails.referenceOrderNo}}</p>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-6">
                <div class="card text-center shadow-none border-0 w-100">
                    <div class="card-body">
                      <h5 *ngIf="orderType=='Standard Sales Order'" class="card-title">{{'customOrderDetails.purchaseOrderNumber' | cxTranslate}}</h5>
                      <h5 *ngIf="orderType=='Sample Request'" class="card-title">{{'customOrderDetails.sampleRequestNumber' | cxTranslate}}</h5>
                      <p class="card-text">{{orderDetails.purchaseOrderNumber}}</p>
                    </div>
                </div>
            </div>
        </div>
        <!-- bottom section -->
        <div class="row row-images bg-modal-header-bg m-0">
            <div class="col-12 text-center">
                <div class="row">
                    <div class="col-12 col-md-4">
                        <div class="card plain bg-transparent pt-4">
                            <div class="card-header">
                                <img class="card-img-top" src="/assets/Calendar.svg" alt="Estimated Delivery Date">
                            </div>
                            <div class="card-body">
                                <p class="card-text"><strong>{{'customOrderDetails.estimatedDeliveryDate' | cxTranslate}}</strong></p>
                                <p class="card-text">{{orderDetails.requestedDeliveryDate}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-4">
                        <div class="card plain bg-transparent pt-4">
                            <div class="card-header">
                                <img class="card-img-top" src="/assets/Clock.svg" alt="Status">
                            </div>
                            <div class="card-body">
                                <p class="card-text"><strong>{{'customOrderDetails.status' | cxTranslate}}</strong></p>
                                <p class="card-text" *ngIf="orderDetails.status == 'Cancelled' || orderDetails.status == 'CANCELLED'">{{'customOrderDetails.Cancelled' | cxTranslate}}</p>
                                <p class="card-text" *ngIf="orderDetails.status == 'Confirmed' || orderDetails.status == 'CONFIRMED'">{{'customOrderDetails.Confirmed' | cxTranslate}}</p>
                                <p class="card-text" *ngIf="orderDetails.status == 'Dispatched' || orderDetails.status == 'DISPATCHED'">{{'customOrderDetails.Dispatched' | cxTranslate}}</p>
                                <p class="card-text" *ngIf="orderDetails.status == 'Invoiced' || orderDetails.status == 'INVOICED'">{{'customOrderDetails.Invoiced' | cxTranslate}}</p>
                                <p class="card-text" *ngIf="orderDetails.status == 'Pending' || orderDetails.status == 'PENDING'">{{'customOrderDetails.Pending' | cxTranslate}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-4">
                        <div class="card plain bg-transparent pt-4">
                            <div class="card-header">
                                <img class="card-img-top wide" src="/assets/Truck.svg" alt="Type">
                            </div>
                            <div class="card-body">
                                <p class="card-text"><strong>{{'customOrderDetails.type' | cxTranslate}}</strong></p>
                                <p class="card-text" *ngIf="orderType == 'Standard Sales Order'">{{'customOrderDetails.standard_sales_order' | cxTranslate}}</p>
                                <p class="card-text" *ngIf="orderType == 'Sample Request'">{{'customOrderDetails.request_sample' | cxTranslate}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="order-details card bg-white" *ngIf="orderType == 'Standard Sales Order'">
        <div class="tabs">
            <!-- DESKTOP -->
            <ul class="nav nav-tabs d-none d-md-flex">
                <li class="nav-item">
                  <a data-toggle="tab" class="nav-link active" href="#tab1">{{'customOrderDetails.orderDetails' | cxTranslate}}</a>
                </li>
                <li class="nav-item">
                  <a data-toggle="tab" class="nav-link" href="#tab2">{{'customOrderDetails.orderDocuments' | cxTranslate}}</a>
                </li>
                <li class="nav-item">
                    <a data-toggle="tab" class="nav-link" href="#tab3">{{'customOrderDetails.shippingDocuments' | cxTranslate}}</a>
                  </li>
                  <li class="nav-item">
                    <a data-toggle="tab" class="nav-link" href="#tab4">{{'customOrderDetails.invoices' | cxTranslate}}</a>
                  </li>
            </ul>
            <!-- MOBILE -->
            <select class="d-block d-md-none" (change)="onChange($event.target.value)">
                <option value="#tab1">{{'customOrderDetails.orderDetails' | cxTranslate}}</option>
                <option value="#tab2">{{'customOrderDetails.orderDocuments' | cxTranslate}}</option>
                <option value="#tab3">{{'customOrderDetails.shippingDocuments' | cxTranslate}}</option>
                <option value="#tab4">{{'customOrderDetails.invoices' | cxTranslate}}</option>
            </select>
        </div><!-- tabs -->
        
        <div class="tab-content">
            <!-- {{'customOrderDetails.orderDetails' | cxTranslate}} -->
            <div class="tab-pane active order-details-tab" id="tab1">
                <div class="row">
                    <div class="col-12">
                        <h3>{{'customOrderDetails.orderDetails' | cxTranslate}}</h3>
                    </div>
                </div>
                <div class="row order-results">
                    <div class="col-12 col-md-8">
                        <div class="card-headings d-none d-md-flex">
                            <label>{{'customOrderDetails.item' | cxTranslate}}</label>
                            <label>{{'customOrderDetails.quality' | cxTranslate}}</label>
                        </div>
                        <!-- PRODUCTS -->
                        <div class="card" *ngFor="let entry of orderDetails.entries">
                            <div class="card-header">
                                <div class="card-icon">
                                    <img src= "{{entry.product.productImage}}" alt="">
                                </div>
                            </div>
                            <div class="card-body">
                                <h4>{{entry.productName}}</h4>
                                <div class="quantity">{{entry.infineumQuantity + " " + entry.selectedUnit}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-md-4">
                        <div class="shipping-address">
                            <label>{{'customOrderDetails.shippingAddress' | cxTranslate}}</label>
                            <div class="card bg-modal-header-bg">
                                <label *ngIf="orderDetails?.shippedTo?.name" class="heading">{{orderDetails?.shippedTo?.name}}</label>
                                <label *ngIf="orderDetails?.deliveryAddress?.firstName">{{orderDetails?.deliveryAddress?.firstName + " " + orderDetails?.deliveryAddress?.lastName}}</label>
                                <label *ngIf="orderDetails?.deliveryAddress?.companyName">{{orderDetails?.deliveryAddress?.companyName}}</label>
                                <p *ngFor="let addr of formattedAddress">{{addr}}</p>
                             </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- ORDER DOCUMENTS -->
            <div class="tab-pane" id="tab2">
                <div class="row">
                    <div class="col-12">
                        <h3>{{'customOrderDetails.orderDocuments' | cxTranslate}}</h3>
                        <table class='PDS styled' *ngIf="user.canViewOrderInformation && orderAttachmentDoc.length>0">
                            <thead class='headerPDStable'>
                                <th></th>
                                <th>{{'customOrderDetails.documentName' | cxTranslate}}</th>
                                <th>{{'customOrderDetails.document' | cxTranslate}}</th>
                            </thead>
                            <tbody>
                                <tr *ngFor="let orderDoc of orderAttachmentDoc">
                                    <td class="table-icon"><img src='assets/pdf.svg'></td>
                                    <td id='supplyingCompany' data-label="Document Name">{{orderDoc.PORTAL_DESC}}</td>
                                    <td data-label="Document"><button (click)="downloadPdf(orderDoc.PORTAL_DESC, orderDoc.ARCHIVE_DOC, orderDoc.ARCHIVE_ID, $event)" class='btn btn-primary btn-plain'>{{'customOrderDetails.capsDownload' | cxTranslate}}</button></td>
                                </tr>
                            </tbody>
                        </table>
                        <!-- No results -->
                        <div *ngIf="orderAttachmentDoc.length==0">
                            <p>{{'customOrderDetails.thereAreNoOrderDoc' | cxTranslate}}</p>
                        </div>
                    </div>
                </div>
            </div>
            <!-- SHIPPING DOCUMENTS -->
            <div class="tab-pane" id="tab3">
                <div class="row">
                    <div class="col-12">
                        <h3>{{'customOrderDetails.shippingDocuments' | cxTranslate}}</h3>
                        <table class='styled' *ngIf="user.canViewOrderShippingInfo && shippingAttachmentDoc.length > 0">
                            <thead>
                                <th></th>
                                <th>{{'customOrderDetails.documentName' | cxTranslate}}</th>
                                <th>{{'customOrderDetails.document' | cxTranslate}}</th>
                            </thead>
                            <tbody>
                                <tr *ngFor="let shippingDoc of shippingAttachmentDoc">
                                    <td class="table-icon"><img src='assets/pdf.svg'></td>
                                        <td id='supplyingCompany' data-label="Document Name">{{shippingDoc.PORTAL_DESC}}</td>
                                        <td data-label="Document"><button (click)="downloadPdf(shippingDoc.PORTAL_DESC, shippingDoc.ARCHIVE_DOC, shippingDoc.ARCHIVE_ID, $event)" class='btn btn-primary btn-plain'>{{'customOrderDetails.download' | cxTranslate}}</button></td>
                                    </tr>
                                    
                            </tbody>
                        </table>
                        <!-- No results -->
                        <div *ngIf="shippingAttachmentDoc.length==0">
                            <p>{{'customOrderDetails.thereAreNoShippingDoc' | cxTranslate}}</p>
                        </div>
                    </div>
                </div>
            </div>
            <!-- INVOICES -->
            <div class="tab-pane" id="tab4">
                <div class="row">
                    <div class="col-12">
                        <h3>{{'customOrderDetails.invoicesSmall' | cxTranslate}}</h3>
                        <table class='styled' *ngIf="user.canViewOrderInvoices && invoiceAttachmentDoc.length > 0">
                            <thead>
                                <th></th>
                                <th>{{'customOrderDetails.documentName' | cxTranslate}}</th>
                                <th>{{'customOrderDetails.document' | cxTranslate}}</th>
                            </thead>
                            <tbody>
                                <tr *ngFor="let invoiceDoc of invoiceAttachmentDoc">
                                    <td class="table-icon"><img src='assets/pdf.svg'></td>
                                        <td id='supplyingCompany' data-label="Document Name">{{invoiceDoc.PORTAL_DESC}}</td>
                                        <td data-label="Document"><button (click)="downloadPdf(invoiceDoc.PORTAL_DESC, invoiceDoc.ARCHIVE_DOC, invoiceDoc.ARCHIVE_ID, $event)" class='btn btn-primary btn-plain'>{{'customOrderDetails.download' | cxTranslate}}</button></td>
                                    </tr>
                                    
                            </tbody>
                        </table>
                        <!-- No results -->
                        <div *ngIf="invoiceAttachmentDoc.length==0">
                            <p>{{'customOrderDetails.ThereAreNoInvoices' | cxTranslate}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div><!-- .tab-content -->
        
    </div><!-- .order-details-bottom -->
    
    <!-- Sample Requests -->
    <div class="order-details order-details-tab card bg-white" *ngIf="orderType == 'Sample Request'">
        <!-- {{'customOrderDetails.orderDetails' | cxTranslate}} -->
        <div class="tab-pane">
            <div class="row">
                <div class="col-12">
                    <h3>{{'customOrderDetails.orderDetails' | cxTranslate}}</h3>
                </div>
            </div>
            <div class="row order-results">
                <div class="col-12 col-md-8">
                    <div class="card-headings d-none d-md-flex">
                        <label>{{'customOrderDetails.item' | cxTranslate}}</label>
                        <label>{{'customOrderDetails.quality' | cxTranslate}}</label>
                    </div>
                    <!-- PRODUCTS -->
                    <div class="card" *ngFor="let entry of orderDetails.entries">
                        <div class="card-header">
                            <div class="card-icon">
                                <img src= "{{entry.product.productImage}}" alt="">
                            </div>
                        </div>
                        <div class="card-body">
                            <h4>{{entry.productName}}</h4>
                            <div class="quantity">{{entry.infineumQuantity + " " + entry.selectedUnit}}</div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-4">
                    <div class="shipping-address">
                        <label>{{'customOrderDetails.shippingAddress' | cxTranslate}}</label>
                        <div class="card bg-modal-header-bg">
                            <label *ngIf="orderDetails?.shippedTo?.name" class="heading">{{orderDetails?.shippedTo?.name}}</label>
                            <label *ngIf="orderDetails?.deliveryAddress?.firstName">{{orderDetails?.deliveryAddress?.firstName + " " + orderDetails?.deliveryAddress?.lastName}}</label>
                            <label *ngIf="orderDetails?.deliveryAddress?.companyName">{{orderDetails?.deliveryAddress?.companyName}}</label>
                            <p *ngFor="let addr of formattedAddress">{{addr}}</p>
                        </div>
                    </div>
                    <div class="shipping-address">
                        <label>{{'customOrderDetails.trackingNumber' | cxTranslate}}</label>
                        <div class="card bg-modal-header-bg">
                            <label *ngIf="trackingNo" class="color-blue" (click)="openTrackingUrl(trackingUrl)" role="button" >{{trackingNo}}<img class="img-position pl-2 mt-n1" width="20" src="/assets/Arrow.svg"></label>
                            <p *ngIf="!trackingNo">{{'customOrderDetails.thereAreNoTrackingNo' | cxTranslate}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- END ORDER SAMPLES -->
</div><!-- .container -->
<!--    
    <div class="card-group" *ngIf="orderType == 'Sample Request'">
        <div class="card">
        <div class="card-body">
            <div class="tab-content">
                <div class="">
            <div class="row">
                <div class="col-lg-12 col-md-12">
                    <label class="__first-tab-title">{{'customOrderDetails.orderDetails' | cxTranslate}}</label>
                </div>
                </div>
                <div class="row" style="margin-top: 3%;">
                    <div class="col-lg-5 col-md-5">
                        <label class="__first-tab-text">{{'customOrderDetails.item' | cxTranslate}}</label>
                    </div>
                    <div class="col-lg-3 col-md-3">
                        <label class="__first-tab-text __margin-left-quantity">{{'customOrderDetails.quality' | cxTranslate}}</label>
                    </div>
                    <div class="col-lg-4 col-md-4">
                        <label class="__first-tab-text">{{'customOrderDetails.shippingAddress' | cxTranslate}}</label>
                    </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-8 col-md-8">
                            <hr class="__hr-line"/>
                        <div class="card-group" *ngFor="let entry of orderDetails.entries">
                            <div class="card-block __tab-first-img-bg">
                              <div class="card-body">
                              <div class="__tab-first-image-section">
                                <img class="card-img-left responsive-img" src="{{entry.product.productImage}}" alt="Card image cap">
                            </div>
                        </div>
                            </div>
                            <div class="card">
                              <div class="card-body d-flex align-items-center">
                                <h5 class="mx-auto w-100 __text-align-left"> <br><p>{{entry.productName}}</p></h5>
                              </div>
                            </div>
                            <div class="card">
                              <div class="card-body d-flex align-items-center">
                                <h5 class="__card-title-list mx-auto w-100">{{entry.infineumQuantity + " " + entry.selectedUnit}} </h5>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-4 col-md-4">
                            <div class="__saved-body-border">
                                <div class="card __tab-first-img-bg">
                            <div class="card-body __last-cart-checkout">
                                <label *ngIf="orderDetails?.shippedTo?.name">{{orderDetails?.shippedTo?.name}}</label>
                                <label *ngIf="orderDetails?.deliveryAddress?.firstName">{{orderDetails?.deliveryAddress?.firstName + " " + orderDetails?.deliveryAddress?.lastName}}</label>
                                <label *ngIf="orderDetails?.deliveryAddress?.companyName">{{orderDetails?.deliveryAddress?.companyName}}</label>
                                <span *ngFor="let addr of formattedAddress">{{addr}}<br></span>
                             </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-12">
                                <label class="__tracking-number-title">TRACKING NUMBER</label>
                                <div class="__saved-body-border">
                                    <div class="card __tab-first-img-bg">
                                <div class="card-body">
                                    <label class="__tracking-number-text" (click)="openTrackingUrl(trackingUrl)">{{trackingNo}}<img class="img-position" src="../../assets/small-arrow.png"></label>
                                 </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                        </div>
            </div>
        </div>
        </div>
        </div>
      </div>
-->    
    
    

<!-- for tab  -->
<!--
      <div class="card-group" *ngIf="orderType == 'Standard Sales Order'">
        <div class="card">
          <div class=" __tab-header">
            <div class="row">
                <div class="col-lg-12 col-md-12">
                    <ul class="nav nav-tabs d-none d-md-flex">
                        <li class="nav-item">
                          <a data-toggle="tab" class="nav-link active __margin-left" href="#first10">{{'customOrderDetails.orderDetails' | cxTranslate}}</a>
                        </li>
                        <li class="nav-item __style-second-tab">
                          <a data-toggle="tab" class="nav-link" href="#second10">ORDER DOCUMENTS</a>
                        </li>
                        <li class="nav-item __style-second-tab">
                            <a data-toggle="tab" class="nav-link" href="#third10">SHIPPING DOCUMENTS</a>
                          </li>
                          <li class="nav-item __style-second-tab">
                            <a data-toggle="tab" class="nav-link" href="#fourth10">INVOICES</a>
                          </li>
                    </ul>
                    <select class="d-block d-md-none" (change)="onChange($event.target.value)">
                        <option value="#first10">{{'customOrderDetails.orderDetails' | cxTranslate}}</option>
                        <option value="#second10">Order Documents</option>
                        <option value="#third10">Shipping Documents</option>
                        <option value="#fourth10">Invoices</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="card-body">
            <div class="tab-content">
                <div id="Xfirst10" class="tab-pane active">
            <div class="row">
                <div class="col-lg-12 col-md-12">
                    <label class="__first-tab-title">{{'customOrderDetails.orderDetails' | cxTranslate}}</label>
                </div>
                </div>
                <div class="row" style="margin-top: 3%;">
                    <div class="col-lg-5 col-md-5">
                        <label class="__first-tab-text">{{'customOrderDetails.item' | cxTranslate}}</label>
                    </div>
                    <div class="col-lg-3 col-md-3">
                        <label class="__first-tab-text __margin-left-quantity">{{'customOrderDetails.quality' | cxTranslate}}</label>
                    </div>
                    <div class="col-lg-4 col-md-4">
                        <label class="__first-tab-text">{{'customOrderDetails.shippingAddress' | cxTranslate}}</label>
                    </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-8 col-md-8">
                            <hr class="__hr-line"/>
                        <div class="card-group" *ngFor="let entry of orderDetails.entries">
                            <div class="card-block __tab-first-img-bg">
                              <div class="card-body">
                              <div class="__tab-first-image-section">
                                <img class="card-img-left responsive-img" src="{{entry.product.productImage}}" alt="Card image cap">
                            </div>
                        </div>
                            </div>
                            <div class="card">
                              <div class="card-body d-flex align-items-center">
                                <h5 class="mx-auto w-100 __text-align-left"><br><p>{{entry.productName}}</p></h5>
                              </div>
                            </div>
                            <div class="card">
                              <div class="card-body d-flex align-items-center">
                                <h5 class="__card-title-list mx-auto w-100">{{entry.infineumQuantity + " " + entry.selectedUnit}} </h5>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-4 col-md-4">
                            <div class="__saved-body-border">
                                <div class="card __tab-first-img-bg">
                            <div class="card-body __last-cart-checkout">
                                <label *ngIf="orderDetails?.shippedTo?.name">{{orderDetails?.shippedTo?.name}}</label>
                                <label *ngIf="orderDetails?.deliveryAddress?.firstName">{{orderDetails?.deliveryAddress?.firstName + " " + orderDetails?.deliveryAddress?.lastName}}</label>
                                <label *ngIf="orderDetails?.deliveryAddress?.companyName">{{orderDetails?.deliveryAddress?.companyName}}</label>
                                <span *ngFor="let addr of formattedAddress">{{addr}}<br></span>
                             </div>
                            </div>
                        </div>
                        </div>
                        </div>
            </div>
            <div id="second10" class="tab-pane">
                <div class="row align-items-center">
                    <div class="col-lg-12 col-md-12">
                        <label class="__first-tab-title">ORDER DOCUMENTS</label>
                        <table class='PDS'>
                            <thead class='headerPDStable'>
                                <th class='__titlePDS' style= 'width: 0;'></th>
                                    <th class='__titlePDS'>DOCUMENT NAME</th>
                                    <th class='__titlePDS'>DOCUMENT</th>
                                </thead>
                                <tbody *ngIf="user.canViewOrderInformation">
                                    <tr *ngFor="let orderDoc of orderAttachmentDoc">
                                        <td class='elementMSDS' style= 'width: 0;'><img src='assets/pdf.png'></td>
                                        <td id='supplyingCompany' class='elementPDS-D'>{{orderDoc.PORTAL_DESC}}</td>
                                        <td><button (click)="downloadPdf(orderDoc.PORTAL_DESC, orderDoc.ARCHIVE_DOC, orderDoc.ARCHIVE_ID, $event)" class='__MSDSdownload'>Download</button></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div id="third10" class="tab-pane">
                    <div class="row align-items-center">
                        <div class="col-lg-12 col-md-12">
                            <label class="__first-tab-title">SHIPPING DOCUMENTS</label>
                            <table class='PDS'>
                                <thead class='headerPDStable'>
                                    <th class='__titlePDS' style= 'width: 0;'></th>
                                        <th class='__titlePDS'>DOCUMENT NAME</th>
                                        <th class='__titlePDS'>DOCUMENT</th>
                                    </thead>
                                    <tbody *ngIf="user.canViewOrderShippingInfo">
                                        <tr *ngFor="let shippingDoc of shippingAttachmentDoc">
                                            <td class='elementMSDS' style= 'width: 0;'><img src='assets/pdf.png'></td>
                                                <td id='supplyingCompany' class='elementPDS-D'>{{shippingDoc.PORTAL_DESC}}</td>
                                                <td><button (click)="downloadPdf(shippingDoc.PORTAL_DESC, shippingDoc.ARCHIVE_DOC, shippingDoc.ARCHIVE_ID, $event)" class='__MSDSdownload'>Download</button></td>
                                            </tr>
                                            
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div id="fourth10" class="tab-pane">
                        <div class="row align-items-center">
                            <div class="col-lg-12 col-md-12">
                                <label class="__first-tab-title">INVOICE DOCUMENTS</label>
                        <table class='PDS'>
                            <thead class='headerPDStable'>
                                <th class='__titlePDS' style= 'width: 0;'></th>
                                    <th class='__titlePDS'>DOCUMENT NAME</th>
                                    <th class='__titlePDS'>DOCUMENT</th>
                                </thead>
                                <tbody *ngIf="user.canViewOrderInvoices">
                                    <tr *ngFor="let invoiceDoc of invoiceAttachmentDoc">
                                        <td class='elementMSDS' style= 'width: 0;'><img src='assets/pdf.png'></td>
                                            <td id='supplyingCompany' class='elementPDS-D'>{{invoiceDoc.PORTAL_DESC}}</td>
                                            <td><button (click)="downloadPdf(invoiceDoc.PORTAL_DESC, invoiceDoc.ARCHIVE_DOC, invoiceDoc.ARCHIVE_ID, $event)" class='__MSDSdownload'>Download</button></td>
                                        </tr>
                                        
                                </tbody>
                            </table>
                                </div>
                            </div>
                        </div>
        </div>
        </div>
        </div>
      </div>
-->