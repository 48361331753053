<div class="container __toast-container">
    <div class="row" [ngStyle]="{'display': __styleToaster}">
      <div class="col-lg-12">
        <div class="alert alert-success __toaster-border" role="alert">
            {{'customUpdateUser.personalUpdatedSuccessfully' | cxTranslate}}
          <button type="button" class="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
    </div>
  </div>
  </div>
<div class="container">
    <div class="row">
        <div class="col-md-3"></div>
        <div class="col-md-6 __detail-section-border">
            <label for="" class="__profile-name-title">{{'customUpdateUser.name' | cxTranslate}}</label>
            <h5 class="__profile-text-1">{{userDetails.firstName + " " + userDetails.lastName}}</h5>
            <label for="" class="__profile-name-title">{{'customUpdateUser.emailAddress' | cxTranslate}}</label>
            <h5 class="__profile-text-1">{{userDetails.uid}}</h5>
            <hr class="__line-profile">
            <span class="__text-profile-2">{{'customUpdateUser.description' | cxTranslate}}</span>
            </div>
            <div class="col-md-3"></div>
            </div>
    <div class="row">
        <div class="col-md-3"></div>
        <div class="col-md-6 __update-pass-border">
        <form novalidate="" ng-reflect-form="[object Object]" class="ng-untouched ng-pristine ng-invalid">
            <label>
                <span class="label-content">{{'customUpdateUser.jobTitle' | cxTranslate}}<span>{{'customUpdateUser.optional' | cxTranslate}}</span></span>
                <input type="text" [(ngModel)] = "jobTitle" name="jobTitleName" formcontrolname="" class="form-control ng-untouched ng-pristine">
               </label>
            <label style="margin-top: 20px;">
                <span class="label-content">{{'customUpdateUser.mobileNumber' | cxTranslate}}<span>{{'customUpdateUser.optional' | cxTranslate}}</span></span>
                <input type="text" [(ngModel)] = "phone1" name="mobileFirst" formcontrolname="" class="form-control ng-untouched ng-pristine">
            </label>
            <label style="margin-top: 30px;">
                <span class="label-content">{{'customUpdateUser.phoneNumber' | cxTranslate}}<span>{{'customUpdateUser.optional' | cxTranslate}}</span></span>
                <input type="text" [(ngModel)] = "phone2" name="mobileSecond" formcontrolname="" class="form-control ng-untouched ng-pristine">
            </label>
            <div class="row __margin-top">
                
                    <div class="col-12 col-md-12 col-lg-4">
                    <button (click)="updateProfile(jobTitle, phone1, phone2)" class="btn btn-primary" type="button">{{'customUpdateUser.update' | cxTranslate}}
                        <!-- <i aria-hidden="true" class="fa fa-chevron-right __btn-right-arrow"></i> -->
                    </button>
                    </div>
                    <div class="col-12 col-md-12 col-lg-4">
                    <button (click)="navigateToHome()" class="__password-update-cancel-btn">{{'customUpdateUser.cancel' | cxTranslate}}
                    </button>
                </div>
                
                <!-- </div> -->
                <!-- <div class="col-md-6">
                    <button class="__password-update-cancel-btn">CANCEL
                    </button>
                </div> -->
            </div>
        </form>
        </div>
        <div class="col-md-3"></div>
    </div> 
    <!-- <div class="row"> 
        <div class="col-md-3"></div>
        <div class="col-md-6">   
            <form novalidate="" ng-reflect-form="[object Object]" class="ng-untouched ng-pristine ng-valid">
                <label>
                    <span class="label-content">Title</span>
                    <select formcontrolname="titleCode" class="form-control ng-untouched ng-pristine ng-valid" ng-reflect-name="titleCode">
                        <option value="" ng-reflect-value=""></option>
                        <option value="rev" ng-reflect-value="rev"> Rev. </option>
                        <option value="mrs" ng-reflect-value="mrs"> Mrs. </option>
                        <option value="miss" ng-reflect-value="miss"> Miss </option>
                        <option value="mr" ng-reflect-value="mr"> Mr. </option>
                        <option value="ms" ng-reflect-value="ms"> Ms. </option>
                        <option value="dr" ng-reflect-value="dr"> Dr. </option>
                        <option value="006" ng-reflect-value="006"> Frau </option>
                        <option value="012" ng-reflect-value="012"> M </option>
                        <option value="008" ng-reflect-value="008"> Dhr. </option>
                        <option value="0003" ng-reflect-value="0003"> Company </option>
                        <option value="0002" ng-reflect-value="0002"> Mr. </option>
                        <option value="009" ng-reflect-value="009"> Mevrouw </option>
                        <option value="0001" ng-reflect-value="0001"> Ms. </option>
                        <option value="007" ng-reflect-value="007"> Brandmajor </option>
                        <option value="004" ng-reflect-value="004"> Mrs. </option>
                        <option value="005" ng-reflect-value="005"> Herr </option>
                        <option value="010" ng-reflect-value="010"> Monsieur </option>
                        <option value="011" ng-reflect-value="011"> Madame </option>
                        <option value="013" ng-reflect-value="013"> Herr Dipl.-Ing. </option>
                        <option value="014" ng-reflect-value="014"> Herr Ing. </option>
                        <option value="015" ng-reflect-value="015"> Frau Dipl.-Ing. </option>
                        <option value="018" ng-reflect-value="018"> Prof. </option>
                        <option value="016" ng-reflect-value="016"> Herr Prof </option>
                        <option value="017" ng-reflect-value="017"> Frau Prof </option>
                        <option value="020" ng-reflect-value="020"> Herr Dr. </option>
                        <option value="019" ng-reflect-value="019"> Ing. </option>
                        <option value="021" ng-reflect-value="021"> Frau Dr. </option>
                        <option value="023" ng-reflect-value="023"> Signor </option>
                        <option value="022" ng-reflect-value="022"> Dr. </option>
                        <option value="024" ng-reflect-value="024"> Sr. </option>
                        <option value="027" ng-reflect-value="027"> Sig.na </option>
                    </select>
                </label>
                <label>
                    <span class="label-content">First name</span>
                    <input type="text" name="firstName" formcontrolname="firstName" class="form-control ng-untouched ng-pristine ng-valid" ng-reflect-name="firstName" placeholder="First name">
                </label>
                <label>
                    <span class="label-content">Last name</span>
                    <input type="text" name="lastName" formcontrolname="lastName" class="form-control ng-untouched ng-pristine ng-valid" ng-reflect-name="lastName" placeholder="Last name">
                </label>
                <label>
                    <span class="label-content">Job Title</span>
                    <input type="text" name="jobtitle" formcontrolname="jobtitle" class="form-control ng-untouched ng-pristine ng-valid" ng-reflect-name="jobtitle" placeholder="Job title">
                </label>
                <label>
                    <span class="label-content">Mobile phone number</span>
                    <input type="text" name="mobile1" formcontrolname="mobile1" class="form-control ng-untouched ng-pristine ng-valid" ng-reflect-name="mobile1" placeholder="Mobile phone number">
                </label>
                <label>
                    <span class="label-content">Phone number 2</span>
                    <input type="text" name="mobile2" formcontrolname="mobile2" class="form-control ng-untouched ng-pristine ng-valid" ng-reflect-name="mobile2" placeholder="Phone number 2">
                </label>
                <div class="row" style="margin-top: 10px;">
                    <div class="col-md-6">
                        <a class="btn btn-block btn-secondary" ng-reflect-router-link="/" href="/infineum/en/USD/"> Cancel </a>
                    </div>
                    <div class="col-md-6">
                        <button class="btn btn-block btn-primary"> Save </button>
                    </div>
                </div>
            </form>
        </div>
        <div class="col-md-3"></div>
    </div> -->
</div>