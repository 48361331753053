import { NgxPaginationModule } from 'ngx-pagination';
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { ConfigModule, GlobalMessageService } from "@spartacus/core";
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SpartacusModule } from './spartacus/spartacus.module';
import { BannerHomePageComponent } from './banner-home-page/banner-home-page.component';
import { ClaimSearchComponent } from './claim-search/claim-search.component';
import { FooterComponent } from './footer/footer.component';
import { ProductsComponent } from './products/products.component';
import { HeaderComponent } from './header/header.component';
import { LoginComponent } from './login/login.component';
import { TermsComponent } from './terms/terms.component';
import { ContactComponent } from './contact/contact.component';
import { CommonModule } from '@angular/common';
import { MyAccountComponent } from './my-account/my-account.component';
import { ProductDetailsComponent } from './product-details/product-details.component';
import { UpdateUserComponent } from './update-user/update-user.component';
import { UpdatePasswordComponent } from './update-password/update-password.component';
import { TabProductDetailsComponent } from './tab-product-details/tab-product-details.component';
import { LogonHelpComponent } from './logon-help/logon-help.component';
import { TestDetailProductComponent } from './test-detail-product/test-detail-product.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDialogModule } from "@angular/material/dialog";
import { DialogDataClaimComponent } from './claim-search/dialog-data-claim/dialog-data-claim.component';
import { WhishlistComponent } from './whishlist/whishlist.component';
import { MatCardModule } from '@angular/material/card';
import { DialogFilterClaimComponent } from './claim-search/dialog-filter-claim/dialog-filter-claim.component';
import { BannerProductComponent } from './banner-product/banner-product.component';
import { BannerCalimsComponent } from './banner-calims/banner-calims.component';
import { CardsGridComponent } from './cards-grid/cards-grid.component';
import { ProductCarouselComponent } from './product-carousel/product-carousel.component';
import { LatestsNewsComponent } from "./latests-news/latests-news.component";
import { VideoComponent } from './video/video.component';
import { ClaimsearchService } from "./APIcall/claimsearch.service";

import {CustomPdpModule} from "./custom-pdp/custom-pdp.module"
// import { CustomProductListModule } from "./custom-product-list/custom-product-list.module";
// import { CustomProductSearchModule } from './custom-product-search/custom-product-search.module';

import { ProductListComponent } from './custom-product-list/product-list/product-list.component';
import { CustomerSpecificationComponent } from './customer-specification/customer-specification.component';
import { BreadcrumbComponentComponent } from './breadcrumb-component/breadcrumb-component.component';
import { CustomClaimSearchComponent } from './custom-claim-search/custom-claim-search.component';
import { MiniCartComponentComponent } from './mini-cart-component/mini-cart-component.component';
import { SiteLogoComponent } from './site-logo/site-logo.component';
import { CustomCartComponent } from './custom-cart/custom-cart.component';
import { CustomWishListComponent } from './custom-wish-list/custom-wish-list.component';
import { CustomCheckoutComponent } from './custom-checkout/custom-checkout.component';
import { CheckoutOrderSummaryComponent } from './checkout-order-summary/checkout-order-summary.component'
import { ForgotComponent } from './forgot/forgot.component';
import { CheckoutOrderConfirmationComponent } from './checkout-order-confirmation/checkout-order-confirmation.component';
import { CustomHeaderLinksComponent } from './custom-header-links/custom-header-links.component';
import { HeaderFaqLinkComponent } from './header-faq-link/header-faq-link.component';
import { CustomOrderHistoryComponent } from './custom-order-history/custom-order-history.component';
import { CustomOrderDetailsComponent } from './custom-order-details/custom-order-details.component';
import { HelpComponent } from './help/help.component';
import { PrivacyComponent } from './privacy/privacy.component';
import {Error404Component } from './error404/error404.component';
import {NamePageComponent } from './name/namepage.component';
import { CustomResetPasswordComponent } from './custom-reset-password/custom-reset-password.component';
import { LoaderComponent } from './loader/loader.component';
import { LoaderService } from './services/loader.service';
import { LoaderInterceptor } from './interceptors/loader-interceptor.service';
import { TokenInterceptor } from './auth/token.interceptor';
import { RouterModule } from '@angular/router';
import { ScrollToTopComponent } from './shared/scroll-to-top/scroll-to-top.component';
import { I18nModule } from '@spartacus/core';
import { NgcCookieConsentModule, NgcCookieConsentConfig } from 'ngx-cookieconsent';

import { environment } from '../environments/environment';
import { CustomGlobalMessageService } from './APIcall/custom-global-message.service';

const cookieConfig: NgcCookieConsentConfig = {
  enabled: localStorage.getItem("cookie-accept") ? ((localStorage.getItem("cookie-accept") == "false") ? false : true) : true,
  cookie: {
    domain: '/'
  },
  palette: {
    popup: {
      background: '#0d3973'
    },
    button: {background: 'transparent', border: '#ffffff', text: '#ffffff'}
    
  },
  content: {
    // header: 'WE NEED YOUR CONSENT',
    message: `We use cookies to distinguish you from other users, to improve your user experience and to understand how you use our site. We also share this information with our analytics provider. By using this site, you consent to this. To find out more about why we use cookies, how to control your cookie preferences or how to disable them, please see our`,
    dismiss: 'AGREE',
    allow: 'Accept',
    deny: 'Decline',
    link: 'cookie policy.',
    href: environment.siteUrl + '/terms-and-conditions',
    close: '&#x274c;',
    policy: 'Cookie Policy',
    target: '_blank',
  },
  theme: 'classic',
  // type: 'opt-out',
  position: 'bottom'
};
@NgModule({
  declarations: [
    AppComponent,
    BannerHomePageComponent,
    FooterComponent,
    ProductsComponent,
    HeaderComponent,
    LoginComponent,
    MyAccountComponent,
    ProductDetailsComponent,
    UpdateUserComponent,
    UpdatePasswordComponent,
    LogonHelpComponent,
    TestDetailProductComponent,
    DialogDataClaimComponent,
    DialogFilterClaimComponent,
    WhishlistComponent,
    BannerProductComponent,
    BannerCalimsComponent,
    CardsGridComponent,
    ProductCarouselComponent,
    ContactComponent,
    ProductListComponent,
    ClaimSearchComponent,
    TabProductDetailsComponent,
    CustomerSpecificationComponent,
    BreadcrumbComponentComponent,
    CustomClaimSearchComponent,
    MiniCartComponentComponent,
    SiteLogoComponent,
    CustomCartComponent,
    CustomWishListComponent,
    CustomCheckoutComponent,
    CheckoutOrderSummaryComponent,
    ForgotComponent,
    CheckoutOrderConfirmationComponent,
    CustomHeaderLinksComponent,
    HeaderFaqLinkComponent,
    LatestsNewsComponent,
    CustomOrderHistoryComponent,
    CustomOrderDetailsComponent,
    HelpComponent,
    PrivacyComponent,
    Error404Component,
    NamePageComponent,
    CustomResetPasswordComponent,
    ScrollToTopComponent,
    //CustomPdpModule,
    LoaderComponent
  ],
  imports: [
    CarouselModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    NgxPaginationModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    HttpClientModule,
    AppRoutingModule,
    StoreModule.forRoot({}),
    EffectsModule.forRoot([]),
    SpartacusModule,
    I18nModule,
    ConfigModule.withConfig({
      routing: {
        protected: true,
        routes:{
          termsAndConditions: {
            paths: ['terms-and-conditions'],
            protected: false
          },
          logon: {
            paths: ['logonHelp'],
            protected: false
          },
          help: {
              paths: ['help'],
              protected: false
          }
        }
      },
      asm: {
        agentSessionTimer: {
          startingDelayInSeconds: 900
        }
      },
      cmsComponents: {
        /*"infineumHomeBannerFlexComponent": {
          component: BannerHomePageComponent
        },*/
        "InfineumProductBannerComponent": {
          component: BannerProductComponent
        },
        "InfineumClaimsSearchBannerComponent": {
          component: BannerCalimsComponent
        },
        "InfineumCarouselComponents":{
          component: ProductCarouselComponent
        },
        "InfineumLatestNewsComponents": {
          component: LatestsNewsComponent
        },
        "InfineumVideoPlayerComponent": {
          component: VideoComponent
        },
        "TermsAndConditionsParagraphFlex": {
          component: TermsComponent
        },
        "ReturningCustomerLoginComponent": {
          component: LoginComponent
        },
        "contactUsFlexComponent": {
          component: ContactComponent
        },
        "InfineumCategoryGridBoxes": {
          component: CardsGridComponent
        },
        "UpdateProfileComponent": {
          component: UpdateUserComponent
        },
        "UpdatePasswordComponent": {
          component: UpdatePasswordComponent
        },
        "CMSTabParagraphContainer": {
          component: TabProductDetailsComponent
        },
        // "ClaimsListFlexComponent": {
        //   component: ClaimSearchComponent
        // },
        "tempflex": {
          component: TestDetailProductComponent
        },
        OrderConfirmationRequestSentComponent: {
          component: CheckoutOrderConfirmationComponent
        },
        "LogonHelpComponent": {
          component: LogonHelpComponent
        },
        SearchResultsListComponent :{
          component:ProductListComponent
        },
        CMSProductListComponent :{
          component:ProductListComponent
        },
        BreadcrumbComponent : {
          component:BreadcrumbComponentComponent
        },

        MiniCartComponent : {
        component:MiniCartComponentComponent
        }
        ,
        SimpleBannerComponent : {
        component:SiteLogoComponent
        },
        ClaimsListFlexComponent : {
          component:CustomClaimSearchComponent
        },
        CartComponent : {
          component:CustomCartComponent
        },
        // CMSParagraphComponent : {
        //   component:ContactComponent
        // },
        faqTextParagraph : {
        component:ContactComponent
        },
        InfineumWishlistFlexComponent : {
          component:CustomWishListComponent
        },
        CheckoutOrderDeliveryFlexComponent : {
          component:CustomCheckoutComponent
        },
        CheckoutOrderBodyFlexComponent : {
          component:CheckoutOrderSummaryComponent
        },
        "ForgotPasswordComponent": {
          component: ForgotComponent
        },
        NavigationComponent: {
          component: CustomHeaderLinksComponent
        },
        CMSLinkComponent: {
          component: HeaderFaqLinkComponent
        },
        CMSSiteContextComponent: {
          //component: HeaderFaqLinkComponent
        },
        // FooterNavigationComponent:{
        //   component: FooterComponent
        // },
        AccountOrderHistoryComponent: {
          component: CustomOrderHistoryComponent
        },
        AccountOrderDetailsOverviewComponent: {
          component: CustomOrderDetailsComponent
        },
        HelpPageFlexComponent: {
            component: HelpComponent
        },
        TermsFlexComponent: {
            component: PrivacyComponent
        },
        SimpleResponsiveBannerComponent:{
          component: Error404Component
        },
        NamePageFlexComponent:{
          component: NamePageComponent
        },
        ContactUsFlexComponent: {
          component: ContactComponent
        },
        ResetPasswordComponent: {
          // component: UpdatePasswordComponent
          component: CustomResetPasswordComponent
        },
        CMSParagraphComponent: {
         component: CustomCartComponent
        },
      },
    }),
    BrowserAnimationsModule,
    MatDialogModule,
    MatCardModule,
    CustomPdpModule,
    // CustomProductListModule,
    // CustomProductSearchModule,
    RouterModule,
    NgcCookieConsentModule.forRoot(cookieConfig)
  ],
  exports: [NgxPaginationModule],
  providers: [
    {
      provide: GlobalMessageService,
      useClass: CustomGlobalMessageService,
    },
    ClaimsearchService, {provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true }, 
    LoaderService,{provide: HTTP_INTERCEPTORS,useClass: LoaderInterceptor,multi: true}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }