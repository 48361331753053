import { HttpClient } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AsmService } from "@spartacus/asm/core";
import { AsmAuthService, AsmAuthStorageService, CsAgentAuthService } from '@spartacus/asm/root';
// TODO:Spartacus - Many methods from 'UserService' were removed, for more details please look into 4.0 migration documentation.
// TODO:Spartacus - UserActions - Following actions 'ForgotPasswordEmailRequestAction', 'ResetPasswordAction', 'EmailActions', 'UpdatePasswordAction', 'UserDetailsAction' were removed. Logic was moved to '@spartacus/user'.
import { AuthService as AuthServiceSpartacus, AuthStorageService, ClientTokenService, StateWithProcess, StateWithUser,  UserIdService } from '@spartacus/core';
import { take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

// import { CsAgentAuthService } from '@spartacus/asm/root/services/csagent-auth.service';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  API_ENDPOINT = environment;
  isAsm: any = "";
  asmUserId: any = "";
  asmToken: any = "";
  userId: any = "";

  constructor(
    public authServiceSpartacus: AuthServiceSpartacus,
    public csAgentAuthService: CsAgentAuthService,
    public asmService: AsmService,
    public userService: UserIdService,
    public userIdService: UserIdService,
    public clientTokenService: ClientTokenService,
    public authStorageService: AuthStorageService,
    public asmAuthService: AsmAuthService,
    public asmAuthStorageService: AsmAuthStorageService,
    // private userServiceService : UserServiceService,
    public store: Store<StateWithUser | StateWithProcess<void>>,
    private router: Router,
    private ngZone: NgZone,
    private http: HttpClient
  ) { }


  getApiUrl() {
    return this.API_ENDPOINT.baseUrl + this.API_ENDPOINT.hybrisApiURL;
  }

  getApiDomainUrl() {
    return this.API_ENDPOINT.baseUrl;
  }

  setAsmMode(isAsm) {
    this.isAsm = isAsm;
  }

  getAsmMode() {
    return this.isAsm;
  }

  getUserId(): any {
    // this.userServiceService.getUserDetails().then((user) => {
    //   this.userId = user["uid"];
    //   return this.userId;
    // })
    this.userId = localStorage.getItem('userEmail');
    return this.userId;
  }

  getUserFromAsm() {

    this.csAgentAuthService.isCustomerSupportAgentLoggedIn().subscribe(isCustomerSupportAgentLoggedIn => {

      if (isCustomerSupportAgentLoggedIn) {
        this.isAsm = "true";
        this.csAgentAuthService.isCustomerEmulated().subscribe(isLoggedIn => {
          console.log("isCustomerEmulated::::::::::::::::::::::::::", isLoggedIn);
          if (isLoggedIn) {
            this.isAsm = "true";
            for (let element of window.location.pathname.split("/")) {
              if (element == 'login') {
                // this.router.navigate(['/']);
              }
            }
            // this.ngZone.run(() => this.router.navigateByUrl('/?asm=true').then());
            this.getClientToken();
          } else {
            this.isAsm = "false";
          }
        });
      } else {
        console.log("out side of customer support agent:::::::::");
        this.isAsm = "false";
      }
    });

  }

  getClientToken() {
    this.clientTokenService.getClientToken().pipe(take(1)).subscribe((token) => {
      // this.authStorageService.getToken().pipe(take(1)).subscribe(token => {
      //   this.asmAuthStorageService.setEmulatedUserToken(token);
      //   let csAgentToken = this.asmAuthStorageService.getEmulatedUserToken();
      // console.log("csAgentToken::::::::::::",csAgentToken,csAgentToken)
      console.log("asm emulated user token::::::::::::::::::::::::::::::", token);
      this.asmToken = token;
      this.userIdService.getUserId().pipe(take(1)).subscribe(userId => {
        console.log("asm user id::::::::::::::", userId);
        this.asmUserId = userId;
        // this.store.dispatch(new UserActions.LoadUserDetails(userId));
        //   this.userServiceService.getUserDetails().then((user) => {
        //     localStorage.setItem('userEmail', user["uid"]);
        // });
        // this.userIdService.takeUserId(true).subscribe((user) => {
        //   console.log("user in asm call::::::::::::::::", user);
        // })
        this.http
          .get(this.getApiUrl() + `/orgUsers/${this.asmUserId}`)
          .subscribe((response) => {
            localStorage.setItem('userEmail', response["uid"]);

          }, (error) => {
            console.log("error::::::::::;", error)
          });
      })
    })
  }
}
