import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslationService } from '@spartacus/core';
import { Observable } from 'rxjs';

import { ProductDetailsService } from '../APIcall/product-details.service';
import { AppService } from '../app.services';

@Component({
  selector: 'app-breadcrumb-component',
  templateUrl: './breadcrumb-component.component.html',
  styleUrls: ['./breadcrumb-component.component.scss']
})
export class BreadcrumbComponentComponent implements OnInit {

  categoryImage: any = '';
  categoryName: any = '';
  productName: any = '';
  searchType: any = '';
  searchCategoryName: any = '';
  categoryUrl: any = '';
  breadcumTitle: any = '';
  claimSearchText: string = '';
  claimSearchText$: Observable<string> = this.translation.translate('customBreadcrumb.claimsSearch')
  constructor(private translation: TranslationService, private productDetailsService: ProductDetailsService, private ref: ChangeDetectorRef, private router: Router, private appService: AppService) { }

  ngOnInit(): void {
    this.claimSearchText$.subscribe(trans => {
      this.claimSearchText = trans
    })
    this.productDetailsService.currentBreadcrumb.subscribe((data) => {
      this.categoryImage = data && data['categoryImage'] && this.appService.getApiDomainUrl() + data['categoryImage'];
      this.categoryName = data && data['categoryName'];
      this.productName = data && data['productName'];
      this.searchType = data && data['searchType'];
      this.categoryUrl = data && data['categoryUrl'];
      this.breadcumTitle = data && data['breadcumTitle'];;
      console.log("breadcumTitle::::::::::::::::::",this.breadcumTitle)
      this.ref.detectChanges(); 
    })

    this.breadcumTitle = "";
    let i = 0;
    for(let element of window.location.pathname.split("/")){
      if(element == 'claimsList'){
        this.breadcumTitle = 'claimsList';
        break;
      }else if(element == 'wishlist'){
        this.breadcumTitle = 'wishlist';
        break;
      }
      i++;
    }
    // this.ref.detectChanges(); 
    console.log("this.breadcumTitle:::::::::::::",this.breadcumTitle)
    
  }

  checkForCategoryOrProduct() {
    let productName: any = "";
    let i = 0;
    for(let element of window.location.pathname.split("/")){
      if(element == 'p'){
        this.searchType = 'PRODUCT';
        productName = window.location.pathname.split("/")[i+1];
        break;
      }else if(element == 'product'){
        this.searchType = 'PRODUCT';
        productName = window.location.pathname.split("/")[i+1];
        break;
      }else if(element == 'c'){
        this.searchType = 'CATEGORY';
        productName = window.location.pathname.split("/")[i+1];
        this.searchCategoryName = productName;
        break;
      }else if(element == 'search'){
        this.searchType = 'SEARCH';
        productName = window.location.pathname.split("/")[i+1];
        break;
      }
      i++;
    }
  }

  naviateToHomePage() {
    this.router.navigate(['/']);
  }

  naviateToCategory() {
    this.router.navigate([this.categoryUrl]);
  }
  naviateToCart() {
    this.router.navigate(['/cart']);
  }
  naviateToOrderHistory() {
    this.router.navigate(['/my-account/orders']);
  }

}
