<!-- <div class="container mw-100">
    <div class="row">
        <div class="col-12 banner">
            <div class="m-auto w-100">
                <h1 class="title">{{ title }}</h1>
            </div>
        </div>
    </div>
</div> -->

<div class="container">
    <div class="page page-help">
        <div class="row">
            <div class="col-12">
                <div class="page-content">
                    <!-- CONTENT -->
                    <h2>{{'customTermCondition.legalNotice' | cxTranslate}}</h2>
                    <p>{{'customTermCondition.para1' | cxTranslate}}</p>
                    <p>{{'customTermCondition.para2' | cxTranslate}}</p>
                    <!-- PRIVACY POLICY -->
                    <h2>{{'customTermCondition.privacyPolicy' | cxTranslate}}</h2>
                    <p>{{'customTermCondition.para3' | cxTranslate}}</p>
                    <p>{{'customTermCondition.para4' | cxTranslate}}</p>
                    <ul>
                        <li>{{'customTermCondition.toProvideAService' | cxTranslate}}</li>
                    </ul>
                    <p>{{'customTermCondition.para5' | cxTranslate}}</p>
                    <ul>
                        <li>{{'customTermCondition.tomeetOurBusiness' | cxTranslate}}</li>
                    </ul>
                    <p>{{'customTermCondition.para6' | cxTranslate}}</p>
                    <p>{{'customTermCondition.para7' | cxTranslate}}</p>
                    <hr>
                    <h3>{{'customTermCondition.sharingPersonalInfo' | cxTranslate}}</h3>
                    <p>{{'customTermCondition.para8' | cxTranslate}}</p>
                    <p>{{'customTermCondition.para9' | cxTranslate}}</p>
                    <hr>
                    <!-- <h3>Cookies</h3>
                    <p>We use cookies on this Site. Please see our Cookie Policy for further details.</p>
                    <hr> -->
                    <h3>{{'customTermCondition.dataIntigrity' | cxTranslate}}</h3>
                    <p>{{'customTermCondition.para10' | cxTranslate}}</p>
                    <hr>
                    <h3>{{'customTermCondition.linksToOthers' | cxTranslate}}</h3>
                    <p>{{'customTermCondition.para11' | cxTranslate}}</p>
                    <hr>
                    <h3>{{'customTermCondition.yourRights' | cxTranslate}}</h3>
                    <p>{{'customTermCondition.para12' | cxTranslate}}</p>
                    <hr>
                    <h3>{{'customTermCondition.retentionPersonalInfo' | cxTranslate}}</h3>
                    <p>{{'customTermCondition.para13' | cxTranslate}}</p>
                    <hr>
                    <h3>{{'customTermCondition.changeToThis' | cxTranslate}}</h3>
                    <p>{{'customTermCondition.para14' | cxTranslate}}</p>
                    <hr>
                    <h3>{{'customTermCondition.contactUs' | cxTranslate}}</h3>
                    <p>{{'customTermCondition.para15' | cxTranslate}}<a href="mailto:UKHR@Infineum.com">{{'customTermCondition.ukhrInfo' | cxTranslate}}</a>{{'customTermCondition.pullStop' | cxTranslate}}</p>
                    <p>{{'customTermCondition.para16' | cxTranslate}}</p>
                    <p>{{'customTermCondition.infineumInternational' | cxTranslate}}<br>
                        {{'customTermCondition.miltonHillBu' | cxTranslate}}<br>
                        {{'customTermCondition.poIngland' | cxTranslate}}<br>
                        {{'customTermCondition.registerInEng' | cxTranslate}}<br>
                        {{'customTermCondition.telNo' | cxTranslate}}
                    </p>
                    <!-- COOKIES -->
                    <h2>{{'customTermCondition.cookis' | cxTranslate}}</h2>
                    <h3>{{'customTermCondition.yourPrivacyInfineum' | cxTranslate}}</h3>
                    <p>{{'customTermCondition.para17' | cxTranslate}} </p>
                    <hr>
                    <h3>{{'customTermCondition.whatIsCookies' | cxTranslate}}</h3>
                    <p>{{'customTermCondition.para18' | cxTranslate}}</p>
                    <hr>
                    <h3>{{'customTermCondition.serviceThatNeed' | cxTranslate}}</h3>
                    <p>{{'customTermCondition.para19' | cxTranslate}}</p>
                    <hr>
                    <h3>{{'customTermCondition.controllingWhatCookies' | cxTranslate}}</h3>
                    <p>{{'customTermCondition.youCanSet' | cxTranslate}}</p>
                    <ul>
                        <li>{{'customTermCondition.acceptAllCookies' | cxTranslate}}</li>
                        <li>{{'customTermCondition.tellYouWhen' | cxTranslate}}</li>
                        <li>{{'customTermCondition.notRecieveCookies' | cxTranslate}}</li>
                    </ul>
                    <p>{{'customTermCondition.para20' | cxTranslate}}</p>
                    <hr>
                    <h3>{{'customTermCondition.rejectingCookies' | cxTranslate}}</h3>
                    <p>{{'customTermCondition.ifYouWantToRestrict' | cxTranslate}}</p>
                    <hr>
                    <h3>{{'customTermCondition.mobilePhones' | cxTranslate}}</h3>
                    <p>{{'customTermCondition.forInformation' | cxTranslate}}</p>
                    <hr>
                    <h3>{{'customTermCondition.necessaryCookies' | cxTranslate}}</h3>
                    <p>{{'customTermCondition.para21' | cxTranslate}}</p>
                    <hr>
                    <h3>{{'customTermCondition.analyticCookies' | cxTranslate}}</h3>
                    <p>{{'customTermCondition.para22' | cxTranslate}}</p>
                    <p>{{'customTermCondition.para23' | cxTranslate}}</p>
                    <p>{{'customTermCondition.theSpecific' | cxTranslate}}</p>
                    <table class="table">
                      <thead class="thead-light">
                        <tr>
                          <th scope="col" width="20%">{{'customTermCondition.cookiesFor' | cxTranslate}}</th>
                          <th scope="col">{{'customTermCondition.name' | cxTranslate}}</th>
                          <th scope="col" width="60%">{{'customTermCondition.purpose' | cxTranslate}}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th scope="row">{{'customTermCondition.googleAnalytic' | cxTranslate}}</th>
                          <td class="align-top">_gat_UA-11394374-1
                            _dc_gtm_UA-11394374-1
                            _ga
                            _gaexp
                            _gid
                          </td>
                          <td>{{'customTermCondition.para24' | cxTranslate}}</td>
                        </tr>
                      </tbody>
                    </table>
                    <hr>
                    <h3>{{'customTermCondition.functional' | cxTranslate}}</h3>
                    <p>{{'customTermCondition.para25' | cxTranslate}}</p>
                    <p>{{'customTermCondition.noneReportedCoo' | cxTranslate}}</p>
                    <hr>
                    <h3>{{'customTermCondition.thirdParty' | cxTranslate}}</h3>
                    <p>{{'customTermCondition.para26' | cxTranslate}}</p>
                    <p>
                        <ul>
                            <li>{{'customTermCondition.para27' | cxTranslate}} <a href="https://vimeo.com/cookie_policy" target="_blank">{{'customTermCondition.httpsPolicy' | cxTranslate}}</a></li>
                        </ul>
                    <p>
                    <!-- <p>{{'customTermCondition.disclaimerInTerm' | cxTranslate}}</p> -->
                <!-- <button type="submit" (click)="backClicked()" class="btn btn-primary btn-back mt-5">Back</button> -->
                </div>
            </div>
        </div>
    </div><!-- .page -->
</div>