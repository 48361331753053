import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UserServiceService } from '../APIcall/user-service.service';
import { AuthService } from '@spartacus/core';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
declare var $: any;
@Component({
  selector: 'app-site-logo',
  templateUrl: './site-logo.component.html',
  styleUrls: ['./site-logo.component.scss']
})
export class SiteLogoComponent implements OnInit {
  isUser: boolean = false;
  subscription: Subscription;
  constructor(private userService: UserServiceService, private ref: ChangeDetectorRef, private authService:AuthService, private router: Router) { }

  ngOnInit(): void {
    // this.userService.getUserDetails().then((user)=>{
    //   console.log("side logo ::::::::::::", user['uid'])
    //   if(user['uid']){
    //     this.isUser = true;
    //     this.ref.detectChanges();
    //   }else{
    //     this.isUser = false;
    //     this.ref.detectChanges();
    //   }
    // })
    this.subscription = this.authService.isUserLoggedIn().subscribe((isLoggedIn) => {
      console.log("isLoggedIn::::::::",isLoggedIn)
      this.isUser = isLoggedIn;
      this.ref.detectChanges();
      if(isLoggedIn) {
        for(let element of window.location.pathname.split("/")){
          if(element == 'login'){
            console.log("3333333333333333333")
            // this.router.navigate(['/']);
          }
        }
        // this.router.navigate(['/']);
      } else {
        if(window.location.pathname.indexOf('/login/pw/change') == -1) {
          this.router.navigate(['/login']);
        }
        // this.router.navigate(['/login']);
      }
      // if(this.isUser){
      //   $("cx-page-slot > cx-login").removeClass("hideLogin");
      //   $("cx-page-slot > cx-login").addClass("showLogin");
      // }else{
      //   $("cx-page-slot > cx-login").addClass("hideLogin");
      //   $("cx-page-slot > cx-login").removeClass("showLogin");
      // }
    })
  }

  navigateHome() {
    this.router.navigate(['/']);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
